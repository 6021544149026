import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {generateUuid, Question} from 'src/app/domain/question';
import { QUESTION_TYPES } from 'src/app/shared/question-type'
import { Router } from '@angular/router';
import { ConfirmatoryActionService } from 'src/app/confirmatory-action/confirmatory-action.service';
import {getQuestionByUuid, Questionnaire, removeQuestionByUuid} from 'src/app/domain/questionnaire';

declare var draggable: any;

@Component({
  selector: 'app-questions-list',
  templateUrl: './questions-list.component.html',
  styleUrls: ['./questions-list.component.scss']
})
export class QuestionsListComponent implements OnInit {

  @Input()
  set addQuestion(addQuestion: boolean) {
    if (addQuestion) {
      this.addNewQuestion();
    }
  }
  @Input() questionnaire: Questionnaire;
  @Output() addQuestionChange = new EventEmitter<boolean>(true);
  @Output() questionnaireChange = new EventEmitter<{questionnaire: Questionnaire, changedField: string}>();

  newQuestions: Question[];

  public isQuestionListLoading: boolean = true;

  readonly QUESTION_TYPES: any[] = QUESTION_TYPES;

  private dragIndex: number;

  constructor(private router: Router,
              private confirmatoryActionService: ConfirmatoryActionService) { }

  ngOnInit(): void {
    this.isQuestionListLoading = false;
  }

  onNewQuestionBlur(newQuestion: Question, newQuestionIndex: number) {
    if (newQuestion) {
      if (newQuestion.type != null && newQuestion.displayName != null && newQuestion.displayName.length > 0) {
        this.questionnaire.question.push(newQuestion);
        this.newQuestions.splice(newQuestionIndex, 1);
        this.questionnaireChange.emit({questionnaire: this.questionnaire, changedField: 'New question'});
      }
    }
  }

  deleteQuestion(uuid: string) {
    let dependentQuestions: Question[] = this.getDependentQuestions(uuid);
    let confirmDeleteMessage: string = 'Are you sure you want to delete this question?';
    if (dependentQuestions.length > 0) {
      confirmDeleteMessage += ' Questions depending on this question will be deactivated.';
    }
    this.confirmatoryActionService.confirm("Confirmation", confirmDeleteMessage, "Yes", "No", "sm")
      .then((confirmed) => {
        if (confirmed) {
          for (let dependentQuestion of dependentQuestions) {
            dependentQuestion.questionActiveInd = false;
            delete dependentQuestion.prerequisiteAnswer;
            delete dependentQuestion.prerequisiteQuestionUuid;
          }
          removeQuestionByUuid(this.questionnaire, uuid);
          this.questionnaireChange.emit({questionnaire: this.questionnaire, changedField: 'Questions list'});
        }
      })
      .catch(() => {});
  }

  deleteNewQuestion(questionIndex: number) {
    this.newQuestions.splice(questionIndex, 1);
  }

  allowDrop(ev: DragEvent) {
    ev.preventDefault();
  }

  drag(dragIndex: number) {
    this.dragIndex = dragIndex;
  }

  drop(dropIndex: number) {
    if (this.questionnaire.question == null || this.questionnaire.question.length < 2 || this.dragIndex == dropIndex) {
      return;
    }

    if (this.questionnaire.question.length == 2) {
      let dragQuestion: Question = this.questionnaire.question.splice(0, 1)[0];
      this.questionnaire.question.push(dragQuestion);
    } else if (this.questionnaire.question.length > 2) {
      if (dropIndex > this.dragIndex) {
        if (dropIndex === this.questionnaire.question.length - 1) {
          let dragQuestion: Question = this.questionnaire.question.splice(this.dragIndex, 1)[0];
          this.questionnaire.question.push(dragQuestion);
        } else {
          let dragQuestion: Question = this.questionnaire.question.splice(this.dragIndex, 1)[0];
          this.questionnaire.question.splice(dropIndex, 0, dragQuestion);
        }
      } else if (dropIndex < this.dragIndex) {
        let dragQuestion: Question = this.questionnaire.question.splice(this.dragIndex, 1)[0];
        this.questionnaire.question.splice(dropIndex, 0, dragQuestion);
      }
    }
    this.questionnaireChange.emit({questionnaire: this.questionnaire, changedField: "Question position"});
  }

  onChange(field: string) {
    this.questionnaireChange.emit({ questionnaire: this.questionnaire, changedField: field});
  }

  addNewQuestion() {
    if (!this.newQuestions) {
      this.newQuestions = [];
    }
    let newQuestion: Question = {
      type: null,
      displayName: null,
      questionActiveInd: false,
      required: false,
      answers: [],
      uuid: generateUuid()
    };
    this.newQuestions.push(newQuestion);
    this.addQuestionChange.emit(false);
  }

  onActiveChange(question: Question, field: string) {
    if (!question.questionActiveInd) {
      let dependentQuestions: Question[] = this.getDependentQuestions(question.uuid);
      if (dependentQuestions.length > 0) {
        this.confirmatoryActionService.confirm(
            "Confirmation",
            "Deactivating this question will also deactivate all questions that are dependent on this question. Do you want to continue?",
            "Yes",
            "No",
            "sm")
          .then((confirmed) => {
            if (confirmed) {
              for (let dependentQuestion of dependentQuestions) {
                this.questionnaire.question[this.questionnaire.question.indexOf(dependentQuestion)].questionActiveInd = false;
              }
              this.onChange(field);
            } else {
              question.questionActiveInd = true;
            }
          }, () => {
            question.questionActiveInd = true;
          })
          .catch(() => {});
      } else {
        this.onChange(field);
      }
    } else {
      this.onChange(field);
    }
  }

  getDependentQuestions(uuid: string): Question[] {
    return this.questionnaire.question.filter(q => q.prerequisiteQuestionUuid === uuid)
  }

  getActiveTitle(question: Question): string {
    if (question.type === 'Question' && (!question.answers || question.answers.length == 0)) {
      return 'There must be at least one answer.';
    } else if (question.type === 'Finding' && (!question.answers || question.answers.length == 0)) {
      return 'A Finding must be selected.'
    } else if (question.prerequisiteQuestionUuid && !getQuestionByUuid(this.questionnaire, question.prerequisiteQuestionUuid).questionActiveInd) {
      return 'The prerequisite question is not active.';
    } else {
      return 'Question Active Indicator';
    }
  }

  isQuestionDisabled(question: Question): boolean {
    if ((question.prerequisiteQuestionUuid && !getQuestionByUuid(this.questionnaire, question.prerequisiteQuestionUuid).questionActiveInd)
        || ((question.type === 'Question' || question.type === 'Finding') && question.answers.length == 0)) {
      return true;
    } else {
      return false;
    }
  }

}
