import { Injectable } from '@angular/core';
import { Observable, timer, throwError } from 'rxjs';
import { WaterSystem } from '../domain/water-system';
import { Checkout } from '../domain/checkout';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { concatMap, filter, map, take, tap, catchError, takeUntil } from 'rxjs/operators';
import { SyncSchedulerService } from './sync-scheduler.service';
import { LocalStorageService } from '../local-storage.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SyncResultFactory } from '../utils/sync-result-factory';
import { tables } from '../utils/app-db';
import { SyncResult, SyncType } from '../domain/sync';
import {BasicDetailsService} from './basic-details/basic-details.service';

@Injectable({
  providedIn: 'root'
})
export class WaterSystemService {

  private readonly WATER_SYSTEM_URL = environment.serverUrl + '/watersystem';

  constructor(private http: HttpClient,
              private connectivityService: ConnectivityService,
              private localStorageService: LocalStorageService,
              private syncScheduler: SyncSchedulerService,
              private basicDetailsService: BasicDetailsService) { }

  /**
   * Returns water system saved locally
   * If none found, then will be check-out remotely if online
   * @param id of the water system
   */
  find(id: number): Observable<Checkout<WaterSystem>> {
    return new Observable<Checkout<WaterSystem>>(obs => {
      this.findLocally(id).subscribe(wsLocal => {
        if (wsLocal) {
          obs.next(wsLocal);
          obs.complete();
        } else {
          if (this.connectivityService.isOnline()) {
            this.findRemotelyTimer(id).subscribe (wsRemote => {
              obs.next(wsRemote);
              obs.complete();
            }, error => {
              obs.error(error);
            });
          } else {
            obs.complete();
          }
        }
      });
    });
  }

  findLocally(id: number): Observable<Checkout<WaterSystem>> {
    return this.localStorageService.dbRetrieve<Checkout<WaterSystem>>(tables.waterSystem, id);
  }

  findRemotely(id: number): Observable<Checkout<WaterSystem>> {
    return this.http.get<Checkout<WaterSystem>>(`${this.WATER_SYSTEM_URL}/${id}`);
  }

  private findRemotelyTimer(id: number): Observable<Checkout<WaterSystem>> {
    const stopTimer$ = timer(10 * 60 * 1000);
    return timer(0, 15000)
      .pipe(
        takeUntil(stopTimer$),
        concatMap(() => this.findRemotely(id).pipe(map(response => response))),
        filter(backendData => backendData.dataReady),
        take(1),
        tap(found => {
          this.store(id, found);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  /**
   * Saves the water system locally
   * If online, then also saved to remote service, otherwise save is scheduled
   * @param id of the water system
   * @param waterSystem the water system
   */
  save(id: number, waterSystem: Checkout<WaterSystem>): Observable<SyncResult> {
    this.store(id, waterSystem);
    return this.syncScheduler.schedule(SyncType.WaterSystem, id);
  }

  saveRemotely(id: number, waterSystem: WaterSystem): Observable<{}> {
    return this.http.post(this.WATER_SYSTEM_URL, waterSystem);
  }

  private store(id: number, waterSystem: Checkout<WaterSystem>): void {
    this.localStorageService.dbStoreStorage(tables.waterSystem, id, waterSystem);
  }

  status(id: number): Observable<SyncResult> {
    return this.syncScheduler.status(SyncType.WaterSystem, id);
  }

  /**
   * Syncs scheduled water systems if online
   */
  sync(): void {
    if (this.connectivityService.isOnline()) {
      this.syncScheduler.scheduled(SyncType.WaterSystem).subscribe(syncRequests => {
        syncRequests.forEach(syncRequest => {
          this.findLocally(syncRequest.data.id).subscribe(waterSystem => {
            // todo: validations? error handling
            // todo: subscription cleanup
            if (waterSystem) {
              this.saveRemotely(syncRequest.data.id, waterSystem.data).subscribe((result: WaterSystem) => {
                  this.findLocally(result.id).subscribe((local: Checkout<WaterSystem>) => {
                    local.data = result;
                    this.store(result.id, local);
                    this.basicDetailsService.changeWaterSystem(local);
                  });
                  this.syncScheduler.unschedule(SyncType.WaterSystem, syncRequest.data.id,
                    SyncResultFactory.synced(SyncType.WaterSystem, syncRequest.data.id));
                },
                error => this.syncScheduler.unschedule(SyncType.WaterSystem, syncRequest.data.id,
                  SyncResultFactory.errorHttp(SyncType.WaterSystem, syncRequest.data.id, error))
              );
            } else {
              this.syncScheduler.unschedule(SyncType.WaterSystem, syncRequest.data.id,
                SyncResultFactory.synced(SyncType.WaterSystem, syncRequest.data.id));
            }
          });
        });
      });
    }
  }

  deleteLocally(id: number): void {
    this.localStorageService.dbRemove(tables.waterSystem, id);
  }
}
