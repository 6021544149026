<div *ngIf="isQuestionListLoading; else listLoaded">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading Question List...</span>
  </div>
  <span>Loading Question List...</span>
</div>
<ng-template #listLoaded>
  <div class="table-responsive">
    <table class="table table-bordered table-hover table-sm">
      <thead class="thead-light">
        <tr class="d-flex">
          <th scope="col" class="col-3">Type</th>
          <th scope="col" class="col-6">Display Name</th>
          <th scope="col" class="col-1">Active</th>
          <th scope="col" class="col-2">Actions</th>
        </tr>
      </thead>
      <tbody *ngIf="(questionnaire.question && questionnaire.question.length > 0) || (newQuestions && newQuestions.length > 0)">
        <tr *ngFor="let question of questionnaire.question; let i = index" class="d-flex" draggable="true" (dragstart)="drag(i)" (drop)="drop(i)" (dragover)="allowDrop($event)">
          <td class="col-3">{{ question?.type }}</td>
          <td class="col-6">{{ question?.displayName }}</td>
          <td class="col-1">
            <div class="form-check">
              <input type="checkbox" [(ngModel)]="question.questionActiveInd" (change)="onActiveChange(question, $event.target.title)"
                     [attr.title]="getActiveTitle(question)"
                     [disabled]="isQuestionDisabled(question)"
                     id="{{ 'question-active-' + i }}"
                     class="form-check-input">
              <label class="form-check-label sr-only" for="{{ 'question-active-' + i }}">Active</label>
            </div>
          </td>
          <td class="col-2">
            <button type="button" *ngIf="questionnaire?.id" class="btn btn-outline-dark btn-sm py-0"
                    [routerLink]="['question', question.uuid]" routerLinkActive="active"
                    [state]="{ data : questionnaire, uuid: question.uuid }"
                    attr.aria-label="Edit {{ question.type }} {{ question.displayName }}">
              Edit
            </button>
            <button type="button" class="btn btn-outline-dark btn-sm py-0 ml-4"
                    (click)="deleteQuestion(question.uuid)"
                    attr.aria-label="Delete {{ question.type }} {{ question.displayName }}">
              Delete
            </button>
          </td>
        </tr>
        <tr *ngFor="let newQuestion of newQuestions; let i = index" class="d-flex">
          <td class="col-3">
            <div class="form-group my-0">
              <label for="{{ 'add-question-type-' + i }}" [hidden]="true">Question Type:*</label>
              <select id="{{ 'add-question-type-' + i }}" name="type" [(ngModel)]="newQuestion.type"
                      (change)="onNewQuestionBlur(newQuestion, i)" required="true"
                      class="form-control">
                <option *ngFor="let type of QUESTION_TYPES" value="{{type}}">{{type}}</option>
              </select>
            </div>
          </td>
          <td class="col-6">
            <div class="form-group my-0">
              <label for="{{ 'add-question-display-name-' + i }}" [hidden]="true">Question Display Name:*</label>
              <input type="text" id="{{ 'add-question-display-name-' + i }}" name="display-name"
                     [(ngModel)]="newQuestion.displayName" required="true"
                     (change)="onNewQuestionBlur(newQuestion, i)" class="form-control"
                     maxlength="200">
            </div>
          </td>
          <td class="col-1">
            <div class="form-check">
              <input type="checkbox" id="{{ 'add-question-active-' + i }}" name="active" [(ngModel)]="newQuestion.questionActiveInd" disabled class="form-check-input">
              <label class="form-check-label sr-only" for="{{ 'add-question-active-' + i }}">Active</label>
            </div>
          </td>
          <td class="col-2"><button type="button" class="btn btn-outline-dark btn-sm py-0" (click)="deleteNewQuestion(i)" attr.aria-label="Delete new question {{ i + 1 }}">Delete</button></td>
        </tr>
      </tbody>
      <tbody *ngIf="(questionnaire.question == null || questionnaire.question.length == 0) && (newQuestions == null || newQuestions.length == 0)">
        <tr>
          <td colspan="4">
            No Questions Found.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
