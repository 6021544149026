import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SiteVisitsService } from '../site-visits.service';
import { Unsubscribable } from 'rxjs';
import { FindingCollection } from '../../../domain/inspection';
import { PotentialContaminantsListService } from '../../potential-contaminants-list.service';
import { DocumentService } from '../../../document.service';
import { FacilityAny } from '../../../domain/facility';
import { PotentialContaminantType } from '../../../domain/potential-contaminant';
import { Visit } from '../../../domain/visit';
import { ContactAction } from '../../../domain/contact';

@Component({
  selector: 'app-site-visit-summary',
  templateUrl: './site-visit-summary.component.html',
  styleUrls: ['./site-visit-summary.component.scss']
})
export class SiteVisitSummaryComponent implements OnInit, OnDestroy {

  isSiteVisitsLoading: boolean;
  lastVisit: Visit;

  @Input()
  isCollapsed = true;

  deficiencies: FindingCollection[];
  public potentialContaminantsList: PotentialContaminantType[];

  isContactsDiff: boolean = false;

  private visits$: Unsubscribable;
  private potentialContaminantsList$: Unsubscribable;

  constructor(private siteVisitsService: SiteVisitsService,
              private potentialContaminantsListService: PotentialContaminantsListService,
              private documentService: DocumentService) { }

  ngOnInit(): void {
    this.isSiteVisitsLoading = true;
    this.visits$ = this.siteVisitsService.visits$.subscribe(siteVisits => {
      this.findLastVisit(siteVisits);
      this.findDeficiences();
      this.hasContactsDiff();
      this.isSiteVisitsLoading = false;
    });
    this.potentialContaminantsList$ = this.potentialContaminantsListService.getPotentialContaminantsList().subscribe(
      result => {
        this.potentialContaminantsList = result;
      }
    );
  }

  ngOnDestroy() {
    if (this.visits$ != null) {
      this.visits$.unsubscribe();
    }
    if (this.potentialContaminantsList$ != null) {
      this.potentialContaminantsList$.unsubscribe();
    }
  }


  private findLastVisit(siteVisits: Visit[]): void {
    if (siteVisits && siteVisits.length > 0) {
      const pastVisits = siteVisits.filter(s => s.status.toUpperCase() === 'COMPLETED');
      if (pastVisits && pastVisits.length > 0) {
        this.lastVisit = pastVisits.reduce((a, b) => a.reportCompletedDate > b.reportCompletedDate ? a : b);
      }
    }
    /* Disabled 6/27/2024
    if (this.lastVisit && this.lastVisit.inspection && this.lastVisit.inspection.reportPath) {
      this.documentService.getDownloadUrl(this.lastVisit.inspection.reportPath).subscribe(url => {
        this.lastVisit.inspection.reportUrl = url.s3SecureUrl;
      });
    }
    */
    if (this.siteVisitsService.isInitialized()) {
      this.isSiteVisitsLoading = false;
    }
  }

  private findDeficiences(): void {
    if (this.lastVisit && this.lastVisit.inspection
      && this.lastVisit.inspection.findingTypeCollection) {
        this.deficiencies = this.lastVisit.inspection.findingTypeCollection.findingCollections
          .filter(fc => 'Significant Deficiencies' === fc.title
            || 'Deficiencies' === fc.title);
    }
  }

  allFacilities(): FacilityAny[] {
    return this.lastVisit.inspection.facilities.concat(this.lastVisit.inspection.addedFacilities);
  }

  hasContactsDiff() {
    if (this.lastVisit && this.lastVisit.inspection && this.lastVisit.inspection.contacts) {
      for (const contact of this.lastVisit.inspection.contacts) {
        if (contact.diff) {
          this.isContactsDiff = true;
          return;
        }
      }
    }
    if (this.lastVisit && this.lastVisit.inspection && this.lastVisit.inspection.waterOperators) {
      for (const waterOperator of this.lastVisit.inspection.waterOperators) {
        if (waterOperator.action && waterOperator.action === ContactAction.Create) {
          this.isContactsDiff = true;
          return;
        }
      }
    }
  }
}
