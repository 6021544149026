export interface Attachment {
  key: string;
  pwsId: number;
  visitId: number;
  questionnaireId: string;
  questionUuid: string;
  filename: string;
  path: string;
  file?: File;
  action?: 'add' | 'delete';
}

export function makeAttachment(key: string, pwsId: number, visitId: number, questionnaireId: string, questionUuid: string,
                     filename: string): Attachment {
  return {
    key: key,
    pwsId: pwsId,
    visitId: visitId,
    questionnaireId: questionnaireId,
    questionUuid: questionUuid,
    filename: filename,
    path: generateAttachmentPath(pwsId, visitId, questionnaireId, questionUuid, filename)
  } as Attachment;
}

export function addAttachmentWithFile(key: string, pwsId: number, visitId: number, questionnaireId: string, questionUuid: string,
                            file: File): Attachment {
  const attachment = makeAttachment(key, pwsId, visitId, questionnaireId, questionUuid, file.name);
  attachment.file = file;
  attachment.action = 'add';
  return attachment;
}

export function copyAttachmentRequired(attachement: Attachment) {
  return {
    key: attachement.key,
    pwsId: attachement.pwsId,
    visitId: attachement.visitId,
    questionnaireId: attachement.questionnaireId,
    questionUuid: attachement.questionUuid,
    filename: attachement.filename,
    path: attachement.path
  } as Attachment;
}

export function generateAttachmentPath(pwsId: number, visitId: number, questionnaireId: string, questionUuid: string,
                                       filename: string): string {
  return `watersystem/${pwsId}/visit/${visitId}/questionnaire/${questionnaireId}/question/${questionUuid}/${filename}`;
}

