import {Pipe, PipeTransform} from '@angular/core';
import {COUNTY_TYPES} from './county-type';

export const WATER_SYSTEM_CLASS_TYPES = [
  {code: 'A', value: 'A'},
  {code: 'B', value: 'B'},
  {code: 'C', value: 'C'},
  {code: 'D', value: 'D'},
  {code: 'E', value: 'E'},
  {code: 'X', value: 'Not applicable'}
];

@Pipe({name: 'waterSystemClassType'})
export class WaterSystemClassTypePipe implements PipeTransform {
  transform(key: string): string {
    const element = WATER_SYSTEM_CLASS_TYPES.find(e => e.code === key);
    if (element) {
      return element.value;
    }
    return key;
  }
}
