import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { ConnectivityService } from 'src/app/connectivity/connectivity.service';
import { IamService } from 'src/app/utils/iam.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  menuHidden = true;
  userDetails: KeycloakProfile;
  isOnline: boolean;
  skipToMainContentLink: string;

  constructor(
    private keycloakService: KeycloakService,
    private connectivityService: ConnectivityService,
    private iamService: IamService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.isOnline = this.connectivityService.isOnline();
    this.connectivityService.online$().subscribe((online => this.isOnline = online));
    this.userDetails = await this.iamService.getUserDetails();
    this.skipToMainContentLink = `${this.router.url}#contents`;
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe( (event: NavigationEnd) => {
      this.skipToMainContentLink = event.urlAfterRedirects.split('#')[0] + '#contents';
    });
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  async doLogout() {
    if (this.isOnline) {
      await this.keycloakService.logout(location.origin);
    }
  }

  get isAdmin() {
    return this.iamService.isUserInRole('admin');
  }

  get isInspector() {
    return this.iamService.isUserInRole('inspector');
  }
}
