import { Component, Input, OnInit } from "@angular/core";
import { FacilityType } from "src/app/domain/inspection";
import { Questionnaire } from "src/app/domain/questionnaire";
import { Visit } from "src/app/domain/visit";

@Component({
  selector: "app-missing-summary",
  templateUrl: "./missing-summary.component.html",
  styleUrls: ["./missing-summary.component.scss"],
})
export class MissingSummaryComponent implements OnInit {
  @Input() siteVisit: Visit;
  @Input() facilityType: FacilityType;
  @Input() waterSystemId: number;
  @Input() siteVisitId: number;
  @Input() isCollapsed = false;
  questionnaire: Questionnaire;
  questionnaireId: string;

  constructor() {}

  ngOnInit(): void {
    if (
      this.siteVisit.inspection.questionnaires &&
      this.siteVisit.inspection.questionnaires[this.facilityType] &&
      this.siteVisit.inspection.questionnaires[this.facilityType].missing &&
      this.siteVisit.inspection.questionnaires[this.facilityType].missing
        .missing
    ) {
      this.questionnaire =
        this.siteVisit.inspection.questionnaires[
          this.facilityType
        ].missing.missing;
    }
  }
}
