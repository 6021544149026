import { Pipe, PipeTransform } from '@angular/core';
import { SURVEY_SUBCATEGORY_TYPES } from './finding-type';

@Pipe({name: 'surveySubcategoryType'})
export class SurveySubcategoryTypePipe implements PipeTransform {
  transform(code: string): string {
    if (!code || code === '' || !(code in SURVEY_SUBCATEGORY_TYPES)) {
      return code;
    } else {
      return SURVEY_SUBCATEGORY_TYPES[code];
    }
  }
}