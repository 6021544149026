import { Component, Input, OnInit } from '@angular/core';
import { WaterOperator } from '../../../../domain/water-operator';

@Component({
  selector: 'app-water-operator-summary',
  templateUrl: './water-operator-summary.component.html'
})
export class WaterOperatorSummaryComponent implements OnInit {

  @Input()
  waterOperators: WaterOperator[];
  @Input()
  header: String;

  constructor() {
  }

  ngOnInit() {
  }
}
