import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicDetailsComponent } from './basic-details/basic-details.component';
import { WaterSystemComponent } from './water-system.component';
import { WaterSystemRoutingModule } from './water-system-routing.module';
import { GeneralComponent } from './basic-details/general/general.component';
import { NgbCollapseModule, NgbPopoverModule, NgbModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceAreaComponent } from './basic-details/service-area/service-area.component';
import { PopulationComponent } from './basic-details/population/population.component';
import { MessageService } from '../message/message.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactsComponent } from './contacts/contacts.component';
import { AddContactComponent } from './contacts/inventory-contacts/add-contact/add-contact.component';
import { EditContactComponent } from './contacts/inventory-contacts/edit-contact/edit-contact.component';
import { ContactInfoFormComponent } from './contacts/inventory-contacts/contact-info-form/contact-info-form.component';
import { SharedModule } from '../shared/shared.module';
import { ContactAddressPipe } from '../shared/contact-address.pipe';
import { PhoneNumberPipe } from '../shared/phone-number.pipe';
import { RoleTypePipe } from '../shared/role-type.pipe';
import { WaterSystemFluorideTypePipe } from '../shared/water-system-fluoride-type.pipe';
import { WaterSystemStatusTypePipe } from '../shared/water-system-status-type.pipe';
import { WaterSystemTypePipe } from '../shared/water-system-type.pipe';
import { ProductionSourceWaterProtectionComponent } from './basic-details/production-source-water-protection/production-source-water-protection.component';
import { WhpProgramStatusTypePipe } from '../shared/whp-program-status-type.pipe';
import { InventoryContactsComponent } from './contacts/inventory-contacts/inventory-contacts.component';
import { AddOperatorComponent } from './contacts/water-operators/add-operator/add-operator.component';
import { WaterOperatorsComponent } from './contacts/water-operators/water-operators.component';
import { OperatorInfoFormComponent } from './contacts/water-operators/operator-info-form/operator-info-form.component';
import { DistrictComplianceOfficerComponent } from './contacts/district-compliance-officer/district-compliance-officer.component'
import { PositionTypePipe } from '../shared/position-type.pipe';
import { WaterSystemNotesComponent } from './water-system-notes/water-system-notes.component';
import { NotesComponent } from './shared/notes/notes.component';
import { AddNoteComponent } from './shared/notes/add-note/add-note.component';
import { EditNoteComponent } from './shared/notes/edit-note/edit-note.component';
import { NoteInfoFormComponent } from './shared/notes/note-info-form/note-info-form.component';
import { ConfirmatoryActionService } from '../confirmatory-action/confirmatory-action.service';
import { SiteVisitsComponent } from './site-visits/site-visits.component';
import { CurrentSiteVisitComponent } from './site-visits/current-site-visit/current-site-visit.component';
import { PastSiteVisitsComponent } from './site-visits/past-site-visits/past-site-visits.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { QuestionnaireListComponent } from './questionnaire-list/questionnaire-list.component';
import { ResponseAdditionalInformationComponent } from './questionnaire/response-additional-information/response-additional-information.component';
import { FacilitiesComponent } from './facilities/facilities.component';
import { FlowVisualizationComponent } from './facilities/flow-visualization/flow-visualization.component';
import { FacilityTypePipe } from '../shared/facility-type.pipe';
import { FacilityStatusPipe } from '../shared/facility-status.pipe';
import { AvailabilityTypePipe } from '../shared/availability-type.pipe';
import { FacilityInfoFormComponent } from './facilities/facility-info-form/facility-info-form.component';
import { AddFacilityComponent } from './facilities/add-facility/add-facility.component';
import { EditFacilityComponent } from './facilities/edit-facility/edit-facility.component';
import { PumpTypePipe } from '../shared/pump-type.pipe';
import { QuestionnaireViewComponent } from './questionnaire-view/questionnaire-view.component';
import { QuestionniareSummaryComponent } from './questionnaire-view/questionniare-summary/questionniare-summary.component';
import { QuestionSummaryComponent } from './questionnaire-view/question-summary/question-summary.component';
import { QuestionAdditionalInformationViewComponent } from './questionnaire-view/question-additional-information-view/question-additional-information-view.component';
import { MissingFacilityComponent } from './missing-facility/missing-facility.component';
import { TreatmentProcessInfoComponent } from './facilities/treatment-process-info/treatment-process-info.component';
import { MissingSummaryComponent } from './questionnaire-view/missing-summary/missing-summary.component';
import { PrimaryRoleDirective } from './contacts/inventory-contacts/contact-info-form/primary-role.directive';
import { WaterSystemSharedModule } from './shared/water-system-shared.module';
import { FaclilityViewComponent } from './facilities/faclility-view/faclility-view.component';
import {
  SiteVisitSummaryComponent
} from './site-visits/site-visit-summary/site-visit-summary.component';
import { FacilityListViewComponent } from './facilities/facility-list-view/facility-list-view.component';
import { VisitSummaryComponent } from './questionnaire-view/visit-summary/visit-summary.component';
import { IwmzComponent } from './wellhead-protection/iwmz.component';
import {
  PotentialContaminantsComponent
} from './wellhead-protection/potential-contaminants/potential-contaminants.component';
import { MeasuresComponent } from './wellhead-protection/measures/measures.component';
import { UnitMeasureCapacityTypePipe } from '../shared/unit-measure-capacity-type-pipe';
import { ReportSummaryComponent } from './site-visits/report-summary/report-summary.component';
import { ReportSummaryViewComponent } from './site-visits/report-summary-view/report-summary-view.component';
import { FacilitiesViewComponent } from './facilities-view/facilities-view.component';
import { ActiveDeficienciesComponent } from './deficiencies/active-deficiencies.component';
import { BasicDetailsSummaryComponent } from './basic-details/basic-details-summary/basic-details-summary.component';
import { GeneralSummaryComponent } from './basic-details/general/general-summary/general-summary.component';
import { PopulationSummaryComponent } from './basic-details/population/population-summary/population-summary.component';
import { ServiceAreaSummaryComponent } from './basic-details/service-area/service-area-summary/service-area-summary.component';
import { ProductionSourceWaterProtectionSummaryComponent } from './basic-details/production-source-water-protection/production-source-water-protection-summary/production-source-water-protection-summary.component';
import { InventoryContactsSummaryComponent } from './contacts/inventory-contacts-summary/inventory-contacts-summary.component';
import { InventoryContactSummaryComponent } from './contacts/inventory-contacts-summary/inventory-contact-summary/inventory-contact-summary.component';
import { ContactsSummaryComponent } from './contacts/contacts-summary/contacts-summary.component';
import { WaterOperatorsSummaryComponent } from './contacts/water-operators-summary/water-operators-summary.component';
import { WaterOperatorSummaryComponent } from './contacts/water-operators-summary/water-operator-summary.component/water-operator-summary.component';

@NgModule({
  declarations: [
    BasicDetailsComponent,
    WaterSystemComponent,
    GeneralComponent,
    ServiceAreaComponent,
    PopulationComponent,
    ContactsComponent,
    AddContactComponent,
    EditContactComponent,
    ContactInfoFormComponent,
    WaterSystemFluorideTypePipe,
    WaterSystemStatusTypePipe,
    WaterSystemTypePipe,
    ProductionSourceWaterProtectionComponent,
    WhpProgramStatusTypePipe,
    RoleTypePipe,
    ContactAddressPipe,
    PhoneNumberPipe,
    InventoryContactsComponent,
    AddOperatorComponent,
    WaterOperatorsComponent,
    OperatorInfoFormComponent,
    DistrictComplianceOfficerComponent,
    PositionTypePipe,
    WaterSystemNotesComponent,
    NotesComponent,
    AddNoteComponent,
    EditNoteComponent,
    NoteInfoFormComponent,
    SiteVisitsComponent,
    CurrentSiteVisitComponent,
    PastSiteVisitsComponent,
    SiteVisitSummaryComponent,
    QuestionnaireComponent,
    QuestionnaireListComponent,
    ResponseAdditionalInformationComponent,
    FacilitiesComponent,
    FlowVisualizationComponent,
    FacilityInfoFormComponent,
    AddFacilityComponent,
    EditFacilityComponent,
    QuestionnaireViewComponent,
    QuestionniareSummaryComponent,
    QuestionSummaryComponent,
    QuestionAdditionalInformationViewComponent,
    MissingFacilityComponent,
    TreatmentProcessInfoComponent,
    MissingSummaryComponent,
    PrimaryRoleDirective,
    FaclilityViewComponent,
    FacilityListViewComponent,
    VisitSummaryComponent,
    IwmzComponent,
    PotentialContaminantsComponent,
    MeasuresComponent,
    ReportSummaryComponent,
    ReportSummaryViewComponent,
    FacilitiesViewComponent,
    ActiveDeficienciesComponent,
    BasicDetailsSummaryComponent,
    GeneralSummaryComponent,
    PopulationSummaryComponent,
    ServiceAreaSummaryComponent,
    ProductionSourceWaterProtectionSummaryComponent,
    InventoryContactsSummaryComponent,
    InventoryContactSummaryComponent,
    ContactsSummaryComponent,
    WaterOperatorsSummaryComponent,
    WaterOperatorSummaryComponent
  ],
  imports: [
    WaterSystemRoutingModule,
    NgbCollapseModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgbPopoverModule,
    NgbProgressbarModule,
    NgbModule,
    WaterSystemSharedModule
  ],
  exports: [
    FaclilityViewComponent
  ],
  providers: [
    MessageService,
    ConfirmatoryActionService,
    FacilityTypePipe,
    FacilityStatusPipe,
    AvailabilityTypePipe,
    PumpTypePipe,
    UnitMeasureCapacityTypePipe
  ]
})
export class WaterSystemModule { }
