import { Injectable, OnDestroy } from '@angular/core';
import { forkJoin, Unsubscribable, Observable } from 'rxjs';
import { DeficiencyService } from '../deficiency/deficiency.service';
import { Finding, Deficiency, Recommendation } from 'src/app/domain/finding';
import { RecommendationService } from '../recommendation/recommendation.service';
import { FINDING_TYPES } from '../finding-type';
import { SanitarySurveySectionTypePipe } from '../sanitary-survey-section-type.pipe';
import { SurveySubcategoryTypePipe } from '../survey-subcategory-type.pipe';

@Injectable({
  providedIn: 'root'
})
export class FindingService implements OnDestroy {

  private findings$: Unsubscribable;

  constructor(private deficiencyService: DeficiencyService,
              private recommendationService: RecommendationService,
              private sanitarySurveySectionTypePipe: SanitarySurveySectionTypePipe,
              private surveySubcategoryTypePipe: SurveySubcategoryTypePipe) { }

  getFindings(): Observable<Finding[]> {
    return new Observable<Finding[]>(obs => {
      const findingsObservable = forkJoin({
        deficiencies: this.deficiencyService.get(),
        recommendations: this.recommendationService.get()
      });
      this.findings$ = findingsObservable.subscribe(
        response => {
          let findings: Finding[] = [];
          let deficiencies: Finding[] = this.mapDeficiencies(response.deficiencies);
          if (deficiencies && deficiencies.length > 0) {
            findings.push.apply(findings, deficiencies);
          }
          let recommendations: Finding[] = this.mapRecommendations(response.recommendations);
          if (recommendations && recommendations.length > 0) {
            findings.push.apply(findings, recommendations);
          }
          obs.next(findings);
          obs.complete();
        }
      )
    });
  }

  async getAdditionalFindings(): Promise<Finding[]> {
    let additionalFindings: Finding[];
    await this.getFindings().toPromise().then(findings => {
      additionalFindings = findings;
    });
    return additionalFindings.filter(f => f.code.startsWith('O') || f.code.startsWith('9'));
  }

  private mapDeficiencies(deficiencies: Deficiency[]): Finding[] {
    let findings: Finding[] = [];
    deficiencies.forEach(deficiency => {
      let description: string;
      if (deficiency.significantInd != null && deficiency.significantInd === 'Y') {
        description = 'Significant Deficiency';
      } else {
        description = 'Deficiency';
      }
      let finding: Finding = {
        type: FINDING_TYPES.DEFICIENCY,
        description: description,
        sanitarySurveySectionCode: deficiency.sanitarySurveySectionCode,
        sanitarySurveySectionDescription: this.sanitarySurveySectionTypePipe.transform(deficiency.sanitarySurveySectionCode),
        beginDate: deficiency.beginDate,
        code: deficiency.deficiencyCode,
        language: deficiency.deficiencyDesc,
        significantIndicator: deficiency.significantInd,
        annualSiteVisitUse: deficiency.annualSiteVisitUse,
        sanitaryDefectIndicator: deficiency.sanitaryDefectInd
      };
      findings.push(finding);
    });
    return findings;
  }

  private mapRecommendations(recommendations: Recommendation[]): Finding[] {
    let findings: Finding[] = [];
    recommendations.forEach(recommendation => {
      let description: string = this.surveySubcategoryTypePipe.transform(recommendation.surveySubcategoryCode);
      if (description == null) {
        description = FINDING_TYPES.RECOMMENDATION.valueOf();
      }
      let finding: Finding = {
        type: FINDING_TYPES.RECOMMENDATION,
        description: description,
        sanitarySurveySectionCode: recommendation.sanitarySurveySectionCode,
        sanitarySurveySectionDescription: this.sanitarySurveySectionTypePipe.transform(recommendation.sanitarySurveySectionCode),
        beginDate: recommendation.beginDate,
        code: recommendation.sanitarySurveyRecomCode,
        language: recommendation.defaultRecommendation,
        surveySubcategoryCode: recommendation.surveySubcategoryCode,
        surveySubcategoryDescription: this.surveySubcategoryTypePipe.transform(recommendation.surveySubcategoryCode)
      };
      findings.push(finding);
    });
    return findings;
  }

  ngOnDestroy(): void {
    if (this.findings$ != null) {
      this.findings$.unsubscribe();
    }
  }
}
