<div class="card">
  <div class="card-body">
    <div>
      <h1>Edit Inventory Contact</h1>
    </div>
    <div class="pt-3">
      <app-contact-info-form [contacts]="contacts.data" [contact]="contact" (saved)="save($event)" (cancelled)="cancel()"></app-contact-info-form>
    </div>
  </div>
</div>
