import { Component, OnInit } from "@angular/core";
import { Facility } from "src/app/domain/facility";
import { ActivatedRoute, Router } from "@angular/router";
import { WaterSystemFacilitiesService } from "../../water-system-facilities.service";
import { MessageService } from "src/app/message/message.service";
import { Severity } from "src/app/message/Severity";
import { WaterSystemSiteVisitsService } from "../../water-system-site-visits.service";
import * as _ from "lodash";
import { FacilityFlow } from "src/app/domain/facility-flow";
import { FacilityType } from "src/app/domain/inspection";
import { SiteVisit } from "src/app/domain/site-visit";
import { Visit } from "src/app/domain/visit";
import { QuestionniareSubscriber } from "../../questionnaire/questionnaire-subscriber";
import {SiteVisitsService} from "../../site-visits/site-visits.service";
import {flagRequiredQuestionnare, isValid} from "../../site-visits/site-visit-utils";

@Component({
  selector: "app-add-facility",
  templateUrl: "./add-facility.component.html",
  styleUrls: ["./add-facility.component.scss"],
})
export class AddFacilityComponent implements OnInit {
  facility: Facility = {
    pwsId: null,
    facilityId: null,
    name: null,
    facilityCode: null,
    availabilityCode: null,
    statusCode: null,
    statusReason: null,
    facilitySeqNo: null,
    surveyNote: null,
    designCapacity: null,
    dcUnitMeasureCode: null,
    emergencyCapacity: null,
    ecUnitMeasureCode: null
  };

  public isAddingFacility: boolean = false;

  private returnUrl: string;
  private siteVisitId: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private waterSystemFacilitiesService: WaterSystemFacilitiesService,
    private messageService: MessageService,
    private waterSystemSiteVisitsService: WaterSystemSiteVisitsService,
    private subscriber: QuestionniareSubscriber<Visit>,
    private siteVisitsService: SiteVisitsService
  ) {}

  ngOnInit(): void {
    this.route.parent.params.subscribe((params) => {
      this.facility.pwsId = +params["id"];
    });
    this.returnUrl = history.state.data;
    this.siteVisitId = history.state.siteVisitId;
  }

  add(newFacility: { facility: Facility; flowsToFacility: FacilityFlow }) {
    this.isAddingFacility = true;
    newFacility.facility.newIndicator = true;
    const now = new Date();
    newFacility.facility.facilitySeqNo = now.getTime();
    this.waterSystemFacilitiesService.save(
      newFacility.facility,
      newFacility.flowsToFacility
    );
    if (this.siteVisitId) {
      this.updateSurvey();
    } else {
      this.isAddingFacility = false;
      this.cancel();
      this.messageService.add({
        severity: Severity.SUCCESS,
        value: "Inventory has been added.",
      });
      window.scrollTo(0, 0);
    }
  }

  cancel() {
    if (this.returnUrl) {
      this.router.navigate([this.returnUrl]);
    } else {
      this.router.navigate(["facilities"], { relativeTo: this.route.parent });
    }
  }

  updateSurvey() {
    this.waterSystemSiteVisitsService
      .find(this.siteVisitId, this.facility.pwsId)
      .subscribe((siteVisit) => {
        if (siteVisit.inspection.addedFacilities == null) {
          siteVisit.inspection.addedFacilities = [];
        }
        siteVisit.inspection.addedFacilities.push(this.facility);

        if (this.facility.statusCode === "A") {
          switch (this.facility.facilityCode) {
            case "WL":
              if (siteVisit.inspection.questionnaires.well.base) {
                const questionnaire = _.cloneDeep(
                  siteVisit.inspection.questionnaires.well.base
                );
                questionnaire.category = this.facility.name;
                questionnaire.facilityId = this.facility.facilityId;
                flagRequiredQuestionnare(questionnaire);
                siteVisit.inspection.questionnaires.well.facilities.push(
                  questionnaire
                );
                this.removeMissing(siteVisit, FacilityType.source);
                this.waterSystemSiteVisitsService.save(siteVisit);
              }
              break;
            case "IN":
              if (siteVisit.inspection.questionnaires.surfaceWater.base) {
                const questionnaire = _.cloneDeep(
                  siteVisit.inspection.questionnaires.surfaceWater.base
                );
                questionnaire.category = this.facility.name;
                questionnaire.facilityId = this.facility.facilityId;
                flagRequiredQuestionnare(questionnaire);
                siteVisit.inspection.questionnaires.surfaceWater.facilities.push(
                  questionnaire
                );
                this.removeMissing(siteVisit, FacilityType.source);
                this.waterSystemSiteVisitsService.save(siteVisit);
              }
              break;
            case "SH":
            case "SU":
            case "ST":
            case "SS":
            case "SE":
              if (siteVisit.inspection.questionnaires.storage.base) {
                const questionnaire = _.cloneDeep(
                  siteVisit.inspection.questionnaires.storage.base
                );
                questionnaire.category = this.facility.name;
                questionnaire.facilityId = this.facility.facilityId;
                flagRequiredQuestionnare(questionnaire);
                siteVisit.inspection.questionnaires.storage.facilities.push(
                  questionnaire
                );
                this.removeMissing(siteVisit, FacilityType.storage);
                this.waterSystemSiteVisitsService.save(siteVisit);
              }
              break;
            case "TP":
              if (siteVisit.inspection.questionnaires.treatment.base) {
                const questionnaire = _.cloneDeep(
                  siteVisit.inspection.questionnaires.treatment.base
                );
                questionnaire.category = this.facility.name;
                questionnaire.facilityId = this.facility.facilityId;
                flagRequiredQuestionnare(questionnaire);
                siteVisit.inspection.questionnaires.treatment.facilities.push(
                  questionnaire
                );
                this.removeMissing(siteVisit, FacilityType.treatment);
                this.waterSystemSiteVisitsService.save(siteVisit);
              }
              break;
            case "DS":
              if (siteVisit.inspection.questionnaires.distribution.base) {
                const questionnaire = _.cloneDeep(
                  siteVisit.inspection.questionnaires.distribution.base
                );
                questionnaire.category = this.facility.name;
                questionnaire.facilityId = this.facility.facilityId;
                flagRequiredQuestionnare(questionnaire);
                siteVisit.inspection.questionnaires.distribution.facilities.push(
                  questionnaire
                );
                this.removeMissing(siteVisit, FacilityType.distribution);
                this.waterSystemSiteVisitsService.save(siteVisit);
              }
              break;
            case "CC":
              if (siteVisit.inspection.questionnaires.interconnect.base) {
                const questionnaire = _.cloneDeep(
                  siteVisit.inspection.questionnaires.interconnect.base
                );
                questionnaire.category = this.facility.name;
                questionnaire.facilityId = this.facility.facilityId;
                flagRequiredQuestionnare(questionnaire);
                siteVisit.inspection.questionnaires.interconnect.facilities.push(
                  questionnaire
                );
                this.removeMissing(siteVisit, FacilityType.source);
                this.waterSystemSiteVisitsService.save(siteVisit);
              }
              break;
            default:
              this.waterSystemSiteVisitsService.save(siteVisit);
          }
          this.completeAdd(siteVisit);
        } else {
          this.waterSystemSiteVisitsService.save(siteVisit);
          this.completeAdd(siteVisit);
        }
      });
  }

  completeAdd(siteVisit) {
    isValid(siteVisit);
    setTimeout(() => {
      this.isAddingFacility = false;
      this.subscriber.next(siteVisit);
      this.siteVisitsService.updateCurrentVisit(siteVisit);
      this.cancel();
      this.messageService.add({
        severity: Severity.SUCCESS,
        value: "Inventory has been added.",
      });
      window.scrollTo(0, 0);
    }, 10000);
  }

  removeMissing(siteVisit: Visit, type: FacilityType) {
    if (
      siteVisit.inspection.questionnaires[type] &&
      siteVisit.inspection.questionnaires[type].missing &&
      siteVisit.inspection.questionnaires[type].missing.base
    ) {
      siteVisit.inspection.questionnaires[type].missing.missing = _.cloneDeep(
        siteVisit.inspection.questionnaires[type].missing.base
      );
    }
  }
}
