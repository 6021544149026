import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Note } from 'src/app/domain/note';

@Component({
  selector: 'app-note-info-form',
  templateUrl: './note-info-form.component.html',
  styleUrls: ['./note-info-form.component.scss']
})
export class NoteInfoFormComponent implements OnInit {

  @Input() note: Note;

  @Output() saved = new EventEmitter<Note>();

  @Output() cancelled = new EventEmitter<Note>();

  constructor() { }

  ngOnInit() {
  }

  save() {
    this.saved.emit(this.note);
  }

  cancel() {
    this.cancelled.emit();
  }
}
