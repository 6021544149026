import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'visitTypeAnnualSanitary'})
export class VisitTypeAnnualSanitaryPipe implements PipeTransform {
  transform(value: string): string {
    if (value.toUpperCase() === 'ANNUAL') {
      return 'Annual Site Visit';
    } else if (value.toUpperCase() === 'SANITARY') {
      return 'Sanitary Survey';
    } else {
      return 'Unknown';
    }
  }
}
