import { Component, OnInit } from '@angular/core';
import {Visit} from '../../domain/visit';
import {FacilityType} from '../../domain/inspection';
import {WaterSystemSiteVisitsService} from '../water-system-site-visits.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-facilities-view',
  templateUrl: './facilities-view.component.html',
  styleUrls: ['./facilities-view.component.scss']
})
export class FacilitiesViewComponent implements OnInit {

  waterSystemId: number;
  siteVisitId: number;
  siteVisit: Visit;
  facilityType = FacilityType;

  constructor(
    private route: ActivatedRoute,
    private siteVisitService: WaterSystemSiteVisitsService
  ) {}

  ngOnInit(): void {
    this.siteVisitId = Number(this.route.snapshot.params['id']);
    this.waterSystemId = Number(this.route.parent.snapshot.params['id']);
    this.siteVisitService
    .find(this.siteVisitId, this.waterSystemId)
    .subscribe((visit) => {
      this.siteVisit = visit;
    });
  }

}
