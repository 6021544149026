export interface Contact {
  id: number;
  contactSeqNo: number;
  pwsId: number;
  isPrimary: boolean;
  role: string;
  name: string;
  phoneSeqNo: number;
  phone: string;
  email: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  zip: string;
  action?: ContactAction;
  original?: Contact;
  diff?: Contact;
  diffMessage?: string;
}

export enum ContactAction {
  Create = "C", //Created new contact.
  Update = "U", //Updated contact from mndwis.
  Delete = "D", //Flagged for delete, not updated.
  Erase = "E",  //Flagged for delete, updated.
  Remove = "R"  //Flagged for delete, new contact.
}
