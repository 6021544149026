import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-entries-per-page',
  templateUrl: './entries-per-page.component.html',
  styleUrls: ['./entries-per-page.component.scss']
})
export class EntriesPerPageComponent {

  @Output()
  private selected = new EventEmitter<number>();

  readonly options: Array<number> = [10, 25, 50, 100];

  constructor() { }

  select(option: number) {
    this.selected.emit(option);
  }

}
