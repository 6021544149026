import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotificationsComponent } from './notifications/notifications.component';
import { AppAuthGuard } from '../app.guard';
import { Shell } from '../shell/shell.service';
import { QuestionnairesComponent } from './questionnaires/questionnaires.component';
import { EditQuestionnaireComponent } from './questionnaires/edit-questionnaire/edit-questionnaire.component';
import { EditQuestionComponent } from './questionnaires/edit-question/edit-question.component';

const routes: Routes = [
  /* Disabled 6/27/2024: Shell.childRoutes([
    {
      path: 'admin/notifications',
      component: NotificationsComponent,
      canActivate: [AppAuthGuard],
      data: {
        title: 'Notifications',
        roles: ['admin', 'inspector']
      }
    }
  ]),*/
  Shell.childRoutes([
    {
      path: 'admin/questionnaires',
      component: QuestionnairesComponent,
      canActivate: [AppAuthGuard],
      data: {
        title: 'Maintain Questions',
        roles: ['admin']
      }
    },
    {
      path: 'admin/questionnaires/edit/:id',
      component: EditQuestionnaireComponent,
      canActivate: [AppAuthGuard],
      data: {
        title: 'Edit Question Set',
        roles: ['admin']
      }
    },
    {
      path: 'admin/questionnaires/edit',
      component: EditQuestionnaireComponent,
      canActivate: [AppAuthGuard],
      data: {
        title: 'Add Question Set',
        roles: ['admin']
      }
    },
    {
      path: 'admin/questionnaires/edit/:id/question/:uuid',
      component: EditQuestionComponent,
      canActivate: [AppAuthGuard],
      data: {
        title: 'Edit Question',
        roles: ['admin']
      }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
