import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FacilityType } from "src/app/domain/inspection";
import { Questionnaire } from "src/app/domain/questionnaire";
import { Visit } from "src/app/domain/visit";

@Component({
  selector: "app-missing-facility",
  templateUrl: "./missing-facility.component.html",
  styleUrls: ["./missing-facility.component.scss"],
})
export class MissingFacilityComponent implements OnInit {
  @Input() facilityType: FacilityType;
  @Input() title: string;
  @Input() warning: string;
  @Input() waterSystemId: Number;
  @Input() siteVisitId: Number;
  @Input() siteVisit: Visit;
  questionnaire: Questionnaire;

  constructor() {}

  ngOnInit(): void {
    if (this.hasMissingQuestionnaire) {
      this.questionnaire = this.siteVisit.inspection.questionnaires[this.facilityType].missing.missing;
    }
  }

  get hasMissingQuestionnaire(): boolean {
    if (
      this.siteVisit.inspection.questionnaires[this.facilityType] &&
      this.siteVisit.inspection.questionnaires[this.facilityType].missing &&
      this.siteVisit.inspection.questionnaires[this.facilityType].missing.missing
    ) {
      return true;
    }
  }
}
