<div class="container-fluid">
  <div class="p-3" *ngIf="isOnline; else offlineMsg">
    <h1 >Edit Question / Section Header</h1>
    <div *ngIf="isQuestionLoading; else loaded" class="pl-3">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading Question / Section Header...</span>
      </div>
      <span>Loading Question / Section Header...</span>
    </div>
    <ng-template #loaded>
      <form id="question-form" *ngIf="!hasError">
        <app-message></app-message>
        <app-toast aria-live="polite" aria-atomic="true"></app-toast>
        <div class="row">
          <div class="col-12 form-group" *ngIf="!question.questionActiveInd">
            <label for="question-display-name">Display Name: (required)</label>
            <input id="question-display-name" class="form-control" [(ngModel)]="question.displayName" maxlength="200" name="question-display-name" required="true" #questionDisplayName="ngModel" (change)="onChange($event.target.title)" title="Question Display Name">
            <div *ngIf="(questionDisplayName.dirty || questionDisplayName.touched) && questionDisplayName.invalid">
              <div *ngIf="questionDisplayName.hasError('required')" class="alert alert-danger">
                Display Name is required.
              </div>
            </div>
          </div>
          <div class="col-12 form-group" *ngIf="question.questionActiveInd" data-toggle="tooltip" title="The question or question set must be deactivated in order to edit the display name.">
            <label for="question-display-name">Display Name: (required)</label>
            <input id="question-display-name" class="form-control" [(ngModel)]="question.displayName" maxlength="200" name="question-display-name" required="true" #questionDisplayName="ngModel" disabled>
            <div *ngIf="(questionDisplayName.dirty || questionDisplayName.touched) && questionDisplayName.invalid">
              <div *ngIf="questionDisplayName.hasError('required')" class="alert alert-danger">
                Display Name is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-12 form-group">
            <span><strong>Type:</strong></span>
            <br/>
            <span>{{question?.type}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 form-group">
            <div class="form-check">
                <input *ngIf="canChangeActive"
                       class="form-check-input"
                       type="checkbox"
                       id="question-active"
                       [(ngModel)]="question.questionActiveInd"
                       name="question-active"
                       (change)="onActiveChange($event.target.title)"
                       title="Question Active Indicator">
                <input *ngIf="!canChangeActive"
                       class="form-check-input"
                       type="checkbox"
                       id="question-active"
                       [(ngModel)]="question.questionActiveInd"
                       name="question-active"
                       disabled
                       data-toggle="tooltip"
                       [attr.title]="getDisabledActiveTitle()">
                <label class="form-check-label" for="question-active">Active</label>
              </div>
          </div>
        </div>
        <div class="row" *ngIf="question.type === 'Question'">
          <div class="col-12 form-group">
            <div class="row">
              <div class="col-12 form-group">
                <div class="form-check">
                  <input class="form-check-input"
                         type="checkbox"
                         id="question-required"
                         [(ngModel)]="question.required"
                         [disabled]="questionnaire.activeInd && question.questionActiveInd"
                         name="question-required"
                         (change)="onChange($event.target.title)"
                         data-toggle="tooltip"
                         title="Required">
                  <label class="form-check-label" for="question-required">Required</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12 form-group">
                <label for="question-prerequisite-question">Only Ask When:</label>
                <select id="question-prerequisite-question" class="form-control" [(ngModel)]="question.prerequisiteQuestionUuid"
                        name="question-prerequisite-question" #prereqQuestionIndex="ngModel"
                        (change)="onPrerequisiteQuestionChange($event.target.title)" title="Prerequisite Question"
                        [disabled]="questionnaire.activeInd && question.questionActiveInd">
                  <option [ngValue]="null"></option>
                  <ng-container *ngFor="let q of questionnaire.question; let i = index">
                    <option *ngIf="isPossiblePrerequisiteQuestion(q)" [ngValue]="q.uuid">{{ q.displayName }}</option>
                  </ng-container>
                </select>
              </div>
              <div class="col-md-6 col-sm-12 form-group">
                <label for="question-prerequisite-answer">
                  Prerequisite Answer:
                  <span *ngIf="question.prerequisiteQuestionUuid"> (required)</span>
                </label>
                <select id="question-prerequisite-answer" class="form-control"
                        [(ngModel)]="question.prerequisiteAnswer"
                        name="question-prerequisite-answer"
                        [required]="question.prerequisiteQuestionUuid"
                        #prereqAnswer="ngModel"
                        (change)="onPrerequisiteAnswerChange($event.target.title)"
                        title="Prerequisite Answer"
                        [disabled]="questionnaire.activeInd && question.questionActiveInd">
                  <option [ngValue]="null"></option>
                  <ng-container *ngIf="question.prerequisiteQuestionUuid">
                    <option *ngFor="let a of prerequisiteQuestion().answers" value="{{ a.name }}">{{ a.name }}</option>
                  </ng-container>
                </select>
                <div *ngIf="(prereqAnswer.dirty || prereqAnswer.touched) && prereqAnswer.invalid">
                  <div *ngIf="prereqAnswer.hasError('required')" class="alert alert-danger">
                    Prerequisite Answer is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <app-answers-list [answers]="question.answers" (answersChange)="updateAnswer($event)" [(addAnswer)]="add" [denyEdit]="questionnaire.activeInd && question.questionActiveInd"></app-answers-list>
          </div>
          <div class="col-12 pt-3">
            <div *ngIf="!questionnaire.activeInd || !question.questionActiveInd">
              <button id="edit-question-add-answer-button" type="button" class="btn btn-outline-primary mr-2" (click)="addAnswer()">Add Answer</button>
              <button id="edit-question-add-yes-no-answers-button" type="button" class="btn btn-outline-primary" (click)="addYesNoAnswers()" [disabled]="isYesOrNoAnswerExists()"
                [attr.title]="isYesOrNoAnswerExists() ? 'A Yes or No answer exists already.' : ''">Add Yes and No Answers</button>
            </div>
            <div *ngIf="questionnaire.activeInd && question.questionActiveInd" data-toggle="tooltip" title="The question or question set must be deactivated in order to add an answer.">
              <button id="edit-question-add-answer-button" type="button" class="btn btn-outline-primary mr-2" disabled="true">Add Answer</button>
              <button id="edit-question-add-yes-no-answers-button" type="button" class="btn btn-outline-primary" disabled="true">Add Yes and No Answers</button>
            </div>
          </div>
        </div>
        <div class="row pt-4" *ngIf="question.type === 'Question'">
          <div class="col-12">
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-sm">
                <thead class="thead-light">
                  <tr class="d-flex">
                    <th scope="col" class="col-12">Questions Depending on This Question</th>
                  </tr>
                </thead>
                <tbody *ngIf="dependentQuestions.length > 0">
                  <tr *ngFor="let question of dependentQuestions" class="d-flex">
                    <td class="col-12">
                      <span>{{ question.displayName }}</span>
                    </td>
                  </tr>
                </tbody>
                <tbody *ngIf="dependentQuestions.length == 0">
                  <tr>
                    <td colspan="4">
                      None
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="question.type === 'Finding'">
          <div class="col-12">
            <app-additional-finding [additionalFinding]="question.answers[0]" (findingChange)="updateFinding($event)" [denyEdit]="questionnaire.activeInd && question.questionActiveInd"></app-additional-finding>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-12">
            <button id="edit-question-delete-button" type="button" class="btn btn-danger" (click)="deleteQuestion()">Delete {{question?.type}}</button>
          </div>
        </div>
      </form>
      <div *ngIf="hasError">
        <app-message></app-message>
      </div>
    </ng-template>
  </div>

  <ng-template #offlineMsg>
    <div class="p-3">Questions are not available offline.</div>
  </ng-template>
</div>
