import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, concatMap, map, take, takeUntil, filter, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { LocalStorageService } from '../local-storage.service';
import { tables } from '../utils/app-db';
import { FacilityAny, TreatmentPlant } from '../domain/facility';
import { Checkout } from '../domain/checkout';
import { SyncSchedulerService } from './sync-scheduler.service';
import { SyncType } from '../domain/sync';
import { SyncResultFactory } from '../utils/sync-result-factory';

@Injectable({
  providedIn: 'root'
})
export class WaterSystemTreatmentFacilitiesService {

  private readonly WATER_SYSTEM_URL = environment.serverUrl + '/watersystem';
  private readonly TREATMENT_FACILITY_SUB_ENDPOINT = 'treatmentplant';
  private readonly TREATMENT_FACILITY_CODE = 'TP';

  constructor(private connectivityService: ConnectivityService,
              private httpClient: HttpClient,
              private localStorageService: LocalStorageService,
              private syncSchedulerService: SyncSchedulerService) { }

  /**
   * Returns a water system's treatment plant facilities.
   *
   * @param id the id of the water system
   */
  find(id: number): Observable<TreatmentPlant[]> {
    return new Observable<TreatmentPlant[]>(obs => {
      this.findLocally(id).subscribe(wsLocal => {
        if (wsLocal) {
          obs.next(wsLocal);
          obs.complete();
        } else {
          if (this.connectivityService.isOnline()) {
            this.findRemotelyTimer(id).subscribe (wsRemote => {
              obs.next(wsRemote);
              obs.complete();
            }, error => {
              obs.error(error);
            });
          } else {
            obs.complete();
          }
        }
      });
    });
  }

  /**
   * Returns one treatment plant facility saved locally.
   *
   * @param waterSystemId the water system id
   * @param facilityId the facility id
   */
  findOne(waterSystemId: number, facilityId: number): Observable<TreatmentPlant> {
    return new Observable<TreatmentPlant>(obs => {
      this.findOneLocally(waterSystemId, facilityId).subscribe(wsLocal => {
        if (wsLocal && wsLocal.length > 0) {
          obs.next(wsLocal[0]);
          obs.complete();
        } else {
          obs.next(null);
          obs.complete();
        }
      });
    });
  }

  findLocally(id: number): Observable<TreatmentPlant[]> {
    return new Observable<TreatmentPlant[]>(obs => {
      this.localStorageService.dbRetrieveAllByIndex<TreatmentPlant>(tables.waterSystemTreatmentFacility, 'pwsId', id)
        .pipe(
          switchMap(local => {
            if (local && local.length > 0) {
              return of(local);
            } else {
              return this.localStorageService.dbRetrieveAllByIndex<any>(tables.waterSystemZeroFacilitiesOfType,
                'pwsIdFacilityCode', [id, this.TREATMENT_FACILITY_CODE])
                .pipe(
                  map(l => {
                    if (l && l.length > 0) {
                      return l;
                    } else {
                      return null;
                    }
                  })
                );
            }
          })
        ).subscribe(local => {
          obs.next(local);
          obs.complete();
        });
      });
  }

  findOneLocallyByKey(key: number): Observable<TreatmentPlant> {
    return this.localStorageService.dbRetrieve<TreatmentPlant>(tables.waterSystemTreatmentFacility, key);
  }

  private findOneLocally(waterSystemId: number, facilityId: number): Observable<TreatmentPlant[]> {
    return this.localStorageService.dbRetrieveAllByIndex<TreatmentPlant>(tables.waterSystemTreatmentFacility,
      'pwsIdFacilityId', [waterSystemId, facilityId]);
  }

  private findRemotely(id: number): Observable<Checkout<any[]>> {
    return this.httpClient.get<Checkout<any[]>>(`${this.WATER_SYSTEM_URL}/${id}/${this.TREATMENT_FACILITY_SUB_ENDPOINT}`);
  }

  private findRemotelyTimer(id: number): Observable<TreatmentPlant[]> {
    const stopTimer$ = timer(10 * 60 * 1000);
    return timer(0, 15000)
      .pipe(
        takeUntil(stopTimer$),
        concatMap(() => this.findRemotely(id).pipe(map(response => response))),
        filter(backendData => backendData.dataReady),
        take(1),
        map(found => {
          const treatmentPlants: TreatmentPlant[] = this.mapToTreatmentPlantFacility(found.data);
          this.store(treatmentPlants);
          if (found.data == null || found.data.length === 0) {
            this.localStorageService.dbStore(tables.waterSystemZeroFacilitiesOfType,
              { 'pwsId': id, 'facilityCode': this.TREATMENT_FACILITY_CODE});
          }
          return treatmentPlants;
        }),
        catchError(error => {
          if (error.status === 404) {
            this.localStorageService.dbStore(tables.waterSystemZeroFacilitiesOfType,
              { 'pwsId': id, 'facilityCode': this.TREATMENT_FACILITY_CODE});
            return of(error.error);
          } else {
            return throwError(error);
          }
        })
      );
  }

  private mapToTreatmentPlantFacility(results: any[]): TreatmentPlant[] {
    const treatmentPlants: TreatmentPlant[] = [];
    if (results && results.length > 0) {
      for (const result of results) {
        const treatmentPlant: TreatmentPlant = {
          pwsId: result.pwsId,
          facilityId: result.facilityId,
          name: result.name,
          facilityCode: result.facilityCode,
          availabilityCode: result.information.availabilityCode,
          statusCode: result.information.statusCode,
          downstreamFacility: result.information.downstreamFacility,
          facilitySeqNo: result.information.id,
          designCapacity: result.information.designCapacity,
          dcUnitMeasureCode: result.information.dcUnitMeasureCode,
          emergencyCapacity: result.information.emergencyCapacity,
          ecUnitMeasureCode: result.information.ecUnitMeasureCode,
          operatingRate: result.information.operatingRate,
          orUnitMeasureCode: result.information.orUnitMeasureCode,
          facilityTreatments: result.information.treatment,
          statusReason: result.information.statusReason,
          surveyNote: result.information.surveyNote
        };
        treatmentPlants.push(treatmentPlant);
      }
    }
    return treatmentPlants;
  }

  store(treatmentPlants: TreatmentPlant[]): void {
    if (treatmentPlants && treatmentPlants.length > 1) {
      treatmentPlants = treatmentPlants.sort((a, b) => {
        if (a.facilityId > b.facilityId) {
          return 1;
        } else if (a.facilityId < b.facilityId) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    treatmentPlants.forEach(treatmentPlant => {
      this.localStorageService.dbStore(tables.waterSystemTreatmentFacility, treatmentPlant);
    });
  }

  deleteLocally(id: number): void {
    this.localStorageService.dbRetrieveAllByIndex<TreatmentPlant>(tables.waterSystemTreatmentFacility, 'pwsId', id).subscribe(results => {
      if (results) {
        for (const facility of results) {
          this.localStorageService.dbRemove(tables.waterSystemTreatmentFacility, facility.facilitySeqNo);
        }
      }
    });
    this.localStorageService.dbRetrieveAllByIndex<any>(tables.waterSystemZeroFacilitiesOfType, 'pwsId', id).subscribe(results => {
      if (results) {
        for (const result of results) {
          this.localStorageService.dbRemove(tables.waterSystemZeroFacilitiesOfType, result.key);
        }
      }
    });
  }

  insertRemotely(treatmentFacility: TreatmentPlant): Observable<any> {
    const remoteFacility = {
      facilityId: treatmentFacility.facilityId,
      facilityCode: treatmentFacility.facilityCode,
      name: treatmentFacility.name,
      pwsId: treatmentFacility.pwsId,
      information: {
        id: treatmentFacility.facilitySeqNo,
        waterSystemId: treatmentFacility.pwsId,
        name: treatmentFacility.name,
        facilityCode: treatmentFacility.facilityCode,
        availabilityCode: treatmentFacility.availabilityCode,
        statusCode: treatmentFacility.statusCode,
        statusReason: treatmentFacility.statusReason,
        downstreamFacility: treatmentFacility.downstreamFacility,
        surveyNote: treatmentFacility.surveyNote,
        designCapacity: treatmentFacility.designCapacity,
        dcUnitMeasureCode: treatmentFacility.dcUnitMeasureCode,
        emergencyCapacity: treatmentFacility.emergencyCapacity,
        ecUnitMeasureCode: treatmentFacility.ecUnitMeasureCode
      }
    };
    return this.httpClient.post(`${this.WATER_SYSTEM_URL}/${treatmentFacility.pwsId}/${this.TREATMENT_FACILITY_SUB_ENDPOINT}`,
      remoteFacility);
  }

  updateRemotely(treatmentFacility: TreatmentPlant): Observable<any> {
    const remoteFacility = {
      facilityId: treatmentFacility.facilityId,
      facilityCode: treatmentFacility.facilityCode,
      name: treatmentFacility.name,
      pwsId: treatmentFacility.pwsId,
      information: {
        id: treatmentFacility.facilitySeqNo,
        waterSystemId: treatmentFacility.pwsId,
        name: treatmentFacility.name,
        facilityCode: treatmentFacility.facilityCode,
        availabilityCode: treatmentFacility.availabilityCode,
        statusCode: treatmentFacility.statusCode,
        statusReason: treatmentFacility.statusReason,
        downstreamFacility: treatmentFacility.downstreamFacility,
        designCapacity: treatmentFacility.designCapacity,
        dcUnitMeasureCode: treatmentFacility.dcUnitMeasureCode,
        emergencyCapacity: treatmentFacility.emergencyCapacity,
        ecUnitMeasureCode: treatmentFacility.ecUnitMeasureCode,
        operatingRate: treatmentFacility.operatingRate,
        orUnitMeasureCode: treatmentFacility.orUnitMeasureCode,
        surveyNote: treatmentFacility.surveyNote,
        treatment: treatmentFacility.facilityTreatments
      }
    };
    return this.httpClient.put(`${this.WATER_SYSTEM_URL}/${treatmentFacility.pwsId}/`
      + `${this.TREATMENT_FACILITY_SUB_ENDPOINT}/${treatmentFacility.facilityId}`, remoteFacility);
  }

  deleteOne(facility: FacilityAny): Observable<any> {
    if (this.connectivityService.isOnline()) {
      this.deleteOneLocally(facility.facilitySeqNo);
      return this.deleteRemotely(facility);
    } else {
      this.syncSchedulerService.schedule(SyncType.FacilityTreatmentDelete, facility.facilitySeqNo).subscribe();
    }
  }

  deleteOneLocally(id: number): void {
    this.localStorageService.dbRemove(tables.waterSystemTreatmentFacility, id);
  }

  private deleteRemotely(facility: FacilityAny): Observable<any> {
    return this.httpClient.delete(`${this.WATER_SYSTEM_URL}/${facility.pwsId}/`
      + `${this.TREATMENT_FACILITY_SUB_ENDPOINT}/${facility.facilityId}`);
  }

  syncFacilityTreatmentDelete(): void {
    if (this.connectivityService.isOnline) {
      if (this.connectivityService.isOnline()) {
        this.syncSchedulerService.scheduled(SyncType.FacilityTreatmentDelete).subscribe(syncRequests => {
          syncRequests.forEach(syncRequest => {
            this.findOneLocallyByKey(syncRequest.data.id).subscribe(facility => {
              if (facility) {
                this.deleteRemotely(facility).subscribe(() => {
                  this.syncSchedulerService.unschedule(SyncType.FacilityTreatmentDelete, syncRequest.data.id,
                      SyncResultFactory.synced(SyncType.FacilityTreatmentDelete, syncRequest.data.id));
                  this.deleteOneLocally(facility.facilitySeqNo);
                }, error => {
                  this.syncSchedulerService.unschedule(SyncType.FacilityTreatmentDelete, syncRequest.data.id,
                      SyncResultFactory.errorHttp(SyncType.FacilityTreatmentDelete, syncRequest.data.id, error));
                });
              } else {
                this.syncSchedulerService.unschedule(SyncType.FacilityTreatmentDelete, syncRequest.data.id,
                  SyncResultFactory.synced(SyncType.FacilityTreatmentDelete, syncRequest.data.id));
              }
            });
          });
        });
      }
    }
  }
}
