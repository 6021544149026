import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Visit } from 'src/app/domain/visit';

@Injectable({
  providedIn: 'root'
})
export class SiteVisitsService {

  private visits = new BehaviorSubject<Visit[]>([]);
  private initialized: boolean = false;
  visits$ = this.visits.asObservable();

  constructor() { }

  changeWaterSystemSiteVisits(visits: Visit[]) {
    this.visits.next(visits);
  }

  createCurrentVisit(currentVisit: Visit) {
    let visit = [currentVisit];
    this.changeWaterSystemSiteVisits(visit.concat(this.visits.getValue()));
  }

  updateCurrentVisit(visit: Visit) {
    this.visits.value[0] = visit;
    this.changeWaterSystemSiteVisits(this.visits.value);
  }

  deleteCurrentVisit() {
    this.changeWaterSystemSiteVisits(this.visits.value.slice(1));
  }

  initialize() {
    this.initialized = true;
  }

  isInitialized(): boolean {
    return this.initialized;
  }
}
