import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contact } from '../../../../domain/contact';
import { ROLE_TYPES } from 'src/app/shared/role-type';
import { STATE_TYPES } from 'src/app/shared/state-type';
import { CityService } from "../../../../shared/city/city.service";
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-contact-info-form',
  templateUrl: './contact-info-form.component.html',
  styleUrls: ['./contact-info-form.component.scss']
})
export class ContactInfoFormComponent implements OnInit {

  @Input()
  contact: Contact;

  @Input()
  contacts: Contact[];

  @Output()
  saved = new EventEmitter<Contact>();

  @Output()
  cancelled = new EventEmitter<void>();

  readonly ROLE_TYPES: any[] = ROLE_TYPES;
  readonly STATE_TYPES: any[] = STATE_TYPES;
  public cities: string[];

  constructor(private cityService: CityService) { }

  ngOnInit(): void {
    this.formatPhoneNumber();
    this.cityService.getCitiesList()
      .pipe(take(1))
      .subscribe(cities => {
        this.cities = cities;
      });
  }

  save() {
    this.saved.emit(this.contact);
  }

  cancel() {
    this.cancelled.emit();
  }

  formatPhoneNumber() {
    if (this.contact.phone && this.contact.phone !== '') {
      this.contact.phone = this.contact.phone.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
    }
  }
}
