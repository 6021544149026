import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Well } from "../../../domain/facility";
import { ExistingMeasure, WaterSystem } from "../../../domain/water-system";
import { BasicDetailsService } from "../../basic-details/basic-details.service";
import { Checkout } from "../../../domain/checkout";
import { Unsubscribable } from "rxjs";
import { MeasuresListService } from "../../measures-list.service";
import { ConfirmatoryActionService } from "../../../confirmatory-action/confirmatory-action.service";
import { MeasureType } from "../../../domain/measure-type";

@Component({
  selector: 'app-measures',
  templateUrl: './measures.component.html',
})
export class MeasuresComponent implements OnInit, OnDestroy {

  @Input()
  well: Well;

  @Output()
  edit = new EventEmitter<Well>();

  public isPreExistingLoading = false;
  public isMeasuresListLoading = true;
  // Disabled 7/2/2024: public hasExistingActive: boolean = false;
  public waterSystem: Checkout<WaterSystem>;
  public allPreExisting: ExistingMeasure[] = [];
  public classes: any[];
  public selectedClass: string;
  public filteredMeasuresList: MeasureType[];
  public measuresList: MeasureType[];
  public newMeasure: MeasureType;

  private basicDetails$: Unsubscribable;
  private measuresList$: Unsubscribable;

  constructor(private basicDetailsService: BasicDetailsService,
              private measuresListService: MeasuresListService,
              private confirmatoryActionService: ConfirmatoryActionService) { }

  ngOnInit() {
    this.basicDetails$ = this.basicDetailsService.waterSystem$.subscribe(
      waterSystem => {
        this.waterSystem = waterSystem;
        /* Disabled 7/2/2024
        if (waterSystem && waterSystem.data && waterSystem.data.iwmzInformation
          && waterSystem.data.iwmzInformation.pwsExistingMeasures
          && waterSystem.data.iwmzInformation.pwsExistingMeasures.length) {
          this.allPreExisting = waterSystem.data.iwmzInformation.pwsExistingMeasures;
          for (const measure of this.allPreExisting) {
            if (this.isActiveForWell(measure)) {
              if (this.well.existingMeasures) {
                const found =
                  this.well.existingMeasures.find(m => m.facilityId === measure.facilityId
                    && m.measureCode === measure.measureCode);
                measure.resolved = true;
              }
              this.hasExistingActive = true;
              break;
            }
          }
        }
        this.isPreExistingLoading = false;
        */
      }
    );

    this.classes = [
      {
        measureClassCode: null,
        measureClassDesc: 'Select a class...'
      }
    ];
    this.measuresList$ = this.measuresListService.getMeasuresList().subscribe(
      result => {
        this.measuresList = this.sortMeasuresListDefault(result);
        this.classes = this.getClasses();
        this.isMeasuresListLoading = false;
      }
    )
  }

  get isLoading() {
    return this.isPreExistingLoading && this.isMeasuresListLoading;
  }

  /**
   * @deprecated not currently used, but code is being retained for future use
   *
   * @param measure the existing measure
   */
  isActiveForWell(measure: ExistingMeasure): boolean {
    if (measure.measureStatusCode && measure.measureStatusCode === 'Active'
      && measure.facilityId && measure.facilityId === this.well.facilityId) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @deprecated not currently used, but code is being retained for future use
   *
   * @param measure the existing measure
   */
  getStatus(measure: ExistingMeasure): string {
    if (measure.resolved) {
      return 'Resolved';
    } else {
      return measure.measureStatusCode;
    }
  }

  /**
   * @deprecated not currently used, but code is being retained for future use
   *
   * @param index index of the existing measure in the list
   */
  resolve(index) {
    let measure: ExistingMeasure = this.allPreExisting[index];
    this.allPreExisting[index].resolved = true;
    measure.resolved = true;
    if (!this.well.existingMeasures) {
      this.well.existingMeasures = [];
    }
    this.well.existingMeasures.push(measure);
  }

  /**
   * @deprecated not currently used, but code is being retained for future use
   *
   * @param index index of the existing measure in the list
   */
  undoResolve(index) {
    delete this.allPreExisting[index].resolved;
    let found = this.well.existingMeasures.find(m => m.facilityId === this.allPreExisting[index].facilityId
      && m.measureCode === this.allPreExisting[index].measureCode);
    const foundIndex = this.well.existingMeasures.indexOf(found);
    this.well.existingMeasures.splice(foundIndex, 1);
    if (this.well.existingMeasures.length === 0) {
      delete this.well.existingMeasures;
    }
  }

  sortMeasuresListDefault(results: MeasureType[]): MeasureType[] {
    return results.sort((a, b) => {
      return (a.measureDesc > b.measureDesc)
        ? 1
        : ((b.measureDesc > a.measureDesc)
          ? -1
          : 0);
    });
  }

  getClasses() {
    if (!this.measuresList) {
      return;
    }
    let tempClasses = this.measuresList.map(({ measureClassCode, measureClassDesc }) =>
      ({ measureClassCode, measureClassDesc })
    );
    const map = new Map(tempClasses.map(c => [c.measureClassCode, c]));
    if (!map || map.size === 0) {
      return
    }
    return ([...map.values()]).sort((a, b) => {
      return (a.measureClassDesc > b.measureClassDesc)
        ? 1
        : ((b.measureClassDesc > a.measureClassDesc)
          ? -1
          : 0);
    });
  }

  addMeasure() {
    this.newMeasure = {
      measureClassCode: null,
      measureClassDesc: null,
      measureCode: null,
      measureDesc: null,
      monitoringInd: null,
      updated: null
    };
  }

  onClassChange(classValue) {
    this.filteredMeasuresList = this.measuresList.filter(pc => pc.measureClassCode === classValue);
  }

  addNew() {
    if (!this.well.newMeasures) {
      this.well.newMeasures = [];
    }
    this.well.newMeasures.push(this.newMeasure);
    this.newMeasure = null;
    this.selectedClass = null;
    this.filteredMeasuresList = [];
  }

  onMeasureChange() {
    this.edit.emit(this.well);
  }

  delete(processIndex) {
    this.confirmatoryActionService.confirm(
      "Confirm Deletion",
      "Are you sure you want to delete this Measure?",
      "Yes",
      "No",
      "md")
      .then((confirmed) => {
        if (confirmed) {
          this.well.newMeasures.splice(processIndex, 1);
          this.onMeasureChange();
        }
      })
      .catch(() => {});
  }

  deleteNew() {
    this.newMeasure = null;
    this.selectedClass = null;
    this.filteredMeasuresList = [];
  }

  ngOnDestroy(): void {
    if (this.basicDetails$ != null) {
      this.basicDetails$.unsubscribe();
    }
    if (this.measuresList$ != null) {
      this.measuresList$.unsubscribe();
    }
  }
}
