<div *ngIf="isLoading; else loaded">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading Additional Finding...</span>
  </div>
  <span>Loading Additional Finding...</span>
</div>
<ng-template #loaded>
  <div class="table-responsive">
    <table class="table table-bordered table-hover table-sm" id="edit-additional-finding-table">
      <thead class="thead-light">
        <tr>
          <th scope="col" style="width:40%">Finding</th>
          <th scope="col" style="width:10%">Note</th>
          <th scope="col" style="width:10%">Photo</th>
          <th scope="col" style="width:15%">Attachment</th>
          <th scope="col" style="width:25%">Note Label Override</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="width:40%">
            <div class="form-group m-2">
              <label for="additional-finding-value" [hidden]="true">Additional Finding</label>
              <select id="additional-finding-value" class="form-control" [(ngModel)]="additionalFinding.finding.code" (change)="onFindingChange($event.target.value, $event.target.title)" name="additional-finding" title="Finding" [disabled]="denyEdit">
                <option *ngFor="let type of findings" [value]="type.code">{{type.code}} - {{type.sanitarySurveySectionDescription}}</option>
              </select>
            </div>
          </td>
          <td style="width:10%">
            <div class="form-group form-check m-2 d-inline-block align-middle">
              <label for="additional-finding-note-required" [hidden]="true" class="form-check-label">Note</label>
              <input type="checkbox" id="additional-finding-note-required" [(ngModel)]="additionalFinding.noteRequired" (change)="onChange($event.target.title)" name="additional-finding-note-required" class="form-check-input" [disabled]="denyEdit" title="Finding Note Required">
            </div>
          </td>
          <td style="width:10%">
            <div class="form-group form-check m-2 d-inline-block align-middle">
              <label for="additional-finding-photo-required" [hidden]="true" class="form-check-label">Photo</label>
              <input type="checkbox" id="additional-finding-photo-required" [(ngModel)]="additionalFinding.photoRequired" (change)="onChange($event.target.title)" name="additional-finding-photo-required" class="form-check-input" [disabled]="denyEdit" title="Finding Photo Required">
            </div>
          </td>
          <td style="width:15%">
            <div class="form-group form-check m-2 d-inline-block align-middle">
              <label for="additional-finding-attachment-required" [hidden]="true" class="form-check-label">Attachment</label>
              <input type="checkbox" id="additional-finding-attachment-required" [(ngModel)]="additionalFinding.attachmentRequired" (change)="onChange($event.target.title)" name="additional-finding-attachment-required" class="form-check-input" [disabled]="denyEdit" title="Finding Attachment Required">
            </div>
          </td>
          <td style="width:25%">
            <div class="form-group m-2">
              <label for="additional-finding-note-label" [hidden]="true">Note Label Override</label>
              <input type="text" id="additional-finding-note-label" name="note-label" class="answer-input-text form-control" [(ngModel)]="additionalFinding.noteLabelOverride" maxlength="30" (change)="onChange($event.target.title)" [disabled]="denyEdit" title="Finding Note Label Override">
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>