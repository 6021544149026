import {
  FacilityQuestionnaire,
  Questionnaire, QuestionnaireList,
} from "src/app/domain/questionnaire";
import { Visit } from "src/app/domain/visit";

export function validate(visit: Visit, init= false) {
  visit.inspection.valid = isValid(visit, init);
}

export function isValid(visit: Visit, init = false) {
  if (visit && visit.inspection && visit.inspection.questionnaires) {
    if (init) flagRequired(visit.inspection.questionnaires);
    return (
      isQuestionnairesValid(visit.inspection.questionnaires.pws) &&
      isFacilityQuesitonnairesValid(
        visit.inspection.questionnaires.distribution
      ) &&
      isFacilityQuesitonnairesValid(
        visit.inspection.questionnaires.interconnect
      ) &&
      isFacilityQuesitonnairesValid(visit.inspection.questionnaires.storage) &&
      isFacilityQuesitonnairesValid(
        visit.inspection.questionnaires.surfaceWater
      ) &&
      isFacilityQuesitonnairesValid(
        visit.inspection.questionnaires.treatment
      ) &&
      isFacilityQuesitonnairesValid(visit.inspection.questionnaires.well)
    );
  } else {
    return false;
  }
}

function isFacilityQuesitonnairesValid(
  facilities: FacilityQuestionnaire
): boolean {
  if (facilities && facilities.facilities) {
    return isQuestionnairesValid(facilities.facilities);
  } else {
    return true;
  }
}

function isQuestionnairesValid(questionnaires: Questionnaire[]): boolean {
  const questionnaire = questionnaires.find((q) => q.complete === false);
  return questionnaire ? false : true;
}

function flagRequired(questionnaireList: QuestionnaireList) {
  console.log("Flagging required")
  flagRequiredQuestionnaires(questionnaireList.pws)
  flagRequiredQuestionnaires(questionnaireList.well.facilities)
  flagRequiredQuestionnaires(questionnaireList.storage.facilities)
  if (!questionnaireList.storage.facilities.length) {
    flagRequiredQuestionnare(questionnaireList.storage.missing.missing)
  }
  flagRequiredQuestionnaires(questionnaireList.distribution.facilities)
  flagRequiredQuestionnaires(questionnaireList.interconnect.facilities)
  flagRequiredQuestionnaires(questionnaireList.treatment.facilities)
  if (!questionnaireList.treatment.facilities.length) {
    flagRequiredQuestionnare(questionnaireList.treatment.missing.missing)
  }
  flagRequiredQuestionnaires(questionnaireList.surfaceWater.facilities)
}

function flagRequiredQuestionnaires(questionnaires: Questionnaire[]) {
  questionnaires.forEach(q => flagRequiredQuestionnare(q))
}

export function flagRequiredQuestionnare(questionnaire: Questionnaire) {
  if (questionnaire.facilityStatusCode === 'I') {
    return;
  }
  if (questionnaire.question.some(q =>
    q.required && q.questionActiveInd && !q.prerequisiteQuestionUuid)) {
    questionnaire.complete = false;
  }
}
