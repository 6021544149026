import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WaterSystemComponent } from './water-system.component';
import { Shell } from '../shell/shell.service';
import { AppAuthGuard } from '../app.guard';
import { BasicDetailsComponent } from './basic-details/basic-details.component';
import { ContactsComponent } from './contacts/contacts.component';
import { AddContactComponent } from './contacts/inventory-contacts/add-contact/add-contact.component';
import { EditContactComponent } from './contacts/inventory-contacts/edit-contact/edit-contact.component';
import { AddOperatorComponent } from './contacts/water-operators/add-operator/add-operator.component';
import { AddNoteComponent } from './shared/notes/add-note/add-note.component';
import { EditNoteComponent } from './shared/notes/edit-note/edit-note.component';
import { WaterSystemNotesComponent } from './water-system-notes/water-system-notes.component';
import { SiteVisitsComponent } from './site-visits/site-visits.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { QuestionnaireListComponent } from './questionnaire-list/questionnaire-list.component';
import { FacilitiesComponent } from './facilities/facilities.component';
import { EditFacilityComponent } from './facilities/edit-facility/edit-facility.component';
import { AddFacilityComponent } from './facilities/add-facility/add-facility.component';
import { QuestionnaireViewComponent } from './questionnaire-view/questionnaire-view.component';
import { ReportSummaryViewComponent } from './site-visits/report-summary-view/report-summary-view.component';
import { FacilitiesViewComponent } from './facilities-view/facilities-view.component';
import { BasicDetailsSummaryComponent } from './basic-details/basic-details-summary/basic-details-summary.component';
import { ContactsSummaryComponent } from './contacts/contacts-summary/contacts-summary.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'water-system/:id',
      component: WaterSystemComponent,
      canActivate: [AppAuthGuard],
      data: {
        title: 'Water System',
        roles: ['admin', 'inspector']
      },
      children: [
        {
          path: 'contacts',
          component: ContactsComponent,
          canActivate: [AppAuthGuard],
          data: {
            title: 'Manage Contacts',
            roles: ['admin', 'inspector']
          }
        },
        {
          path: 'contacts/add-contact',
          component: AddContactComponent,
          canActivate: [AppAuthGuard],
          data: {
            title: 'Add Inventory Contact',
            roles: ['admin', 'inspector']
          }
        },
        {
          path: 'contacts/edit-contact/:id',
          component: EditContactComponent,
          canActivate: [AppAuthGuard],
          data: {
            title: 'Edit Inventory Contact',
            roles: ['admin', 'inspector']
          }
        },
        {
          path: 'contacts/add-operator',
          component: AddOperatorComponent,
          canActivate: [AppAuthGuard],
          data: {
            title: 'Add Water Operator',
            roles: ['admin', 'inspector']
          }
        },
        {
          path: 'basic-details',
          component: BasicDetailsComponent,
          canActivate: [AppAuthGuard],
          data: {
            title: 'Basic Details',
            roles: ['admin', 'inspector']
          }
        },
        {
          path: 'notes',
          component: WaterSystemNotesComponent,
          canActivate: [AppAuthGuard],
          data: {
            title: 'Notes',
            roles: ['admin', 'inspector']
          }
        },
        {
          path: 'notes/add-note',
          component: AddNoteComponent,
          canActivate: [AppAuthGuard],
          data: {
            title: 'Add Note',
            roles: ['admin', 'inspector']
          }
        },
        {
          path: 'notes/edit-note/:id',
          component: EditNoteComponent,
          canActivate: [AppAuthGuard],
          data: {
            title: 'Edit Note',
            roles: ['admin', 'inspector']
          }
        },
        {
          path: 'site-visits',
          component: SiteVisitsComponent,
          canActivate: [AppAuthGuard],
          data: {
            title: 'Site Visits',
            roles: ['admin', 'inspector']
          }
        },
        {
          path: 'visit/:id',
          component: QuestionnaireListComponent,
          canActivate: [AppAuthGuard],
          data: {
            title: 'Site Visit',
            roles: ['admin', 'inspector']
          },
          children: [
            {
              path: 'questionnaire/:type',
              component: QuestionnaireComponent,
              canActivate: [AppAuthGuard],
              data: {
                title: 'Site Visit Questionnaire',
                roles: ['admin', 'inspector']
              }
            }
          ]
        },
        {
          path: 'visit/:id/view',
          component: QuestionnaireViewComponent,
          canActivate: [AppAuthGuard],
          data: {
            title: 'Question Set Summary',
            roles: ['admin', 'inspector']
          }
        },
        {
          path: 'visit/:id/viewfacility',
          component: FacilitiesViewComponent,
          canActivate: [AppAuthGuard],
          data: {
            title: 'Facility Summary',
            roles: ['admin', 'inspector']
          }
        },
        {
          path: 'visit/:id/viewBasicDetails',
          component: BasicDetailsSummaryComponent,
          canActivate: [AppAuthGuard],
          data: {
            title: 'Basic Details Summary',
            roles: ['admin', 'inspector']
          }
        },
        {
          path: 'visit/:id/viewContacts',
          component: ContactsSummaryComponent,
          canActivate: [AppAuthGuard],
          data: {
            title: 'Contacts Summary',
            roles: ['admin', 'inspector']
          }
        },
        {
          path: 'facilities',
          component: FacilitiesComponent,
          canActivate: [AppAuthGuard],
          data: {
            title: 'Facilities',
            roles: ['admin', 'inspector']
          }
        },
        {
          path: 'facilities/edit-facility/:id',
          component: EditFacilityComponent,
          canActivate: [AppAuthGuard],
          data: {
            title: 'Edit Facility',
            roles: ['admin', 'inspector']
          }
        },
        {
          path: 'facilities/add-facility',
          component: AddFacilityComponent,
          canActivate: [AppAuthGuard],
          data: {
            title: 'Add Facility',
            roles: ['admin', 'inspector']
          }
        },
        {
          path: 'visit/:id/findings-summary',
          component: ReportSummaryViewComponent,
          canActivate: [AppAuthGuard],
          data: {
            title: 'Findings Summary',
            roles: ['admin', 'inspector']
          }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WaterSystemRoutingModule { }
