import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PotentialContaminantType } from "../domain/potential-contaminant";

@Injectable({
  providedIn: 'root'
})
export class PotentialContaminantsListService {

  private readonly POTENTIAL_CONTAMINANTS_LIST_URL = environment.serverUrl + '/potentialcontaminationtype';

  constructor(private httpClient: HttpClient) { }

  /**
   * Gets the potential ContaminantS list from the remote API.
   */
  getPotentialContaminantsList(): Observable<PotentialContaminantType[]> {
    return this.httpClient.get<PotentialContaminantType[]>(`${this.POTENTIAL_CONTAMINANTS_LIST_URL}`);
  }
}
