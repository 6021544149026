<div id="water-system-service-area-summary" *ngIf="summary && (summary.licFpls || summary.licLoc || summary.licMda
|| summary.licDot || summary.serviceAreaPrimary || (summary.serviceAreaSecondary && summary.serviceAreaSecondary.length)); else noChanges">
  <div class="border p-2 panel-collapsible-header">
    <a href="javascript:void(0);" (click)="isCollapsed = !isCollapsed"
       [attr.aria-expanded]="!isCollapsed" aria-controls="collapse-service-area">
      <div>
        <h2>
          Service Area Characteristics and Licensing
          <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isCollapsed"></i>
          <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isCollapsed"></i>
        </h2>
      </div>
    </a>
  </div>
  <div id="collapse-service-area" class="card" [ngbCollapse]="isCollapsed">
    <div class="card-body row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <span><strong>Licensing</strong></span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-sm-12 col-12" *ngIf="summary.licFpls">
            <span><strong>FPLS:</strong></span>
            <br/>
            <span>{{summary.licFpls | yesNo}}</span>
          </div>
          <div class="col-md-3 col-sm-12 col-12" *ngIf="summary.licLoc">
            <span><strong>LOC:</strong></span>
            <br/>
            <span>{{summary.licLoc | yesNo}}</span>
          </div>
          <div class="col-md-3 col-sm-12 col-12" *ngIf="summary.licMda">
            <span><strong>MDA:</strong></span>
            <br/>
            <span>{{summary.licMda | yesNo}}</span>
          </div>
          <div class="col-md-3 col-sm-12 col-12" *ngIf="summary.licDot">
            <span><strong>DOT:</strong></span>
            <br/>
            <span>{{summary.licDot | yesNo}}</span>
          </div>
        </div>
        <div class="pt-3" *ngIf="summary.serviceAreaPrimary || (summary.serviceAreaSecondary && summary.serviceAreaSecondary.length)">
          <div class="row col-12">
            <span><strong>Service Area Characteristics</strong></span>
          </div>
          <div class="col-md-4 col-sm-12 col-12" *ngIf="summary.serviceAreaPrimary">
            <span><strong>Primary:</strong></span>
            <br/>
            <span>{{summary.serviceAreaPrimary | serviceAreaType}}</span>
          </div>
          <div class="col-md-4 col-sm-12 col-12" *ngIf="summary.serviceAreaSecondary && summary.serviceAreaSecondary.length">
            <span><strong>Secondary:</strong></span>
            <div *ngFor="let serviceArea of summary.serviceAreaSecondary; let i = index">
              <span>{{serviceArea | serviceAreaType}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #noChanges>
  <div>No Service Area Characteristics and Licensing Changes.</div>
</ng-template>
