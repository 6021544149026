import {Pipe, PipeTransform} from '@angular/core';
import {OWNER_TYPES} from './owner-type';

export const SERVICE_AREA_TYPES = [
  {code: '07', value: 'Airport'},
  {code: '21', value: 'Apartments'},
  {code: '32', value: 'Association'},
  {code: '63', value: 'Bank'},
  {code: 'BA', value: 'Bar'},
  {code: '72', value: 'Bed & Breakfast'},
  {code: '91', value: 'Bottling Plant'},
  {code: '70', value: 'Business'},
  {code: '1Y', value: 'Camp'},
  {code: 'CA', value: 'Campground'},
  {code: '2L', value: 'Catering'},
  {code: 'CH', value: 'Church'},
  {code: '3U', value: 'City Hall'},
  {code: '4P', value: 'College'},
  {code: '4R', value: 'Colony'},
  {code: 'CI', value: 'Commercial/Industrial'},
  {code: '4Y', value: 'Community Center'},
  {code: '5E', value: 'Condominiums'},
  {code: '5M', value: 'Convenience Store'},
  {code: '5O', value: 'Cooperative'},
  {code: '5P', value: 'Correctional Facility'},
  {code: '5V', value: 'County Building'},
  {code: '6D', value: 'Creamery'},
  {code: '6I', value: 'Dairy'},
  {code: '6O', value: 'Day Care'},
  {code: 'ZJ', value: 'Ethanol Plant'},
  {code: '8D', value: 'Fairgrounds'},
  {code: '8Y', value: 'Fire Station'},
  {code: '9L', value: 'Food Service'},
  {code: 'F2', value: 'Golf Course'},
  {code: 'HF', value: 'Health Facility'},
  {code: 'U3', value: 'Homeowners Association'},
  {code: 'HO', value: 'Hotel/Motel'},
  {code: 'X3', value: 'Housing'},
  {code: 'Z3', value: 'Housing Development'},
  {code: 'I4', value: 'Implement Dealer'},
  {code: 'M4', value: 'Industrial/Agricultural'},
  {code: 'IN', value: 'Institution'},
  {code: 'S5', value: 'Lodging'},
  {code: 'K6', value: 'Maintenance Shop'},
  {code: 'MH', value: 'Manufactured Home Park'},
  {code: 'X7', value: 'Mining Operation'},
  {code: 'MU', value: 'Municipal'},
  {code: 'H8', value: 'Nursery'},
  {code: 'N8', value: 'Office Building'},
  {code: 'X8', value: 'Orchard'},
  {code: 'OT', value: 'Other'},
  {code: 'Q9', value: 'Post Office'},
  {code: 'V9', value: 'Preschool'},
  {code: 'NA', value: 'RV Park'},
  {code: 'RA', value: 'Recreation Area'},
  {code: 'YB', value: 'Residential'},
  {code: 'RE', value: 'Resort'},
  {code: 'RS', value: 'Restaurant'},
  {code: 'HC', value: 'Retail Business'},
  {code: 'GD', value: 'Rural Water'},
  {code: 'UD', value: 'School'},
  {code: 'GF', value: 'Senior Housing'},
  {code: 'ZE', value: 'Special Event'},
  {code: 'MF', value: 'State Park'},
  {code: 'XG', value: 'Township'},
  {code: 'YG', value: 'Township Hall'},
  {code: 'II', value: 'Water Association'},
  {code: 'WR', value: 'Wayside Rest'},
  {code: 'ZI', value: 'Winery'}
];

@Pipe({name: 'serviceAreaType'})
export class ServiceAreaTypePipe implements PipeTransform {
  transform(key: string): string {
    const element = SERVICE_AREA_TYPES.find(e => e.code === key);
    if (element) {
      return element.value;
    }
    return key;
  }
}
