import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageFacility } from "../../domain/facility";

@Injectable({
  providedIn: 'root'
})
export class StorageFacilityService {

  private storageFacilities = new BehaviorSubject<StorageFacility[]>([]);
  storages$ = this.storageFacilities.asObservable();

  constructor() { }

  changeWaterSystemStorageFacilities(storageFacilities: StorageFacility[]) {
    this.storageFacilities.next(storageFacilities);
  }
}
