<form (submit)="save()" id="contact-mailing-form" #contactInfo="ngForm" [contacts]="contacts" [pwsId]="contact.pwsId"
  [contactId]="contact.id" appPrimaryRole>
  <div class="row">
    <div class="form-group col-12">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="contact-mailing-primary" name="primary"
          [(ngModel)]="contact.isPrimary">
        <label class="form-check-label" for="contact-mailing-primary">Primary</label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="contact-mailing-role">Role:</label>
      <select id="contact-mailing-role" class="form-control" name="role" [(ngModel)]="contact.role">
        <option *ngFor="let type of ROLE_TYPES" value="{{type.code}}">{{type.description}}</option>
      </select>
    </div>
  </div>

  <div *ngIf="contactInfo.errors?.primaryRole && (contactInfo.touched || contactInfo.dirty)"
    class="errorMessage text-danger">
    {{contactInfo.errors?.primaryRole}}
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="contact-mailing-name">Name:</label>
      <input type="text" class="form-control" id="contact-mailing-name" name="name" [(ngModel)]="contact.name">
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="contact-mailing-phone">Phone:</label>
      <input type="tel" class="form-control" id="contact-mailing-phone" name="phone" [(ngModel)]="contact.phone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
        phoneNumberMask numbersOnly maxlength="12">
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="contact-mailing-email">Email:</label>
      <input type="text" class="form-control" id="contact-mailing-email" name="email" [(ngModel)]="contact.email">
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="contact-mailing-address-1">Address Line 1:</label>
      <input type="text" class="form-control" id="contact-mailing-address-1" name="address-1"
        [(ngModel)]="contact.address1">
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="contact-mailing-address-2">Address Line 2:</label>
      <input type="text" class="form-control" id="contact-mailing-address-2" name="address-2"
        [(ngModel)]="contact.address2">
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="contact-mailing-address-3">Address Line 3:</label>
      <input type="text" class="form-control" id="contact-mailing-address-3" name="address-3"
        [(ngModel)]="contact.address3">
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="contact-mailing-city">City:</label>
      <select id="contact-mailing-city" class="form-control" name="city" [(ngModel)]="contact.city">
        <option value=""></option>
        <option *ngFor="let city of cities" value="{{city}}">{{city}}</option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="contact-mailing-state">State:</label>
      <select id="contact-mailing-state" class="form-control" name="state" [(ngModel)]="contact.state">
        <option value=""></option>
        <option *ngFor="let type of STATE_TYPES" value="{{type.code}}">{{type.value}}</option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="contact-mailing-zip">Zip Code:</label>
      <input type="text" class="form-control" id="contact-mailing-zip" name="zip" [(ngModel)]="contact.zip">
    </div>
  </div>

  <div *ngIf="!contactInfo.valid" class="errorMessage text-danger">
    Contact form is invalid please fix any errors before saving.
  </div>

  <input type="submit" class="btn btn-primary" value="Save" [disabled]="!contactInfo.valid"/>
  <input type="button" class="btn btn-outline-secondary ml-4" value="Cancel" (click)="cancel()" />

</form>
