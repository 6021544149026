import { BrowserModule } from '@angular/platform-browser';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';
import { initializer } from './utils/app-init';
import { AppAuthGuard } from './app.guard';
import { SharedModule } from './shared/shared.module';
import { ProfileModule } from './profile/profile.module';
import { WaterSystemModule } from './water-system/water-system.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { IamService } from './utils/iam.service';
import { AdminModule } from './admin/admin.module';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { dbConfig } from './utils/app-db';
import { StorageService } from './utils/storage.service';
import { RemoteSyncService } from './remote-sync.service';
import { CacheRemoteApisService } from "./utils/cache-remote-apis.service";

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        NgbModule,
        KeycloakAngularModule,
        ShellModule,
        HomeModule,
        SharedModule,
        ProfileModule,
        WaterSystemModule,
        AdminModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
        NgxIndexedDBModule.forRoot(dbConfig)], providers: [
        AppAuthGuard,
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            multi: true,
            deps: [KeycloakService, IamService, StorageService, RemoteSyncService, CacheRemoteApisService]
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
