import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { HttpClient } from '@angular/common/http';
import { tables } from './utils/app-db';
import { StoredFile } from './domain/file';
import { IdbStorage } from './domain/storage';
import { catchError } from 'rxjs/operators';

/**
 * Primary reason behind the subject is to push sync events to the UI
 * If an alternative is found to notify clients of async events, then there's no reason for a subject
 */
@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private readonly subject = new BehaviorSubject<LocalStorageService>(this);

  constructor(
    private dbService: NgxIndexedDBService,
    private http: HttpClient
  ) { }

  observable(): Observable<LocalStorageService> {
    return this.subject.asObservable();
  }

  store(key: string, item: any): void {
    localStorage.setItem(key, JSON.stringify(item));
    this.next();
  }

  retrieve<T>(key: string): T | null {
    const item = localStorage.getItem(key);
    return item !== null ? JSON.parse(item) : null;
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  private next(): void {
    this.subject.next(this);
  }

  storeFile(url: string, key: string) {
    this.http.get<Blob>(url, {responseType: 'blob' as 'json'}).pipe(
      catchError(err => {
        console.log('Error getting file');
        console.log(err);
        return throwError(err);
      })
    ).subscribe(result => {
      this.dbService.update(tables.file, {key: key, file: result} as StoredFile).subscribe(() => {
        console.log(`Stored ${key} file.`);
      }, err => {
        console.log(`Error storing ${key} file.`);
        console.log(err);
      });
    });
  }

  retrieveFile(key: string): Observable<StoredFile> {
    return this.dbService.getByKey(tables.file, key);
  }

  openFileUrl(key: string) {
    this.retrieveFile(key).subscribe(result => {
      const url = window.URL.createObjectURL(result.file);
      window.open(url, '_blank');
    });
  }

  dbStore(store: string, item: any): void {
    this.dbService.update(store, item).subscribe(() => {
      console.log(`Stored item in ${store}`);
    }, err => {
      console.log(`Error storing item in ${store}`);
      console.log(err);
    }, () => {
      this.next();
    });
  }

  dbStoreStorage(store: string, key: string | number, item: IdbStorage<any>): void {
    item.key = key;
    this.dbStore(store, item);
  }

  dbStoreStorageAny(store: string, key: string | number, item: any): void {
    this.dbStore(store, {key: key, data: item} as IdbStorage<any>);
  }

  dbRetrieve<T>(store: string, key: string | number | IDBValidKey): Observable<T> {
    return this.dbService.getByKey(store, key);
  }

  dbRetrieveAllByIndex<T>(store: string, key: string, value: any): Observable<T[]> {
    return this.dbService.getAllByIndex(store, key, IDBKeyRange.only(value));
  }

  dbRetrieveAll<T>(store: string): Observable<T[]> {
    return this.dbService.getAll(store);
  }

  dbRetrieveStorage<T>(store: string, key: string | number): Observable<T> {
    return new Observable<T>(obs => {
      this.dbRetrieve<IdbStorage<T>>(store, key).subscribe(result => {
        if (result) {
          obs.next(result.data);
        } else {
          obs.next();
        }
      });
    });
  }

  dbRemove(store: string, key: string | number): void {
    this.dbService.delete(store, key).subscribe(() => {
      console.log(`Removed ${key} from ${store}`);
    }, err => {
      console.log(`Error removing ${key} from ${store}`);
      console.log(err);
    });
  }

  dbRemoveObs(store: string, key: string | number): Observable<any[]> {
    return this.dbService.delete(store, key);
  }
}
