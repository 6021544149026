import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    const _this = this;
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e) => {
      _this.route.fragment.subscribe(f => {
        const selector = f ? `#${f} h1` : 'h1';
        const main: HTMLInputElement = document.querySelector(selector);
        if (main) {
          main.focus();
        }
      });
    });
   }
}
