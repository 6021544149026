import { Pipe, PipeTransform } from '@angular/core';
import { UNIT_MEASURE_CAPACITY_TYPES } from "./unit-measure-capacity-type";

@Pipe({name: 'unitMeasureCapacityType'})
export class UnitMeasureCapacityTypePipe implements PipeTransform {
  transform(code: string): string {
    for (let type of UNIT_MEASURE_CAPACITY_TYPES) {
      if (code === type.code) {
        return type.value;
      }
    }
    return code;
  }
}
