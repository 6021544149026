import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'seasonalIndYesNoNotverified'})
export class SeasonalIndYesNoNotverifiedPipe implements PipeTransform {
  transform(value: string): string {
    if (value === 'n') {
      return 'No';
    } else if (value === 'y') {
      return 'Yes';
    } else if (value === 'u') {
      return 'Not Verified';
    } else {
      return 'Unknown';
    }
  }
}