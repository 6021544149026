import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Checkout} from 'src/app/domain/checkout';
import {DistrictContact} from 'src/app/domain/district-contact';

@Injectable({
  providedIn: 'root'
})
export class DistrictContactService {

  private districtContact = new BehaviorSubject<Checkout<DistrictContact>>(null);
  districtContact$ = this.districtContact.asObservable();

  constructor() { }

  setWaterSystemDistrictContact(system: Checkout<DistrictContact>) {
    this.districtContact.next(system);
  }
}
