import { Injectable } from '@angular/core';
import { ConnectivityService } from './connectivity/connectivity.service';
import { SiteVisitCriteria } from './domain/SiteVisitCriteria';
import { KeycloakProfile } from 'keycloak-js';
import { PageRequest } from './domain/page-request';
import { SiteVisitService } from './site-visit.service';
import { StorageService } from './utils/storage.service';
import { WaterSystemSiteVisitsService } from './water-system/water-system-site-visits.service';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RemoteSyncService {

  constructor(private connectivityService: ConnectivityService,
              private siteVisitService: SiteVisitService,
              private waterSystemSiteVisitsService: WaterSystemSiteVisitsService,
  ) { }

  sync(userDetails: KeycloakProfile) {
    if (this.connectivityService.isOnline()) {
      const criteria = new SiteVisitCriteria();
      criteria.username = userDetails.username;
      const pageRequest = new PageRequest(0, 50);
      this.siteVisitService.searchWithLocalStorage(
        `${SiteVisitService.checkedOutKey}-${userDetails.username}`,
        criteria,
        pageRequest
      ).pipe(first()).subscribe(checkedOut => {
        checkedOut.results.forEach(siteVisit => {
          this.syncVisits(siteVisit.id);
        });
      });
    }
  }

  syncVisits(pwsId: number) {
    this.waterSystemSiteVisitsService.findAllLocally(pwsId).subscribe(visits => {
      if (visits && visits.length > 0) {
        for (let visit of visits) {
          if (visit.id == null) {
            this.waterSystemSiteVisitsService.createRemotely(pwsId).subscribe(() => {
              this.waterSystemSiteVisitsService.findAll(pwsId, true).subscribe();
            });
          }
        }
      }
    });
  }
}
