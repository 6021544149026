<div *ngIf="waterOperators && waterOperators.length; else noWaterOperators" class="content pb-3">
  <span class="h3 border-dark border-bottom row">{{ header }}</span>
  <div *ngFor="let waterOperator of waterOperators; let i = index"
       class="row"
       [style.border-top-color]="i > 0 ? '#d9d9d6' : ''"
       [style.border-top-width]="i > 0 ? 'thin' : ''"
       [style.border-top-style]="i > 0 ? 'solid' : ''">
    <div class="col-sm">
      <span><strong>Name:</strong></span>
      <br/>
      <span>{{ waterOperator.name }}</span>
    </div>
    <div class="col-sm">
      <span><strong>Phone:</strong></span>
      <br/>
      <span>{{ waterOperator.phone | phoneNumber }}</span>
    </div>
    <div class="col-sm">
      <span><strong>Class:</strong></span>
      <br/>
      <span>{{ waterOperator.certificateClass }}</span>
    </div>
    <div class="col-sm mb-3">
      <span><strong>Expiration Date:</strong></span>
      <br/>
      <span>{{ waterOperator.expirationDate | date:'MM/dd/yyyy' }}</span>
    </div>
  </div>
</div>
<ng-template #noWaterOperators>
  <span class="h3 row">No {{ header }}</span>
</ng-template>
