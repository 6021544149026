import {Component, Input, OnInit} from '@angular/core';
import {VisitTypeAnnualSanitaryPipe} from "../../../shared/visit-type.pipe";
import {Visit} from "../../../domain/visit";
import {FacilityType} from "../../../domain/inspection";

@Component({
  selector: 'app-visit-summary',
  templateUrl: './visit-summary.component.html',
  styleUrls: ['./visit-summary.component.scss']
})
export class VisitSummaryComponent implements OnInit {

  @Input()
  siteVisit: Visit;

  @Input()
  waterSystemId: number;

  @Input()
  collapseAll = true;

  facilityType = FacilityType;

  constructor() { }

  ngOnInit(): void {
  }

  get hasWellQuestions(): boolean {
    if (
      this.siteVisit.inspection.questionnaires.well &&
      this.siteVisit.inspection.questionnaires.well.facilities &&
      this.siteVisit.inspection.questionnaires.well.facilities.length > 0 &&
      this.siteVisit.inspection.questionnaires.well.facilities
    ) {
      return true;
    } else {
      return false;
    }
  }

  get hasStorageQuestions(): boolean {
    if (
      (this.siteVisit.inspection.questionnaires.storage.facilities &&
        this.siteVisit.inspection.questionnaires.storage.facilities.length >
        0 &&
        this.siteVisit.inspection.questionnaires.storage.facilities) ||
      (this.siteVisit.inspection.questionnaires.storage.missing &&
        this.siteVisit.inspection.questionnaires.storage.missing.missing)
    ) {
      return true;
    } else {
      return false;
    }
  }

  get hasTreatmentQuestions(): boolean {
    if (
      (this.siteVisit.inspection.questionnaires.treatment.facilities &&
        this.siteVisit.inspection.questionnaires.treatment.facilities.length > 0) ||
      (this.siteVisit.inspection.questionnaires.storage.missing &&
        this.siteVisit.inspection.questionnaires.storage.missing.missing)
    ) {
      return true;
    } else {
      return false;
    }
  }

  get hasDistributionQuestions(): boolean {
    if (
      this.siteVisit.inspection.questionnaires.distribution &&
      this.siteVisit.inspection.questionnaires.distribution.facilities &&
      this.siteVisit.inspection.questionnaires.distribution.facilities.length >
      0
    ) {
      return true;
    } else {
      return false;
    }
  }

  get hasSurfaceWaterQuestions(): boolean {
    if (
      this.siteVisit.inspection.questionnaires.surfaceWater &&
      this.siteVisit.inspection.questionnaires.surfaceWater.facilities &&
      this.siteVisit.inspection.questionnaires.surfaceWater.facilities.length >
      0
    ) {
      return true;
    } else {
      return false;
    }
  }

  get hasInterconnectQuestions(): boolean {
    if (
      this.siteVisit.inspection.questionnaires.interconnect &&
      this.siteVisit.inspection.questionnaires.interconnect.facilities &&
      this.siteVisit.inspection.questionnaires.interconnect.facilities.length >
      0
    ) {
      return true;
    } else {
      return false;
    }
  }

}
