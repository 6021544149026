import { Component, OnInit } from '@angular/core';
import { Question } from 'src/app/domain/question';
import { ConnectivityService } from 'src/app/connectivity/connectivity.service';
import { QUESTION_TYPES } from 'src/app/shared/question-type';
import { ConfirmatoryActionService } from 'src/app/confirmatory-action/confirmatory-action.service';
import { MessageService } from 'src/app/message/message.service';
import { Severity } from 'src/app/message/Severity';
import {
  getQuestionByUuid,
  Questionnaire,
  removeQuestionByUuid,
  getDependentQuestions,
  getAllDependentQuestions
} from 'src/app/domain/questionnaire';
import { QuestionnairesService } from '../questionnaires.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Answer } from 'src/app/domain/answer';
import { ToastService } from 'src/app/toast/toast.service';

@Component({
  selector: 'app-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: ['./edit-question.component.scss']
})
export class EditQuestionComponent implements OnInit {

  questionnaire: Questionnaire;
  question: Question;
  uuid: string;
  dependentQuestions: Question[] = [];
  allDependentQuestions: Question[] = [];
  public isOnline: boolean;
  public isQuestionLoading: boolean = true;
  public add: boolean = false;
  public prerequisiteQuestionInactive: boolean = false;
  public hasError: boolean;
  error = null;

  readonly QUESTION_TYPES: string[] = QUESTION_TYPES;

  constructor(private connectivityService: ConnectivityService,
              private confirmatoryActionService: ConfirmatoryActionService,
              private messageService: MessageService,
              private questionnairesService: QuestionnairesService,
              private route: ActivatedRoute,
              private router: Router,
              public toastService: ToastService) { }

  ngOnInit(): void {
    this.hasError = false;
    this.isOnline = this.connectivityService.isOnline();
    this.connectivityService.online$().subscribe(online => {
      this.isOnline = online;
    });
    this.getQuestionnaire();
  }

  getQuestionnaire() {
    this.questionnaire = history.state.data;
    this.uuid = history.state.uuid;
    if (this.questionnaire == null) {
      this.route.paramMap.subscribe(params => {
        const id = params.get('id');
        if (id == null) {
          this.hasError = true;
          this.messageService.add({ severity: Severity.ERROR, value: 'A question set ID was not provided.' });
          return;
        }

        this.questionnairesService.get(id).subscribe(
          questionnaire => {
            if (questionnaire) {
              this.questionnaire = questionnaire;
              if (this.uuid) {
                this.question = getQuestionByUuid(this.questionnaire, this.uuid);
                this.getDependentQuestions();
                this.isQuestionLoading = false;
              } else {
                this.route.paramMap.subscribe(params => {
                  this.getQuestionByParams(params)
                });
              }
            } else {
              this.hasError = true;
              this.messageService.add({ severity: Severity.ERROR, value: `Question set with ID ${id} does not exist.` });
              this.isQuestionLoading = false;
            }
          },
          error => {
            this.error = error;
            this.isQuestionLoading = false;
          }
        );
      });
    } else if (!this.uuid) {
      this.route.paramMap.subscribe(params => {
        this.getQuestionByParams(params)
      });
    } else {
      this.question = getQuestionByUuid(this.questionnaire, this.uuid);
      this.getDependentQuestions();
      this.isQuestionLoading = false;
    }
  }

  getQuestionByParams(params: ParamMap) {
    this.uuid = params.get('uuid');
    this.question = getQuestionByUuid(this.questionnaire, this.uuid);
    if (!this.question) {
      this.hasError = true;
      this.messageService.add({ severity: Severity.ERROR, value: `The question ID ${this.uuid} is not valid for this question set.` });
    } else {
      this.getDependentQuestions();
    }
    this.isQuestionLoading = false;
  }

  onChange(field: string) {
    this.messageService.clear();
    if (this.question.type && this.question.displayName && this.question.displayName !== '') {
      this.updateQuestion(field);
    }
  }

  addAnswer() {
    this.add = true;
  }

  deleteQuestion() {
    let confirmDeleteMessage: string = 'Are you sure you want to delete this question?';
    if (this.dependentQuestions.length > 0) {
      confirmDeleteMessage += ' Questions depending on this question will be deactivated.';
    }
    this.confirmatoryActionService.confirm("Confirmation", confirmDeleteMessage, "Yes", "No", "sm")
      .then((confirmed) => {
        if (confirmed) {
          this.messageService.clear();
          for (let dependentQuestion of this.dependentQuestions) {
            dependentQuestion.questionActiveInd = false;
            delete dependentQuestion.prerequisiteAnswer;
            delete dependentQuestion.prerequisiteQuestionUuid;
          }
          removeQuestionByUuid(this.questionnaire, this.uuid)
          this.questionnairesService.upsertRemotely(this.questionnaire).subscribe(
            () => {
              this.router.navigate(['/admin/questionnaires/edit', this.questionnaire.id]);
              const now = new Date();
              const toast = {
                text: 'The Question was deleted at ' + now.toLocaleString(),
                classname: 'toast bg-success fade show',
                autohide: true,
                delay: 5000,
                showLoading: false
              };
              this.toastService.show(toast);
              setTimeout(() => this.toastService.remove(toast), 5000);
            }
          );
        }
      })
      .catch(() => {});
  }

  updateQuestion(field: string) {
    const updatingToast = {
      text: 'Updating ' + field + '...',
      classname: 'toast bg-warning fade show',
      autohide: false,
      delay: 5000,
      showLoading: true
    };
    this.toastService.show(updatingToast);
    this.questionnairesService.upsertRemotely(this.questionnaire).subscribe(
      () => {
        setTimeout(() => this.toastService.remove(updatingToast), 1000);
        const now = new Date();
        const toast = {
          text: field + ' was updated at ' + now.toLocaleString(),
          classname: 'toast bg-success fade show',
          autohide: true,
          delay: 5000,
          showLoading: false
        };
        this.toastService.show(toast);
        setTimeout(() => this.toastService.remove(toast), 5000);
      },
      error => {
        this.toastService.remove(updatingToast);
        this.messageService.add({ severity: Severity.ERROR, value: 'The Question failed to save due to: ' + error.message });
      }
    );
  }

  addYesNoAnswers() {
    let yesAnswer: Answer = {
      name: 'Yes',
      noteRequired: false,
      photoRequired: false,
      attachmentRequired: false,
      noteLabelOverride: null
    };
    let noAnswer: Answer = {
      name: 'No',
      noteRequired: false,
      photoRequired: false,
      attachmentRequired: false,
      noteLabelOverride: null
    };
    if (!this.question.answers) {
      this.question.answers = [];
    }
    this.question.answers.push(yesAnswer);
    this.question.answers.push(noAnswer);
    this.onChange('Yes and No Answers');
  }

  isYesOrNoAnswerExists(): boolean {
    for (let answer of this.question.answers) {
      if (answer.name === 'Yes' || answer.name === 'No') {
        return true;
      }
    }
    return false;
  }

  updateAnswer(answerChange: {answers: Answer[], changedField: string}) {
    if (answerChange.answers.length === 0) {
      this.question.questionActiveInd = false;
      for (let dependentQuestion of this.dependentQuestions) {
        this.questionnaire.question[this.questionnaire.question.indexOf(dependentQuestion)].questionActiveInd = false;
      }
    }
    this.onChange(answerChange.changedField);
  }

  onPrerequisiteQuestionChange(field: string) {
    if (!this.question.prerequisiteQuestionUuid) {
      if (this.question.prerequisiteAnswer) {
        delete this.question.prerequisiteAnswer;
        delete this.question.prerequisiteQuestionUuid;
        this.question.required = false;
        this.prerequisiteQuestionInactive = false;
        this.onChange(field);
      }
    } else {
      if (!getQuestionByUuid(this.questionnaire, this.question.prerequisiteQuestionUuid).questionActiveInd) {
        this.prerequisiteQuestionInactive = true;
      }
      delete this.question.prerequisiteAnswer;
      this.question.required = true;
    }
  }

  onPrerequisiteAnswerChange(field: string) {
    if (this.question.prerequisiteQuestionUuid && this.question.prerequisiteAnswer) {
      this.onChange(field);
    } else {
      delete this.question.prerequisiteAnswer;
    }
  }

  onActiveChange(field: string) {
    if (!this.question.questionActiveInd) {
      if (this.dependentQuestions.length > 0) {
        this.confirmatoryActionService.confirm(
            "Confirmation",
            "Deactivating this question will also deactivate all questions that are dependent on this question. Do you want to continue?",
            "Yes",
            "No",
            "sm")
          .then((confirmed) => {
            if (confirmed) {
              for (let dependentQuestion of this.dependentQuestions) {
                this.questionnaire.question[this.questionnaire.question.indexOf(dependentQuestion)].questionActiveInd = false;
              }
              this.onChange(field);
            } else {
              this.question.questionActiveInd = true;
            }
          }, () => {
            this.question.questionActiveInd = true;
          })
          .catch(() => {});
      } else {
        this.onChange(field);
      }
    } else {
      this.onChange(field);
    }
  }

  getDependentQuestions() {
    this.dependentQuestions = getDependentQuestions(this.questionnaire, this.question.uuid)
    this.allDependentQuestions = getAllDependentQuestions(this.questionnaire, this.question.uuid)

    if (this.question.prerequisiteQuestionUuid) {
      if (!getQuestionByUuid(this.questionnaire, this.question.prerequisiteQuestionUuid).questionActiveInd) {
        this.prerequisiteQuestionInactive = true;
      }
    }
  }

  getDisabledActiveTitle(): string {
    if (!this.question?.displayName) {
      return 'The question display name must be entered.';
    } else if (this.question.type === 'Section Header') {
      return 'Question Active Indicator';
    } else if (this.question.type === 'Question' && (!this.question?.answers || this.question?.answers.length == 0)) {
      return 'There must be at least one answer.';
    } else if (this.question.type === 'Finding' && (!this.question?.answers || this.question?.answers.length == 0)) {
      return 'A Finding must be selected.'
    } else if (this.question.prerequisiteQuestionUuid && !this.question.prerequisiteAnswer) {
       return 'A prerequisite answer is required.';
    } else {
      return 'The prerequisite question is not active.';
    }
  }

  updateFinding(findingChange: {additionalFinding: Answer, changedField: string}) {
    if (findingChange.additionalFinding && findingChange.additionalFinding.finding && findingChange.additionalFinding.finding.code) {
      this.question.answers[0] = findingChange.additionalFinding;
      this.onChange(findingChange.changedField);
    }
  }

  get canChangeActive(): boolean {
    if (this.question && this.question.displayName && (this.question.prerequisiteQuestionUuid
      || (!this.question.prerequisiteQuestionUuid && !this.question.prerequisiteAnswer
        && !this.prerequisiteQuestionInactive))
      && (this.question.type === 'Section Header' || this.question.answers.length > 0)) {
      return true;
    } else {
      return false;
    }
  }

  isPossiblePrerequisiteQuestion(question: Question):boolean {
    return question.type === 'Question' && question.uuid !== this.question.uuid
      && !this.isInFollowUpChain(question)
  }

  isInFollowUpChain(question: Question): boolean {
    if (this.allDependentQuestions.find(q => q.uuid === question.uuid)) {
      return true;
    } else {
      return false;
    }
  }

  prerequisiteQuestion():Question {
    return getQuestionByUuid(this.questionnaire, this.question.prerequisiteQuestionUuid)
  }
}
