import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BoosterStation } from "../../domain/facility";

@Injectable({
  providedIn: 'root'
})
export class BoosterStationFacilityService {

  private boosterStationFacilities = new BehaviorSubject<BoosterStation[]>([]);
  boosterStations$ = this.boosterStationFacilities.asObservable();

  constructor() { }

  changeWaterSystemBoosterStationFacilities(boosterStationFacilities: BoosterStation[]) {
    this.boosterStationFacilities.next(boosterStationFacilities);
  }
}
