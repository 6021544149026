import {Pipe, PipeTransform} from '@angular/core';
import { FACILITY_TYPES } from './facility-type';

@Pipe({name: 'facilityType'})
export class FacilityTypePipe implements PipeTransform {
  transform(code: string): string {
    if (!code || code === '') {
      return code;
    }

    for (let type of FACILITY_TYPES) {
      if (code === type.code) {
        return type.value;
      }
    }
    return code;
  }
}