import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Questionnaire } from 'src/app/domain/questionnaire';
import { ConnectivityService } from 'src/app/connectivity/connectivity.service';
import { of, Observable } from 'rxjs';
import {LocalStorageService} from '../../local-storage.service';
import {tables} from '../../utils/app-db';

@Injectable({
  providedIn: 'root'
})
export class QuestionnairesService {

  private questionnairesUrl = environment.serverUrl + '/questionset';

  constructor(private httpClient: HttpClient,
              private connectivityService: ConnectivityService,
              private localStorageService: LocalStorageService
  ) { }

  getAll(): Observable<Questionnaire[]> {
    if (this.connectivityService.isOnline()) {
      return new Observable<Questionnaire[]>((obs) => {
        this.getAllRemotely().subscribe(results => {
          results.forEach(result => this.localStorageService.dbStore(tables.questionnaire, result));
          obs.next(results);
          obs.complete();
        });
      });
    } else {
      return this.getAllLocally();
    }
  }

  getAllRemotely(): Observable<Questionnaire[]> {
    return this.httpClient.get<Questionnaire[]>(this.questionnairesUrl);
  }

  getAllLocally(): Observable<Questionnaire[]> {
    return this.localStorageService.dbRetrieveAll<Questionnaire>(tables.questionnaire);
  }

  get(id: string): Observable<any> {
    return this.httpClient.get<any>(`${this.questionnairesUrl}/${id}`);
  }

  upsertRemotely(questionnaire: Questionnaire) {
    if (this.connectivityService.isOnline()) {
      return this.httpClient.post(`${this.questionnairesUrl}`, questionnaire);
    } else {
      return of(null);
    }
  }

  deleteRemotely(questionnaire: Questionnaire): Observable<{}> {
    if (this.connectivityService.isOnline()) {
      return this.httpClient.delete(`${this.questionnairesUrl}/${questionnaire.id}`);
    } else {
      return of(null);
    }
  }
}
