export const FACILITY_TYPES = [
  {code: 'BS', value: 'Booster Station', addable: true},
  {code: 'CD', value: 'Combined Discharge', addable: false},
  {code: 'CC', value: 'Consecutive Connection', addable: true},
  {code: 'DS', value: 'Distribution System', addable: true},
  {code: 'IN', value: 'Intake', addable: true},
  {code: 'NP', value: 'Non-piped, Purchased', addable: false},
  {code: 'PF', value: 'Pump Facility', addable: false},
  {code: 'ST', value: 'Storage', addable: true},
  {code: 'SE', value: 'Storage-Elevated', addable: true},
  {code: 'SU', value: 'Storage-Ground', addable: true},
  {code: 'SH', value: 'Storage-Hydropneumatic', addable: true},
  {code: 'SS', value: 'Storage-Standpipe', addable: true},
  {code: 'TP', value: 'Treatment Plant', addable: true},
  {code: 'WL', value: 'Well', addable: true}
];
