import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, concatMap, map, take, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Note } from '../domain/note';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { LocalStorageService } from '../local-storage.service';
import { SyncResultFactory } from '../utils/sync-result-factory';
import { SyncSchedulerService } from './sync-scheduler.service';
import { tables } from '../utils/app-db';
import { SyncResult, SyncType } from '../domain/sync';
import { NotesService } from "./shared/notes/notes.service";

@Injectable({
  providedIn: 'root'
})
export class WaterSystemNotesService {

  private readonly WATER_SYSTEM_URL = environment.serverUrl + '/watersystem';
  private readonly WATER_SYSTEM_NOTES_URL = environment.serverUrl + '/notepws';
  private readonly NOTES_SUB_ENDPOINT = 'notepws';

  constructor(private connectivityService: ConnectivityService,
              private httpClient: HttpClient,
              private localStorageService: LocalStorageService,
              private syncScheduler: SyncSchedulerService,
              private notesService: NotesService) { }

  /**
   * Returns water system notes.
   *
   * @param id the id of the water system
   * @param getRemotely if data should be retrieved from remote directly
   */
  find(id: number, getRemotely: boolean): Observable<Note[]> {
    return new Observable<Note[]>(obs => {
      this.findLocally(id).subscribe(wsLocal => {
        if (wsLocal) {
          obs.next(wsLocal);
          obs.complete();
        } else {
          if (this.connectivityService.isOnline()) {
            this.findRemotelyTimer(id).subscribe (wsRemote => {
              obs.next(wsRemote);
              obs.complete();
            }, error => {
              obs.error(error);
            });
          } else {
            obs.complete();
          }
        }
      });
    });
  }

  findLocally(id: number): Observable<Note[]> {
    return this.localStorageService.dbRetrieveStorage<Note[]>(tables.waterSystemNote, id);
  }

  findRemotely(id: number): Observable<Note[]> {
    return this.httpClient.get<Note[]>(`${this.WATER_SYSTEM_URL}/${id}/${this.NOTES_SUB_ENDPOINT}`);
  }

  private findRemotelyTimer(id: number): Observable<Note[]> {
    const stopTimer$ = timer(10 * 60 * 1000);
    return timer(0, 15000)
      .pipe(
        takeUntil(stopTimer$),
        concatMap(() => this.findRemotely(id).pipe(map(response => response))),
        take(1),
        tap(found => {
          this.store(id, found);
          return found;
        }),
        catchError(error => {
          if (error.status === 404) {
            return of(error.error);
          } else {
            return throwError(error);
          }
        })
      );
  }

  save(id: number, waterSystemNotes: Note[]): void {
    this.store(id, waterSystemNotes);
    this.syncScheduler.schedule(SyncType.Notes, id).subscribe();
  }

  saveLocally(id: number, waterSystemNotes: Note[]): void {
    this.store(id, waterSystemNotes);
  }

  insertRemotely(note: Note): Observable<{}> {
    const { action, ...apiNote } = note;
    return this.httpClient.post(`${this.WATER_SYSTEM_NOTES_URL}`, apiNote);
  }

  updateRemotely(id: number, note: Note): Observable<{}> {
    const { action, ...apiNote } = note;
    return this.httpClient.put(`${this.WATER_SYSTEM_NOTES_URL}/${note.id}`, apiNote);
  }

  deleteRemotely(note: Note): Observable<{}> {
    return this.httpClient.delete(`${this.WATER_SYSTEM_NOTES_URL}/${note.id}`);
  }

  private store(id: number, notes: Note[]): void {
    this.localStorageService.dbStoreStorageAny(tables.waterSystemNote, id, notes);
  }

  /**
   * Syncs scheduled water systems notes if online
   */
  sync(): void {
    if (this.connectivityService.isOnline()) {
      this.syncScheduler.scheduled(SyncType.Notes).subscribe(syncResults => {
        syncResults.forEach(syncResult => {
          this.findLocally(syncResult.data.id).subscribe(waterSystemNotes => {
            for (const note of waterSystemNotes) {
              let ob = null;
              if (note.action === 'I') {
                ob = this.insertRemotely(note);
              } else if (note.action === 'U') {
                ob = this.updateRemotely(syncResult.data.id, note);
              } else if (note.action === 'D') {
                ob = this.deleteRemotely(note);
              }
              if (ob != null) {
                ob.subscribe(
                  () => {
                    this.syncScheduler.unschedule(SyncType.Notes, syncResult.data.id,
                      SyncResultFactory.synced(SyncType.Notes, syncResult.data.id));
                    this.findRemotelyTimer(syncResult.data.id).subscribe(notes => {
                      this.notesService.changeWaterSystemNotes(notes);
                    });
                  },
                  error => this.syncScheduler.unschedule(SyncType.Notes, syncResult.data.id,
                    this.translateSyncErrorResponse(SyncType.Notes, syncResult.data.id, error))
                );
              }
            }
            this.syncScheduler.unschedule(SyncType.Notes, syncResult.data.id,
              SyncResultFactory.synced(SyncType.Notes, syncResult.data.id));
          });
        });
      })
    }
  }

  // https://angular.io/guide/http#error-handling
  private translateSyncErrorResponse(type: SyncType, id: number, error: HttpErrorResponse | ErrorEvent): SyncResult {
    const messages: Array<string> = [];

    if (error instanceof HttpErrorResponse) {
      // todo: user-friendly messages, handle additional scenarios
      switch (error.status) {
        case 403: {
          messages.push('You are unauthorized to save this water system note.');
          break;
        }
        case 422: {
          // todo: handle validation messages
          messages.push(error.message);
          break;
        }
        case 500: {
          messages.push('The API has encountered an error.');
          break;
        }
        case 503: {
          messages.push('The API is currently down.');
          break;
        }
        case 0: {
          messages.push('A communication error occurred.');
          break;
        }
        default: {
          messages.push('An unexpected error occurred.');
          break;
        }
      }
    } else {
      messages.push(error.error.message);
    }

    return SyncResultFactory.error(type, id, messages);
  }

  deleteLocally(id: number): void {
    this.localStorageService.dbRemove(tables.waterSystemNote, id);
  }
}
