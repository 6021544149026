<div id="water-operators-summary" *ngIf="isWaterOperatorsChanged; else noChanges">
  <div class="border p-2 panel-collapsible-header">
    <a href="javascript:void(0);" (click)="isCollapsed = !isCollapsed"
       [attr.aria-expanded]="!isCollapsed" aria-controls="collapse-water-operators">
      <div>
        <h2>
          Water Operators
          <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon"
             *ngIf="isCollapsed"></i>
          <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon"
             *ngIf="!isCollapsed"></i>
        </h2>
      </div>
    </a>
  </div>
  <div id="collapse-water-operators" class="card" [ngbCollapse]="isCollapsed">
    <div class="card-body row">
      <div class="col-12">
        <app-water-operator-summary [waterOperators]="addedWaterOperators"
                                    [header]="'Added Water Operators'"></app-water-operator-summary>
      </div>
    </div>
  </div>
</div>
<ng-template #noChanges>
  <div>No Water Operator Changes.</div>
</ng-template>
