<div class="container-fluid">
  <div class="p-3" *ngIf="isOnline; else offlineMsg">
    <h1>Maintain Questions</h1>
    <div *ngIf="isQuestionnairesLoading" class="pl-3">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading Question Sets...</span>
      </div>
      <span>Loading Question Sets...</span>
    </div>
    <div *ngIf="isQuestionnaireDeleting" class="pl-3">
      <div class="spinner-border" role="status">
        <span class="sr-only">Deleting Question Set...</span>
      </div>
      <span>Deleting Question Set...</span>
    </div>
    <div *ngIf="!isQuestionnairesLoading && !isQuestionnaireDeleting">
      <app-message></app-message>
      <div *ngIf="!disableAdd">
        <button type="button" class="btn btn-outline-primary" [routerLink]="['/admin/questionnaires/edit']">Add Question Set</button>
        <div class="ml-2 d-inline">
          <label for="import-question-set" class="form-label btn btn-outline-primary mb-0">
            <input class="form-control d-none" type="file" id="import-question-set" (change)="onImportQuestionSet($event.target.files)" accept=".json">
            Import Question Set
          </label>
        </div>
      </div>
      <div class="table-responsive pt-3">
        <table class="table table-bordered table-hover table-sm" id="questionnaires-table">
          <thead class="thead-light">
            <tr>
              <th scope="col">Set Category</th>
              <th scope="col">Set Type</th>
              <th scope="col">Description</th>
              <th scope="col">Active</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody *ngIf="questionnaires && questionnaires.length > 0">
            <tr *ngFor="let questionnaire of questionnaires">
              <td>{{ questionnaire?.category }}</td>
              <td>{{ questionnaire?.item }}</td>
              <td>{{ questionnaire?.description }}</td>
              <td><span>{{ questionnaire?.activeInd ? 'Active' : 'Inactive' }}</span></td>
              <td class="align-middle">
                <button type="button" class="btn btn-outline-dark btn-sm py-0" [routerLink]="['edit', questionnaire.id]" routerLinkActive="active" [state]="{ data : questionnaire }"
                        attr.aria-label="Edit questionnaire with type {{ questionnaire.item }} and description {{ questionnaire.description }}">
                  Edit
                </button>
                <button type="button" class="btn btn-outline-dark btn-sm py-0 ml-2" (click)="deleteQuestionnaire(questionnaire)"
                        attr.aria-label="Delete questionnaire with type {{ questionnaire.item }} and description {{ questionnaire.description }}">
                  Delete
                </button>
                <button type="button" class="btn btn-outline-dark btn-sm py-0 ml-2" (click)="exportQuestionnaire(questionnaire)"
                        attr.aria-label="Export questionnaire with type {{ questionnaire.item }} and description {{ questionnaire.description }}">
                  Export
                </button>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="questionnaires == null || questionnaires.length == 0">
            <tr>
              <td colspan="5">
                No Question Sets Found.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ng-template #offlineMsg>
    <div class="p-3">Question Sets are not available offline.</div>
  </ng-template>
</div>
