<div *ngIf="contacts && contacts.length; else noContacts" class="content pb-3">
  <span class="h3 border-dark border-bottom row">{{ header }}</span>
  <div *ngFor="let contact of contacts; let i = index"
       class="row"
       [style.border-top-color]="i > 0 ? '#d9d9d6' : ''"
       [style.border-top-width]="i > 0 ? 'thin' : ''"
       [style.border-top-style]="i > 0 ? 'solid' : ''">
    <div class="col-sm">
      <span><strong>Primary</strong></span>
      <span *ngIf="contact.diff && contact.diff.isPrimary"> (Edited)</span>
      <span><strong>:</strong></span>
      <br/>
      <span>{{ contact.isPrimary | yesNo }}</span>
    </div>
    <div class="col-sm">
      <span><strong>Role</strong></span>
      <span *ngIf="contact.diff && contact.diff.role"> (Edited)</span>
      <span><strong>:</strong></span>
      <br/>
      <span>{{ contact.role | roleType }}</span>
    </div>
    <div class="col-sm">
      <span><strong>Name</strong></span>
      <span *ngIf="contact.diff && contact.diff.name"> (Edited)</span>
      <span><strong>:</strong></span>
      <br/>
      <span>{{ contact.name }}</span>
    </div>
    <div class="col-sm">
      <span><strong>Phone</strong></span>
      <span *ngIf="contact.diff && contact.diff.phone"> (Edited)</span>
      <span><strong>:</strong></span>
      <br/>
      <span>{{ contact.phone | phoneNumber }}</span>
    </div>
    <div class="col-sm">
      <span><strong>Email</strong></span>
      <span *ngIf="contact.diff && contact.diff.email"> (Edited)</span>
      <span><strong>:</strong></span>
      <br/>
      <span>{{ contact.email }}</span>
    </div>
    <div class="col-sm">
      <span><strong>Address</strong></span>
      <span
        *ngIf="contact.diff && (contact.diff.address1 || contact.diff.address2 || contact.diff.address3
        || contact.diff.city || contact.diff.state || contact.diff.zip)"> (Edited)</span>
      <span><strong>:</strong></span>
      <br/>
      <address class="pre-line-breaker">
        {{ contact | contactAddress }}
      </address>
    </div>
  </div>
</div>
<ng-template #noContacts>
  <span class="h3 row">No {{ header }}.</span>
</ng-template>
