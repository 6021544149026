import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConnectivityService } from '../connectivity/connectivity.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private readonly CONFIGURATION_URL = environment.serverUrl + '/admin/configuration';

  constructor(private connectivityService: ConnectivityService,
              private httpClient: HttpClient) { }

  /**
   * Gets the configuration from the remote API for the provided type.
   *
   * @param type the type of configuration
   */
  get(type: string): Observable<any> {
    if (this.connectivityService.isOnline()) {
      return this.httpClient.get<any>(`${this.CONFIGURATION_URL}/${type}`);
    }
  }

  /**
   * Updates the configuration in the remote API.
   *
   * @param type the type of configuration
   * @param configuration the configuration values to update
   */
  update(type: string, configuration: any): Observable<{}> {
    if (this.connectivityService.isOnline()) {
      return this.httpClient.post(`${this.CONFIGURATION_URL}/${type}`, configuration);
    } else {
      return of(null);
    }
  }
}
