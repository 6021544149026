import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { take } from 'rxjs/operators';
import { Note } from 'src/app/domain/note';
import { Severity } from 'src/app/message/Severity';
import { MessageService } from 'src/app/message/message.service';
import { NotesService } from '../notes.service';
import { WaterSystemNotesService } from 'src/app/water-system/water-system-notes.service';
import { ConnectivityService } from "../../../../connectivity/connectivity.service";

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})
export class AddNoteComponent implements OnInit {

  note: Note = {
    id: null,
    createdBy: null,
    createDate: null,
    note: null,
    pwsId: null,
    relatedItem: null,
    action: null,
    name: null
  }

  private notes: Note[];

  userDetails: KeycloakProfile;

  public isAdding: boolean = false;

  constructor(private messageService: MessageService,
              private notesService: NotesService,
              private waterSystemNotesService: WaterSystemNotesService,
              private router: Router,
              private route: ActivatedRoute,
              private keycloakService: KeycloakService,
              private connectivityService: ConnectivityService) { }

  async ngOnInit() {
    this.notesService.notes$.subscribe(notes => this.notes = notes);
    this.route.parent.params.subscribe(params => {
      this.note.pwsId = params['id'];
    });
    if (await this.keycloakService.isLoggedIn()) {
      this.userDetails = await this.keycloakService.loadUserProfile();
    }
    this.note.createDate = new Date();
    this.note.createdBy = this.userDetails.username;
    this.note.name = this.userDetails.firstName + ' ' + this.userDetails.lastName;
    this.note.relatedItem = history.state.relatedItem;
    this.note.action = 'I';
    this.note.id = this.note.createDate.getTime();
  }

  add(note: Note) {
    this.isAdding = true;
    this.messageService.clear();
    if (this.notes == null) {
      this.notes = [];
    }
    this.notes.push(note);
    if (this.connectivityService.isOnline()) {
      this.waterSystemNotesService.insertRemotely(note).pipe(take(1)).subscribe(() => {
        this.waterSystemNotesService.findRemotely(note.pwsId).pipe(take(1)).subscribe(notes => {
          this.waterSystemNotesService.saveLocally(+note.pwsId, notes);
          this.notesService.changeWaterSystemNotes(notes);
          this.successNavigateToParent();
        });
      });
    } else {
      this.waterSystemNotesService.save(+note.pwsId, this.notes);
      this.notesService.changeWaterSystemNotes(this.notes);
      this.successNavigateToParent();
    }
  }

  cancel() {
    this.router.navigate(['notes'], { relativeTo: this.route.parent });
  }

  successNavigateToParent(): void {
    this.isAdding = false;
    this.router.navigate(['notes'], { relativeTo: this.route.parent });
    this.messageService.add({ severity: Severity.SUCCESS, value: 'Note has been added.' });
    window.scrollTo(0, 0);
  }
}
