import {Component, OnInit, Input} from '@angular/core';
import {BasicDetailsService} from '../basic-details.service';
import {Checkout} from 'src/app/domain/checkout';
import {WaterSystem} from 'src/app/domain/water-system';
import {UNIT_MEASURE_CAPACITY_TYPES} from 'src/app/shared/unit-measure-capacity-type';

@Component({
  selector: 'app-production-source-water-protection',
  templateUrl: './production-source-water-protection.component.html',
  styleUrls: ['./production-source-water-protection.component.scss']
})
export class ProductionSourceWaterProtectionComponent implements OnInit {

  @Input() waterSystem: Checkout<WaterSystem>;

  readonly UNIT_MEASURE_CAPACITY_TYPES: any[] = UNIT_MEASURE_CAPACITY_TYPES;

  public isProductionSourceCollapsed = true;

  constructor(private basicDetailsService: BasicDetailsService) { }

  ngOnInit() {
  }

  onChange() {
    this.basicDetailsService.changeWaterSystem(this.waterSystem);
  }

  formattedStorageCapacity(): string {
    if (this.waterSystem.data.storageCapacity == null) {
      return '';
    } else if (this.waterSystem.data.storageCapacity === 0) {
      return '0 Gallons';
    }
    let formattedString: string = this.formattedAmount();
    if (this.waterSystem.data.storageCapacityMetric != null && this.waterSystem.data.storageCapacityMetric !== '') {
      formattedString = formattedString + ' Gallons';
    }
    return formattedString;
  }

  formattedAmount(): string {
    return this.waterSystem.data.storageCapacity.toLocaleString();
  }
}
