import { Directive, Input } from "@angular/core";
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
} from "@angular/forms";
import { Contact, ContactAction } from "src/app/domain/contact";
import { ROLE_TYPES } from "src/app/shared/role-type";

@Directive({
  selector: "[appPrimaryRole]",
  providers: [
    { provide: NG_VALIDATORS, useExisting: PrimaryRoleDirective, multi: true },
  ],
})
export class PrimaryRoleDirective {
  @Input("pwsId") pwsId: number;
  @Input("contactId") contactId: number;
  @Input("contacts") contacts: Contact[];

  constructor() {}

  validate(control: AbstractControl): ValidationErrors | null {
    if (!Array.isArray(this.contacts) || !this.contacts.length) {
      return null;
    }
    const otherContacts = this.contacts.filter((c) => c.id != this.contactId);
    const samePrimaryRole = otherContacts.filter(
      (c) =>
        c.isPrimary &&
        c.isPrimary === control.value.primary &&
        c.role === control.value.role &&
        c.action !== ContactAction.Delete &&
        c.action !== ContactAction.Erase &&
        c.action !== ContactAction.Remove
    );
    if (samePrimaryRole.length > 0) {
      const role = ROLE_TYPES.filter((r) => r.code === control.value.role)[0];
      const errors = {
        primaryRole: `A primary contact for the ${role.description} Role already exists.  Please either choose a different role or make it a non-primary contact.`,
      } as ValidationErrors;
      return errors;
    }
    return null;
  }
}
