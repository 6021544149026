<div class="card mb-3">
  <div class="card-body">
    <h1>Facility Summary</h1>
    <div *ngIf="siteVisit && siteVisit.inspection && siteVisit.inspection.modifiedFacilities; else noModified">
      <h2>Modified</h2>
      <app-facility-list-view [facilities]="siteVisit.inspection.modifiedFacilities"
                              [allFacilities]="siteVisit.inspection.facilities">
      </app-facility-list-view>
    </div>
    <ng-template #noModified>
      <h2>No Modified facilities.</h2>
    </ng-template>
    <div *ngIf="siteVisit && siteVisit.inspection && siteVisit.inspection.addedFacilities; else noAdded">
      <h2>Added</h2>
      <app-facility-list-view [facilities]="siteVisit.inspection.addedFacilities"
                              [allFacilities]="siteVisit.inspection.facilities">
      </app-facility-list-view>
    </div>
    <ng-template #noAdded>
      <h2>No Added facilities.</h2>
    </ng-template>
  </div>
</div>
