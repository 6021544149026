import { Component, OnInit, Input } from '@angular/core';
import { WaterSystem } from 'src/app/domain/water-system';
import { OWNER_TYPES } from 'src/app/shared/owner-type';
import { WATER_SYSTEM_CLASS_TYPES } from 'src/app/shared/water-system-class-type';
import { BasicDetailsService } from '../basic-details.service';
import { Checkout } from 'src/app/domain/checkout';
import { WATER_SYSTEM_TYPES } from 'src/app/shared/water-system-type';
import { CityService } from '../../../shared/city/city.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {

  @Input() waterSystem: Checkout<WaterSystem>;

  public isGeneralCollapsed = true;

  readonly OWNER_TYPES: any[] = OWNER_TYPES;
  readonly WATER_SYSTEM_CLASS_TYPES: any[] = WATER_SYSTEM_CLASS_TYPES;
  readonly WATER_SYSTEM_TYPES: any[] = WATER_SYSTEM_TYPES;
  public cities: string[];

  constructor(private basicDetailsService: BasicDetailsService,
              private cityService: CityService) { }

  ngOnInit() {
    this.cityService.getCitiesList()
      .pipe(take(1))
      .subscribe(cities => {
        this.cities = cities;
      });
  }

  onChange() {
    this.basicDetailsService.changeWaterSystem(this.waterSystem);
  }
}
