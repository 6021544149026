import { Component, Input, OnInit } from '@angular/core';
import { WaterOperator } from '../../../domain/water-operator';
import { ContactAction } from '../../../domain/contact';

@Component({
  selector: 'app-water-operators-summary',
  templateUrl: './water-operators-summary.component.html'
})
export class WaterOperatorsSummaryComponent implements OnInit {

  @Input()
  waterOperators: WaterOperator[];

  addedWaterOperators: WaterOperator[]
  isCollapsed = true;
  isWaterOperatorsChanged: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
    this.addedWaterOperators = [];
    this.waterOperators.forEach((w) => {
      if (w.action && w.action === ContactAction.Create) {
        this.addedWaterOperators.push(w);
        this.isWaterOperatorsChanged = true;
      }
    })
  }
}
