import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { WaterOperator } from 'src/app/domain/water-operator';
import { WaterOperatorsListService } from "../../../water-operators-list.service";
import { Observable, Unsubscribable } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map } from "rxjs/operators";
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-operator-info-form',
  templateUrl: './operator-info-form.component.html',
  styleUrls: ['./operator-info-form.component.scss']
})
export class OperatorInfoFormComponent implements OnInit, OnDestroy {

  @Output()
  saved = new EventEmitter<WaterOperator>();

  @Output()
  cancelled = new EventEmitter<void>();

  public operator: WaterOperator;
  public isLoading: boolean = true;
  public waterOperatorsList: WaterOperator[] = [];
  private waterOperatorsList$: Unsubscribable;

  constructor(private waterOperatorsListService: WaterOperatorsListService) {
    NgbTypeahead.prototype.dismissPopup = function() {
      if (this.isPopupOpen()) {
        this._resubscribeTypeahead.next(null);
        this._closePopup();
        this._writeInputValue(null);
        this._changeDetector.markForCheck();
      }
    }
  }

  ngOnInit() {
     this.waterOperatorsList$ = this.waterOperatorsListService.getWaterOperatorsList().subscribe(results => {
       this.waterOperatorsList = this.sortDefault(results);
       this.isLoading = false;
     });
  }

  save() {
    const { formattedPhone, formattedExpirationDate, certificateNumber, ...copy } = this.operator;
    this.saved.emit(copy);
  }

  cancel() {
    this.cancelled.emit();
  }

  searchName = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 2),
    map(term => this.waterOperatorsList.filter(wo => wo.name.toUpperCase().startsWith(term.toUpperCase())))
  )

  onSearchNameSelect(item: NgbTypeaheadSelectItemEvent) {
    this.operator = item.item;
    this.formatFormFields();
    item.preventDefault();
  }

  formatter = (wo: WaterOperator) => {
    if (this.operator != null && this.operator.name != null && this.operator.name !== '') {
      return wo.name + ' - ' + wo.certificateClass;
    } else {
      return '';
    }
  }

  sortDefault(waterOperators: WaterOperator[]): WaterOperator[] {
    if (waterOperators == null || waterOperators.length === 0 || waterOperators.length === 1) {
      return waterOperators;
    }
    return waterOperators.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (b.name < a.name) {
        return 1;
      } else {
        return 0;
      }
    })
  }

  formatFormFields() {
    if (this.operator != null) {
      if (this.operator.phone && this.operator.phone !== '') {
        this.operator.formattedPhone = this.operator.phone.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
      }
      if (this.operator.expirationDate) {
        this.operator.formattedExpirationDate = new Date(this.operator.expirationDate).toLocaleDateString();
      }
    }
  }

  scroll($event: any) {
    const elem = $event.currentTarget.nextElementSibling.getElementsByClassName('active')[0];
    elem.scrollIntoView({behavior: 'auto', block: 'nearest'});
  }

  ngOnDestroy() {
    if (this.waterOperatorsList$ != null) {
      this.waterOperatorsList$.unsubscribe();
    }
  }
}
