import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Event } from './domain/event';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private eventUrl = environment.serverUrl + '/event';

  constructor(
    private httpClient: HttpClient
  ) { }

  send(event: Event<any>) {
    return this.httpClient.post(`${this.eventUrl}/${event.type}`, event.data);
  }
}
