import { TreatmentProcess } from './treatment-process';
import { PotentialContaminant } from './potential-contaminant';
import { ExistingMeasure } from './water-system';
import { MeasureType } from './measure-type';

export interface DistributionFacility extends Facility {
  storageCapacity: number;
  scUnitMeasureCode: string;
  chlorinationInd: boolean;
  facilityWaterPurchase: Array<Facility>;
  publiclyOwnedLslNumber: number;
  privatelyOwnedLslNumber: number;
  splitOwnerLslNumber: number;
  splitOwnerLslLeadPipePrivateSideNumber: number;
  splitOwnerLslLeadPipePublicSideNumber: number;
  ownerCode: string;
}

export interface Watermain extends Facility {
  watermainSeqNo: number;
  mainSize: number;
  mainAge: string;
  mainAgeDescription: string;
  watermainMaterialCode: string;
  watermainMaterialDescription: string;
}

export interface Facility {
  pwsId: number;
  facilityId: number;
  name: string;
  facilityCode: string;
  availabilityCode: string;
  statusCode: string;
  statusReason: string;
  facilitySeqNo: number;
  downstreamFacility?: number[];
  newIndicator?: boolean;
  surveyNote: string;
  designCapacity: number;
  dcUnitMeasureCode: string;
  emergencyCapacity: number;
  ecUnitMeasureCode: string;
}

export interface TreatmentPlant extends Facility {
  operatingRate: number;
  orUnitMeasureCode: string;
  facilityTreatments: TreatmentProcess[];
}

export interface StorageFacility extends Facility {
  storageCapacity: number;
  scUnitMeasureCode: string;
  chlorinationInd: boolean;
}

export interface SurfaceWaterFacility extends Facility {
  waterCode: string;
}

export interface Well extends Facility {
  samplePointId: string;
  uniqueWellNbr: string;
  yearInstalledDate: number;
  yearLastRehabilitated: number;
  depth: number;
  caseDiameter: number;
  caseDepth: number;
  screenLength: number;
  depthStatic: number;
  pumpCode: string;
  pumpCapacity: number;
  pumpingRate: number;
  rateSourceCode: string;
  drawdown: number;
  iwmzUrl: string;
  iwmzPath: string;
  iwmzPcsi?: PotentialContaminant[];
  existingMeasures?: ExistingMeasure[];
  newMeasures?: MeasureType[];
}

export interface BoosterStation extends Facility {
  chlorination?: string;
}

export interface Interconnect extends Facility {}

export type FacilityAny = (Facility | DistributionFacility | TreatmentPlant | StorageFacility
  | SurfaceWaterFacility | Well | BoosterStation | Interconnect);

export const isTreatmentPlant = (f: FacilityAny): f is TreatmentPlant => f.facilityCode === 'TP';

export const isWell = (f: FacilityAny): f is Well => f.facilityCode === 'WL';

export const isBoosterStation = (f: FacilityAny): f is BoosterStation => f.facilityCode === 'BS';

export const isDistributionFacility = (f: FacilityAny): f is DistributionFacility => f.facilityCode === 'DS';

export const isStorageFacility = (f: FacilityAny): f is StorageFacility => ['ST', 'SE', 'SU', 'SH', 'SS'].indexOf(f.facilityCode) > -1;

export const isSurfaceWater = (f: FacilityAny): f is SurfaceWaterFacility => f.facilityCode === 'IN';

export const isInterconnect = (f: FacilityAny): f is Interconnect => f.facilityCode === 'CC';
