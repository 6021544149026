import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer, of, throwError } from 'rxjs';
import { takeUntil, concatMap, catchError, map, filter, take, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { Checkout } from '../domain/checkout';
import { BoosterStation, FacilityAny } from '../domain/facility';
import { SyncType } from '../domain/sync';
import { LocalStorageService } from '../local-storage.service';
import { tables } from '../utils/app-db';
import { SyncResultFactory } from '../utils/sync-result-factory';
import { SyncSchedulerService } from './sync-scheduler.service';

@Injectable({
  providedIn: 'root'
})
export class WaterSystemBoosterStationFacilityService {

  private readonly WATER_SYSTEM_URL = environment.serverUrl + '/watersystem';
  private readonly BOOSTER_STATION_SUB_ENDPOINT = 'boosterstation';
  private readonly BOOSTER_STATION_FACILITY_CODE = 'BS';

  constructor(private connectivityService: ConnectivityService,
              private httpClient: HttpClient,
              private localStorageService: LocalStorageService,
              private syncSchedulerService: SyncSchedulerService) { }

  /**
   * Returns a water system's booster station facilities.
   *
   * @param id the id of the water system
   */
  find(id: number): Observable<BoosterStation[]> {
    return new Observable<BoosterStation[]>(obs => {
      this.findLocally(id).subscribe(wsLocal => {
        if (wsLocal) {
          obs.next(wsLocal);
          obs.complete();
        } else {
          if (this.connectivityService.isOnline()) {
            this.findRemotelyTimer(id).subscribe (wsRemote => {
              obs.next(wsRemote);
              obs.complete();
            }, error => {
              obs.error(error);
            });
          } else {
            obs.complete();
          }
        }
      });
    });
  }

  /**
   * Returns one booster station facility saved locally.
   *
   * @param waterSystemId the water system id
   * @param facilityId the facility id
   */
  findOne(waterSystemId: number, facilityId: number): Observable<BoosterStation> {
    return new Observable<BoosterStation>(obs => {
      this.findOneLocally(waterSystemId, facilityId).subscribe(wsLocal => {
        if (wsLocal && wsLocal.length > 0) {
          obs.next(wsLocal[0]);
          obs.complete();
        } else {
          obs.next(null);
          obs.complete();
        }
      });
    });
  }

  findLocally(id: number): Observable<BoosterStation[]> {
    return new Observable<BoosterStation[]>(obs => {
      this.localStorageService.dbRetrieveAllByIndex<BoosterStation>(tables.waterSystemBoosterStation, 'pwsId', id)
        .pipe(
          switchMap(local => {
            if (local && local.length > 0) {
              return of(local);
            } else {
              return this.localStorageService.dbRetrieveAllByIndex<any>(tables.waterSystemZeroFacilitiesOfType,
                'pwsIdFacilityCode', [id, this.BOOSTER_STATION_FACILITY_CODE])
                .pipe(
                  map(l => {
                    if (l && l.length > 0) {
                      return l;
                    } else {
                      return null;
                    }
                  })
                );
            }
          })
        ).subscribe(local => {
          obs.next(local);
          obs.complete();
        });
      });
  }

  findOneLocallyByKey(key: number): Observable<BoosterStation> {
    return this.localStorageService.dbRetrieve<BoosterStation>(tables.waterSystemBoosterStation, key);
  }

  private findOneLocally(waterSystemId: number, facilityId: number): Observable<BoosterStation[]> {
    return this.localStorageService.dbRetrieveAllByIndex<BoosterStation>(tables.waterSystemBoosterStation,
      'pwsIdFacilityId', [waterSystemId, facilityId]);
  }

  private findRemotely(id: number): Observable<Checkout<BoosterStation[]>> {
    return this.httpClient.get<Checkout<any[]>>(`${this.WATER_SYSTEM_URL}/${id}/${this.BOOSTER_STATION_SUB_ENDPOINT}`);
  }

  private findRemotelyTimer(id: number): Observable<BoosterStation[]> {
    const stopTimer$ = timer(10 * 60 * 1000);
    return timer(0, 15000)
      .pipe(
        takeUntil(stopTimer$),
        concatMap(() => this.findRemotely(id).pipe(map(response => response))),
        filter(backendData => backendData.dataReady),
        take(1),
        map(found => {
          const facilities: BoosterStation[] = this.mapToBoosterStationFacilities(found.data);
          this.store(facilities);
          if (found.data == null || found.data.length === 0) {
            this.localStorageService.dbStore(tables.waterSystemZeroFacilitiesOfType,
              { 'pwsId': id, 'facilityCode': this.BOOSTER_STATION_FACILITY_CODE});
          }
          return facilities;
        }),
        catchError(error => {
          if (error.status === 404) {
            this.localStorageService.dbStore(tables.waterSystemZeroFacilitiesOfType,
              { 'pwsId': id, 'facilityCode': this.BOOSTER_STATION_FACILITY_CODE});
            return of(error.error);
          } else {
            return throwError(error);
          }
        })
      );
  }

  private mapToBoosterStationFacilities(results: any[]): BoosterStation[] {
    const facilities: BoosterStation[] = [];
    if (results && results.length > 0) {
      for (const result of results) {
        const facility: BoosterStation = {
          pwsId: result.pwsId,
          facilityId: result.facilityId,
          name: result.name,
          facilityCode: result.facilityCode,
          availabilityCode: result.information.availabilityCode,
          statusCode: result.information.statusCode,
          statusReason: result.information.statusReason,
          downstreamFacility: result.information.downstreamFacility,
          facilitySeqNo: result.information.id,
          surveyNote: result.information.surveyNote,
          designCapacity: result.information.designCapacity,
          dcUnitMeasureCode: result.information.dcUnitMeasureCode,
          emergencyCapacity: result.information.emergencyCapacity,
          ecUnitMeasureCode: result.information.ecUnitMeasureCode,
          chlorination: result.information.chlorination
        };
        facilities.push(facility);
      }
    }
    return facilities;
  }

  store(boosterStationFacilities: BoosterStation[]): void {
    if (boosterStationFacilities && boosterStationFacilities.length > 1) {
      boosterStationFacilities = boosterStationFacilities.sort((a, b) => {
        if (a.facilityId > b.facilityId) {
          return 1;
        } else if (a.facilityId < b.facilityId) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    boosterStationFacilities.forEach(boosterStation => {
      this.localStorageService.dbStore(tables.waterSystemBoosterStation, boosterStation);
    });
  }

  deleteLocally(id: number): void {
    this.localStorageService.dbRetrieveAllByIndex<BoosterStation>(tables.waterSystemBoosterStation, 'pwsId', id).subscribe(results => {
      if (results) {
        for (const facility of results) {
          this.localStorageService.dbRemove(tables.waterSystemBoosterStation, facility.facilitySeqNo);
        }
      }
    });
    this.localStorageService.dbRetrieveAllByIndex<any>(tables.waterSystemZeroFacilitiesOfType, 'pwsId', id).subscribe(results => {
      if (results) {
        for (const result of results) {
          this.localStorageService.dbRemove(tables.waterSystemZeroFacilitiesOfType, result.key);
        }
      }
    });
  }

  insertRemotely(boosterStation: BoosterStation): Observable<any> {
    const remoteFacility = {
      facilityId: boosterStation.facilityId,
      facilityCode: boosterStation.facilityCode,
      name: boosterStation.name,
      pwsId: boosterStation.pwsId,
      information: {
        id: boosterStation.facilitySeqNo,
        waterSystemId: boosterStation.pwsId,
        name: boosterStation.name,
        facilityCode: boosterStation.facilityCode,
        availabilityCode: boosterStation.availabilityCode,
        statusCode: boosterStation.statusCode,
        statusReason: boosterStation.statusReason,
        downstreamFacility: boosterStation.downstreamFacility,
        surveyNote: boosterStation.surveyNote,
        designCapacity: boosterStation.designCapacity,
        dcUnitMeasureCode: boosterStation.dcUnitMeasureCode,
        emergencyCapacity: boosterStation.emergencyCapacity,
        ecUnitMeasureCode: boosterStation.ecUnitMeasureCode,
        chlorination: boosterStation.chlorination
      }
    };
    return this.httpClient.post(`${this.WATER_SYSTEM_URL}/${boosterStation.pwsId}/${this.BOOSTER_STATION_SUB_ENDPOINT}`, remoteFacility);
  }

  updateRemotely(boosterStation: BoosterStation): Observable<any> {
    const remoteFacility = {
      facilityId: boosterStation.facilityId,
      facilityCode: boosterStation.facilityCode,
      name: boosterStation.name,
      pwsId: boosterStation.pwsId,
      information: {
        id: boosterStation.facilitySeqNo,
        waterSystemId: boosterStation.pwsId,
        name: boosterStation.name,
        facilityCode: boosterStation.facilityCode,
        availabilityCode: boosterStation.availabilityCode,
        statusCode: boosterStation.statusCode,
        statusReason: boosterStation.statusReason,
        downstreamFacility: boosterStation.downstreamFacility,
        surveyNote: boosterStation.surveyNote,
        designCapacity: boosterStation.designCapacity,
        dcUnitMeasureCode: boosterStation.dcUnitMeasureCode,
        emergencyCapacity: boosterStation.emergencyCapacity,
        ecUnitMeasureCode: boosterStation.ecUnitMeasureCode,
        chlorination: boosterStation.chlorination
      }
    };
    return this.httpClient.put(`${this.WATER_SYSTEM_URL}/${boosterStation.pwsId}/`
      + `${this.BOOSTER_STATION_SUB_ENDPOINT}/${boosterStation.facilityId}`, remoteFacility);
  }

  deleteOne(facility: FacilityAny): Observable<any> {
    if (this.connectivityService.isOnline()) {
      this.deleteOneLocally(facility.facilitySeqNo);
      return this.deleteRemotely(facility);
    } else {
      this.syncSchedulerService.schedule(SyncType.FacilityBoosterStationDelete, facility.facilitySeqNo).subscribe();
    }
  }

  deleteOneLocally(id: number): void {
    this.localStorageService.dbRemove(tables.waterSystemBoosterStation, id);
  }

  private deleteRemotely(facility: FacilityAny): Observable<any> {
    return this.httpClient.delete(`${this.WATER_SYSTEM_URL}/${facility.pwsId}/${this.BOOSTER_STATION_SUB_ENDPOINT}/${facility.facilityId}`);
  }

  syncFacilityBoosterStationDelete(): void {
    if (this.connectivityService.isOnline) {
      if (this.connectivityService.isOnline()) {
        this.syncSchedulerService.scheduled(SyncType.FacilityBoosterStationDelete).subscribe(syncRequests => {
          syncRequests.forEach(syncRequest => {
            this.findOneLocallyByKey(syncRequest.data.id).subscribe(facility => {
              if (facility) {
                this.deleteRemotely(facility).subscribe(() => {
                  this.syncSchedulerService.unschedule(SyncType.FacilityBoosterStationDelete, syncRequest.data.id,
                      SyncResultFactory.synced(SyncType.FacilityBoosterStationDelete, syncRequest.data.id));
                  this.deleteOneLocally(facility.facilitySeqNo);
                }, error => {
                  this.syncSchedulerService.unschedule(SyncType.FacilityBoosterStationDelete, syncRequest.data.id,
                      SyncResultFactory.errorHttp(SyncType.FacilityBoosterStationDelete, syncRequest.data.id, error));
                });
              } else {
                this.syncSchedulerService.unschedule(SyncType.FacilityBoosterStationDelete, syncRequest.data.id,
                  SyncResultFactory.synced(SyncType.FacilityBoosterStationDelete, syncRequest.data.id));
              }
            });
          });
        });
      }
    }
  }
}
