<div class="card mb-3">
  <div class="card-body">
    <h1>Basic Details</h1>
    <form id="basic-details-form" (submit)="save()" *ngIf="waterSystem">
      <div class="pt-3">
        <app-general [waterSystem]="waterSystem"></app-general>
      </div>
      <div class="pt-3">
        <app-population [waterSystem]="waterSystem"></app-population>
      </div>
      <div class="pt-3">
        <app-service-area [waterSystem]="waterSystem"></app-service-area>
      </div>
      <div class="pt-3">
        <app-production-source-water-protection [waterSystem]="waterSystem"></app-production-source-water-protection>
      </div>
      <div class="pt-3">
        <button id="basic-details-submit-button" type="submit" class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</div>
