<div id="active-deficiencies">
  <div class="border p-2 panel-collapsible-header">
    <a href="javascript:void(0);" (click)="isActiveDeficienciesCollapsed = !isActiveDeficienciesCollapsed"
       [attr.aria-expanded]="!isActiveDeficienciesCollapsed" aria-controls="collapse-active-deficienciess">
      <div>
        <h2>
          Active Deficiencies
          <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isActiveDeficienciesCollapsed"></i>
          <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isActiveDeficienciesCollapsed"></i>
        </h2>
      </div>
    </a>
  </div>
  <div id="collapse-active-deficiencies" class="card" [ngbCollapse]="isActiveDeficienciesCollapsed">
    <div class="card-body row">
      <div class="col-12 table-responsive">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col"
                  class="col-1"
                  sortable="significantIndicator"
                  (sort)="onSort($event)"
                  tabindex="0"
                  rotateOption="noEmpty">
                Significant
              </th>
              <th scope="col"
                  class="col-1"
                  sortable="code"
                  (sort)="onSort($event)"
                  tabindex="0"
                  rotateOption="noEmpty">
                Code
              </th>
              <th scole="col"
                  class="col-6"
                  sortable="description"
                  (sort)="onSort($event)"
                  tabindex="0"
                  rotateOption="noEmpty">
                Description
              </th>
              <th scope="col"
                  class="col-1"
                  sortable="isResolved"
                  (sort)="onSort($event)"
                  tabindex="0"
                  rotateOption="noEmpty">
                Status
              </th>
              <th scope="col"
                  class="col-3">
                Actions
              </th>
            </tr>
          </thead>

          <tbody *ngIf="hasExistingActive">
            <ng-container *ngFor="let deficiency of deficiencies.data; let i = index">
              <tr>
                <td class="col-1" style="padding-left:22px">{{ deficiency.significantIndicator && deficiency.significantIndicator === 'Y' ? 'Yes' : 'No' }}</td>
                <td class="col-1" style="padding-left:22px">{{ deficiency.code }}</td>
                <td class="col-6" style="padding-left:22px">{{ deficiency.description }}</td>
                <td class="col-1" style="padding-left:22px">{{ deficiency.isResolved && deficiency.isResolved === true ? 'Resolved' : 'Active' }}</td>
                <td class="col-3" style="padding-left:22px">
                  <button type="button"
                          class="btn btn-outline-dark btn-sm py-0"
                          (click)="showDetails[i] = !showDetails[i]"
                          attr.aria-label="Show additional details for row {{ i + 1 }}">
                    {{ showDetails[i] ? 'Hide' : 'Show' }} Details
                  </button>
                  <br/>
                  <button *ngIf="!deficiency.isResolved || deficiency.isResolved === false"
                          type="button"
                          class="btn btn-outline-dark btn-sm py-0 mt-2"
                          (click)="resolve(i)"
                          attr.aria-label="Flag deficiency row {{ i + 1 }} as resolved">
                    Flag as Resolved
                  </button>
                  <button *ngIf="deficiency.isResolved && deficiency.isResolved === true"
                          type="button"
                          class="btn btn-outline-dark btn-sm py-0 mt-2"
                          (click)="undoResolve(i)"
                          attr.aria-label="Undo resolved from deficiency row {{ i + 1 }}">
                    Undo Resolved
                  </button>
                </td>
              </tr>
              <ng-container *ngIf="showDetails[i]">
                <tr class="text-center">
                  <td colspan="5">
                    <strong>Sanitary Survey Section:</strong> {{ deficiency.sanitarySurveySectionDescription }}
                    <br/>
                    <strong>Details:</strong> {{ deficiency.detail ? deficiency.detail : 'None' }}
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>

          <tbody *ngIf="!hasExistingActive">
            <tr>
              <td colspan="4">
                There are no active deficiencies / significant deficiencies for this water system.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
