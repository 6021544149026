import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WaterSystemInspectionService {

  private readonly WATER_SYSTEM_URL = environment.serverUrl + '/watersystem';
  private readonly SUB_ENDPOINT = 'checkout';

  constructor(private http: HttpClient) { }

  cancel(id: number) {
    return this.http.delete(`${this.WATER_SYSTEM_URL}/${id}/${this.SUB_ENDPOINT}`);
  }

  checkin(id: number) {
    return this.http.put(`${this.WATER_SYSTEM_URL}/${id}/${this.SUB_ENDPOINT}`, {});
  }
}
