import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WaterSystemComplianceOfficerService } from './water-system-compliance-officer.service';
import { WaterSystemContactsService } from './water-system-contacts.service';
import { WaterSystemDistrictContactService } from './water-system-district-contact.service';
import { WaterSystemNotesService } from './water-system-notes.service';
import { WaterSystemOperatorsService } from './water-system-operators.service';
import { WaterSystemService } from './water-system.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { WaterSystemSiteVisitsService } from './water-system-site-visits.service';
import { WaterSystemWellsService } from './water-system-wells.service';
import { WaterSystemStorageFacilitiesService } from "./water-system-storage-facility.service";
import { WaterSystemTreatmentFacilitiesService } from "./water-system-treatment-facility.service";
import { WaterSystemFacilitiesService } from './water-system-facilities.service';
import { WaterSystemSurfaceWaterFacilityService } from './water-system-surface-water-facility.service';
import { WaterSystemDistributionFacilityService } from './water-system-distribution-facility.service';
import { WaterSystemBoosterStationFacilityService } from './water-system-booster-station-facility.service';
import { WaterSystemInterconnectFacilityService } from './water-system-interconnect-facility.service';
import { WaterSystemDeficienciesService } from "./water-system-deficiencies.service";
import {SiteVisitService} from '../site-visit.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  private readonly WATER_SYSTEM_URL = environment.serverUrl + '/watersystem';
  private readonly CHECKOUT_SUB_ENDPOINT = 'checkout';

  constructor(
    private http: HttpClient,
    private waterSystemService: WaterSystemService,
    private waterSystemContactsService: WaterSystemContactsService,
    private waterSystemComplianceOfficerService: WaterSystemComplianceOfficerService,
    private waterSystemDistrictContactService: WaterSystemDistrictContactService,
    private waterSystemNotesService: WaterSystemNotesService,
    private waterSystemOperatorsService: WaterSystemOperatorsService,
    private waterSystemSiteVisitsService: WaterSystemSiteVisitsService,
    private waterSystemWellsService: WaterSystemWellsService,
    private waterSystemDistributionService: WaterSystemDistributionFacilityService,
    private waterSystemStorageFacilitiesService: WaterSystemStorageFacilitiesService,
    private waterSystemTreatmentFacilitiesService: WaterSystemTreatmentFacilitiesService,
    private waterSystemFacilitiesService: WaterSystemFacilitiesService,
    private waterSystemSurfaceWaterFacilityService: WaterSystemSurfaceWaterFacilityService,
    private waterSystemBoosterStationFacilityService: WaterSystemBoosterStationFacilityService,
    private waterSystemInterconnectFacilityService: WaterSystemInterconnectFacilityService,
    private waterSystemDeficienciesService: WaterSystemDeficienciesService,
    private siteVisitService: SiteVisitService
  ) { }

  checkout(id: number): Observable<any> {
    return this.http.post(`${this.WATER_SYSTEM_URL}/${id}/${this.CHECKOUT_SUB_ENDPOINT}`, {});
  }

  removeWaterSystemLocally(id: number, username: string): void {
    this.waterSystemService.deleteLocally(id);
    this.waterSystemContactsService.deleteLocally(id);
    this.waterSystemOperatorsService.deleteLocally(id);
    this.waterSystemComplianceOfficerService.deleteLocally(id);
    this.waterSystemDistrictContactService.deleteLocally(id);
    this.waterSystemNotesService.deleteLocally(id);
    this.waterSystemSiteVisitsService.deleteAllLocally(id);
    this.waterSystemWellsService.deleteLocally(id);
    this.waterSystemDistributionService.deleteLocally(id);
    this.waterSystemStorageFacilitiesService.deleteLocally(id);
    this.waterSystemTreatmentFacilitiesService.deleteLocally(id);
    this.waterSystemFacilitiesService.deleteFlowAllLocally(id);
    this.waterSystemSurfaceWaterFacilityService.deleteLocally(id);
    this.waterSystemBoosterStationFacilityService.deleteLocally(id);
    this.waterSystemInterconnectFacilityService.deleteLocally(id);
    this.waterSystemDeficienciesService.deleteLocally(id);
  }
}
