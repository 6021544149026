import { Pipe, PipeTransform } from '@angular/core';
import { PUMP_TYPES } from './pump-type';

@Pipe({name: 'pumpType'})
export class PumpTypePipe implements PipeTransform {
  transform(code: string): string {
    if (!code || code === '') {
      return 'None';
    }

    for (let type of PUMP_TYPES) {
      if (code === type.code) {
        return type.value;
      }
    }
    return code;
  }
}