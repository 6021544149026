import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbdSortableHeader } from './table-sortable';
import { LoaderComponent } from '../loader/loader.component';
import { MaxValidatorDirective } from './max-number-validator';
import { MinValidatorDirective } from './min-number-validator';
import { VisitTypeAnnualSanitaryPipe } from './visit-type.pipe';
import { ConfirmatoryActionComponent } from '../confirmatory-action/confirmatory-action.component';
import { MessageComponent } from '../message/message.component';
import { ToastComponent } from '../toast/toast.component';
import { CountyTypePipe } from './county-type.pipe';
import { FacilityTypePipe } from './facility-type.pipe';
import { ChipComponent } from './chip/chip.component';
import { ChipInputComponent } from './chip-input/chip-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChipSelectInspectorComponent } from './chip-select-inspector/chip-select-inspector.component';
import { PhoneNumberMaskDirective } from './phone-number-mask.directive';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { FacilityStatusPipe } from './facility-status.pipe';
import { AvailabilityTypePipe } from './availability-type.pipe';
import { UnitMeasureCapacityTypePipe } from './unit-measure-capacity-type-pipe';
import {OwnerTypePipe} from './owner-type';
import {WaterSystemClassTypePipe} from './water-system-class-type';
import {SeasonalIndYesNoNotverifiedPipe} from './seasonal-indicator.pipe';
import {YesNoPipe} from './yes-no.pipe';
import {ServiceAreaTypePipe} from './service-area-type';

@NgModule({
  declarations: [
    NgbdSortableHeader,
    LoaderComponent,
    MinValidatorDirective,
    MaxValidatorDirective,
    VisitTypeAnnualSanitaryPipe,
    ConfirmatoryActionComponent,
    MessageComponent,
    ToastComponent,
    CountyTypePipe,
    AvailabilityTypePipe,
    FacilityTypePipe,
    FacilityStatusPipe,
    ChipComponent,
    ChipInputComponent,
    ChipSelectInspectorComponent,
    PhoneNumberMaskDirective,
    NumbersOnlyDirective,
    UnitMeasureCapacityTypePipe,
    OwnerTypePipe,
    WaterSystemClassTypePipe,
    SeasonalIndYesNoNotverifiedPipe,
    YesNoPipe,
    ServiceAreaTypePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    NgbdSortableHeader,
    LoaderComponent,
    MinValidatorDirective,
    MaxValidatorDirective,
    VisitTypeAnnualSanitaryPipe,
    ConfirmatoryActionComponent,
    MessageComponent,
    ToastComponent,
    CountyTypePipe,
    AvailabilityTypePipe,
    FacilityTypePipe,
    FacilityStatusPipe,
    ChipComponent,
    ChipInputComponent,
    ChipSelectInspectorComponent,
    PhoneNumberMaskDirective,
    NumbersOnlyDirective,
    UnitMeasureCapacityTypePipe,
    OwnerTypePipe,
    WaterSystemClassTypePipe,
    SeasonalIndYesNoNotverifiedPipe,
    YesNoPipe,
    ServiceAreaTypePipe
  ],
  bootstrap: [ToastComponent]
})
export class SharedModule { }
