<div class="row">
  <div class="col-md-3">Facility Type</div>
  <div class="col-md-9">{{facility.facilityCode | facilityType}}</div>
</div>
<div class="row">
  <div class="col-md-3">Name</div>
  <div class="col-md-9">{{facility.name}}</div>
</div>
<div class="row">
  <div class="col-md-3">Availability</div>
  <div class="col-md-9">{{facility.availabilityCode | availabilityType}}</div>
</div>
<div class="row">
  <div class="col-md-3">Status</div>
  <div class="col-md-9">{{facility.statusCode | facilityStatus}}</div>
</div>
<div class="row">
  <div class="col-md-3">Status Reason</div>
  <div class="col-md-9">{{facility.statusReason}}</div>
</div>
<div class="row">
  <div class="col-md-3">Flows To:</div>
  <div class="col-md-9">{{facilityIdToName(facility.downstreamFacility)}}</div>
</div>
<div class="row">
  <div class="col-md-3">Design Capacity:</div>
  <div class="col-md-9">{{facility.designCapacity | number}} {{facility.dcUnitMeasureCode | unitMeasureCapacityType}}</div>
</div>
<div class="row">
  <div class="col-md-3">Emergency Capacity:</div>
  <div class="col-md-9">{{facility.emergencyCapacity | number}} {{facility.ecUnitMeasureCode | unitMeasureCapacityType}}</div>
</div>
<ng-container *ngIf="boosterStation">
  <div class="row">
    <div class="col-md-3">Chlorination:</div>
    <div class="col-md-3">{{boosterStation.chlorination ? 'Yes' : 'No'}}</div>
  </div>
</ng-container>
<ng-container *ngIf="well">
  <div class="row">
    <div class="col-md-3">Pumping Rate:</div>
    <div class="col-md-9">{{well.pumpingRate | number}} Gallons per Minute</div>
  </div>
</ng-container>
<div class="row">
  <div class="col-md-3">Notes</div>
  <div class="col-md-9">{{facility.surveyNote}}</div>
</div>
<ng-container *ngIf="well">
  <div class="row" *ngIf="well.iwmzPcsi && well.iwmzPcsi.length">
    <div class="col-md-3">New Potential Contaminants</div>
    <div class="col-md-9">
      <div class="row card" *ngFor="let pc of well.iwmzPcsi">
        <div class="col-12">Class: {{pc.pcsi_type.classDescription}}</div>
        <div class="col-12">Type: {{pc.pcsi_type.potentialContaminationDescription}}</div>
        <div class="col-12">Bearing: {{pc.bearing}} degrees</div>
        <div class="col-12">Distance: {{pc.distance}} feet</div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="well.existingMeasures && well.existingMeasures.length">
    <div class="col-md-3">Resolved Measures</div>
    <div class="col-md-9">
      <div class="row card" *ngFor="let measure of well.existingMeasures">
        <div class="col-12">Code: {{measure.measureCode}}</div>
        <div class="col-12">Description: {{measure.measureDesc}}</div>
        <div class="col-12">Status: Resolved</div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="well.newMeasures && well.newMeasures.length">
    <div class="col-md-3">New Active Measures</div>
    <div class="col-md-9">
      <div class="row card" *ngFor="let measure of well.newMeasures">
        <div class="col-12">Class: {{measure.measureClassDesc}}</div>
        <div class="col-12">Code: {{measure.measureCode}}</div>
        <div class="col-12">Description: {{measure.measureDesc}}</div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="treatment">
  <div class="row" *ngIf="treatment && treatment.facilityTreatments">
    <div class="col-md-3">Treatments</div>
    <div class="col-md-9">
      <div class="row card" *ngFor="let treatment of treatment.facilityTreatments">
        <div class="col-12">Treatment Objective: {{treatment.objective.description}}</div>
        <div class="col-12">Process Mechanism: {{treatment.processMechanism.description}}</div>
        <div class="col-12">Begin Date: {{treatment.treatmentBegin}}</div>
        <div class="col-12">End Date: {{treatment.treatmentEnd || 'No End Date'}}</div>
        <div *ngIf="treatment.status" class="col-12 ">Status: {{formatStatus(treatment.status)}}</div>
      </div>
    </div>
  </div>
</ng-container>
