import {Component, HostListener, OnInit} from '@angular/core';
import {ConnectivityService} from './connectivity.service';

/*
https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine/Online_and_offline_events
 */
@Component({
  selector: 'app-connectivity',
  templateUrl: './connectivity.component.html',
  styleUrls: ['./connectivity.component.scss']
})
export class ConnectivityComponent implements OnInit {

  online: boolean;

  constructor(private connectivityService: ConnectivityService) {}

  ngOnInit() {
    this.online = this.connectivityService.isOnline();
    this.connectivityService.online$().subscribe((online) => this.online = online);
  }
}
