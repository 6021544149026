export const PUMP_TYPES = [
  {code: 'ARTE', value: 'Artesian'},
  {code: 'CENT', value: 'Centrifugal'},
  {code: 'HAND', value: 'Handpump'},
  {code: 'JACK', value: 'Jack'},
  {code: 'JET ', value: 'Jet'},
  {code: 'PACK', value: 'Pack'},
  {code: 'PIST', value: 'Piston'},
  {code: 'SAND', value: 'Sandpoint'},
  {code: 'SUBM', value: 'Submersible'},
  {code: 'SUBV', value: 'Submersible VFD'},
  {code: 'SUCT', value: 'Suction'},
  {code: 'VLSD', value: 'Variable Speed'},
  {code: 'VTUR', value: 'Vertical Turbine'},
  {code: 'VTUV', value: 'Vertical Turbine VFD'}
];
