<div class="card">
  <div class="card-body">
    <div>
      <h1>Add Water Operator</h1>
    </div>
    <div class="pt-3" *ngIf="!isAdding">
      <app-operator-info-form (saved)="add($event)" (cancelled)="cancel()"></app-operator-info-form>
    </div>
    <div class="pt-3" *ngIf="isAdding">
      <div class="spinner-border" role="status" aria-hidden="true">
        <span class="sr-only">Adding Water Operator...</span>
      </div>
      <span>Adding Water Operator...</span>
    </div>
  </div>
</div>
