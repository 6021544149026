import {Pipe, PipeTransform} from '@angular/core';
import { AVAILABILITY_TYPES } from './availability-type';

@Pipe({name: 'availabilityType'})
export class AvailabilityTypePipe implements PipeTransform {
  transform(code: string): string {
    if (!code || code === '') {
      return code;
    }

    for (let type of AVAILABILITY_TYPES) {
      if (code === type.code) {
        return type.value;
      }
    }
    return code;
  }
}