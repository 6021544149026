import { IdbStorage } from './storage';

export interface SyncRequest extends IdbStorage<SyncData> {
  type: SyncType;
}

export interface SyncResult extends IdbStorage<SyncResultData> {
  type: SyncType;
}

export enum SyncType {
  WaterSystem = "WaterSystem",
  Contacts = "Contacts",
  WaterOperators = "WaterOperators",
  SiteVisit = "SiteVisit",
  SiteVisitDelete = "SiteVisitDelete",
  FacilityFlow = "FacilityFlow",
  FacilityFlowDelete = "FacilityFlowDelete",
  FacilityWell = "FacilityWell",
  FacilityWellDelete = "FacilityWellDelete",
  FacilitySurfaceWater = "FacilitySurfaceWater",
  FacilitySurfaceWaterDelete = "FacilitySurfaceWaterDelete",
  FacilityStorage = "FacilityStorage",
  FacilityStorageDelete = "FacilityStorageDelete",
  FacilityTreatment = "FacilityTreatment",
  FacilityTreatmentDelete = "FacilityTreatmentDelete",
  FacilityDistribution = "FacilityDistribution",
  FacilityDistributionDelete = "FacilityDistributionDelete",
  FacilityBoosterStation = "FacilityBoosterStation",
  FacilityBoosterStationDelete = "FacilityBoosterStationDelete",
  FacilityInterconnect = "FacilityInterconnect",
  FacilityInterconnectDelete = "FacilityInterconnectDelete",
  Deficiencies = "Deficiencies",
  Notes = "Notes"
}

export interface SyncData {
  id: number;
  data?: any;
}

export interface SyncResultData extends SyncData {
  readonly status: string;
  readonly date: Date;
  readonly messages: Array<string>;
}
