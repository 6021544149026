<div id="water-system-contacts-mailing">
  <div class="border p-2 panel-collapsible-header">
    <a href="javascript:void(0);" (click)="isContactsMailingCollapsed = !isContactsMailingCollapsed"
      [attr.aria-expanded]="!isContactsMailingCollapsed" aria-controls="collapse-contacts-mailing">
      <div>
        <h2>
          Contacts/Mailing
          <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon"
            *ngIf="isContactsMailingCollapsed"></i>
          <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon"
            *ngIf="!isContactsMailingCollapsed"></i>
        </h2>
      </div>
    </a>
  </div>
  <div id="collapse-contacts-mailing" class="card" [ngbCollapse]="isContactsMailingCollapsed">
    <div class="card-body row">
      <div class="col-12 table-responsive">
        <div class="pb-3">
          <button type="button" class="btn btn-outline-primary" routerLink="add-contact">Add Inventory Contact</button>
        </div>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Primary</th>
              <th scope="col">Role</th>
              <th scope="col">Name</th>
              <th scope="col">Phone</th>
              <th scope="col">Email</th>
              <th scope="col">Address</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody *ngIf="contacts">
            <tr *ngFor="let contact of contacts.data; let i = index" [ngClass]="{'table-danger': !isNotDeleted(contact)}"
              [attr.aria-label]="isNotDeleted(contact)?null:'Deleted'">
              <ng-container *ngIf="isNotRemoved(contact)">
                <td>{{ contact.isPrimary ? 'Yes' : 'No' }}</td>
                <td>{{ contact.role | roleType }}</td>
                <td>{{ contact.name }}</td>
                <td>{{ contact.phone | phoneNumber }}</td>
                <td>
                  <span *ngIf="contact.email"><a href="mailto:{{ contact.email }}">{{ contact.email }}</a></span>
                </td>
                <td>
                  <address class="pre-line-breaker">
                    {{ contact | contactAddress }}
                  </address>
                </td>
                <td>
                  <div *ngIf="isNotDeleted(contact); else deleted" class="display-flex flex-column">
                    <a class="btn btn-link mb-1" [routerLink]="['edit-contact', i]" [state]="{ data: contact }"
                      attr.aria-label="Edit inventory contact {{ contact.name }}">Edit</a>
                    <input type="button" class="btn btn-outline-secondary" value="Delete" (click)="delete(i)"
                      attr.aria-label="Delete inventory contact {{ contact.name }}" />
                  </div>
                  <ng-template #deleted>
                    <input *ngIf="canRestore(contact); else cannotRestore" type="button" class="btn btn-link" value="Restore" (click)="restore(i)"
                      attr.aria-label="Delete inventory contact {{ contact.name }}" />
                    <ng-template #cannotRestore>
                      <span>Can't Restore due to Role Conflict</span>
                    </ng-template>
                  </ng-template>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
