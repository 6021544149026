import { Injectable } from '@angular/core';
import { Observable, of, timer, throwError } from 'rxjs';
import { Checkout } from '../domain/checkout';
import { WaterOperator } from '../domain/water-operator';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { concatMap, filter, map, take, tap, catchError, takeUntil } from 'rxjs/operators';
import { SyncSchedulerService } from './sync-scheduler.service';
import { LocalStorageService } from '../local-storage.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SyncResultFactory } from '../utils/sync-result-factory';
import { tables } from '../utils/app-db';
import { SyncResult, SyncType } from '../domain/sync';
import { OperatorsService } from "./contacts/operators.service";

@Injectable({
  providedIn: 'root'
})
export class WaterSystemOperatorsService {

  private readonly WATER_SYSTEM_URL = environment.serverUrl + '/watersystem';
  private readonly WATER_OPERATOR_SUB_ENDPOINT = 'wateroperator';

  constructor(private http: HttpClient,
              private connectivityService: ConnectivityService,
              private localStorageService: LocalStorageService,
              private syncScheduler: SyncSchedulerService,
              private operatorsService: OperatorsService) { }

  /**
   * Returns water system operators saved locally
   * If none found, then will be check-out remotely if online
   * @param id of the water system
   */
  find(id: number): Observable<Checkout<WaterOperator[]>> {
    return new Observable<Checkout<WaterOperator[]>>(obs => {
      this.findLocally(id).subscribe(wsLocal => {
        if (wsLocal) {
          obs.next(wsLocal);
          obs.complete();
        } else {
          if (this.connectivityService.isOnline()) {
            this.findRemotelyTimer(id).subscribe (wsRemote => {
              obs.next(wsRemote);
              obs.complete();
            }, error => {
              obs.error(error);
            });
          } else {
            obs.complete();
          }
        }
      });
    });
  }

  findLocally(id: number): Observable<Checkout<WaterOperator[]>> {
    return this.localStorageService.dbRetrieve<Checkout<WaterOperator[]>>(tables.waterSystemOperator, id);
  }

  findRemotely(id: number): Observable<Checkout<WaterOperator[]>> {
    return this.http.get<Checkout<WaterOperator[]>>(`${this.WATER_SYSTEM_URL}/${id}/${this.WATER_OPERATOR_SUB_ENDPOINT}`);
  }

  private findRemotelyTimer(id: number): Observable<Checkout<WaterOperator[]>> {
    const stopTimer$ = timer(10 * 60 * 1000);
    return timer(0, 15000)
      .pipe(
        takeUntil(stopTimer$),
        concatMap(() => this.findRemotely(id).pipe(map(response => response))),
        filter(backendData => backendData.dataReady),
        take(1),
        tap(found => {
          this.store(id, found);
          return found;
        }),
        catchError(error => {
          if (error.status === 404) {
            this.store(id, error.error);
            return of(error.error);
          } else {
            return throwError(error);
          }
        })
      );
  }

  /**
   * Saves the water system operators locally
   * If online, then also saved to remote service, otherwise save is scheduled
   * @param id of the water system
   * @param waterSystemOperators the water system operators
   */
  save(id: number, waterSystemOperators: Checkout<WaterOperator[]>): void {
    this.store(id, waterSystemOperators);
    this.syncScheduler.schedule(SyncType.WaterOperators, id).subscribe();
  }

  saveRemotely(id: number, waterSystemOperators: WaterOperator[]): Observable<{}> {
    return this.http.put(`${this.WATER_SYSTEM_URL}/${id}/${this.WATER_OPERATOR_SUB_ENDPOINT}`, waterSystemOperators);
  }

  store(id: number, waterSystemOperators: Checkout<WaterOperator[]>): void {
    this.localStorageService.dbStoreStorage(tables.waterSystemOperator, id, waterSystemOperators);
  }

  status(id: number): Observable<SyncResult> {
    return this.syncScheduler.status(SyncType.WaterOperators, id);
  }

  /**
   * Syncs scheduled water systems operators if online
   */
  sync(): void {
    if (this.connectivityService.isOnline()) {
      this.syncScheduler.scheduled(SyncType.WaterOperators).subscribe(syncRequests => {
        syncRequests.forEach(syncRequest => {
          this.findLocally(syncRequest.data.id).subscribe(waterSystemOperators => {
            this.saveRemotely(syncRequest.data.id, waterSystemOperators.data).subscribe(
              () => {
                this.syncScheduler.unschedule(SyncType.WaterOperators, syncRequest.data.id,
                  SyncResultFactory.synced(SyncType.WaterOperators, syncRequest.data.id));
                this.findRemotelyTimer(syncRequest.data.id).subscribe(() => {});
              },
              error => this.syncScheduler.unschedule(SyncType.WaterOperators, syncRequest.data.id,
                this.translateSyncErrorResponse(SyncType.WaterOperators, syncRequest.data.id, error))
            );
          });
        });
      })
    }
  }

  // https://angular.io/guide/http#error-handling
  private translateSyncErrorResponse(type: SyncType, id: number, error: HttpErrorResponse | ErrorEvent): SyncResult {
    const messages: Array<string> = [];

    if (error instanceof HttpErrorResponse) {
      // todo: user-friendly messages, handle additional scenarios
      switch (error.status) {
        case 403: {
          messages.push('You are unauthorized to save this water system operators.');
          break;
        }
        case 422: {
          // todo: handle validation messages
          messages.push(error.message);
          break;
        }
        case 500: {
          messages.push('The API has encountered an error.');
          break;
        }
        case 503: {
          messages.push('The API is currently down.');
          break;
        }
        case 0: {
          messages.push('A communication error occurred.');
          break;
        }
        default: {
          messages.push('An unexpected error occurred.');
          break;
        }
      }
    } else {
      messages.push(error.error.message);
    }

    return SyncResultFactory.error(type, id, messages);
  }

  deleteLocally(id: number): void {
    this.localStorageService.dbRemove(tables.waterSystemOperator, id);
  }
}
