import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Visit } from 'src/app/domain/visit';
import { LocalStorageService } from 'src/app/local-storage.service';
import { IamService } from 'src/app/utils/iam.service';
import { KeycloakProfile } from 'keycloak-js';
import { SiteVisitService } from 'src/app/site-visit.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WaterSystemSiteVisitsService } from '../../water-system-site-visits.service';
import { catchError, concatMap, map, takeUntil, takeWhile } from 'rxjs/operators';
import { ConnectivityService } from 'src/app/connectivity/connectivity.service';
import { ConfirmatoryActionService } from 'src/app/confirmatory-action/confirmatory-action.service';
import { of, throwError, timer } from 'rxjs';
import { QuestionniareSubscriber } from '../../questionnaire/questionnaire-subscriber';
import { WaterSystemFacilitiesService } from '../../water-system-facilities.service';
import { SiteVisitsService } from '../site-visits.service';
import { CheckoutService } from "../../checkout.service";

@Component({
  selector: 'app-current-site-visit',
  templateUrl: './current-site-visit.component.html',
  styleUrls: ['./current-site-visit.component.scss']
})
export class CurrentSiteVisitComponent implements OnInit {

  @Input() currentVisit: Visit;
  @Output() currentVisitEvent = new EventEmitter<string>();

  error = null;

  public isCurrentVisitCollapsed = false;
  public isCurrentVisitStarting = false;
  public isCurrentVisitUploading = false;
  public isCurrentVisitUploaded = false;
  public deletingCurrentVisit = false;
  completed = false;

  private userDetails: KeycloakProfile;
  isOnline: boolean;
  isPeoplePresentValid = false;

  constructor(
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private iamService: IamService,
    private siteVisitService: SiteVisitService,
    private route: ActivatedRoute,
    private waterSystemSiteVisitsService: WaterSystemSiteVisitsService,
    private connectivityService: ConnectivityService,
    private confirmatoryActionService: ConfirmatoryActionService,
    private visitSubscriber: QuestionniareSubscriber<Visit>,
    private waterSystemFacilitiesService: WaterSystemFacilitiesService,
    private siteVisitsService: SiteVisitsService,
    private checkoutService: CheckoutService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.isOnline = this.connectivityService.isOnline();
    this.connectivityService.online$().subscribe(online => {
      this.isCurrentVisitUploaded = false;
      this.isOnline = online;
      if (this.isOnline && this.currentVisit && this.currentVisit.id == null) {
        this.isCurrentVisitUploading = true;
        this.route.parent.params.subscribe(params => {
          const pwsId = +params['id'];
          this.createRemoteVisit(pwsId, true);
        });
      }
    });
    if (this.isOnline && this.completing) {
      this.pollForCurrentVisit(this.currentVisit.id, this.currentVisit.pwsId);
    }
    this.userDetails = await this.iamService.getUserDetails();
    if (this.currentVisit && this.currentVisit.inspection && this.currentVisit.inspection.peoplePresent) {
      this.isPeoplePresentValid = true;
    }
  }

  get isInspector() {
    return this.iamService.isUserInRole('inspector');
  }

  startSiteVisit() {
    this.isCurrentVisitStarting = true;
    this.route.parent.params.subscribe(params => {
      const pwsId = +params['id'];
      if (this.connectivityService.isOnline()) {
        this.createRemoteVisit(pwsId);
      }
    });
  }

  createRemoteVisit(pwsId: number, syncing: boolean = false) {
    this.waterSystemSiteVisitsService.createRemotely(pwsId).subscribe(() => {
      this.waterSystemSiteVisitsService.findAll(pwsId, true).subscribe(siteVisits => {
        if (siteVisits) {
          for (const visit of siteVisits) {
            if (visit.status.toUpperCase() === 'IN_PROGRESS') {
              this.currentVisit = visit;
              this.visitSubscriber.next(visit);
              this.siteVisitsService.createCurrentVisit(visit);
              this.isCurrentVisitStarting = false;
              this.isCurrentVisitUploading = false;
              if (syncing) {
                this.isCurrentVisitUploaded = true;
              }
              return;
            }
          }
        }
      });
    });
  }

  pollForCurrentVisit(id: number, waterSystemId: number) {
    const stopTimer$ = timer(10 * 60 * 1000);
    timer(10000, 15000)
      .pipe(
        takeUntil(stopTimer$),
        concatMap(() => this.waterSystemSiteVisitsService.findFromAll(id, waterSystemId, true).pipe(map(response => response))),
        map(found => {
          console.log('pollForCurrentVisit found!');
          this.currentVisit = found;
          if (found.status === 'completed') {
            this.checkoutService.removeWaterSystemLocally(this.currentVisit.pwsId, null);
            this.currentVisit = null;
            this.siteVisitsService.updateCurrentVisit(found);
            this.router.navigateByUrl('/home');
            //this.currentVisitEvent.emit('completed');
          }
          return found;
        }),
        catchError(error => {
          if (error.status === 404) {
            return of(error.error);
          } else {
            return throwError(error);
          }
        })
      ).pipe(takeWhile(value => value.status !== 'completed')).subscribe();
  }

  deleteCurrentSiteVisit() {
    this.confirmatoryActionService.confirm(
      'Confirmation',
      '<b>Warning</b>: The current site visit and all photos, attachments, and newly added facilities for the current site visit will ' +
      'be lost forever. Are you sure you want to delete the current site visit?',
      'Yes, Delete the Current Site Visit',
      'No, I\'ve Changed My Mind',
      'lg')
      .then((confirmed) => {
        if (confirmed) {
          this.deletingCurrentVisit = true;
          this.route.parent.params.subscribe(params => {
            const pwsId = +params['id'];
            if (this.connectivityService.isOnline()) {
              this.waterSystemSiteVisitsService.deleteInProgress(pwsId).subscribe(() => {
                this.deleteLocalCurrentVisit();
              });
            } else {
              if (this.currentVisit.id != null) {
                this.waterSystemSiteVisitsService.deleteOfflineInProgress(pwsId);
              }
              this.deleteLocalCurrentVisit();
            }
            if (this.currentVisit.inspection && this.currentVisit.inspection.addedFacilities
              && this.currentVisit.inspection.addedFacilities.length > 0) {
              this.waterSystemFacilitiesService.deleteFacilities(this.currentVisit.inspection.addedFacilities);
            }
          });
        }
      })
      .catch(() => {});
  }

  deleteLocalCurrentVisit() {
    this.waterSystemSiteVisitsService.deleteLocally(this.currentVisit.id);
    this.waterSystemSiteVisitsService.deleteLocalQuestionnaireAttachments(this.currentVisit.id);
    this.currentVisit = null;
    this.visitSubscriber.next(null);
    this.siteVisitsService.deleteCurrentVisit();
    this.deletingCurrentVisit = false;
  }

  /**
   * @deprecated not currently used, but code is being retained for future use
   *
   */
  confirmMndwisUpdated() {
    if (this.connectivityService.isOnline) {
      this.confirmatoryActionService.confirm(
        'Confirmation',
        'Have you updated MNDWIS?',
        'Yes, I have updated MNDWIS',
        'No, I\'ve not updated MNDWIS',
        'lg')
      .then((confirmed) => {
        if (confirmed) {
          this.completeCurrentSiteVisit();
        }
      })
      .catch(() => {});
    }
  }

  confirmCompleteCurrentSiteVisit() {
    if (this.connectivityService.isOnline) {
      this.confirmatoryActionService.confirm(
        'Confirmation',
        'Are you sure you want to complete the current site visit?' +
        '<br/><b>Warning:</b> This will also cancel the checkout and remove it from your Checked Out PWS list.',
        'Yes, Complete the Current Site Visit',
        'No, I\'ve Changed My Mind',
        'lg')
        .then((confirmed) => {
          if (confirmed) {
            //  disabled 7/12/24
            //  this.confirmMndwisUpdated();
            this.completeCurrentSiteVisit();
          }
        })
        .catch(() => {});
    }
  }

  completeCurrentSiteVisit() {
      this.currentVisit.status = 'pending';
      this.currentVisit.statusMessage = 'Request to complete site visit is pending.';
      this.waterSystemSiteVisitsService.save(this.currentVisit);
      this.visitSubscriber.next(this.currentVisit);
      this.siteVisitsService.updateCurrentVisit(this.currentVisit);
      this.pollForCurrentVisit(this.currentVisit.id, this.currentVisit.pwsId);
  }

  get completing() {
    return this.currentVisit && ['pending', 'report'].includes(this.currentVisit.status);
  }

  get isValid() {
    return this.currentVisit && this.currentVisit.inspection && this.currentVisit.inspection.valid;
  }

  saveCurrentSiteVisit() {
    this.isPeoplePresentValid = !!this.currentVisit.inspection?.peoplePresent;
    this.waterSystemSiteVisitsService.save(this.currentVisit);
  }

  saveVisitDate(value: Date) {
    if (value) {
      this.currentVisit.visitDate = value;
      this.saveCurrentSiteVisit();
    }
  }
}
