import { Component, OnInit, Input, QueryList, ViewChildren, AfterViewInit, ChangeDetectorRef, OnChanges } from '@angular/core';
import { forEach } from 'lodash';
import { DocumentService } from 'src/app/document.service';
import { Visit } from 'src/app/domain/visit';
import { NgbdSortableHeader, SortEvent, compare } from '../../../shared/table-sortable';
import {ConnectivityService} from '../../../connectivity/connectivity.service';

@Component({
  selector: 'app-past-site-visits',
  templateUrl: './past-site-visits.component.html',
  styleUrls: ['./past-site-visits.component.scss']
})
export class PastSiteVisitsComponent implements OnChanges, AfterViewInit {

  @Input() pastVisits: Visit[];

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  public isPastVisitsCollapsed = false;
  public hasPastVisits = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private documentService: DocumentService,
    private connectivityService: ConnectivityService
    ) { }

  ngOnChanges() {
    this.connectivityService.online$().subscribe(online => {
      this.initList(online);
    });
    this.initList(this.connectivityService.isOnline());
  }

  initList(isOnline: boolean) {
    if (this.pastVisits && this.pastVisits.length > 0) {
      this.hasPastVisits = true;
      /* Disabled 6/27/2024
      if (isOnline) {
        this.pastVisits.forEach(visit => {
          if (visit.inspection && visit.inspection.reportPath && !visit.inspection.reportUrl) {
            this.documentService.getDownloadUrl(visit.inspection.reportPath).subscribe(url => {
              visit.inspection.reportUrl = url.s3SecureUrl;
            });
          }
        });
      }
      */
    } else {
      this.pastVisits = [];
    }
  }

  ngAfterViewInit() {
    this.headers.forEach((header: NgbdSortableHeader) => {
      if (header.sortable === 'visitDate') {
        header.direction = 'desc';
        header.sort.emit({column: header.sortable, direction: header.direction});
      }
    });
    this.changeDetectorRef.detectChanges();
  }

  onSort({column, direction}: SortEvent) {
    this.sortDefault(this.pastVisits);
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '' || column === '') {
      this.pastVisits = this.sortDefault(this.pastVisits);
    } else {
      this.pastVisits = [...this.pastVisits].sort((a, b) => {
        if (a[column] instanceof Date) {
          const res = compare(a[column].getTime(), b[column].getTime());
          return direction === 'asc' ? res : -res;
        } else {
          const res = compare(a[column], b[column]);
          return direction === 'asc' ? res : -res;
        }
      });
    }
  }

  sortDefault(visits: Visit[]): Visit[] {
    if (visits == null) {
      return null;
    } else if (visits.length === 1) {
      return visits;
    }
    visits.sort((a, b) => {
      return new Date(b.visitDate).getTime() - new Date(a.visitDate).getTime();
    });
    return visits;
  }

}
