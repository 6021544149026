import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Checkout } from 'src/app/domain/checkout';
import { Contact, ContactAction } from '../../../../domain/contact';
import { ContactsService } from '../../contacts.service';
import { MessageService } from 'src/app/message/message.service';
import { Severity } from 'src/app/message/Severity';
import { WaterSystemContactsService } from 'src/app/water-system/water-system-contacts.service';
import { Unsubscribable } from 'rxjs';
import { ConnectivityService } from 'src/app/connectivity/connectivity.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent implements OnInit, OnDestroy {

  contact: Contact = {
    id: -1,
    contactSeqNo: null,
    pwsId: null,
    isPrimary: false,
    role: '',
    name: '',
    phoneSeqNo: null,
    phone: '',
    email: '',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    zip: '',
    action: ContactAction.Create
  };

  contacts: Checkout<Contact[]>;
  public isAddingContact: boolean;

  private contacts$: Unsubscribable;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private contactsService: ContactsService,
              private messageService: MessageService,
              private waterSystemContactsService: WaterSystemContactsService,
              private connectivityService: ConnectivityService) { }

  ngOnInit(): void {
    this.isAddingContact = false;
    this.contacts$ = this.contactsService.contacts$.subscribe(contacts => {
      this.contacts = contacts;
      const minContact = this.contacts.data.reduce((prev,curr) => prev.id < curr.id ? prev : curr);
      if (minContact.id < 0) {
        this.contact.id = minContact.id - 1;
      }
    });
    this.route.parent.params.subscribe(params => {
      this.contact.pwsId = +params['id'];
    });
  }

  add(contact: Contact) {
    this.isAddingContact = true;
    if (this.contacts.data == null) {
      this.contacts.data = [];
    }
    this.contacts.data.push(contact);
    if (this.connectivityService.isOnline()) {
      this.waterSystemContactsService.createRemotely(contact.pwsId, this.contacts).pipe(take(1)).subscribe(() => {
        this.waterSystemContactsService.findRemotely(contact.pwsId).pipe(take(1)).subscribe(contacts => {
          // TODO: example to update local list from remote.
          this.waterSystemContactsService.saveLocally(contact.pwsId, contacts);
          this.isAddingContact = false;
          this.successNavigateToParent();
        });
      });
    } else {
      this.waterSystemContactsService.save(contact.pwsId, this.contacts);
      this.isAddingContact = false;
      this.successNavigateToParent();
    }
  }

  cancel() {
    this.router.navigate(['contacts'], { relativeTo: this.route.parent });
  }

  successNavigateToParent(): void {
    this.router.navigate(['contacts'], { relativeTo: this.route.parent });
    this.messageService.add({ severity: Severity.SUCCESS, value: 'Contact has been added.' });
    window.scrollTo(0, 0);
  }

  ngOnDestroy(): void {
    if (this.contacts$ != null) {
      this.contacts$.unsubscribe();
    }
  }
}
