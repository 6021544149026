export class PageRequest {
  index: number;
  limit: number;
  // todo: sorting

  constructor(index: number, limit: number) {
    this.index = index;
    this.limit = limit;
  }

}
