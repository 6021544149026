import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Attachment, copyAttachmentRequired } from 'src/app/domain/attachment';
import { Question } from 'src/app/domain/question';
import { LocalStorageService } from 'src/app/local-storage.service';
import { DocumentService } from 'src/app/document.service';
import { tables } from 'src/app/utils/app-db';
import {mergeMap} from "rxjs/operators";

@Component({
  selector: 'app-question-additional-information-view',
  templateUrl: './question-additional-information-view.component.html',
  styleUrls: ['./question-additional-information-view.component.scss']
})
export class QuestionAdditionalInformationViewComponent implements OnInit {

  @Input() question: Question;
  @Input() waterSystemId: number;
  @Input() siteVisitId: number;
  @Input() questionnaireId: string;
  photo: Attachment;
  attachments: Attachment[];
  noteLabelOverride: string;
  isPhotoCollapsed: boolean = true;
  isAttachmentsCollapsed: boolean = true;
  isNoteCollapsed: boolean = true;
  isFindingCollapsed: boolean = true;
  hasPhoto = false;
  hasAttachments = false;
  hasNote = false;
  hasFinding = false;
  hasAdditional = false;
  photoThumbnailId: string;

  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService,
    private documentService: DocumentService,
    ) { }

  ngOnInit(): void {
    if (this.question.photo) {
      this.getPhoto();
      this.hasPhoto = true;
      this.photoThumbnailId = 'photoThumbnail-' + this.question.photo.key;
    }
    if (this.question.attachments && this.question.attachments.length > 0) {
      this.getAttachments();
      this.hasAttachments = true;
    }
    if (this.question.note) {
      this.hasNote = true;
    }
    if (this.question.finding) {
      this.hasFinding = true;
    }
    this.hasAdditional = this.hasPhoto || this.hasAttachments || this.hasNote || this.hasFinding;
    this.noteLabelOverride = 'Note';
    let value = null;
    if (this.question.value) {
      value = this.question.value;
    }

    if (value) {
      for (let answer of this.question.answers) {
        if (value === answer.name) {
          if (answer.noteLabelOverride != null && answer.noteLabelOverride !== '') {
            this.noteLabelOverride = answer.noteLabelOverride;
          }
        }
      }
    }
  }

  drawThumbnail(file: File) {
    const reader = new FileReader();
    const photoThumbnailId = this.photoThumbnailId;
    reader.onload = function(event) {
      let img = new Image();
      img.onload = function() {
        const photoThumbnailCanvas = <HTMLCanvasElement> document.getElementById(photoThumbnailId);
        const ctx = photoThumbnailCanvas.getContext('2d');
        if (img.width < 150) {
          photoThumbnailCanvas.width = img.width;
          photoThumbnailCanvas.height = img.height;
        } else {
          const ratio = img.width / 150;
          photoThumbnailCanvas.width = 150;
          photoThumbnailCanvas.height = img.height / ratio;
        }
        ctx.drawImage(img, 0, 0, photoThumbnailCanvas.width, photoThumbnailCanvas.height);
      }
      img.src = event.target.result.toString();
    }
    reader.readAsDataURL(file);
  }

  getAttachments() {
    if (this.attachments == null) {
      this.attachments = [];
    }
    for (let attachment of this.question.attachments) {
      if (attachment && attachment.key) {
        this.localStorageService.dbRetrieve<Attachment>(tables.questionnaireAttachment, attachment.key).subscribe(
          (result) => {
            if (result === undefined) {
              this.getAttachmentRemotely(attachment);
            } else {
              this.attachments.push(result);
            }
          },
          (error) => {
            console.error("Error fetching attachment:", error);
          }
        )
      }
    }
  }

  getAttachmentRemotely(attachmentInfo: Attachment) {
    this.documentService
      .getDownloadUrl(attachmentInfo.path)
      .pipe(
        mergeMap((documentUrlRequest) =>
          this.httpClient.get(documentUrlRequest.s3SecureUrl, {
            responseType: 'blob' as 'json',
          })
        )
      )
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const fileBlob: Blob = new Blob(binaryData, { type: dataType });

          let attachment = copyAttachmentRequired((attachmentInfo));
          attachment.file = new File([fileBlob], attachmentInfo.filename, {
            type: dataType
          });
          this.attachments.push(attachment);
        },
        (error) => {
          console.error("Error fetching attachment remotely:", error);
        }
      )
  }

  expandAll() {
    this.isPhotoCollapsed = false;
    this.isAttachmentsCollapsed = false;
    this.isNoteCollapsed = false;
    this.isFindingCollapsed = false;
  }

  collapseAll() {
    this.isPhotoCollapsed = true;
    this.isAttachmentsCollapsed = true;
    this.isNoteCollapsed = true;
    this.isFindingCollapsed = true;
  }

  openPhoto() {
    this.localStorageService.dbRetrieve<Attachment>(tables.questionnaireAttachment, this.photo.key).subscribe(result => {
      const url = window.URL.createObjectURL(result.file);
      window.open(url, '_blank');
    });
  }

  openAttachment(attachment: Attachment) {
    this.localStorageService.dbRetrieve<Attachment>(tables.questionnaireAttachment, attachment.key).subscribe(result => {
      const url = window.URL.createObjectURL(result.file);
      window.open(url, '_blank');
    });
  }

  getPhoto() {
    this.localStorageService.dbRetrieve<Attachment>(tables.questionnaireAttachment, this.question.photo.key).subscribe(
      (result) => {
        if (result === undefined) {
          this.getPhotoRemotely();
        } else {
          this.photo = result;
          if (this.photo.file) {
            this.drawThumbnail(this.photo.file);
          }
        }
      },
      (error) => {
        console.error("Error retrieving photo:", error);
      }
    )
  }

  getPhotoRemotely() {
    this.documentService
      .getDownloadUrl(this.question.photo.path)
      .pipe(
        mergeMap((documentUrlRequest) =>
          this.httpClient.get(documentUrlRequest.s3SecureUrl, {
            responseType: 'blob' as 'json',
          })
        )
      )
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const fileBlob: Blob = new Blob(binaryData, { type: dataType });

          this.photo = copyAttachmentRequired((this.question.photo));
          this.photo.file = new File([fileBlob], this.question.photo.filename, {
            type: dataType
          });

          if (this.photo && this.photo.file) {
            this.drawThumbnail(this.photo.file);
          }
        },
        (error) => {
          console.error("Error fetching photo remotely:", error);
        }
      )
  }
}
