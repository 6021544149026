<div id="water-system-population" *ngIf="waterSystem">
    <div class="border p-2 panel-collapsible-header">
      <a href="javascript:void(0);" (click)="isPopulationCollapsed = !isPopulationCollapsed"
        [attr.aria-expanded]="!isPopulationCollapsed" aria-controls="collapse-population">
        <div>
          <h2>
            Population
            <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isPopulationCollapsed"></i>
            <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isPopulationCollapsed"></i>
          </h2>
        </div>
      </a>
    </div>
    <div id="collapse-population" class="card" [ngbCollapse]="isPopulationCollapsed">
      <div class="card-body row">
        <div class="col-12" *ngIf="waterSystem.data.type === 'COMM'">
          <span id="populationCommunityMinText"><p>Population should be greater than 24 for an active Community system.</p></span>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="form-group col-12 col-sm-6 col-md-3">
              <label for="population-january">January:</label>
              <input id="population-january"
                     class="form-control"
                     [(ngModel)]="waterSystem.data.popJan"
                     type="number"
                     min="0"
                     [minnumber]="0"
                     max="9999999"
                     [maxnumber]="9999999"
                     (ngModelChange)="onChange($event)"
                     #populationJanuary="ngModel"
                     [attr.aria-describedby]="waterSystem.data.type === 'COMM' ? populationCommunityMinText : null"/>
              <div *ngIf="populationJanuary.invalid && (populationJanuary.dirty || populationJanuary.touched)" class="alert alert-danger">
                <div *ngIf="populationJanuary.errors.min">
                  The value cannot be negative.
                </div>
                <div *ngIf="populationJanuary.errors.max">
                  The value cannot exceed 9999999.
                </div>
              </div>
              <div *ngIf="showMinPopulationWarning(populationJanuary.value)" class="alert alert-warning">
                Population should be greater than 24.
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3">
              <label for="population-february">February:</label>
              <input id="population-february"
                     class="form-control"
                     [(ngModel)]="waterSystem.data.popFeb"
                     type="number"
                     min="0"
                     [minnumber]="0"
                     max="9999999"
                     [maxnumber]="9999999"
                     (ngModelChange)="onChange($event)"
                     #populationFebruary="ngModel"
                     [attr.aria-describedby]="waterSystem.data.type === 'COMM' ? populationCommunityMinText : null"/>
              <div *ngIf="populationFebruary.invalid && (populationFebruary.dirty || populationFebruary.touched)" class="alert alert-danger">
                <div *ngIf="populationFebruary.errors.min">
                  The value cannot be negative.
                </div>
                <div *ngIf="populationFebruary.errors.max">
                  The value cannot exceed 9999999.
                </div>
              </div>
              <div *ngIf="showMinPopulationWarning(populationFebruary.value)" class="alert alert-warning">
                Population should be greater than 24.
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3">
              <label for="population-march">March:</label>
              <input id="population-march"
                     class="form-control"
                     [(ngModel)]="waterSystem.data.popMar"
                     type="number"
                     min="0"
                     [minnumber]="0"
                     max="9999999"
                     [maxnumber]="9999999"
                     (ngModelChange)="onChange($event)"
                     #populationMarch="ngModel"
                     [attr.aria-describedby]="waterSystem.data.type === 'COMM' ? populationCommunityMinText : null"/>
              <div *ngIf="populationMarch.invalid && (populationMarch.dirty || populationMarch.touched)" class="alert alert-danger">
                <div *ngIf="populationMarch.errors.min">
                  The value cannot be negative.
                </div>
                <div *ngIf="populationMarch.errors.max">
                  The value cannot exceed 9999999.
                </div>
              </div>
              <div *ngIf="showMinPopulationWarning(populationMarch.value)" class="alert alert-warning">
                Population should be greater than 24.
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3">
              <label for="population-april">April:</label>
              <input id="population-april"
                     class="form-control"
                     [(ngModel)]="waterSystem.data.popApr"
                     type="number"
                     min="0"
                     [minnumber]="0"
                     max="9999999"
                     [maxnumber]="9999999"
                     (ngModelChange)="onChange($event)"
                     #populationApril="ngModel"
                     [attr.aria-describedby]="waterSystem.data.type === 'COMM' ? populationCommunityMinText : null"/>
              <div *ngIf="populationApril.invalid && (populationApril.dirty || populationApril.touched)" class="alert alert-danger">
                <div *ngIf="populationApril.errors.min">
                  The value cannot be negative.
                </div>
                <div *ngIf="populationApril.errors.max">
                  The value cannot exceed 9999999.
                </div>
              </div>
              <div *ngIf="showMinPopulationWarning(populationApril.value)" class="alert alert-warning">
                Population should be greater than 24.
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3">
              <label for="population-may">May:</label>
              <input id="population-may"
                     class="form-control"
                     [(ngModel)]="waterSystem.data.popMay"
                     type="number"
                     min="0"
                     [minnumber]="0"
                     max="9999999"
                     [maxnumber]="9999999"
                     (ngModelChange)="onChange($event)"
                     #populationMay="ngModel"
                     [attr.aria-describedby]="waterSystem.data.type === 'COMM' ? populationCommunityMinText : null"/>
              <div *ngIf="populationMay.invalid && (populationMay.dirty || populationMay.touched)" class="alert alert-danger">
                <div *ngIf="populationMay.errors.min">
                  The value cannot be negative.
                </div>
                <div *ngIf="populationMay.errors.max">
                  The value cannot exceed 9999999.
                </div>
              </div>
              <div *ngIf="showMinPopulationWarning(populationMay.value)" class="alert alert-warning">
                Population should be greater than 24.
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3">
              <label for="population-june">June:</label>
              <input id="population-june"
                     class="form-control"
                     [(ngModel)]="waterSystem.data.popJun"
                     type="number"
                     min="0"
                     [minnumber]="0"
                     max="9999999"
                     [maxnumber]="9999999"
                     (ngModelChange)="onChange($event)"
                     #populationJune="ngModel"
                     [attr.aria-describedby]="waterSystem.data.type === 'COMM' ? populationCommunityMinText : null"/>
              <div *ngIf="populationJune.invalid && (populationJune.dirty || populationJune.touched)" class="alert alert-danger">
                <div *ngIf="populationJune.errors.min">
                  The value cannot be negative.
                </div>
                <div *ngIf="populationJune.errors.max">
                  The value cannot exceed 9999999.
                </div>
              </div>
              <div *ngIf="showMinPopulationWarning(populationJune.value)" class="alert alert-warning">
                Population should be greater than 24.
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3">
              <label for="population-july">July:</label>
              <input id="population-july"
                     class="form-control"
                     [(ngModel)]="waterSystem.data.popJul"
                     type="number"
                     min="0"
                     [minnumber]="0"
                     max="9999999"
                     [maxnumber]="9999999"
                     (ngModelChange)="onChange($event)"
                     #populationJuly="ngModel"
                     [attr.aria-describedby]="waterSystem.data.type === 'COMM' ? populationCommunityMinText : null"/>
              <div *ngIf="populationJuly.invalid && (populationJuly.dirty || populationJuly.touched)" class="alert alert-danger">
                <div *ngIf="populationJuly.errors.min">
                  The value cannot be negative.
                </div>
                <div *ngIf="populationJuly.errors.max">
                  The value cannot exceed 9999999.
                </div>
              </div>
              <div *ngIf="showMinPopulationWarning(populationJuly.value)" class="alert alert-warning">
                Population should be greater than 24.
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3">
              <label for="population-august">August:</label>
              <input id="population-august"
                     class="form-control"
                     [(ngModel)]="waterSystem.data.popAug"
                     type="number"
                     min="0"
                     [minnumber]="0"
                     max="9999999"
                     [maxnumber]="9999999"
                     (ngModelChange)="onChange($event)"
                     #populationAugust="ngModel"
                     [attr.aria-describedby]="waterSystem.data.type === 'COMM' ? populationCommunityMinText : null"/>
              <div *ngIf="populationAugust.invalid && (populationAugust.dirty || populationAugust.touched)" class="alert alert-danger">
                <div *ngIf="populationAugust.errors.min">
                  The value cannot be negative.
                </div>
                <div *ngIf="populationAugust.errors.max">
                  The value cannot exceed 9999999.
                </div>
              </div>
              <div *ngIf="showMinPopulationWarning(populationAugust.value)" class="alert alert-warning">
                Population should be greater than 24.
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3">
              <label for="population-september">September:</label>
              <input id="population-september"
                     class="form-control"
                     [(ngModel)]="waterSystem.data.popSep"
                     type="number"
                     min="0"
                     [minnumber]="0"
                     max="9999999"
                     [maxnumber]="9999999"
                     (ngModelChange)="onChange($event)"
                     #populationSeptember="ngModel"
                     [attr.aria-describedby]="waterSystem.data.type === 'COMM' ? populationCommunityMinText : null"/>
              <div *ngIf="populationSeptember.invalid && (populationSeptember.dirty || populationSeptember.touched)" class="alert alert-danger">
                <div *ngIf="populationSeptember.errors.min">
                  The value cannot be negative.
                </div>
                <div *ngIf="populationSeptember.errors.max">
                  The value cannot exceed 9999999.
                </div>
              </div>
              <div *ngIf="showMinPopulationWarning(populationSeptember.value)" class="alert alert-warning">
                Population should be greater than 24.
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3">
              <label for="population-october">October:</label>
              <input id="population-october"
                     class="form-control"
                     [(ngModel)]="waterSystem.data.popOct"
                     type="number"
                     min="0"
                     [minnumber]="0"
                     max="9999999"
                     [maxnumber]="9999999"
                     (ngModelChange)="onChange($event)"
                     #populationOctober="ngModel"
                     [attr.aria-describedby]="waterSystem.data.type === 'COMM' ? populationCommunityMinText : null"/>
              <div *ngIf="populationOctober.invalid && (populationOctober.dirty || populationOctober.touched)" class="alert alert-danger">
                <div *ngIf="populationOctober.errors.min">
                  The value cannot be negative.
                </div>
                <div *ngIf="populationOctober.errors.max">
                  The value cannot exceed 9999999.
                </div>
              </div>
              <div *ngIf="showMinPopulationWarning(populationOctober.value)" class="alert alert-warning">
                Population should be greater than 24.
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3">
              <label for="population-november">November:</label>
              <input id="population-november"
                     class="form-control"
                     [(ngModel)]="waterSystem.data.popNov"
                     type="number"
                     min="0"
                     [minnumber]="0"
                     max="9999999"
                     [maxnumber]="9999999"
                     (ngModelChange)="onChange($event)"
                     #populationNovember="ngModel"
                     [attr.aria-describedby]="waterSystem.data.type === 'COMM' ? populationCommunityMinText : null"/>
              <div *ngIf="populationNovember.invalid && (populationNovember.dirty || populationNovember.touched)" class="alert alert-danger">
                <div *ngIf="populationNovember.errors.min">
                  The value cannot be negative.
                </div>
                <div *ngIf="populationNovember.errors.max">
                  The value cannot exceed 9999999.
                </div>
              </div>
              <div *ngIf="showMinPopulationWarning(populationNovember.value)" class="alert alert-warning">
                Population should be greater than 24.
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3">
              <label for="population-december">December:</label>
              <input id="population-december"
                     class="form-control"
                     [(ngModel)]="waterSystem.data.popDec"
                     type="number"
                     min="0"
                     [minnumber]="0"
                     max="9999999"
                     [maxnumber]="9999999"
                     (ngModelChange)="onChange($event)"
                     #populationDecember="ngModel"
                     [attr.aria-describedby]="waterSystem.data.type === 'COMM' ? populationCommunityMinText : null"/>
              <div *ngIf="populationDecember.invalid && (populationDecember.dirty || populationDecember.touched)" class="alert alert-danger">
                <div *ngIf="populationDecember.errors.min">
                  The value cannot be negative.
                </div>
                <div *ngIf="populationDecember.errors.max">
                  The value cannot exceed 9999999.
                </div>
              </div>
              <div *ngIf="showMinPopulationWarning(populationDecember.value)" class="alert alert-warning">
                Population should be greater than 24.
              </div>
            </div>
          </div>
          <div class="row pt-3">
            <div class="input-group form-group col-md-6 col-sm-12">
              <div class="input-group-prepend">
                <label class="input-group-text" for="allMonths">All months</label>
              </div>
              <input id="allMonths"
                     type="number"
                     class="form-control"
                     [(ngModel)]="allMonthsPopulation"
                     #allMonths="ngModel"
                     min="0"
                     [minnumber]="0"
                     max="9999999"
                     [maxnumber]="9999999"
                     (ngModelChange)="onAllMonthsChange(allMonths.value)"
                     [attr.aria-describedby]="waterSystem.data.type === 'COMM' ? populationCommunityMinText : null"/>
              <div class="input-group-append">
                <input class="btn btn-outline-dark"
                       type="button"
                       value="Set"
                       (click)="setPopulations(allMonths.value)"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div *ngIf="showAllMonthsNegativeValueError" class="alert alert-danger">
                The value cannot be negative.
              </div>
              <div *ngIf="showAllMonthsMaxValueError" class="alert alert-danger">
                The value cannot exceed 9999999.
              </div>
              <div *ngIf="showAllMonthsPopulationWarning" class="alert alert-warning">
                Population should be greater than 24.
              </div>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-12">
              <fieldset class="form-check">
                <legend>Seasonal</legend>
                <span class="pt-2">Does the distribution use and pressurization status vary from the overall PWS months open/closed; meeting one of the conditions listed below?</span>
                <ul>
                  <li>PWS open year round, with one or more distribution systems seasonally depressurized and unavailable for monitoring year-round</li>
                  <li>PWS not open year round, with one or more distribution systems remaining pressurized year-round</li>
                </ul>
                <input class="form-check-input" type="radio" name="pop-seasonal-radios" id="pop-seasonal-radio-yes" [value]="'y'" [(ngModel)]="waterSystem.data.seasonal" (ngModelChange)="onChange($event)"/>
                <label for="pop-seasonal-radio-yes" class="form-check-label">Yes</label>
                <input class="form-check-input" type="radio" name="pop-seasonal-radios" id="pop-seasonal-radio-no" [value]="'n'" [(ngModel)]="waterSystem.data.seasonal" (ngModelChange)="onChange($event)"/>
                <label for="pop-seasonal-radio-no" class="form-check-label">No</label>
                <input class="form-check-input" type="radio" name="pop-seasonal-radios" id="pop-seasonal-radio-not-verified" [value]="'u'" [(ngModel)]="waterSystem.data.seasonal" (ngModelChange)="onChange($event)"/>
                <label for="pop-seasonal-radio-not-verified" class="form-check-label">Not Verified</label>
              </fieldset>
            </div>
          </div>
          <div class="row pt-3">
            <div class="form-group col-12">
              <label for="population-comments">Comments</label>
              <textarea id="population-comments" class="form-control" rows="5" [(ngModel)]="waterSystem.data.popComments" (ngModelChange)="onChange($event)"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>