import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConnectivityService } from 'src/app/connectivity/connectivity.service';
import { environment } from 'src/environments/environment';
import { Deficiency } from 'src/app/domain/finding';

@Injectable({
  providedIn: 'root'
})
export class DeficiencyService {

  private readonly DEFICIENCY_URL = environment.serverUrl + '/deficiencytype';

  constructor(private connectivityService: ConnectivityService,
              private httpClient: HttpClient) { }

  /**
   * Gets the deficiencies from the remote API.
   */
  get(): Observable<Deficiency[]> {
    if (this.connectivityService.isOnline()) {
      return this.httpClient.get<Deficiency[]>(`${this.DEFICIENCY_URL}`);
    }
  }
}