import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { QuestionnairesService } from 'src/app/admin/questionnaires/questionnaires.service';
import { ConfirmatoryActionService } from 'src/app/confirmatory-action/confirmatory-action.service';
import {
  FacilityType,
  FacilityTypeTitle,
  Inspection,
} from 'src/app/domain/inspection';
import {
  FacilityQuestionnaire,
  MissingFacility,
  Questionnaire,
  QuestionnaireList,
} from 'src/app/domain/questionnaire';
import { Visit } from 'src/app/domain/visit';
import { DistributionFacilityService } from '../distribution/distribution-facility.service';
import { QuestionniareSubscriber } from '../questionnaire/questionnaire-subscriber';
import { WaterSystemSiteVisitsService } from '../water-system-site-visits.service';
import { WellsService } from '../wells/wells.service';
import { TreatmentService } from '../treatment/treatment.service';
import { StorageFacilityService } from '../storage/storage-facility.service';
import { SurfaceWaterFacilityService } from '../surface-water/surface-water-facility.service';
import { InterconnectFacilityService } from '../booster-station/interconnect-facility.service';
import { BoosterStationFacilityService } from '../booster-station/booster-station-facility.service';
import { Unsubscribable } from 'rxjs';
import {
  BoosterStation,
  FacilityAny,
} from 'src/app/domain/facility';
import { WaterSystemFacilitiesService } from '../water-system-facilities.service';
import { SiteVisitsService } from '../site-visits/site-visits.service';
import {environment} from '../../../environments/environment';
import {validate} from '../site-visits/site-visit-utils';
import { LocalStorageService } from '../../local-storage.service';
import { WaterSystemWellsService } from '../water-system-wells.service';
import { FACILITY_STATUSES } from "../../shared/facility-status";

@Component({
  selector: 'app-questionnaire-list',
  templateUrl: './questionnaire-list.component.html',
  styleUrls: ['./questionnaire-list.component.scss'],
})
export class QuestionnaireListComponent implements OnInit, OnDestroy {
  waterSystemId: number;
  siteVisitId: number;
  siteVisit: Visit;
  wellForm: UntypedFormGroup;
  wellMulti = false;
  boosterStationFacilities: BoosterStation[];
  facilityType = FacilityType;
  facilityTypeTitle = FacilityTypeTitle;

  private visitSubscriber$: Unsubscribable;
  private visitsSubscriber$: Unsubscribable;
  private wellsSubscriber$: Unsubscribable;
  private distributionFacilitiesSubscriber$: Unsubscribable;
  private treatmentPlantsSubscriber$: Unsubscribable;
  private storageFacilitiesSubscriber$: Unsubscribable;
  private surfaceWaterFacilitiesSubscriber$: Unsubscribable;
  private boosterStationsSubscriber$: Unsubscribable;
  private interconnectFacilitiesSubscriber$: Unsubscribable;
  private questionnairesSubscriber$: Unsubscribable;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private siteVisitService: WaterSystemSiteVisitsService,
    private questionnaireService: QuestionnairesService,
    private wellService: WellsService,
    private distributionService: DistributionFacilityService,
    private storageService: StorageFacilityService,
    private treatmentService: TreatmentService,
    private surfaceWaterService: SurfaceWaterFacilityService,
    private subscriber: QuestionniareSubscriber<Visit>,
    private confirm: ConfirmatoryActionService,
    private interconnectFacilityService: InterconnectFacilityService,
    private boosterStationFacilityService: BoosterStationFacilityService,
    private confirmatoryActionService: ConfirmatoryActionService,
    private waterSystemFacilitiesService: WaterSystemFacilitiesService,
    private siteVisitsService: SiteVisitsService,
    private localStorageService: LocalStorageService,
    private waterSystemWellService: WaterSystemWellsService
  ) {}

  ngOnInit() {
    this.siteVisitId = Number(this.route.snapshot.params['id']);
    this.waterSystemId = Number(this.route.parent.snapshot.params['id']);
    this.visitSubscriber$ = this.subscriber.observable$.subscribe((visit) => {
      this.siteVisit = visit;
      if (this.siteVisit) {
        this.initQuestionnaire();
      } else {
        this.visitsSubscriber$ = this.siteVisitsService.visits$.subscribe(siteVisits => {
          if (siteVisits && siteVisits.length > 0) {
            if (['IN_PROGRESS', 'PENDING', 'REPORTING'].includes(siteVisits[0].status.toUpperCase())) {
              this.subscriber.next(siteVisits[0]);
              this.initQuestionnaire();
            }
          }
        });
      }
    });
  }

  initQuestionnaire() {
    if (
      !this.siteVisit.inspection ||
      !this.siteVisit.inspection.questionnaires
    ) {
      this.questionnairesSubscriber$ = this.questionnaireService.getAll().subscribe((questionnaire) => {
        this.prepareQuestionnaire(questionnaire);
      });
    } else {
      this.setupWellForm();
      this.setupOtherFacilities();
    }
  }

  prepareQuestionnaire(questionnaires: Questionnaire[]) {
    this.siteVisit.inspection = {} as Inspection;
    this.siteVisit.inspection.summaryUrl = environment.applicationUrl + '/water-system/'
      + this.siteVisit.pwsId;
    this.siteVisit.inspection.questionnaires = {} as QuestionnaireList;
    this.siteVisit.inspection.facilities = [] as FacilityAny[];
    this.boosterStationsSubscriber$ = this.boosterStationFacilityService.boosterStations$.subscribe(
      (b) => {
        this.boosterStationFacilities = b;
        this.siteVisit.inspection.facilities.push(...b);
      }
    );
    this.siteVisit.inspection.questionnaires.pws = questionnaires.filter(
      (questionnaire) =>
        questionnaire.activeInd &&
        questionnaire.item === 'PWS' &&
        questionnaire.question.find(
          (q) => q.type === 'Question' || q.type === 'Finding'
        )
    );

    this.siteVisit.inspection.questionnaires.well = {} as FacilityQuestionnaire;
    this.siteVisit.inspection.questionnaires.well.base = questionnaires.find(
      (questionnaire) =>
        questionnaire.activeInd && questionnaire.item === 'Source, Well'
    );
    if (this.siteVisit.inspection.questionnaires.well.base) {
      this.wellsSubscriber$ = this.wellService.wells$.subscribe((results) => {
        this.siteVisit.inspection.questionnaires.well.facilities = [];
        results.forEach((well) => {
          this.siteVisit.inspection.facilities.push(well);
          if (well.statusCode === 'A' || well.statusCode === 'I') {
            const wellQuestionnaire = _.cloneDeep(
              this.siteVisit.inspection.questionnaires.well.base
            );
            wellQuestionnaire.category = well.name;
            wellQuestionnaire.facilityId = well.facilityId;
            wellQuestionnaire.facilityStatusCode = well.statusCode;
            this.siteVisit.inspection.questionnaires.well.facilities.push(
              wellQuestionnaire
            );
          }
        });
        this.setupWellForm();
      });
    }

    this.siteVisit.inspection.questionnaires.distribution =
      {} as FacilityQuestionnaire;
    this.siteVisit.inspection.questionnaires.distribution.base =
      questionnaires.find(
        (questionnaire) =>
          questionnaire.activeInd && questionnaire.item === 'Distribution'
      );
    if (this.siteVisit.inspection.questionnaires.distribution.base) {
      this.distributionFacilitiesSubscriber$ = this.distributionService.facilities$.subscribe((results) => {
        this.siteVisit.inspection.questionnaires.distribution.facilities = [];
        results.forEach((distribution) => {
          this.siteVisit.inspection.facilities.push(distribution);
          if (distribution.statusCode === 'A' || distribution.statusCode === 'I') {
            const questionnaire = _.cloneDeep(
              this.siteVisit.inspection.questionnaires.distribution.base
            );
            questionnaire.category = distribution.name;
            questionnaire.facilityId = distribution.facilityId;
            questionnaire.facilityStatusCode = distribution.statusCode;
            this.siteVisit.inspection.questionnaires.distribution.facilities.push(
              questionnaire
            );
          }
        });
      });
    }

    this.siteVisit.inspection.questionnaires.storage =
      {} as FacilityQuestionnaire;
    this.siteVisit.inspection.questionnaires.storage.base = questionnaires.find(
      (questionnaire) =>
        questionnaire.activeInd && questionnaire.item === 'Storage'
    );
    if (this.siteVisit.inspection.questionnaires.storage.base) {
      this.storageFacilitiesSubscriber$ = this.storageService.storages$.subscribe((results) => {
        this.siteVisit.inspection.questionnaires.storage.facilities = [];
        results.forEach((storage) => {
          this.siteVisit.inspection.facilities.push(storage);
          if (storage.statusCode === 'A' || storage.statusCode === 'I') {
            const storageQuestionnaire = _.cloneDeep(
              this.siteVisit.inspection.questionnaires.storage.base
            );
            storageQuestionnaire.category = storage.name;
            storageQuestionnaire.facilityId = storage.facilityId;
            storageQuestionnaire.facilityStatusCode = storage.statusCode;
            this.siteVisit.inspection.questionnaires.storage.facilities.push(
              storageQuestionnaire
            );
          }
        });
      });
    }
    this.siteVisit.inspection.questionnaires.storage.missing =
      {} as MissingFacility;
    this.siteVisit.inspection.questionnaires.storage.missing.base =
      questionnaires.find(
        (questionnaire) =>
          questionnaire.activeInd && questionnaire.item === 'No Storage'
      );
    if (this.siteVisit.inspection.questionnaires.storage.missing.base) {
      this.siteVisit.inspection.questionnaires.storage.missing.missing =
        _.cloneDeep(
          this.siteVisit.inspection.questionnaires.storage.missing.base
        );
      this.siteVisit.inspection.questionnaires.storage.missing.missing.category =
        'No Storage Facilities';
    }

    this.siteVisit.inspection.questionnaires.treatment =
      {} as FacilityQuestionnaire;
    this.siteVisit.inspection.questionnaires.treatment.base =
      questionnaires.find(
        (questionnaire) =>
          questionnaire.activeInd && questionnaire.item === 'Treatment'
      );
    if (this.siteVisit.inspection.questionnaires.treatment.base) {
      this.treatmentPlantsSubscriber$ = this.treatmentService.treatments$.subscribe((results) => {
        this.siteVisit.inspection.questionnaires.treatment.facilities = [];
        results.forEach((treatment) => {
          this.siteVisit.inspection.facilities.push(treatment);
          if (treatment.statusCode === 'A' || treatment.statusCode === 'I') {
            const treatmentQuestionnaire = _.cloneDeep(
              this.siteVisit.inspection.questionnaires.treatment.base
            );
            treatmentQuestionnaire.category = treatment.name;
            treatmentQuestionnaire.facilityId = treatment.facilityId;
            treatmentQuestionnaire.facilityStatusCode = treatment.statusCode;
            this.siteVisit.inspection.questionnaires.treatment.facilities.push(
              treatmentQuestionnaire
            );
          }
        });
      });
    }
    this.siteVisit.inspection.questionnaires.treatment.missing =
      {} as MissingFacility;
    this.siteVisit.inspection.questionnaires.treatment.missing.base =
      questionnaires.find(
        (questionnaire) =>
          questionnaire.activeInd && questionnaire.item === 'No Treatment'
      );
    if (this.siteVisit.inspection.questionnaires.treatment.missing.base) {
      this.siteVisit.inspection.questionnaires.treatment.missing.missing =
        _.cloneDeep(
          this.siteVisit.inspection.questionnaires.treatment.missing.base
        );
      this.siteVisit.inspection.questionnaires.treatment.missing.missing.category =
        'No Treatment Facilities';
    }

    this.siteVisit.inspection.questionnaires.surfaceWater =
      {} as FacilityQuestionnaire;
    this.siteVisit.inspection.questionnaires.surfaceWater.base =
      questionnaires.find(
        (questionnaire) =>
          questionnaire.activeInd &&
          questionnaire.item === 'Source, Surface Water'
      );
    if (this.siteVisit.inspection.questionnaires.surfaceWater.base) {
      this.surfaceWaterFacilitiesSubscriber$ = this.surfaceWaterService.surfaceWaterFacilities$.subscribe((results) => {
        this.siteVisit.inspection.questionnaires.surfaceWater.facilities = [];
        results.forEach((surfaceWater) => {
          this.siteVisit.inspection.facilities.push(surfaceWater);
          if (surfaceWater.statusCode === 'A' || surfaceWater.statusCode === 'I') {
            const surfaceWaterQuestionnaire = _.cloneDeep(
              this.siteVisit.inspection.questionnaires.surfaceWater.base
            );
            surfaceWaterQuestionnaire.category = surfaceWater.name;
            surfaceWaterQuestionnaire.facilityId = surfaceWater.facilityId;
            surfaceWaterQuestionnaire.facilityStatusCode = surfaceWater.statusCode;
            this.siteVisit.inspection.questionnaires.surfaceWater.facilities.push(
              surfaceWaterQuestionnaire
            );
          }
        });
      });
    }

    this.siteVisit.inspection.questionnaires.interconnect =
      {} as FacilityQuestionnaire;
    this.siteVisit.inspection.questionnaires.interconnect.base =
      questionnaires.find(
        (questionnaire) =>
          questionnaire.activeInd &&
          questionnaire.item === 'Source, Consecutive Connection'
      );
    if (this.siteVisit.inspection.questionnaires.interconnect.base) {
      this.interconnectFacilityService.interconnects$.subscribe((results) => {
        this.siteVisit.inspection.questionnaires.interconnect.facilities = [];
        results.forEach((interconnect) => {
          this.siteVisit.inspection.facilities.push(interconnect);
          if (interconnect.statusCode === 'A' || interconnect.statusCode === 'I') {
            const interconnectQuestionnaire = _.cloneDeep(
              this.siteVisit.inspection.questionnaires.interconnect.base
            );
            interconnectQuestionnaire.category = interconnect.name;
            interconnectQuestionnaire.facilityId = interconnect.facilityId;
            interconnectQuestionnaire.facilityStatusCode = interconnect.statusCode;
            this.siteVisit.inspection.questionnaires.interconnect.facilities.push(
              interconnectQuestionnaire
            );
          }
        });
      });
    }
    validate(this.siteVisit, true);
    this.saveSiteVisit();
  }

  setupWellForm() {
    const wellGroup: any = {};

    if (
      this.siteVisit.inspection.questionnaires.well.facilities &&
      this.siteVisit.inspection.questionnaires.well.facilities.length > 0
    ) {
      if (this.siteVisit.inspection.questionnaires.well.facilities.length > 1) {
        this.wellMulti = true;
        this.siteVisit.inspection.questionnaires.well.facilities.forEach(

          (questionnaire) => {
            wellGroup[questionnaire.facilityId] = new UntypedFormControl({
              value: false,
              disabled: questionnaire.facilityStatusCode === "I"
            });
          }
        );
      } else {
        this.wellMulti = false;
      }
    }
    this.wellForm = new UntypedFormGroup(wellGroup);
  }

  setupOtherFacilities() {
    this.boosterStationsSubscriber$ = this.boosterStationFacilityService.boosterStations$.subscribe(
      (b) => {
        this.boosterStationFacilities = b;
      }
    );
  }

  saveSiteVisit() {
    this.siteVisitService.save(this.siteVisit);
  }

  multiSubmit(submitType) {
    this.confirm
      .confirm(
        'Edit Multiple',
        'Are you sure you want to edit multiple questionnaires?  Any previous data for these questionnaires will be overwritten.',
        'Yes',
        'No',
        'sm'
      )
      .then((confirm) => {
        if (confirm) {
          switch (submitType) {
            case 'wells':
              this.multiWells();
              break;
          }
        }
      });
  }

  get selectedWells(): Array<string> {
    return Object.keys(this.wellForm.value).filter(
      (x) => this.wellForm.value[x]
    );
  }

  get hasSelectedWells(): boolean {
    const selected = this.selectedWells;
    return selected && selected.length > 1;
  }

  multiWells() {
    const selected = this.selectedWells;
    if (selected && selected.length > 1) {
      this.router.navigate(['questionnaire', 'well'], {
        relativeTo: this.route,
        queryParams: { id: selected },
      });
    }
  }

  isInactiveFacility(facilityStatusCode: string): boolean {
    return facilityStatusCode === FACILITY_STATUSES[1]['code'];
  }

  get hasStorageQuestions(): boolean {
    return this.siteVisit.inspection.questionnaires.storage &&
      this.siteVisit.inspection.questionnaires.storage.facilities &&
      this.siteVisit.inspection.questionnaires.storage.facilities.length > 0;
  }

  get hasTreatmentQuestions(): boolean {
    return this.siteVisit.inspection.questionnaires.treatment &&
      this.siteVisit.inspection.questionnaires.treatment.facilities &&
      this.siteVisit.inspection.questionnaires.treatment.facilities.length > 0;
  }

  get hasDistributionQuestions(): boolean {
    return this.siteVisit.inspection.questionnaires.distribution &&
      this.siteVisit.inspection.questionnaires.distribution.facilities &&
      this.siteVisit.inspection.questionnaires.distribution.facilities.length >
      0;
  }

  get hasWellQuestions(): boolean {
    return this.siteVisit.inspection.questionnaires.well &&
      this.siteVisit.inspection.questionnaires.well.facilities &&
      this.siteVisit.inspection.questionnaires.well.facilities.length > 0;
  }

  get hasSurfaceWaterQuestions(): boolean {
    return this.siteVisit.inspection.questionnaires.surfaceWater &&
      this.siteVisit.inspection.questionnaires.surfaceWater.facilities &&
      this.siteVisit.inspection.questionnaires.surfaceWater.facilities.length >
      0;
  }

  get hasInterconnectQuestions(): boolean {
    return this.siteVisit.inspection.questionnaires.interconnect &&
      this.siteVisit.inspection.questionnaires.interconnect.facilities &&
      this.siteVisit.inspection.questionnaires.interconnect.facilities.length >
      0;
  }

  get hasSourceQuestions(): boolean {
    return this.hasWellQuestions || this.hasSurfaceWaterQuestions || this.hasInterconnectQuestions;
  }

  get hasOtherFacilities(): boolean {
    return (
      (this.boosterStationFacilities &&
        this.boosterStationFacilities.length > 0)
    );
  }

  isNew(facilityId: number) {
    return (
      this.siteVisit.inspection &&
      this.siteVisit.inspection.addedFacilities &&
      this.siteVisit.inspection.addedFacilities.some(
        (f) => f.facilityId === facilityId
      )
    );
  }

  deleteFacility(facilityId: number, facilityName: string) {
    this.confirmatoryActionService
      .confirm(
        'Confirmation',
        `Warning: ${facilityName} will be deleted.  Are you sure you want to delete ${facilityName} and its data?`,
        `Yes, Delete ${facilityName}`,
        'No, I\'ve Changed My Mind',
        'lg'
      )
      .then((confirmed) => {
        if (confirmed) {
          const facility = this.siteVisit.inspection.addedFacilities.find(
            (f) => f.facilityId === facilityId
          );
          const index = this.siteVisit.inspection.addedFacilities.findIndex(
            (f) => f.facilityId === facilityId
          );
          if (facility) {
            this.waterSystemFacilitiesService.deleteFacility(facility);
            this.siteVisit.inspection.addedFacilities.splice(index,  1);
            this.removeFacilityQuestionnaire(facility);
            this.saveSiteVisit();
            this.subscriber.next(this.siteVisit);
            this.siteVisitsService.updateCurrentVisit(this.siteVisit);
          }
        }
      })
      .catch(() => {});
  }

  removeFacilityQuestionnaire(facility: FacilityAny) {
    switch (facility.facilityCode) {
      case 'WL':
        this.removeFacilityFromQuestionnaire(
          facility.facilityId,
          this.siteVisit.inspection.questionnaires.well
        );
        break;
      case 'IN':
        this.removeFacilityFromQuestionnaire(
          facility.facilityId,
          this.siteVisit.inspection.questionnaires.surfaceWater
        );
        break;
      case 'SH':
      case 'SU':
      case 'ST':
      case 'SS':
      case 'SE':
        this.removeFacilityFromQuestionnaire(
          facility.facilityId,
          this.siteVisit.inspection.questionnaires.storage
        );
        break;
      case 'TP':
        this.removeFacilityFromQuestionnaire(
          facility.facilityId,
          this.siteVisit.inspection.questionnaires.treatment
        );
        break;
      case 'DS':
        this.removeFacilityFromQuestionnaire(
          facility.facilityId,
          this.siteVisit.inspection.questionnaires.distribution
        );
        break;
      case 'CC':
        this.removeFacilityFromQuestionnaire(
          facility.facilityId,
          this.siteVisit.inspection.questionnaires.interconnect
        );
    }
  }

  removeFacilityFromQuestionnaire(
    facilityId: number,
    facilityQuestionnaire: FacilityQuestionnaire
  ) {
    const index = facilityQuestionnaire.facilities.findIndex(
      (f) => f.facilityId === facilityId
    );
    if (index > -1) {
      facilityQuestionnaire.facilities.splice(index, 1);
    }
  }

  openIwmzPcsiReport(facilityId: number) {
    this.waterSystemWellService.findOne(this.waterSystemId, facilityId).subscribe(well => {
      if (well) {
        if (well.uniqueWellNbr == null) {
          alert('There is no IWMZ PDF for this well.');
        } else {
          this.localStorageService.openFileUrl(this.waterSystemWellService.generateFilename(well));
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.visitSubscriber$ != null) {
      this.visitSubscriber$.unsubscribe();
    }
    if (this.visitsSubscriber$ != null) {
      this.visitsSubscriber$.unsubscribe();
    }
    if (this.wellsSubscriber$ != null) {
      this.wellsSubscriber$.unsubscribe();
    }
    if (this.distributionFacilitiesSubscriber$ != null) {
      this.distributionFacilitiesSubscriber$.unsubscribe();
    }
    if (this.treatmentPlantsSubscriber$ != null) {
      this.treatmentPlantsSubscriber$.unsubscribe();
    }
    if (this.storageFacilitiesSubscriber$ != null) {
      this.storageFacilitiesSubscriber$.unsubscribe();
    }
    if (this.surfaceWaterFacilitiesSubscriber$ != null) {
      this.surfaceWaterFacilitiesSubscriber$.unsubscribe();
    }
    if (this.boosterStationsSubscriber$ != null) {
      this.boosterStationsSubscriber$.unsubscribe();
    }
    if (this.interconnectFacilitiesSubscriber$ != null) {
      this.interconnectFacilitiesSubscriber$.unsubscribe();
    }
    if (this.questionnairesSubscriber$ != null) {
      this.questionnairesSubscriber$.unsubscribe();
    }
  }
}
