<div class="card mb-3">
  <div class="card-body">
    <h1>Basic Details Summary</h1>
    <app-general-summary [summary]="waterSystemChanges"></app-general-summary>
    <app-population-summary [summary]="waterSystemChanges"></app-population-summary>
    <app-service-area-summary [summary]="waterSystemChanges"></app-service-area-summary>
    <app-production-source-water-protection-summary
      [summary]="waterSystemChanges"></app-production-source-water-protection-summary>
  </div>
</div>
