export enum FINDING_TYPES {
  SIGNIFICANT_DEFICIENCY = 'Significant Deficiency',
  DEFICIENCY = 'Deficiency',
  REQUIREMENT = 'Requirement',
  RECOMMENDATION = 'Recommendation'
}

export enum SANITARY_SURVEY_SECTION_TYPES {
  'DIST' = 'Distribution',
  'M_AN' = 'Monitoring/Reporting Data Verification',
  'OPER' = 'Operator Compliance with State Requirements',
  'OTHE' = 'Other',
  'PUMP' = 'Pumps/Pump Facilities and Controls',
  'TREA' = 'Treatment',
  'SOUR' = 'Water Source',
  'STOR' = 'Water Storage',
  'MGMT' = 'Water System Management/Operation'
}

export enum SURVEY_SUBCATEGORY_TYPES {
  'REQU' = 'Requirement',
  'RECO' = 'Recommendation'
}

export enum FINDING_ELEMENT_TYPES {
  'Deficiency',
  'Recommendation',
  'Requirement',
  'Significant Deficiency'
}
