<div id="past-site-visits">
  <div class="border p-2 panel-collapsible-header">
    <a href="javascript:void(0);" (click)="isPastVisitsCollapsed = !isPastVisitsCollapsed"
      [attr.aria-expanded]="!isPastVisitsCollapsed" aria-controls="collapse-past-visits">
      <div>
        <h2>
          Past Site Visits
          <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isPastVisitsCollapsed"></i>
          <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon"
            *ngIf="!isPastVisitsCollapsed"></i>
        </h2>
      </div>
    </a>
  </div>
  <div id="collapse-past-visits" class="card" [ngbCollapse]="isPastVisitsCollapsed">
    <div class="card-body row">
      <div class="col-lg-10 col-sm-12 table-responsive">
        <table class="table table-sm">
          <thead>
            <tr class="d-flex">
              <th scope="col" class="col-6" sortable="visitDate" (sort)="onSort($event)" tabindex="0"
                rotateOption="noEmptyReverse">Visit Date</th>
              <th scope="col" class="col-6" sortable="visitType" (sort)="onSort($event)" tabindex="0"
                rotateOption="noEmpty">Visit Type</th>
              <!-- Disabled 6/27/2024: <th scope="col" class="col-4">Report</th> -->
            </tr>
          </thead>
          <tbody *ngIf="pastVisits && hasPastVisits">
            <tr *ngFor="let visit of pastVisits" class="d-flex">
              <td class="col-4">{{ visit.visitDate | date: 'MM/dd/yyyy' : "+0000" }}</td>
              <td class="col-4">{{ visit.visitType | visitTypeAnnualSanitary }}</td>
              <!-- Disabled 6/27/2024: <td class="col-4"><a *ngIf="visit.inspection && visit.inspection.reportUrl"
                  [href]="visit.inspection.reportUrl"
                  target="_blank">View Report</a></td> -->
            </tr>
          </tbody>
          <tbody *ngIf="pastVisits == null || !hasPastVisits">
            <tr>
              <td colspan="12">No Past Visits Found.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
