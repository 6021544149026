<div class="container-fluid">
  <div class="p-3">
    <div class="border p-2 panel-collapsible-header h1">
      <a href="javascript:void(0);" (click)="isCollapsed = !isCollapsed"
         [attr.aria-expanded]="!isCollapsed" aria-controls="collapse-checked-out">
        <h2 class="h1">
          Checked Out PWS
          <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon"
             *ngIf="isCollapsed"></i>
          <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon"
             *ngIf="!isCollapsed"></i>
        </h2>
      </a>
    </div>
    <div id="collapse-checked-out" class="card border-0 bg-transparent" [ngbCollapse]="isCollapsed">
      <div *ngIf="isSiteVisitsLoading; else siteVisitList" class="pl-3 pt-3">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading Upcoming Site Visits...</span>
        </div>
        <span>Loading Site Visits...</span>
      </div>
      <ng-template #siteVisitList>
        <div>
          <div *ngIf="isResultsEmpty(); else hasResults">
            <h2>No Checked out PWS</h2>
          </div>
          <ng-template #hasResults>
            <div *ngIf="page">
              <table class="table table-bordered table-hover table-sm mb-0">
                <thead class="thead-light">
                <tr>
                  <th scope="col" sortable="id" (sort)="onSort($event)" tabindex="0">PWS ID</th>
                  <th scope="col" sortable="name" (sort)="onSort($event)" tabindex="0">PWS Name</th>
                  <th scope="col" sortable="city" (sort)="onSort($event)" tabindex="0">City</th>
                  <th scope="col" sortable="nextVisit" (sort)="onSort($event)" tabindex="0">Next
                    Visit
                  </th>
                  <th scope="col" sortable="nextVisitType" (sort)="onSort($event)" tabindex="0">
                    Visit Type
                  </th>
                  <th scope="col" sortable="seasonal" (sort)="onSort($event)" tabindex="0">
                    Seasonal
                  </th>
                  <th scope="col" sortable="visitStatus" (sort)="onSort($event)" tabindex="0">
                    Checked Out
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let sitevisit of page.results" [ngClass]="{'table-row-link': true}"
                    (click)="navigateToWaterSystemDetails(sitevisit)"
                    (keyup.enter)="navigateToWaterSystemDetails(sitevisit)"
                    tabindex="0" attr.aria-label="View checked out PWS with ID {{sitevisit.id}}">
                  <td>{{ sitevisit.id }}</td>
                  <td>{{ sitevisit?.name }}</td>
                  <td>{{ sitevisit?.city }}</td>
                  <td>{{ sitevisit?.nextVisit | date: 'MM/dd/yyyy' }}</td>
                  <td>{{ sitevisit?.nextVisitType | visitTypeAnnualSanitary }}</td>
                  <td>{{ sitevisit?.seasonal | seasonalIndYesNoNotverified }}</td>
                  <td>{{ getLocalCreatedDate(sitevisit) | date: 'MM/dd/yyyy' }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </div>
  </div>
</div>

