<div class="row">
  <div class="col-md-7">
    <ngb-pagination [collectionSize]="page.totalCount"
                    [pageSize]="page.limit"
                    [page]="pageIndex()"
                    (pageChange)="pageChange($event)"
                    rotate="true"
                    maxSize="5"></ngb-pagination>
  </div>
  <div class="col-md-5 text-md-right">
    <p>Showing {{ from() + 1 }} to {{ to() }} of {{ page.totalCount }} entries</p>
  </div>
</div>
