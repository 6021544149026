import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Well } from 'src/app/domain/facility';

@Injectable({
  providedIn: 'root'
})
export class WellsService {

  private wells = new BehaviorSubject<Well[]>([]);
  wells$ = this.wells.asObservable();

  constructor() { }

  changeWaterSystemWells(wells: Well[]) {
    this.wells.next(wells);
  }
}
