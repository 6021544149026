<div class="card mb-3">
  <div class="card-body">
    <h1 class="sr-only">Site Visit Questionnaire</h1>
    <div id="water-system-question">
      <div *ngIf="questionnaire">
        <h2 class="border-bottom border-dark">
          {{questionnaire.category && questionnaire.category !== '' ? questionnaire.category : questionnaire.item}}
        </h2>
        <form [formGroup]="form" id="survey-form" *ngIf="form">
          <div *ngFor="let question of questionnaire.question; let i = index;">
            <ng-template [ngIf]="isHeader(question,i)">
              <div class="border-bottom border-dark p-2 panel-collapsible-header h3">
                <a href="javascript:void(0);" (click)="isCollapsed[i] = !isCollapsed[i]"
                  [attr.aria-expanded]="!isCollapsed[i]"
                  aria-controls="collapse-checked-out">
                  <div>
                    {{question.displayName}}
                    <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isCollapsed[i]"></i>
                    <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isCollapsed[i]"></i>
                  </div>
                </a>
              </div>
            </ng-template>
            <ng-template [ngIf]="isQuestion(question) && !isQuestionDisabled(question)">
              <div [ngbCollapse]="isQuestionCollapsed(i)">
                <div class="form-group">
                  <div class="row">
                    <div class="col-lg-6 col-md-12">
                      <label [attr.for]="question.displayName">
                        {{question.displayName}}
                        <span *ngIf="question.required"> (Required)</span>
                      </label>
                      <div class="form-check" *ngFor="let answer of question.answers">
                        <input class="form-check-input" type="radio" [formControlName]="question.displayName"
                          [id]="question.displayName + answer.name" [value]="answer.name"
                          (change)="save(question)" />
                        <label class="form-check-label d-inline" for="question.displayName + answer.name">
                          {{answer.name}}
                        </label>
                      </div>
                      <div class="errorMessage text-danger" *ngIf="!isValid(question)">{{question.displayName}} is
                        required</div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" [formControlName]="getReviewName(question)"
                          [id]="getReviewName(question)" (change)="save(question)" />
                        <label class="form-check-label" for="getReviewName(question)">
                          Flag for Review
                        </label>
                      </div>
                      <div>
                        <button class="btn btn-outline-dark btn-sm py-0" title="Clear" aria-label="Clear" (click)="clear(question)" type="button">
                          <span>Clear</span>
                        </button>
                      </div>
                    </div>
                    <div *ngIf="!multi" class="col-lg-6 col-md-12">
                      <app-response-additional-information [question]="question" (questionChange)="save(question)"
                        [form]="form" [waterSystemId]="waterSystemId" [siteVisitId]="siteVisitId"
                        [questionnaireId]="questionnaireId" [clearQuestionUuid]="clearQuestionUuid">
                      </app-response-additional-information>
                    </div>
                  </div>
                  <!--<pre>{{question | json}}</pre>-->
                  <hr />
                </div>
              </div>
            </ng-template>
            <ng-template [ngIf]="isFinding(question)">
              <div [ngbCollapse]="isQuestionCollapsed(i)">
                <div *ngIf="question.finding; else addFindingButton" class="form-group">
                  <div class="row">
                    <div class="col-lg-6 col-md-12">
                      <div class="row">
                        <div class="col-12">
                          <span>{{ question.displayName }}</span>
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col-6">
                          <span>Code:</span>
                          <br/>
                          <span>{{ question.finding.code }}</span>
                        </div>
                        <div class="col-6">
                          <span>Type:</span>
                          <br/>
                          <span>{{ question.finding.description }}</span>
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col-12">
                          <span>Section:</span>
                          <br/>
                          <span>{{ question.finding.sanitarySurveySectionDescription }}</span>
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col-12">
                          <label for="{{ 'additional-finding-' + uuid + '-language' }}">Language (Required):</label>
                          <textarea id="{{ 'additional-finding-' + uuid + '-language' }}"
                                    class="form-control"
                                    [formControlName]="getFormGroupName(question)"
                                    [value]="question.value" maxlength="1000" rows="2"
                                    (change)="save(question)" required></textarea>
                          <div class="errorMessage text-danger" *ngIf="!isValidAdditionalLanguage">
                            Additional Language is required
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3" *ngIf="isFinding(question)">
                        <div class="col-12">
                          <button type="button" class="btn btn-outline-primary" (click)="deleteFinding(question, i)">Delete {{question.displayName}}</button>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                      <app-response-additional-information [question]="question" (questionChange)="save(question)"
                        [form]="form" [clearQuestionUuid]="clearQuestionUuid" [waterSystemId]="waterSystemId" [siteVisitId]="siteVisitId"
                        [questionnaireId]="questionnaireId">
                      </app-response-additional-information>
                    </div>
                  </div>
                  <hr />
                </div>
                <ng-template #addFindingButton>
                  <div class="row mb-3">
                    <div class="col-12">
                      <button type="button" class="btn btn-outline-primary" (click)="addFinding(question, i)">Add {{question.displayName}}</button>
                    </div>
                  </div>
                  <hr />
                </ng-template>
              </div>
            </ng-template>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
