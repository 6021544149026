import { Inspection } from './inspection';

export interface Visit {
  id: number;
  pwsId: number;
  reportCompletedDate: Date;
  status: string;
  statusMessage?: string;
  visitDate: Date;
  visitType: string;
  inspection?: Inspection;
}

export enum VISIT_STATUS {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
}
