<div id="water-system-production-source-summary" *ngIf="summary && (summary.designCapacity || summary.designCapacityMetric
|| summary.emergencyCapacity || summary.emergencyCapacityMetric || summary.maxDailyProduction || summary.maxDailyProductionMetric
|| summary.averageDailyProduction || summary.averageDailyProductionMetric); else noChanges">
  <div class="border p-2 panel-collapsible-header">
    <a href="javascript:void(0);" (click)="isCollapsed = !isCollapsed"
       [attr.aria-expanded]="!isCollapsed"
       aria-controls="collapse-production-source">
      <div>
        <h2>
          Production &amp; Source Water Protection
          <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon"
             *ngIf="isCollapsed"></i>
          <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon"
             *ngIf="!isCollapsed"></i>
        </h2>
      </div>
    </a>
  </div>
  <div id="collapse-production-source" class="card" [ngbCollapse]="isCollapsed">
    <div class="card-body row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <span><strong>Production Data</strong></span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-3" *ngIf="summary.designCapacity">
            <span><strong>Design Capacity:</strong></span>
            <br/>
            <span>{{ summary.designCapacity }} {{ summary.designCapacityMetric | unitMeasureCapacityType }}</span>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-12 col-sm-6 col-md-3" *ngIf="summary.emergencyCapacity">
            <span><strong>Emergency Capacity:</strong></span>
            <br/>
            <span>{{ summary.emergencyCapacity }} {{ summary.emergencyCapacityMetric | unitMeasureCapacityType }}</span>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-12 col-sm-6 col-md-3" *ngIf="summary.maxDailyProduction">
            <span><strong>Max Daily Production:</strong></span>
            <br/>
            <span>{{ summary.maxDailyProduction }} {{ summary.maxDailyProductionMetric | unitMeasureCapacityType }}</span>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-12 col-sm-6 col-md-3" *ngIf="summary.averageDailyProduction">
            <span><strong>Avg. Daily Production:</strong></span>
            <br/>
            <span>{{ summary.averageDailyProduction }} {{ summary.averageDailyProductionMetric | unitMeasureCapacityType }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #noChanges>
  <div>No Production & Source Water Changes.</div>
</ng-template>
