import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Page} from '../../domain/page';
import {PageRequest} from '../../domain/page-request';

/**
 * ngb-pagination isn't 0-based
 *   - pageIndex & pageChange are the integration points where workarounds are present
 *
 * An additional workaround needs to be in place for the pageChange event emitter:
 *   - We pass a page to the paginator, the paginator initializes, then emits a page change event which results in a duplicate search.
 */
@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {

  @Input()
  page: Page<any>;

  @Output()
  pageRequest = new EventEmitter<PageRequest>();

  pageIndex(): number {
    return this.page.index + 1;
  }

  from(): number {
    return this.page.index * this.page.limit;
  }

  to(): number {
    return Math.min(this.from() + this.page.limit, this.page.totalCount);
  }

  pageChange(page: number): void {
    const request = new PageRequest(page - 1, this.page.limit);
    if (request.index !== this.page.index) { // preventing a duplicate search during init
      this.pageRequest.emit(request);
    }
  }

}
