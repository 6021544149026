import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[numbersOnly]'
})
export class NumbersOnlyDirective {

  constructor() { }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent> event;
    if (['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'NumLock', 'ArrowLeft', 'ArrowRight', 'End', 'Home',].indexOf(e.key) !== -1 ||
      // Ctrl+A
      (e.code === 'KeyA' && (e.ctrlKey || e.metaKey)) ||
      // Ctrl+C
      (e.code === 'KeyC' && (e.ctrlKey || e.metaKey)) ||
      // Ctrl+V
      (e.code === 'KeyV' && (e.ctrlKey || e.metaKey)) ||
      // Ctrl+X
      (e.code === 'KeyX' && (e.ctrlKey || e.metaKey))) {
      return;
    }
    // Numbers
    if ((e.shiftKey || ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(e.key) === -1)) {
      e.preventDefault();
    }
  }
}
