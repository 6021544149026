<div id="last-site-visit-summary">
  <div *ngIf="isSiteVisitsLoading; else viewSummary">
    <div class="spinner-border" role="status">
      <span class="sr-only">Refreshing Checkout Status...</span>
    </div>
    <span>Getting Visit Summary...</span>
  </div>
  <ng-template #viewSummary>
    <div class="border p-2 panel-collapsible-header">
      <a href="javascript:void(0);" (click)="isCollapsed = !isCollapsed"
         [attr.aria-expanded]="!isCollapsed" aria-controls="collapse-last-site-visit">
        <div>
          <h2>
            Last Site Visit
            <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon"
               *ngIf="isCollapsed"></i>
            <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon"
               *ngIf="!isCollapsed"></i>
          </h2>
        </div>
      </a>
    </div>
    <div id="collapse-last-site-visit" class="card mb-3" [ngbCollapse]="isCollapsed"
         *ngIf="lastVisit; else noLastVisit">
      <div class="card-body row">
        <div class="row col-12">
          <div class="col-md-3 h3">Visit Type</div>
          <div class="col-md-3">{{ lastVisit.visitType  | visitTypeAnnualSanitary }}</div>
        </div>
        <div class="row col-12">
          <div class="col-md-3 h3">Visit Date</div>
          <div class="col-md-3">{{ lastVisit.visitDate | date: 'MM/dd/yyyy' : "+0000" }}</div>
        </div>
        <div class="row col-12">
          <div class="col-md-3 h3">Report Completed</div>
          <div class="col-md-3">{{ lastVisit.reportCompletedDate | date: 'MM/dd/yyyy' : "+0000" }}
          </div>
        </div>
        <div class="row col-12" *ngIf="lastVisit.inspection && lastVisit.inspection.inspector">
          <div class="col-md-3 h3">Inspector</div>
          <div class="col-md-3">{{
              lastVisit.inspection.inspector.name || "Unknown Inspector"
            }}
          </div>
        </div>
        <div class="row col-12" *ngIf="lastVisit.inspection">
          <div class="col-md-3 h3">People Present</div>
          <div class="col-md-3">{{
              lastVisit.inspection.peoplePresent || "No people listed as present"
            }}
          </div>
        </div>
        <!-- Disabled 6/27/2024
        <div class="row" *ngIf="lastVisit.inspection && lastVisit.inspection.reportUrl">
          <div class="col-md-3 h3">Site Visit Report</div>
          <div class="col-md-3">
            <a *ngIf="lastVisit.inspection && lastVisit.inspection.reportUrl"
               [href]="lastVisit.inspection.reportUrl" target="_blank">View Report</a>
          </div>
        </div>
        -->
        <div class="row col-12" *ngIf="lastVisit.inspection; else noInspectionSummary">
          <div class="col-md-3 h3">Inspection Summary</div>
          <div class="col-md-9">
            <app-visit-summary [siteVisit]="lastVisit" [waterSystemId]="waterSystemId"
                               [collapseAll]="true"></app-visit-summary>
          </div>
        </div>
        <ng-template #noInspectionSummary>
          <div class="row col-12">
            <div class="col-md-3 h3">No Inspection Summary Available</div>
          </div>
        </ng-template>
        <div class="row col-12"
             *ngIf="lastVisit.inspection && lastVisit.inspection.modifiedFacilities; else noModifiedFacilities">
          <div class="col-md-3 h3">Modified Facilities</div>
          <div class="col-md-9">
            <app-facility-list-view [facilities]="lastVisit.inspection.modifiedFacilities"
                                    [potentialContaminantsList]="potentialContaminantsList">
            </app-facility-list-view>
          </div>
        </div>
        <ng-template #noModifiedFacilities>
          <div class="row col-12">
            <div class="col-md-3 h3">No Modified Facilities</div>
          </div>
        </ng-template>
        <div class="row col-12"
             *ngIf="lastVisit.inspection && lastVisit.inspection.addedFacilities; else noAddedFacilities">
          <div class="col-md-3 h3">Added Facilities</div>
          <div class="col-md-9">
            <app-facility-list-view [facilities]="lastVisit.inspection.addedFacilities"
                                    [allFacilities]="allFacilities()"
                                    [potentialContaminantsList]="potentialContaminantsList">
            </app-facility-list-view>
          </div>
        </div>
        <ng-template #noAddedFacilities>
          <div class="row col-12">
            <div class="col-md-3 h3">No Added Facilities</div>
          </div>
        </ng-template>
        <div *ngIf="lastVisit.inspection && lastVisit.inspection.findingTypeCollection
          && lastVisit.inspection.findingTypeCollection.findingCollections
          && lastVisit.inspection.findingTypeCollection.findingCollections.length > 0; else noFindingTypeCollection"
             class="w-100">
          <ng-container *ngFor="let findingCollection of lastVisit.inspection.findingTypeCollection.findingCollections">
            <div class="row col-12">
              <div class="col-md-3 h3">{{ findingCollection.title }}</div>
              <div class="col-md-9">
                <div class="col-12 card" *ngFor="let findingCategory of findingCollection.findingCategories">
                  <div class="row h3 card-header">{{ findingCategory.description }}</div>
                  <div class="card-body" *ngFor="let finding of findingCategory.findings">
                    <div class="row">
                      <div class="col-sm-3 col-md-2 font-weight-bold">Category</div>
                      <div class="col-sm-9 col-md-10">{{ finding.category }}</div>
                    </div>
                    <div class="row">
                      <div class="col-sm-3 col-md-2 font-weight-bold">Code</div>
                      <div class="col-sm-9 col-md-10">{{ finding.code }}</div>
                    </div>
                    <div class="row">
                      <div class="col-sm-3 col-md-2 font-weight-bold">Language</div>
                      <div class="col-sm-9 col-md-10">{{ finding.language }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-template #noFindingTypeCollection>
          <div class="row col-12">
            <div class="col-md-3 h3">No Deficiencies</div>
          </div>
        </ng-template>
        <div class="row col-12"
             *ngIf="lastVisit.inspection && lastVisit.inspection.waterSystem && lastVisit.inspection.waterSystem.diff; else noBasicDetailsDiff">
          <div class="col-md-3 h3">PWS Basic Details Changes</div>
          <div class="col-md-9">
            <app-general-summary
              [summary]="lastVisit.inspection.waterSystem.diff"></app-general-summary>
            <app-population-summary
              [summary]="lastVisit.inspection.waterSystem.diff"></app-population-summary>
            <app-service-area-summary
              [summary]="lastVisit.inspection.waterSystem.diff"></app-service-area-summary>
            <app-production-source-water-protection-summary
              [summary]="lastVisit.inspection.waterSystem.diff"></app-production-source-water-protection-summary>
          </div>
        </div>
        <ng-template #noBasicDetailsDiff>
          <div class="row col-12">
            <div class="col-md-3 h3">No PWS Basic Details Changes</div>
          </div>
        </ng-template>
        <div class="row col-12"
             *ngIf="isContactsDiff; else noContactsDiff">
          <div class="col-md-3 h3">Contacts Changes</div>
          <div class="col-md-9">
            <app-inventory-contacts-summary [contacts]="lastVisit.inspection.contacts"></app-inventory-contacts-summary>
            <app-water-operators-summary [waterOperators]="lastVisit.inspection.waterOperators"></app-water-operators-summary>
          </div>
        </div>
        <ng-template #noContactsDiff>
          <div class="row col-12">
            <div class="col-md-3 h3">No Contacts Changes</div>
          </div>
        </ng-template>
      </div>
    </div>
    <ng-template #noLastVisit>
      <div class="h2">No Previous Site Visits.</div>
    </ng-template>
  </ng-template>
</div>
