import { HttpErrorResponse } from '@angular/common/http';
import { SyncResult, SyncResultData, SyncType } from '../domain/sync';

export class SyncResultFactory {
  static build(type: SyncType, id: number, status: string, messages = []): SyncResult {
    return { type: type, data: { id: id, status: status, date: new Date(), messages: messages }}
  }

  static scheduled(type: SyncType, id: number): SyncResult {
    return this.build(type, id, 'SCHEDULED');
  }
  static synced(type: SyncType, id: number): SyncResult {
    return this.build(type, id, 'SYNCED');
  }
  static error(type: SyncType, id: number, messages: string[]): SyncResult {
    return this.build(type, id, 'SYNC_ERROR', messages);
  }

  // https://angular.io/guide/http#error-handling
  static errorHttp(type: SyncType, id: number, error: HttpErrorResponse | ErrorEvent): SyncResult {
    const messages: Array<string> = [];

    if (error instanceof HttpErrorResponse) {
      // todo: user-friendly messages, handle additional scenarios
      switch (error.status) {
        case 403: {
          messages.push(`You are unauthorized to save ${type} ${id}`);
          break;
        }
        case 422: {
          // todo: handle validation messages
          messages.push(error.message);
          break;
        }
        case 500: {
          messages.push('The API has encountered an error.');
          break;
        }
        case 503: {
          messages.push('The API is currently down.');
          break;
        }
        case 0: {
          messages.push('A communication error occurred.');
          break;
        }
        default: {
          messages.push('An unexpected error occurred.');
          break;
        }
      }
    } else {
      messages.push(error.error.message);
    }

    return SyncResultFactory.error(type, id, messages);
  }

  static unknown(type: SyncType, id: number): SyncResult {
    return this.build(type, id, 'UNKNOWN');
  }
}
