import { Injectable } from '@angular/core';
import { Observable, timer, throwError, of } from 'rxjs';
import { Checkout } from '../domain/checkout';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { concatMap, filter, map, take, tap, catchError, takeUntil } from 'rxjs/operators';
import { SyncSchedulerService } from './sync-scheduler.service';
import { LocalStorageService } from '../local-storage.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SyncResultFactory } from '../utils/sync-result-factory';
import { tables } from '../utils/app-db';
import { SyncResult, SyncType } from '../domain/sync';
import { DeficienciesService } from "./deficiencies/deficiencies.service";
import { ExistingDeficiency } from "../domain/finding";

@Injectable({
  providedIn: 'root'
})
export class WaterSystemDeficienciesService {

  private readonly WATER_SYSTEM_URL = environment.serverUrl + '/watersystem';
  private readonly DEFICIENCY_SUB_ENDPOINT = 'deficiency';

  constructor(private http: HttpClient,
              private connectivityService: ConnectivityService,
              private localStorageService: LocalStorageService,
              private syncScheduler: SyncSchedulerService,
              private deficienciesService: DeficienciesService) { }

  /**
   * Returns water system deficiencies saved locally
   * If none found, then will be check-out remotely if online
   * @param id of the water system
   */
  find(id: number): Observable<Checkout<ExistingDeficiency[]>> {
    return new Observable<Checkout<ExistingDeficiency[]>>(obs => {
      this.findLocally(id).subscribe(wsLocal => {
        if (wsLocal) {
          obs.next(wsLocal);
          obs.complete();
        } else {
          if (this.connectivityService.isOnline()) {
            this.findRemotelyTimer(id).subscribe (wsRemote => {
              obs.next(wsRemote);
              obs.complete();
            }, error => {
              obs.error(error);
            });
          } else {
            obs.complete();
          }
        }
      });
    });
  }

  findLocally(id: number): Observable<Checkout<ExistingDeficiency[]>> {
    return this.localStorageService.dbRetrieve<Checkout<ExistingDeficiency[]>>(tables.waterSystemDeficiencies, id);
  }

  findRemotely(id: number): Observable<Checkout<ExistingDeficiency[]>> {
    return this.http.get<Checkout<ExistingDeficiency[]>>(`${this.WATER_SYSTEM_URL}/${id}/${this.DEFICIENCY_SUB_ENDPOINT}`);
  }

  private findRemotelyTimer(id: number): Observable<Checkout<ExistingDeficiency[]>> {
    const stopTimer$ = timer(10 * 60 * 1000);
    return timer(0, 15000)
      .pipe(
        takeUntil(stopTimer$),
        concatMap(() => this.findRemotely(id).pipe(map(response => response))),
        filter(backendData => backendData.dataReady),
        take(1),
        tap(found => {
          this.store(id, found);
        }),
        catchError(error => {
          if (error.status === 404) {
            this.store(id, error.error);
            return of(error.error);
          } else {
            return throwError(error);
          }
        })
      );
  }

  /**
   * Saves the water system deficiencies locally
   * If online, then also saved to remote service, otherwise save is scheduled
   * @param id of the water system
   * @param waterSystemDeficiencies the water system deficiencies
   */
  save(id: number, waterSystemDeficiencies: Checkout<ExistingDeficiency[]>): void {
    this.store(id, waterSystemDeficiencies);
    this.deficienciesService.changeWaterSystemDeficiencies(waterSystemDeficiencies);
    this.syncScheduler.schedule(SyncType.Deficiencies, id).subscribe();
  }

  private saveRemotely(id: number, waterSystemDeficiencies: ExistingDeficiency[]): Observable<{}> {
    return this.http.put(`${this.WATER_SYSTEM_URL}/${id}/${this.DEFICIENCY_SUB_ENDPOINT}`, waterSystemDeficiencies);
  }

  private store(id: number, waterSystemDeficiencies: Checkout<ExistingDeficiency[]>): void {
    if (waterSystemDeficiencies && waterSystemDeficiencies.data && waterSystemDeficiencies.data.length > 1) {
      waterSystemDeficiencies.data = waterSystemDeficiencies.data.sort((a, b) => {
        if (a.significantIndicator && a.significantIndicator === 'Y') {
          return -1;
        } else if (b.significantIndicator && b.significantIndicator === 'Y') {
          return 1;
        } else {
          return 0;
        }
      });
    }
    this.localStorageService.dbStoreStorage(tables.waterSystemDeficiencies, id, waterSystemDeficiencies);
  }

  status(id: number): Observable<SyncResult> {
    return this.syncScheduler.status(SyncType.Deficiencies, id);
  }

  /**
   * Syncs scheduled water systems deficiencies if online
   */
  sync(): void {
    if (this.connectivityService.isOnline()) {
      this.syncScheduler.scheduled(SyncType.Deficiencies).subscribe(syncRequests => {
        syncRequests.forEach(syncRequest => {
          this.findLocally(syncRequest.data.id).subscribe(waterSystemDeficiencies => {
            if (waterSystemDeficiencies) {
              this.saveRemotely(syncRequest.data.id, waterSystemDeficiencies.data).pipe(take(1)).subscribe(
                () => {
                  this.syncScheduler.unschedule(SyncType.Deficiencies, syncRequest.data.id,
                    SyncResultFactory.synced(SyncType.Deficiencies, syncRequest.data.id));
                },
                error => this.syncScheduler.unschedule(SyncType.Deficiencies, syncRequest.data.id,
                  this.translateSyncErrorResponse(SyncType.Deficiencies, syncRequest.data.id, error))
              );
            } else {
              this.syncScheduler.unschedule(SyncType.Deficiencies, syncRequest.data.id,
                SyncResultFactory.synced(SyncType.Deficiencies, syncRequest.data.id));
            }
          });
        });
      })
    }
  }

  // https://angular.io/guide/http#error-handling
  private translateSyncErrorResponse(type: SyncType, id: number, error: HttpErrorResponse | ErrorEvent): SyncResult {
    const messages: Array<string> = [];

    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 403: {
          messages.push('You are unauthorized to save this water system deficiencies.');
          break;
        }
        case 422: {
          messages.push(error.message);
          break;
        }
        case 500: {
          messages.push('The API has encountered an error.');
          break;
        }
        case 503: {
          messages.push('The API is currently down.');
          break;
        }
        case 0: {
          messages.push('A communication error occurred.');
          break;
        }
        default: {
          messages.push('An unexpected error occurred.');
          break;
        }
      }
    } else {
      messages.push(error.error.message);
    }

    return SyncResultFactory.error(type, id, messages);
  }

  deleteLocally(id: number): void {
    this.localStorageService.dbRemove(tables.waterSystemDeficiencies, id);
  }
}
