import {Pipe, PipeTransform} from '@angular/core';
import {COUNTY_TYPES} from './county-type';
import {WATER_SYSTEM_CLASS_TYPES} from './water-system-class-type';

export const OWNER_TYPES = [
  {code: 'A', value: 'Authority'},
  {code: 'B', value: 'Commission'},
  {code: 'C', value: 'County'},
  {code: 'D', value: 'District'},
  {code: 'F', value: 'Federal'},
  {code: 'I', value: 'Investor'},
  {code: 'M', value: 'Municipal'},
  {code: 'N', value: 'Nonmunicipal'},
  {code: 'O', value: 'Other'},
  {code: 'S', value: 'State'},
  {code: 'U', value: 'Unknown'}
];

@Pipe({name: 'ownerType'})
export class OwnerTypePipe implements PipeTransform {
  transform(key: string): string {
    const element = OWNER_TYPES.find(e => e.code === key);
    if (element) {
      return element.value;
    }
    return key;
  }
}
