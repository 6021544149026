<div ngbAccordion>
  <div ngbAccordionItem #first="ngbAccordionItem" class="border p-2" *ngFor="let facility of facilities">
    <div ngbAccordionHeader>
      <h3 class="panel-collapsible-header" ngbAccordionToggle>
        {{facility.name}}
        <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="first.collapsed"></i>
        <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!first.collapsed"></i>
      </h3>
    </div>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <app-faclility-view [facility]="facility"
                              [allFacilities]="allFacilities"></app-faclility-view>
        </ng-template>
      </div>
    </div>
  </div>
</div>
