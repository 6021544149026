<div id="water-system-general-summary" *ngIf="summary && (summary.type || summary.ownerType || summary.systemClass
|| summary.systemClassPoints || summary.serviceConnections || summary.address1 || summary.address2
|| summary.address3 || summary.city || summary.zipCode); else noChanges">
  <div class="border p-2 panel-collapsible-header">
    <a href="javascript:void(0);" (click)="isCollapsed = !isCollapsed"
       [attr.aria-expanded]="!isCollapsed" aria-controls="collapse-general">
      <div>
        <h2>
          General
          <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon"
             *ngIf="isCollapsed"></i>
          <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon"
             *ngIf="!isCollapsed"></i>
        </h2>
      </div>
    </a>
  </div>
  <div id="collapse-general" class="card" [ngbCollapse]="isCollapsed">
    <div class="card-body row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-4 col-sm-12 col-12">
            <div class="pb-2" *ngIf="summary.type">
              <span><strong>PWS Type:</strong></span>
              <br/>
              <span>{{ summary.type | waterSystemType }}</span>
            </div>
            <div class="pb-2" *ngIf="summary.ownerType">
              <span><strong>Owner Type:</strong></span>
              <br/>
              <span>{{ summary.ownerType | ownerType }}</span>
            </div>
            <div class="pb-2" *ngIf="summary.systemClass">
              <span><strong>System Class:</strong></span>
              <br/>
              <span>{{ summary.systemClass | waterSystemClassType }}</span>
            </div>
            <div class="pb-2" *ngIf="summary.systemClassPoints">
              <span><strong>System Class Points:</strong></span>
              <br/>
              <span>{{ summary.systemClassPoints }}</span>
            </div>
            <div class="pb-2" *ngIf="summary.serviceConnections">
              <span><strong>Service Connections:</strong></span>
              <br/>
              <span>{{ summary.serviceConnections }}</span>
            </div>
            <div class="pb-2" *ngIf="summary.address1">
              <span><strong>Physical Address Line 1:</strong></span>
              <br/>
              <span>{{ summary.address1 }}</span>
            </div>
            <div class="pb-2" *ngIf="summary.address2">
              <span><strong>Physical Address Line 2:</strong></span>
              <br/>
              <span>{{ summary.address2 }}</span>
            </div>
            <div class="pb-2" *ngIf="summary.address3">
              <span><strong>Physical Address Line 3:</strong></span>
              <br/>
              <span>{{ summary.address3 }}</span>
            </div>
            <div class="pb-2" *ngIf="summary.city">
              <span><strong>Physical Address City:</strong></span>
              <br/>
              <span>{{ summary.city }}</span>
            </div>
            <div class="pb-2" *ngIf="summary.zipCode">
              <span><strong>Physical Address Zip Code:</strong></span>
              <br/>
              <span>{{ summary.zipCode }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #noChanges>
  <div>No General Changes.</div>
</ng-template>
