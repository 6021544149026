<div class="card p-3 bg-light">
  <h2>{{title}}</h2>
  <span class="text-danger">{{warning}}</span>
  <div *ngIf="hasMissingQuestionnaire">
    <button class="btn m-2 w-50" [state]="siteVisit"
      [routerLink]="['questionnaire', facilityType]"
      routerLinkActive="true"
      [ngClass]="questionnaire.complete == undefined ? 'btn-primary' : questionnaire.complete == true ? 'btn-success' : 'btn-danger'">
      {{questionnaire.category && questionnaire.category !== '' ? questionnaire.category :
      questionnaire.item}}
  </button>
  </div>
  <div>
    <button class="btn btn-outline-primary m-2" [routerLink]="['../../facilities/add-facility']"
      [state]="{ data : 'water-system/' + waterSystemId + '/visit/' + siteVisitId, siteVisitId: siteVisitId }">Add New
      Inventory</button>
  </div>
</div>
