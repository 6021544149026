import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { DocumentUrlRequest } from './domain/document-url-request';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  private readonly DOCUMENT_URL = environment.serverUrl + '/document';

  constructor(private httpClient: HttpClient) { }

  download(downloadUrl: string, filename: string): Observable<Blob> {
    return this.httpClient.get<Blob>(`${this.DOCUMENT_URL}/${filename}`, {responseType: 'blob' as 'json'});
  }

  upload(uploadUrl: string, document: File) {
    const formData = new FormData();
    formData.append('upload', document, document.name);
    return this.httpClient.post(`${uploadUrl}`, formData);
  }

  getDownloadUrl(filename: string): Observable<DocumentUrlRequest> {
    return this.getUrl(filename, 'get');
  }

  getUploadUrl(filename: string): Observable<DocumentUrlRequest> {
    return this.getUrl(filename, 'put');
  }

  getUrl(filename: string, method: string): Observable<DocumentUrlRequest> {
    const documentUrlRequest: DocumentUrlRequest = {
      s3Path: filename,
      method: method,
      s3SecureUrl: null
    };
    return this.httpClient.post<any>(this.DOCUMENT_URL, documentUrlRequest);
  }
}
