<div class="card">
  <div class="card-body">
    <div>
      <h1>Edit Facility</h1>
    </div>
    <div *ngIf="isLoading; else loaded" class="pl-3 pt-3">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <span>Loading...</span>
    </div>
    <ng-template #loaded>
    <div class="pt-3">
      <app-facility-info-form [facility]="facility" (saved)="save($event)" (cancelled)="cancel()"></app-facility-info-form>
    </div>
  </ng-template>
  </div>
</div>
