<div class="container-fluid">
  <div class="p-3" *ngIf="isOnline; else offlineMsg">
    <h2 class="h1">Upcoming Site Visits</h2>
    <div *ngIf="isDistrictsLoading; else districtsLoaded" class="pl-3">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading Filter...</span>
      </div>
      <span>Loading Filter...</span>
    </div>
    <ng-template #districtsLoaded>
      <form class="form-group">
        <div class="row">
          <div class="form-group col-lg-5 col-md-5 col-sm-12 col-xs-12">
            <label for="districtPartyId">District</label>
            <select id="districtPartyId" class="form-control" [(ngModel)]="criteria.districtPartyId" name="districtPartyId">
              <option [ngValue]="null">All Districts</option>
              <option *ngFor="let district of districts" [value]="district.partyId">{{ district.name }}</option>
            </select>
          </div>
          <div class="form-group col-lg-3 col-md-3 col-sm-5 col-xs-12">
            <label for="pwsId">PWS ID</label>
            <input id="pwsId"
                   type="text"
                   class="form-control"
                   name="pwsId"
                   [(ngModel)]="criteria.pwsId" />
          </div>
          <div class="form-group col-lg-4 col-md-4 col-sm-7 col-xs-12">
            <label for="pwsName">PWS Name</label>
            <input id="pwsName"
                   type="text"
                   class="form-control"
                   name="pwsName"
                   [(ngModel)]="criteria.pwsName"/>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-12" role="group" aria-labelledby="siteVisitRange">
            <div id="siteVisitRange" class="form-label">Next visit</div>
            <div class="row">
              <div class="form-group input-group col-sm-6 col-xs-12">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="siteVisitRangeFrom">From</label>
                </div>
                <input id="siteVisitRangeFrom"
                       type="date"
                       class="form-control"
                       name="nextVisitFrom"
                       [(ngModel)]="criteria.nextVisitFrom"/>
              </div>
              <div class="form-group input-group col-sm-6 col-xs-12">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="siteVisitRangeTo">To</label>
                </div>
                <input id="siteVisitRangeTo"
                       type="date"
                       class="form-control"
                       name="nextVisitTo"
                       [(ngModel)]="criteria.nextVisitTo"/>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="advancedSearch">
          <div class="form-group col-md-6 col-sm-12">
            <label for="city">City:</label>
            <input id="city" type="text" [(ngModel)]="city" name="searchCity" class="form-control" [ngbTypeahead]="searchCity" [editable]="false" (selectItem)="onSearchCitySelect($event)" aria-describedby="city-search-auto-complete-help-text"/>
            <span id="city-search-auto-complete-help-text" class="small">Start typing to get a list of matching cities to select from.</span>
            <div class="mt-2">
              <span class="small">Selected Cities (Multiple can be selected):</span>
              <ng-container *ngIf="criteria.city && criteria.city.length > 0">
                <ul class="list-group" style="width:fit-content">
                  <li *ngFor="let city of criteria.city" class="list-group-item py-1 pl-2 pr-0">
                    <span>{{ city }}</span>
                    <button type="button" class="btn btn-sm py-0 float-right" attr.aria-label="Delete {{city}} from search criteria" (click)="removeCityFromSearch(city)">
                      <span aria-hidden="true"><i class="fas fa-times fa-xs"></i></span>
                    </button>
                  </li>
                </ul>
              </ng-container>
              <span *ngIf="!criteria.city || criteria.city.length == 0" class="small"><br/>None selected</span>
            </div>
          </div>
          <div class="form-group col-md-6 col-sm-12">
            <label for="county">County:</label>
            <input id="county" type="text" [(ngModel)]="county" name="searchCounty" class="form-control" [ngbTypeahead]="searchCounty" [editable]="false" (selectItem)="onSearchCountySelect($event)" aria-describedby="county-search-auto-complete-help-text"/>
            <span id="county-search-auto-complete-help-text" class="small">Start typing to get a list of matching counties to select from.</span>
            <div class="mt-2">
              <span class="small">Selected County (Only one):</span>
              <ng-container *ngIf="criteria.county && criteria.county !== ''">
                <ul class="list-group" style="width:fit-content">
                  <li class="list-group-item py-1 pl-2 pr-0">
                    <span>{{ criteria.county | countyType }}</span>
                    <button type="button" class="btn btn-sm py-0 float-right" attr.aria-label="Delete {{county|countyType}} from search criteria" (click)="removeCountyFromSearch()">
                      <span aria-hidden="true"><i class="fas fa-times fa-xs"></i></span>
                    </button>
                  </li>
                </ul>
              </ng-container>
              <span *ngIf="!criteria.county || criteria.county === ''" class="small"><br/>None selected</span>
            </div>
          </div>
        </div>

        <input type="submit" value="Search" class="btn btn-primary" (click)="search()"/>
        <input type="button" value="Reset" class="btn btn-outline-secondary ml-4" (click)="reset()"/>
      </form>
    </ng-template>

    <div *ngIf="isSiteVisitsLoading" class="pl-3 pt-3">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading Upcoming Site Visits...</span>
      </div>
      <span>Loading Upcoming Site Visits...</span>
    </div>
    <div [hidden]="isSiteVisitsLoading">
      <app-pagination [page]="page" (pageRequested)="requestPage($event)" *ngIf="page">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-light">
          <tr>
            <th scope="col" sortable="id" (sort)="onSort($event)" tabindex="0">PWS ID</th>
            <th scope="col" sortable="name" (sort)="onSort($event)" tabindex="0">PWS Name</th>
            <th scope="col" sortable="city" (sort)="onSort($event)" tabindex="0">City</th>
            <th scope="col" sortable="nextVisit" (sort)="onSort($event)" tabindex="0">Next Visit</th>
            <th scope="col" sortable="nextVisitType" (sort)="onSort($event)" tabindex="0">Visit Type</th>
            <th scope="col" sortable="seasonal" (sort)="onSort($event)" tabindex="0">Seasonal</th>
            <th scope="col" sortable="visitStatus" (sort)="onSort($event)" tabindex="0">Status</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let sitevisit of page.results" [ngClass]="{'table-row-link': true}"
            (click)="navigateToWaterSystemDetails(sitevisit)" (keyup.enter)="navigateToWaterSystemDetails(sitevisit)"
            tabindex="0" attr.aria-label="Select upcoming site visit with PWS ID {{sitevisit.id}}">
            <td>{{sitevisit.id}}</td>
            <td>{{sitevisit?.name}}</td>
            <td>{{sitevisit?.city}}</td>
            <td>{{sitevisit?.nextVisit | date: 'MM/dd/yyyy'}}</td>
            <td>{{sitevisit?.nextVisitType | visitTypeAnnualSanitary}}</td>
            <td>{{sitevisit?.seasonal | seasonalIndYesNoNotverified}}</td>
            <td>{{sitevisit?.visitStatus}}</td>
          </tr>
          </tbody>
        </table>
      </app-pagination>
    </div>
  </div>
  <ng-template #offlineMsg>
    <div class="p-3">Search not available offline.</div>
  </ng-template>
</div>
