<div class="card mb-3">
  <div class="card-body">
    <h2>Treatment Processes</h2>
    <div *ngIf="isLoading; else loaded">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading Treatment Processes...</span>
      </div>
      <span>Loading Treatment Processes...</span>
    </div>
    <ng-template #loaded>
      <div class="table-responsive">
        <table class="table table-bordered table-hover table-sm">
          <thead class="thead-light">
            <tr>
              <th scope="col" class="col-4">Treatment Objective</th>
              <th scope="col" class="col-5">Process Mechanism</th>
              <th scope="col" class="col-1">Begin Date</th>
              <th scope="col" class="col-1">End Date</th>
              <th scope="col" class="col-1">Actions</th>
            </tr>
          </thead>
          <tbody *ngIf="(facility.facilityTreatments && facility.facilityTreatments.length > 0) || (newTreatmentProcesses && newTreatmentProcesses.length > 0)">
            <tr *ngFor="let treatment of facility.facilityTreatments; let i = index"
                [ngClass]="{'table-danger': isDeleted(treatment)}"
                [attr.aria-label]="isDeleted(treatment)?'Deleted':null">
              <td class="col-4">
                <div class="form-group my-0">
                  <label for="{{ 'treatment-objective-' + i }}" [hidden]="true">Treatment Objective:*</label>
                  <select id="{{ 'treatment-objective-' + i }}"
                          [compareWith]="treatmentCodeCompare"
                          [(ngModel)]="treatment.objective"
                          name="treatment-objective"
                          (change)="onTreatmentProcessChange(treatment)"
                          required="true"
                          [disabled]="isDeleted(treatment)"
                          class="form-control">
                    <option *ngFor="let objective of objectives"
                            [ngValue]="objective">{{objective.description}}</option>
                  </select>
                </div>
              </td>
              <td class="col-5">
                <div class="form-group my-0">
                  <label for="{{ 'treatment-process-' + i }}" [hidden]="true">Process Mechanism:*</label>
                  <select id="{{ 'treatment-process-' + i }}"
                          [compareWith]="treatmentCodeCompare"
                          [(ngModel)]="treatment.processMechanism"
                          name="treatment-process"
                          (change)="onTreatmentProcessChange(treatment)"
                          required="true"
                          [disabled]="isDeleted(treatment)"
                          class="form-control">
                    <option *ngFor="let mechanism of mechanisms"
                            [ngValue]="mechanism">{{mechanism.description}}</option>
                  </select>
                </div>
              </td>
              <td class="col-1">
                <div class="form-group my-0">
                  <label for="{{ 'treatment-process-begin-' + i }}" [hidden]="true">Begin Date:</label>
                  <input id="{{ 'treatment-process-begin-' + i }}"
                         type="date"
                         [(ngModel)]="treatment.treatmentBegin"
                         name="treatment-begin"
                         (change)="onTreatmentProcessChange(treatment)"
                         [disabled]="isDeleted(treatment)"
                         class="form-control"/>
                </div>
              </td>
              <td class="col-1">
                <div class="form-group my-0">
                  <label for="{{ 'treatment-process-end-' + i }}" [hidden]="true">End Date:</label>
                  <input id="{{ 'treatment-process-end-' + i }}"
                         type="date"
                         [(ngModel)]="treatment.treatmentEnd"
                         name="treatment-end"
                         (change)="onTreatmentProcessChange(treatment)"
                         [disabled]="isDeleted(treatment)"
                         class="form-control"/>
                </div>
              </td>
              <td class="col-1 align-middle">
                <button *ngIf="isDeleted(treatment); else deleteButton"
                        type="button" class="btn btn-outline-dark btn-sm py-0"
                        (click)="restoreTreatmentProcess(treatment)"
                        attr.aria-label="Restore treatment process {{ i + 1 }}">Restore</button>
                <ng-template #deleteButton>
                  <button type="button"
                          class="btn btn-outline-dark btn-sm py-0"
                          (click)="deleteTreatmentProcess(treatment)"
                          attr.aria-label="Delete treatment process {{ i + 1 }}">Delete</button>
                </ng-template>
              </td>
            </tr>
            <tr *ngFor="let newTreatmentProcess of newTreatmentProcesses; let i = index">
              <td class="col-4">
                <div class="form-group my-0">
                  <label for="{{ 'add-treatment-objectove-' + i }}" [hidden]="true">Treatment Objective:*</label>
                  <select id="{{ 'add-treatment-objective-' + i }}"
                          [compareWith]="treatmentCodeCompare"
                          [(ngModel)]="newTreatmentProcess.objective"
                          name="add-treatment-objective"
                          (change)="onNewTreatmentProcessChange(newTreatmentProcess, i)"
                          required="true" class="form-control">
                    <option *ngFor="let objective of objectives"
                            [ngValue]="objective">{{objective.description}}</option>
                  </select>
                </div>
              </td>
              <td class="col-5">
                <div class="form-group my-0">
                  <label for="{{ 'add-treatment-process-' + i }}" [hidden]="true">Process Mechanism:*</label>
                  <select id="{{ 'add-treatment-process-' + i }}"
                          [compareWith]="treatmentCodeCompare"
                          [(ngModel)]="newTreatmentProcess.processMechanism"
                          name="add-treatment-process"
                          (change)="onNewTreatmentProcessChange(newTreatmentProcess, i)"
                          required="true" class="form-control">
                    <option *ngFor="let mechanism of mechanisms"
                            [ngValue]="mechanism">{{mechanism.description}}</option>
                  </select>
                </div>
              </td>
              <td class="col-1">
                <div class="form-group my-0">
                  <label for="{{ 'add-treatment-process-begin-' + i }}" [hidden]="true">Begin Date:</label>
                  <input id="{{ 'add-treatment-process-begin-' + i }}" type="date" [(ngModel)]="newTreatmentProcess.treatmentBegin" name="add-treatment-begin" class="form-control"/>
                </div>
              </td>
              <td class="col-1">
                <div class="form-group my-0">
                  <label for="{{ 'add-treatment-process-end-' + i }}" [hidden]="true">End Date:</label>
                  <input id="{{ 'add-treatment-process-end-' + i }}" type="date" [(ngModel)]="newTreatmentProcess.treatmentEnd" name="add-treatment-end" class="form-control"/>
                </div>
              </td>
              <td class="col-1 align-middle">
                <button type="button" class="btn btn-outline-dark btn-sm py-0" (click)="deleteNewTreatmentProcess(i)" attr.aria-label="Delete new treatment process {{ i + 1 }}">Delete</button>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="(facility.facilityTreatments == null || facility.facilityTreatments.length == 0) && (newTreatmentProcesses == null || newTreatmentProcesses.length == 0)">
            <tr>
              <td colspan="5">
                No Treatment Processes Found.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col-12">
          <button id="add-treatment-process-button" type="button" class="btn btn-outline-primary" (click)="addTreatmentProcess()">Add Treatment Process</button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
