import { Component, Input, OnInit } from '@angular/core';
import {
  BoosterStation,
  Facility,
  FacilityAny,
  isBoosterStation, isTreatmentPlant,
  isWell,
  TreatmentPlant,
  Well
} from '../../../domain/facility';
import { PotentialContaminantType } from '../../../domain/potential-contaminant';

@Component({
  selector: 'app-faclility-view',
  templateUrl: './faclility-view.component.html',
  styleUrls: ['./faclility-view.component.scss']
})
export class FaclilityViewComponent implements OnInit {

  @Input()
  allFacilities: FacilityAny[];

  @Input()
  facility: Facility;

  well: Well;
  boosterStation: BoosterStation;
  treatment: TreatmentPlant;

  constructor() {
  }

  ngOnInit(): void {
    switch (this.facility.facilityCode) {
      case 'WL':
        this.well = <Well> this.facility;
        break;
      case 'BS':
        this.boosterStation = <BoosterStation> this.facility;
        break;
      case 'TP':
        this.treatment = <TreatmentPlant> this.facility;
    }
  }

  facilityIdToName(ids: number[]): string[] | number[] {
    if (ids && this.allFacilities) {
      const names: string[] = ids.map(id => {
        return this.allFacilities.find(f => f.facilitySeqNo === id).name;
      });
      return names;
    } else {
      return ids;
    }
  }

  formatStatus(status: string) {
    switch (status) {
      case 'added':
        return 'New';
      case 'deleted':
        return 'Removed';
      case 'modified':
        return 'Modified';
      default:
        return '';
    }
  }
}
