import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Question} from 'src/app/domain/question';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/local-storage.service';
import { ActivatedRoute } from '@angular/router';
import { addAttachmentWithFile, Attachment, copyAttachmentRequired } from 'src/app/domain/attachment';
import { tables } from 'src/app/utils/app-db';
import 'src/assets/javascript/capture.js';
import {FINDING_TYPES} from '../../../shared/finding-type';

declare const startup: any;
declare const takepicture: any;

@Component({
  selector: 'app-response-additional-information',
  templateUrl: './response-additional-information.component.html',
  styleUrls: ['./response-additional-information.component.scss']
})
export class ResponseAdditionalInformationComponent implements OnInit {

  @Input() question: Question;
  @Input() form: UntypedFormGroup;
  @Output() questionChange = new EventEmitter<any>();
  @Input() waterSystemId: number;
  @Input() siteVisitId: number;
  @Input() questionnaireId: string;
  @Input() clearQuestionUuid: string;
  photo: Attachment;
  attachments: Attachment[];
  showImagesOnlyMessage: boolean;
  isPhotoRequired: boolean;
  isAttachmentRequired: boolean;
  isNoteRequired: boolean;
  noteLabelOverride: string;
  capturedPhoto: Blob;
  isPhotoCollapsed = true;
  isAttachmentsCollapsed = true;
  isNoteCollapsed = true;
  isFindingCollapsed = false;
  isShowCompleteFindingLanguage = false;
  findingTypes = Object.values(FINDING_TYPES);
  private modalRef: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.setRequiredFields();
    this.form.valueChanges.subscribe(val => {
      this.setRequiredFields(val);
    });
    if (this.question.photo) {
      this.getPhoto();
    }
    if (this.question.attachments && this.question.attachments.length > 0) {
      this.getAttachments();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clearQuestionUuid && changes.clearQuestionUuid.currentValue
      && changes.clearQuestionUuid.currentValue === this.question.uuid) {
      this.clearAdditionalInformation();
    }
  }

  onPhotoChange(event) {
    this.showImagesOnlyMessage = false;
    if (event.target.files && event.target.files.length > 0) {
      const [file] = event.target.files;
      if (file.type.startsWith('image')) {
        this.drawThumbnail(file);
        this.photo = addAttachmentWithFile(this.generateAttachmentKey('photo'), this.waterSystemId, this.siteVisitId,
          this.questionnaireId, this.question.uuid, file);
        this.localStorageService.dbStore(tables.questionnaireAttachment, this.photo);
        this.question.photo = copyAttachmentRequired(this.photo);
        this.save();
        (<HTMLInputElement>document.getElementById(event.target.id)).value = '';
      } else {
        this.showImagesOnlyMessage = true;
        event.preventDefault();
        return;
      }
    }
  }

  onAttachmentChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      for (const file of event.target.files) {
        const attachment = addAttachmentWithFile(this.generateAttachmentKey('attachment') + '-' + file.name,
          this.waterSystemId, this.siteVisitId, this.questionnaireId, this.question.uuid, file);
        if (this.attachments == null) {
          this.attachments = [];
        }
        this.attachments.push(attachment);
        if (this.question.attachments == null) {
          this.question.attachments = [];
        }
        this.localStorageService.dbStore(tables.questionnaireAttachment, attachment);
        this.question.attachments.push(copyAttachmentRequired(attachment));
      }
      this.save();
      (<HTMLInputElement>document.getElementById(event.target.id)).value = '';
    }
  }

  removeAttachment(index: number) {
    this.attachments[index].action = 'delete';
    this.localStorageService.dbStore(tables.questionnaireAttachment, this.attachments[index]);
    this.attachments.splice(index, 1);
    if (this.attachments.length === 0) {
      this.attachments = null;
    }
    this.question.attachments.splice(index, 1);
    if (this.question.attachments.length === 0) {
      this.question.attachments = null;
    }
    this.save();
  }

  setRequiredFields(changedValue: {} = null) {
    this.isPhotoRequired = false;
    this.isAttachmentRequired = false;
    this.isNoteRequired = false;
    this.noteLabelOverride = 'Note';

    let value = null;
    if (changedValue) {
      value = changedValue[this.question.displayName];
    } else if (this.question.value && this.question.value !== '') {
      value = this.question.value;
    }

    if (value) {
      for (const answer of this.question.answers) {
        if (value === answer.name) {
          if (answer.photoRequired != null) {
            this.isPhotoRequired = answer.photoRequired;
          }
          if (answer.attachmentRequired != null) {
            this.isAttachmentRequired = answer.attachmentRequired;
          }
          if (answer.noteRequired != null) {
            this.isNoteRequired = answer.noteRequired;
          }
          if (answer.noteLabelOverride != null && answer.noteLabelOverride !== '') {
            this.noteLabelOverride = answer.noteLabelOverride;
          }
        }
      }
    }
  }

  save() {
    this.questionChange.emit(this.question);
  }

  saveFindingType() {
    this.question.finding.type = this.question.finding.description;
    this.save();
  }

  generatePhotoThumbnailId() {
    return `photoThumbnail-${this.question.uuid}`;
  }

  drawThumbnail(file: File) {
    const reader = new FileReader();
    const photoThumbnailId = this.generatePhotoThumbnailId();
    reader.onload = function(event) {
      const img = new Image();
      img.onload = function() {
        const photoThumbnailCanvas = <HTMLCanvasElement> document.getElementById(photoThumbnailId);
        const ctx = photoThumbnailCanvas.getContext('2d');
        if (img.width < 200) {
          photoThumbnailCanvas.width = img.width;
          photoThumbnailCanvas.height = img.height;
        } else {
          const ratio = img.width / 200;
          photoThumbnailCanvas.width = 200;
          photoThumbnailCanvas.height = img.height / ratio;
        }
        ctx.drawImage(img, 0, 0, photoThumbnailCanvas.width, photoThumbnailCanvas.height);
      };
      img.src = event.target.result.toString();
    };
    reader.readAsDataURL(file);
  }

  async getAttachments() {
    if (!this.attachments) {
      this.attachments = [];
    }
    for (const attachment of this.question.attachments) {
      if (attachment && attachment.key) {
        const attachmentKey: string = this.generateAttachmentKey('attachment') + '-' + attachment.filename;
        await this.localStorageService.dbRetrieve<Attachment>(tables.questionnaireAttachment, attachmentKey).toPromise().then(result => {
          this.attachments.push(result);
        });
      }
    }
  }

  openCapturePictureModal(content) {
    this.modalRef = this.modalService.open(content, { centered: true, windowClass: 'visit-capture-picture-modal' });
    new startup();
  }

  async capturePhoto() {
    new takepicture();
    const canvas = <HTMLCanvasElement> document.getElementById('visit-canvas');
    this.capturedPhoto = await new Promise(resolve => canvas.toBlob(resolve));
  }

  acceptPicture() {
    const date = new Date();
    const capturedPhotoFile: File = new File([this.capturedPhoto], date.getTime().toString() + '.png', {type: 'image/png'});
    this.drawThumbnail(capturedPhotoFile);
    this.photo = addAttachmentWithFile(this.generateAttachmentKey('photo'), this.waterSystemId, this.siteVisitId,
      this.questionnaireId, this.question.uuid, capturedPhotoFile);
    this.localStorageService.dbStore(tables.questionnaireAttachment, this.photo);
    this.question.photo = copyAttachmentRequired(this.photo);
    this.save();
    this.capturedPhoto = null;
    this.modalRef.close();
  }

  removePhoto() {
    if (this.photo) {
      this.photo.action = 'delete';
      this.localStorageService.dbStore(tables.questionnaireAttachment, this.photo);
      this.photo = null;
      this.question.photo = null;
      this.save();
    }
  }

  expandAll() {
    this.isPhotoCollapsed = false;
    this.isAttachmentsCollapsed = false;
    this.isNoteCollapsed = false;
    this.isFindingCollapsed = false;
  }

  collapseAll() {
    this.isPhotoCollapsed = true;
    this.isAttachmentsCollapsed = true;
    this.isNoteCollapsed = true;
    this.isFindingCollapsed = true;
  }

  generateAttachmentKey(type: string): string {
    return `${this.waterSystemId}-${this.siteVisitId}-${this.questionnaireId}-${this.question.uuid}-${type}`;
  }

  openPhoto() {
    this.localStorageService.dbRetrieve<Attachment>(tables.questionnaireAttachment,
      this.generateAttachmentKey('photo')).subscribe(result => {
      const url = window.URL.createObjectURL(result.file);
      window.open(url, '_blank');
    });
  }

  openAttachment(attachment: Attachment) {
    this.localStorageService.dbRetrieve<Attachment>(tables.questionnaireAttachment,
      this.generateAttachmentKey('attachment') + '-' + attachment.filename)
    .subscribe(result => {
      const url = window.URL.createObjectURL(result.file);
      window.open(url, '_blank');
    });
  }

  async getPhoto() {
    await this.localStorageService.dbRetrieve<Attachment>(tables.questionnaireAttachment,
      this.generateAttachmentKey('photo')).toPromise().then(result => {
      this.photo = result;
      if (this.photo && this.photo.file) {
        this.drawThumbnail(this.photo.file);
      }
    });
  }

  toggleShowCompleteFindingLanguage() {
    this.isShowCompleteFindingLanguage = !this.isShowCompleteFindingLanguage;
  }

  clearAdditionalInformation() {
    while (this.attachments && this.attachments.length > 0) {
      this.removeAttachment(0);
    }
    this.removePhoto();
    delete this.question.note;
    this.ngOnInit();
    this.collapseAll();
  }

  protected readonly FINDING_TYPES = FINDING_TYPES;
}
