import { Component, Input, OnInit } from '@angular/core';
import { Checkout } from 'src/app/domain/checkout';
import { WaterOperator } from 'src/app/domain/water-operator';
import { MessageService } from "../../../message/message.service";
import { OperatorsService } from "../operators.service";
import { WaterSystemOperatorsService } from "../../water-system-operators.service";
import { ConfirmatoryActionService } from "../../../confirmatory-action/confirmatory-action.service";
import { Severity } from "../../../message/Severity";
import { ContactAction } from "../../../domain/contact";
import { ActivatedRoute, Router } from "@angular/router";
import { ConnectivityService } from "../../../connectivity/connectivity.service";
import { take } from "rxjs/operators";

@Component({
  selector: 'app-water-operators',
  templateUrl: './water-operators.component.html',
  styleUrls: ['./water-operators.component.scss']
})
export class WaterOperatorsComponent implements OnInit {

  @Input() operators: Checkout<WaterOperator[]>;

  public isWaterOperatorsCollapsed = false;
  public isDeleting: boolean = false;

  constructor(private messageService: MessageService,
              private operatorsService: OperatorsService,
              private waterSystemOperatorsService: WaterSystemOperatorsService,
              private confirmatoryActionService: ConfirmatoryActionService,
              private connectivityService: ConnectivityService,
              private router: Router,
              private route: ActivatedRoute
  ) { }

  ngOnInit() {
  }

  hasActiveOperators() {
    if (this.operators && this.operators.data && this.operators.data.length > 0) {
      for (let op of this.operators.data) {
        if (!op.action) {
          return true;
        } else {
          if (op.action === ContactAction.Create) {
            return true;
          }
        }
      }
    }
    return false;
  }

  isNewlyAdded(operator: WaterOperator) {
    if (operator.action) {
      return true;
    } else {
      return false;
    }
  }

  delete(operator: WaterOperator) {
    this.messageService.clear();
    this.confirmatoryActionService.confirm(
      "Confirmation",
      "Are you sure you want to delete this water operator from this PWS?",
      "Yes",
      "No",
      "sm")
      .then((confirmed) => {
        if (confirmed) {
          if (this.operators && this.operators.data) {
            this.isDeleting = true;
            const index = this.operators.data.findIndex(
              o => o.personSeqNo === operator.personSeqNo
                && o.certificateClass === operator.certificateClass);
            if (this.operators.data[index].id) {
              this.operators.data[index].action = ContactAction.Remove;
            } else {
              this.operators.data.splice(index, 1);
            }
            if (this.connectivityService.isOnline()) {
              this.waterSystemOperatorsService.saveRemotely(operator.pwsId, this.operators.data).pipe(take(1)).subscribe(() => {
                this.waterSystemOperatorsService.findRemotely(operator.pwsId).pipe(take(1)).subscribe(operators => {
                  this.operatorsService.changeWaterSystemOperators(operators);
                  this.waterSystemOperatorsService.store(operator.pwsId, operators);
                  this.navigateToParent();
                }, error => {
                  if (error.status === 404) {
                    this.operatorsService.changeWaterSystemOperators(error.error);
                    this.waterSystemOperatorsService.store(operator.pwsId, error.error);
                    this.navigateToParent();
                  } else {
                    console.log(error);
                    this.isDeleting = false;
                    this.messageService.add({
                      severity: Severity.ERROR,
                      value: 'There was an error with deleting this water operator. Please check the browser console error message and contact IT support.'
                    });
                    window.scrollTo(0, 0);
                  }
                });
              });
            } else {
              this.operatorsService.changeWaterSystemOperators(this.operators);
              this.waterSystemOperatorsService.save(operator.pwsId, this.operators);
              this.navigateToParent();
            }
          }
        }
      })
  }

  isNotRemoved(waterOperator: WaterOperator): boolean {
    return waterOperator.action == null
      || (waterOperator.action != null && waterOperator.action !== ContactAction.Remove);
  }

  navigateToParent(): void {
    this.isDeleting = false;
    this.router.navigate(['contacts'], { relativeTo: this.route.parent });
    this.messageService.add({ severity: Severity.SUCCESS, value: 'The water operator has been deleted from this PWS.' });
    window.scrollTo(0, 0);
  }
}
