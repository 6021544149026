import {Component, Input, OnInit} from '@angular/core';
import {WaterSystem} from '../../../../domain/water-system';

@Component({
  selector: 'app-general-summary',
  templateUrl: './general-summary.component.html',
  styleUrls: ['./general-summary.component.scss']
})
export class GeneralSummaryComponent implements OnInit {

  @Input() summary: WaterSystem;
  public isCollapsed = true;

  constructor() { }

  ngOnInit(): void {
  }
}
