import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmatoryActionComponent } from './confirmatory-action.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmatoryActionService {

  constructor(private modalService: NgbModal) { }

  public confirm(title: string,
                 message: string,
                 btnOkText: string = 'OK',
                 btnCancelText: string = 'Cancel',
                 dialogSize: 'sm'|'md'|'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmatoryActionComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }
}
