import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Answer } from 'src/app/domain/answer';
import { ConfirmatoryActionService } from 'src/app/confirmatory-action/confirmatory-action.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Finding } from 'src/app/domain/finding';
import { FINDING_TYPES, FINDING_ELEMENT_TYPES } from 'src/app/shared/finding-type';
import { FindingService } from 'src/app/shared/finding/finding.service';

@Component({
  selector: 'app-answers-list',
  templateUrl: './answers-list.component.html',
  styleUrls: ['./answers-list.component.scss']
})
export class AnswersListComponent implements OnInit {

  @Input()
  set addAnswer(addAnswer: boolean) {
    if (addAnswer) {
      this.addNewAnswer();
    }
  }
  @Input() answers: Answer[];
  @Output() addAnswerChange = new EventEmitter<boolean>(true);
  @Output() answersChange = new EventEmitter<{answers: Answer[], changedField: string}>();
  @Input() denyEdit: boolean = false;

  newAnswers: Answer[];

  public isAnswerListLoading: boolean = true;
  public sectionTypes: string[];
  public selectedSectionType: string;
  public findings: Finding[];
  public filteredFindings: Finding[];
  public findingTypes = FINDING_TYPES;
  public elementLanguage: string;

  private dragIndex: number;
  private modalRef: NgbModalRef;
  private selectedFinding: Finding;
  private answerIndex: number;

  readonly ELEMENT_TYPES: any[] = Object.keys(FINDING_ELEMENT_TYPES).slice(4);

  constructor(private confirmatoryActionService: ConfirmatoryActionService,
              private findingService: FindingService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    this.findingService.getFindings().subscribe(
      findings => {
        this.findings = findings;
        this.filteredFindings = findings;
        this.sectionTypes = [...new Set(this.findings.map(f => f.sanitarySurveySectionDescription))];
        this.isAnswerListLoading = false;
      }, error => {
        console.log(error);
        this.isAnswerListLoading = false;
      }
    )
  }

  deleteNewAnswer(answerIndex: number) {
    this.newAnswers.splice(answerIndex, 1);
  }

  deleteAnswer(index: number) {
    this.confirmatoryActionService.confirm("Confirmation", "Are you sure you want to delete this answer?", "Yes", "No", "sm")
      .then((confirmed) => {
        if (confirmed) {
          this.answers.splice(index, 1);
          this.answersChange.emit({answers: this.answers, changedField: 'Answers list'});
        }
      })
      .catch(() => {});
  }

  onNewAnswerChange(newAnswer: Answer, newAnswerIndex: number) {
    if (newAnswer) {
      if (newAnswer.name != null && newAnswer.name.length > 0) {
        if (this.isNewAnswerUnique(newAnswer.name)) {
          this.answers.push(newAnswer);
          this.newAnswers.splice(newAnswerIndex, 1);
          this.answersChange.emit({answers: this.answers, changedField: 'New answer'});
        }
      }
    }
  }

  onChange(field: string) {
    for (let answer of this.answers) {
      if (answer.name === '') {
        return;
      }
    }
    this.answersChange.emit({answers: this.answers, changedField: field});
  }
  
  allowDrop(ev: DragEvent) {
    ev.preventDefault();
  }

  drag(dragIndex: number, event: DragEvent) {
    if (this.denyEdit) {
      event.preventDefault();
      return;
    }
    this.dragIndex = dragIndex;
  }

  drop(dropIndex: number) {
    if (this.answers == null || this.answers.length < 2 || this.dragIndex == dropIndex) {
      return;
    }

    if (this.answers.length == 2) {
      let dragAnswer: Answer = this.answers.splice(0, 1)[0];
      this.answers.push(dragAnswer);
    } else if (this.answers.length > 2) {
      if (dropIndex > this.dragIndex) {
        if (dropIndex === this.answers.length - 1) {
          let dragAnswer: Answer = this.answers.splice(this.dragIndex, 1)[0];
          this.answers.push(dragAnswer);
        } else {
          let dragAnswer: Answer = this.answers.splice(this.dragIndex, 1)[0];
          this.answers.splice(dropIndex, 0, dragAnswer);
        }
      } else if (dropIndex < this.dragIndex) {
        let dragAnswer: Answer = this.answers.splice(this.dragIndex, 1)[0];
        this.answers.splice(dropIndex, 0, dragAnswer);
      }
    }
    this.answersChange.emit({answers: this.answers, changedField: 'Answer position'});
  }

  addNewAnswer() {
    if (!this.newAnswers) {
      this.newAnswers = [];
    }
    let newAnswer: Answer = {
      name: null,
      noteRequired: false,
      photoRequired: false,
      attachmentRequired: false,
      noteLabelOverride: null
    };
    this.newAnswers.push(newAnswer);
    this.addAnswerChange.emit(false);
  }

  onAnswerChange(value: string, index: number, field: string) {
    if (value && value !== '' && this.isAnswerUnique(value, index)) {
      this.onChange(field);
    }
  }

  isAnswerUnique(changedAnswer: string, index?: number): boolean {
    for (let i = 0; i < this.answers.length; i++) {
      let answer = this.answers[i];
      if ((((index || index === 0) && index != i)) && changedAnswer !== '' && changedAnswer === answer.name) {
        return false;
      }
    }
    return true;
  }

  isNewAnswerUnique(newAnswer: string) {
    for (let i = 0; i < this.answers.length; i++) {
      let answer = this.answers[i];
      if (newAnswer === answer.name) {
        return false;
      }
    }
    return true;
  }

  openElementCodeModal(content, answerIndex: number) {
    this.answerIndex = answerIndex;
    this.modalRef = this.modalService.open(content, { centered: true, size: 'xl', windowClass: 'element-code-modal' });
  }

  openElementLanguageModal(content, answerIndex: number) {
    this.elementLanguage = this.answers[answerIndex].finding.language;
    this.modalService.open(content, { centered: true, size: 'md' });
  }

  onSectionTypeSelect() {
    this.filteredFindings = this.findings.filter(f => f.sanitarySurveySectionDescription === this.selectedSectionType);
    this.filteredFindings.sort((a, b) => a.code.localeCompare(b.code));
  }

  selectElementCode() {
    this.answers[this.answerIndex].finding = this.selectedFinding;
    this.selectedFinding = null;
    this.selectedSectionType = null;
    this.filteredFindings = this.findings;
    this.modalRef.close();
    this.onChange("Element Code");
  }

  deleteElement(index: number) {
    this.confirmatoryActionService.confirm("Confirmation", "Are you sure you want to delete the element for this answer?", "Yes", "No", "sm")
      .then((confirmed) => {
        if (confirmed) {
          this.answers[index].finding = null;
          this.selectedFinding = null;
          this.selectedSectionType = null;
          this.filteredFindings = this.findings;
          this.onChange("Element Code");
        }
      })
      .catch(() => {});
  }

  cancelSelectElement() {
    if (!this.selectedFinding) {
      this.selectedSectionType = null;
      this.filteredFindings = this.findings;
    }
    this.modalRef.close();
  }
}
