import { SortEvent } from '../shared/table-sortable';

export class SiteVisitCriteria {
  pwsId: string = null;
  districtPartyId: number = null;
  nextVisitFrom: Date = null;
  nextVisitTo: Date = null;
  username: string = null;
  sortOptions: Array<SortEvent> = [];
  city: string[] = null;
  pwsName: string = null;
  county: string = null;
}
