import {Component, OnInit, Input} from '@angular/core';
import {BasicDetailsService} from '../basic-details.service';
import {MessageService} from 'src/app/message/message.service';
import {Checkout} from 'src/app/domain/checkout';
import {WaterSystem} from 'src/app/domain/water-system';
import {Severity} from 'src/app/message/Severity';

@Component({
  selector: 'app-population',
  templateUrl: './population.component.html',
  styleUrls: ['./population.component.scss']
})
export class PopulationComponent implements OnInit {

  @Input() waterSystem: Checkout<WaterSystem>;

  public isPopulationCollapsed = true;
  public showAllMonthsPopulationWarning: boolean = false;
  public showAllMonthsNegativeValueError: boolean = false;
  public showAllMonthsMaxValueError: boolean = false;

  public allMonthsPopulation: number;

  constructor(private basicDetailsService: BasicDetailsService,
              private messageService: MessageService) { }

  ngOnInit() {
  }

  onChange() {
    this.basicDetailsService.changeWaterSystem(this.waterSystem);
  }

  setPopulations(value: number) {
    this.messageService.clear();
    if (value < 0) {
      this.messageService.add({ severity: Severity.ERROR, value: 'Cannot set the population for all months because the population value cannot be negative.' });
      window.scrollTo(0, 0);
      return;
    } else if (value > 9999999) {
      this.messageService.add({ severity: Severity.ERROR, value: 'Cannot set the population for all months because the population value cannot exceed 9999999.' });
      window.scrollTo(0, 0);
      return;
    }

    this.waterSystem.data.popJan = value;
    this.waterSystem.data.popFeb = value;
    this.waterSystem.data.popMar = value;
    this.waterSystem.data.popApr = value;
    this.waterSystem.data.popMay = value;
    this.waterSystem.data.popJun = value;
    this.waterSystem.data.popJul = value;
    this.waterSystem.data.popAug = value;
    this.waterSystem.data.popSep = value;
    this.waterSystem.data.popOct = value;
    this.waterSystem.data.popNov = value;
    this.waterSystem.data.popDec = value;

    this.onChange();
  }

  showMinPopulationWarning(population: number): boolean {
    if (this.waterSystem.data.type && this.waterSystem.data.type === 'COMM') {
      if (population == null || population === 0 || (population >= 0 && population < 25)) {
        return true;
      }
    }
    return false;
  }

  onAllMonthsChange(population: number): void {
    this.showAllMonthsPopulationWarning = false;
    this.showAllMonthsNegativeValueError = false;
    this.showAllMonthsMaxValueError = false;
    if (this.waterSystem.data.type && this.waterSystem.data.type === 'COMM') {
      if (population == 0 || (population > 0 && population < 25)) {
        this.showAllMonthsPopulationWarning = true;
        return;
      }
      if (population < 0) {
        this.showAllMonthsNegativeValueError = true;
        return;
      }
    }
    if (population > 9999999) {
      this.showAllMonthsMaxValueError = true;
      return;
    }
  }
}
