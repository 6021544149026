import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from "../../shared/shared.module";

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class WaterSystemSharedModule { }
