<div id="water-system-service-area" *ngIf="waterSystem">
  <div class="border p-2 panel-collapsible-header">
    <a href="javascript:void(0);" (click)="isServiceAreaCollapsed = !isServiceAreaCollapsed"
      [attr.aria-expanded]="!isServiceAreaCollapsed" aria-controls="collapse-service-area">
      <div>
        <h2>
          Service Area Characteristics and Licensing
          <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isServiceAreaCollapsed"></i>
          <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isServiceAreaCollapsed"></i>
        </h2>
      </div>
    </a>
  </div>
  <div id="collapse-service-area" class="card" [ngbCollapse]="isServiceAreaCollapsed">
    <div class="card-body row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <span><strong>Licensing</strong></span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-sm-12 col-12">
            <fieldset class="form-check">
              <legend>FPLS:</legend>
              <input class="form-check-input" type="radio" name="fplsRadios" id="fplsRadioYes" [value]="true" [(ngModel)]="waterSystem.data.licFpls" (ngModelChange)="onChange($event)"/>
              <label for="fplsRadioYes" class="form-check-label">Yes</label>
              <input class="form-check-input" type="radio" name="fplsRadios" id="fplsRadioNo" [value]="false" [(ngModel)]="waterSystem.data.licFpls" (ngModelChange)="onChange($event)"/>
              <label for="fplsRadioNo" class="form-check-label">No</label>
            </fieldset>
          </div>
          <div class="col-md-3 col-sm-12 col-12">
            <fieldset class="form-check">
              <legend>LOC:</legend>
              <input class="form-check-input" type="radio" name="locRadios" id="locRadioYes" [value]="true" [(ngModel)]="waterSystem.data.licLoc" (ngModelChange)="onChange($event)"/>
              <label for="locRadioYes" class="form-check-label">Yes</label>
              <input class="form-check-input" type="radio" name="locRadios" id="locRadioNo" [value]="false" [(ngModel)]="waterSystem.data.licLoc" (ngModelChange)="onChange($event)"/>
              <label for="locRadioNo" class="form-check-label">No</label>
            </fieldset>
          </div>
          <div class="col-md-3 col-sm-12 col-12">
            <fieldset class="form-check">
              <legend>MDA:</legend>
              <input class="form-check-input" type="radio" name="mdaRadios" id="mdaRadioYes" [value]="true" [(ngModel)]="waterSystem.data.licMda" (ngModelChange)="onChange($event)"/>
              <label for="mdaRadioYes" class="form-check-label">Yes</label>
              <input class="form-check-input" type="radio" name="mdaRadios" id="mdaRadioNo" [value]="false" [(ngModel)]="waterSystem.data.licMda" (ngModelChange)="onChange($event)"/>
              <label for="mdaRadioNo" class="form-check-label">No</label>
            </fieldset>
          </div>
          <div class="col-md-3 col-sm-12 col-12">
            <fieldset class="form-check">
              <legend>DOT:</legend>
              <input class="form-check-input" type="radio" name="dotRadios" id="dotRadioYes" [value]="true" [(ngModel)]="waterSystem.data.licDot" (ngModelChange)="onChange($event)"/>
              <label for="dotRadioYes" class="form-check-label">Yes</label>
              <input class="form-check-input" type="radio" name="dotRadios" id="dotRadioNo" [value]="false" [(ngModel)]="waterSystem.data.licDot" (ngModelChange)="onChange($event)"/>
              <label for="dotRadioNo" class="form-check-label">No</label>
            </fieldset>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-12">
            <span><strong>Service Area Characteristics</strong></span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-12 col-12">
            <div class="form-group">
              <label for="service-area-primary">Primary:</label>
              <select id="service-area-primary" class="form-control" [(ngModel)]="waterSystem.data.serviceAreaPrimary" (ngModelChange)="onChange($event)" required="true">
                <option *ngFor="let type of SERVICE_AREA_TYPES" value="{{type.code}}">{{type.value}}</option>
              </select>
            </div>
          </div>
        </div>
        <div *ngFor="let serviceArea of waterSystem.data.serviceAreaSecondary; let i = index" class="row">
          <div class="col-md-4 col-sm-12 col-12">
            <div class="form-group">
              <label for="{{'service-area-secondary-' + i}}">Secondary:</label>
              <div class="input-group">
                <select id="{{'service-area-secondary-' + i}}" class="form-control" [ngModel]="serviceArea" (ngModelChange)="onSecondaryCharacteristicChange(i, $event)">
                  <option *ngFor="let type of SERVICE_AREA_TYPES" value="{{type.code}}">{{type.value}}</option>
                </select>
                <button id="service-area-remove-secondary-area-button" type="button" class="btn btn-danger input-group-append" (click)="removeArea(i)">Remove</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-12">
            <button id="service-area-add-secondary-area-button" type="button" class="btn btn-outline-primary" (click)="addArea()">Add Secondary</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
