import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { Checkout } from "../../domain/checkout";
import { ExistingDeficiency } from "../../domain/finding";
import { DeficienciesService } from "./deficiencies.service";
import { Unsubscribable } from "rxjs";
import { compare, NgbdSortableHeader, SortEvent } from "../../shared/table-sortable";
import { WaterSystemDeficienciesService } from "../water-system-deficiencies.service";

@Component({
  selector: 'app-active-deficiencies',
  templateUrl: './active-deficiencies.component.html'
})
export class ActiveDeficienciesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  public isActiveDeficienciesCollapsed: boolean = true;
  public deficiencies: Checkout<ExistingDeficiency[]>;
  public hasExistingActive: boolean = false;
  public showDetails = [];

  private deficiencies$: Unsubscribable;

  constructor(private deficienciesService: DeficienciesService,
              private changeDetectorRef: ChangeDetectorRef,
              private waterSystemDeficienciesService: WaterSystemDeficienciesService) { }

  ngOnInit() {
    this.deficiencies$ = this.deficienciesService.deficiencies$.subscribe(
      deficiencies => {
        this.deficiencies = deficiencies;
        if (this.deficiencies
          && this.deficiencies.data
          && this.deficiencies.data instanceof Array
          && this.deficiencies.data.length > 0) {
          this.hasExistingActive = true;
          this.showDetails = new Array(this.deficiencies.data.length).fill(false);
        }
      }
    );
  }

  ngAfterViewInit() {
    this.headers.forEach((header: NgbdSortableHeader) => {
      if (header.sortable === 'significantIndicator') {
        header.direction = 'desc';
        header.sort.emit({column: header.sortable, direction: header.direction});
      }
    });
    this.changeDetectorRef.detectChanges();
  }

  onSort({column, direction}: SortEvent) {
    this.sortDefault(this.deficiencies.data);
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (this.deficiencies.data
      && this.deficiencies.data instanceof Array
      && this.deficiencies.data.length > 1) {
      if (direction === '' || column === '') {
        this.deficiencies.data = this.sortDefault(this.deficiencies.data);
      } else {
        this.deficiencies.data = [...this.deficiencies.data].sort((a, b) => {
          const res = compare(a[column], b[column]);
          return direction === 'asc' ? res : -res;
        });
      }
    }
  }

  sortDefault(deficiencies: ExistingDeficiency[]): ExistingDeficiency[] {
    if (deficiencies && deficiencies instanceof Array && deficiencies.length > 1) {
      return deficiencies.sort((a, b) => {
        if (a.significantIndicator && a.significantIndicator === 'Y') {
          return -1;
        } else if (b.significantIndicator && b.significantIndicator === 'Y') {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      return deficiencies;
    }
  }

  resolve(index) {
    this.deficiencies.data[index].isResolved = true;
    this.waterSystemDeficienciesService.save(this.deficiencies.data[0].pwsId, this.deficiencies);
  }

  undoResolve(index) {
    this.deficiencies.data[index].isResolved = false;
    this.waterSystemDeficienciesService.save(this.deficiencies.data[0].pwsId, this.deficiencies);
  }

  ngOnDestroy() {
    if (this.deficiencies$ != null) {
      this.deficiencies$.unsubscribe();
    }
  }
}
