export const QUESTIONNAIRE_TYPES = [
  {type: 'PWS', order: 10},
  {type: 'Source, Well', order: 20},
  {type: 'Source, Consecutive Connection', order: 30},
  {type: 'Source, Surface Water', order: 40},
  {type: 'Storage', order: 50},
  {type: 'Treatment', order: 60},
  {type: 'Distribution', order: 70},
  {type: 'No Treatment', order: 80},
  {type: 'No Storage', order: 90}
];
