import { QuestionnaireList } from './questionnaire';
import { FacilityAny } from './facility';
import { User } from './user';
import { Finding } from './finding';
import { SANITARY_SURVEY_SECTION_TYPES } from '../shared/finding-type';
import { WaterSystem } from './water-system';
import { Contact } from './contact';
import { WaterOperator } from './water-operator';

export interface Inspection {
  questionnaires?: QuestionnaireList;
  reportPath?: string;
  reportUrl?: string;
  summaryUrl?: string;
  modifiedFacilities?: FacilityAny[];
  addedFacilities?: FacilityAny[];
  facilities?: FacilityAny[];
  valid: boolean;
  peoplePresent?: string;
  inspector?: User;
  findingTypeCollection?: FindingTypeCollection;
  waterSystem?: WaterSystem;
  contacts?: Contact[];
  waterOperators?: WaterOperator[];
}

export enum FacilityType {
  source = 'source',
  distribution = 'distribution',
  storage = 'storage',
  treatment = 'treatment'
}

export enum FacilityTypeTitle {
  source = 'Source Facility',
  distribution = 'Distribution Facility',
  storage = 'Storage Facility',
  treatment = 'Treatment Facility'
}

export interface FindingTypeCollection {
  clean: boolean;
  title: string;
  findingCollections: FindingCollection[];
}

export interface FindingCollection {
  clean: boolean;
  title: string;
  findingCategories: FindingCategory[];
}

export interface FindingCategory {
  code: SANITARY_SURVEY_SECTION_TYPES;
  findings: Finding[];
  description: string;
}
