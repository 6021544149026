import {Injectable} from '@angular/core';
import {SiteVisitRemoteApi} from './site-visit-remote-api';
import {SiteVisitCriteria} from './domain/SiteVisitCriteria';
import {Observable} from 'rxjs';
import {Page} from './domain/page';
import {PageRequest} from './domain/page-request';
import {SiteVisit} from './domain/site-visit';
import {ConnectivityService} from './connectivity/connectivity.service';
import {tables} from './utils/app-db';
import {LocalStorageService} from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SiteVisitService {

  static readonly checkedOutKey = 'checkedOut';

  constructor(
    private gateway: SiteVisitRemoteApi,
    private localStorageService: LocalStorageService,
    private connectivity: ConnectivityService
  ) { }

  search(criteria: SiteVisitCriteria, pageRequest: PageRequest): Observable<Page<SiteVisit>> {
    return this.gateway.search(criteria, pageRequest);
  }

  searchWithLocalStorage(key: string, criteria: SiteVisitCriteria, pageRequest: PageRequest): Observable<Page<SiteVisit>> {
    return new Observable<Page<SiteVisit>>(observer => {
      console.log(`Getting search for ${key}`);
      this.localStorageService.dbRetrieve<Page<SiteVisit>>(tables.search, key).subscribe(result => {
        if (result) {
          observer.next(result);
        }
      }, () => {
        console.log(`Search data for ${key} not found in ${tables.search} storage.`);
      });
      if (this.connectivity.isOnline()) {
        this.search(criteria, pageRequest).subscribe(result => {
          if (result) {
            observer.next(result);
            result.key = key;
            this.localStorageService.dbStore(tables.search, result);
          }
        });
      } else {
        console.log('Offline not getting search from ws.');
      }
    });
  }

  searchRemotely(key: string, criteria: SiteVisitCriteria, pageRequest: PageRequest): Observable<Page<SiteVisit>> {
    return new Observable<Page<SiteVisit>>(obs => {
      this.search(criteria, pageRequest).subscribe(result => {
        if (result) {
          obs.next(result);
          result.key = key;
          this.localStorageService.dbStore(tables.search, result);
          obs.complete();
        }
      });
    });
  }

  getLocal(key: string): Observable<Page<SiteVisit>> {
    return this.localStorageService.dbRetrieve<Page<SiteVisit>>(tables.search, key);
  }
}
