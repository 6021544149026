import { Component, Input, OnInit } from '@angular/core';
import { Question } from 'src/app/domain/question';

@Component({
  selector: 'app-question-summary',
  templateUrl: './question-summary.component.html',
  styleUrls: ['./question-summary.component.scss']
})
export class QuestionSummaryComponent implements OnInit {

  @Input() question: Question;
  @Input() waterSystemId: number;
  @Input() siteVisitId: number;
  @Input() questionnaireId: string;
  isQuestionCollapsed = false;

  constructor() { }

  ngOnInit(): void {
  }
  get isValid(): boolean {
    if (!this.question.value && this.question.required) {
      return false;
    } else {
      return true;
    }
  }

  get hasAttachments(): boolean {
    return this.question.attachments && this.question.attachments.length > 0;
  }

}
