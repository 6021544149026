import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'phoneNumber'})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string): string {
    if (value == null || value === '') {
      return value;
    }

    const length = value.length;
    if (length == 7) {
      const centralOfficeCode = value.slice(0, 3);
      const stationNumber = value.slice(3);
      return centralOfficeCode + '-' + stationNumber;
    } else if (length == 10) {
      const areaCode = value.slice(0, 3);
      const centralOfficeCode = value.slice(3, 6);
      const stationNumber = value.slice(6);
      return areaCode + '-' + centralOfficeCode + '-' + stationNumber;
    } else {
      return value;
    }
  }
}