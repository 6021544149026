<div class="card mb-3">
  <div class="card-body">
    <h2>Measures</h2>
    <div *ngIf="isLoading; else loaded">
      <div class="spinner-border"
           role="status">
        <span class="sr-only">Loading Measures...</span>
      </div>
      <span>Loading Measures...</span>
    </div>
    <ng-template #loaded>
      <!-- Disabled 7/2/2024
      <div class="table-responsive">
        <table class="table table-bordered table-hover table-sm caption-top">
          <caption style="caption-side: top; color: darkslategray">Existing Active</caption>
          <thead class="thead-light">
          <tr>
            <th scope="col"
                class="col-1">
              Code
            </th>
            <th scope="col"
                class="col-8">
              Description
            </th>
            <th scole="col"
                class="col-1">
              Status
            </th>
            <th scope="col"
                class="col-2">
              Actions
            </th>
          </tr>
          </thead>
          <tbody *ngIf="hasExistingActive">
          <tr *ngFor="let measure of allPreExisting; let i = index">
            <ng-container *ngIf="isActiveForWell(measure)">
              <td class="col-1 align-middle">{{ measure.measureCode }}</td>
              <td class="col-8">{{ measure.measureDesc }}</td>
              <td class="col-1 align-middle">{{ getStatus(measure) }}</td>
              <td class="col-2 align-middle" *ngIf="!measure.resolved">
                <button type="button"
                        class="btn btn-outline-dark btn-sm py-0"
                        (click)="resolve(i)"
                        attr.aria-label="Flag measure row {{ i + 1 }} as resolved">
                  Flag as Resolved
                </button>
              </td>
              <td class="col-2 align-middle" *ngIf="measure.resolved">
                <button type="button"
                        class="btn btn-outline-dark btn-sm py-0"
                        (click)="undoResolve(i)"
                        attr.aria-label="Undo resolved from measure row {{ i + 1 }}">
                  Undo Resolved
                </button>
              </td>
            </ng-container>
          </tbody>
          <tbody *ngIf="!hasExistingActive">
            <td colspan="4">
              There are no existing active measures associated with this well.
            </td>
          </tbody>
        </table>
      </div>
      -->
      <div class="table-responsive">
        <table class="table table-bordered table-hover table-sm caption-top">
          <!-- <caption style="caption-side: top; color: darkslategray">New</caption> -->
          <thead class="thead-light">
          <tr>
            <th scope="col"
                class="col-1">
              Code
            </th>
            <th scope="col"
                class="col-10">
              Description
            </th>
            <th scope="col"
                class="col-1">
              Actions
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="!well.newMeasures || well.newMeasures.length === 0">
            <td colspan="3">
              No New Measures Added.
            </td>
          </tr>
          <tr *ngFor="let measure of well.newMeasures; let i = index">
            <td class="col-1">
              <div class="form-group my-0">
                <label for="{{ 'measure-code-' + i }}"
                       [hidden]="true">
                  Measure Code:
                </label>
                <input id="{{ 'measure-code-' + i }}"
                       [(ngModel)]="measure.measureCode"
                       name="measure-code"
                       class="form-control"
                       readonly>
              </div>
            </td>
            <td class="col-10">
              <div class="form-group my-0">
                <label for="{{ 'measure-description-' + i }}"
                       [hidden]="true">
                  Bearing:
                </label>
                <input id="{{ 'measure-description-' + i }}"
                       [(ngModel)]="measure.measureDesc"
                       name="measure-description"
                       class="form-control"
                       readonly>
              </div>
            </td>
            <td class="col-1 align-middle">
              <button type="button"
                      class="btn btn-outline-dark btn-sm py-0"
                      (click)="delete(i)"
                      attr.aria-label="Delete new measure {{ i + 1 }}">
                Delete
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col-12">
          <button id="add-measure-button" type="button" class="btn btn-outline-primary" (click)="addMeasure()">Add Measure</button>
        </div>
      </div>
      <div class="row pt-2" *ngIf="newMeasure">
        <div class="col-lg-4 col-md-5 col-sm-12">
          <div class="form-group my-0">
            <label for="add-measure-class">
              Class (required):
            </label>
            <select id="add-measure-class"
                    [(ngModel)]="selectedClass"
                    name="add-measure-class"
                    required="true"
                    class="form-control"
                    (change)="onClassChange($event.target.value)">
              <option *ngFor="let class of classes"
                      value="{{class.measureClassCode}}">
                {{class.measureClassDesc}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-6 col-md-7 col-sm-12">
          <div class="form-group my-0">
            <label for="add-measure-type">
              Type (required):
            </label>
            <select id="add-measure-type"
                    [(ngModel)]="newMeasure"
                    name="add-measure-type"
                    required="true"
                    class="form-control">
              <option *ngFor="let type of filteredMeasuresList"
                      [ngValue]="type">
                {{type.measureCode + ' - ' + type.measureDesc}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-2 col-md-12 col-sm-12 pt-2" style="margin-top:30px;">
          <button type="button"
                  class="btn btn-outline-dark btn-sm py-0 mr-2"
                  (click)="addNew()"
                  [disabled]="!newMeasure.measureCode">
            Add
          </button>
          <button type="button"
                  class="btn btn-outline-dark btn-sm py-0"
                  (click)="deleteNew()">
            Delete
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
