export const COUNTY_TYPES = new Map([
  ['01', 'Aitkin'],
  ['02', 'Anoka'],
  ['03', 'Becker'],
  ['04', 'Beltrami'],
  ['05', 'Benton'],
  ['06', 'Big Stone'],
  ['07', 'Blue Earth'],
  ['08', 'Brown'],
  ['09', 'Carlton'],
  ['10', 'Carver'],
  ['11', 'Cass'],
  ['12', 'Chippewa'],
  ['13', 'Chisago'],
  ['14', 'Clay'],
  ['15', 'Clearwater'],
  ['16', 'Cook'],
  ['17', 'Cottonwood'],
  ['18', 'Crow Wing'],
  ['19', 'Dakota'],
  ['20', 'Dodge'],
  ['21', 'Douglas'],
  ['22', 'Faribault'],
  ['23', 'Fillmore'],
  ['24', 'Freeborn'],
  ['25', 'Goodhue'],
  ['26', 'Grant'],
  ['27', 'Hennepin'],
  ['28', 'Houston'],
  ['29', 'Hubbard'],
  ['30', 'Isanti'],
  ['31', 'Itasca'],
  ['32', 'Jackson'],
  ['33', 'Kanabec'],
  ['34', 'Kandiyohi'],
  ['35', 'Kittson'],
  ['36', 'Koochiching'],
  ['37', 'Lac qui Parle'],
  ['38', 'Lake'],
  ['39', 'Lake of the Woods'],
  ['40', 'Le Sueur'],
  ['41', 'Lincoln'],
  ['42', 'Lyon'],
  ['43', 'McLeod'],
  ['44', 'Mahnomen'],
  ['45', 'Marshall'],
  ['46', 'Martin'],
  ['47', 'Meeker'],
  ['48', 'Mille Lacs'],
  ['49', 'Morrison'],
  ['50', 'Mower'],
  ['51', 'Murray'],
  ['52', 'Nicollet'],
  ['53', 'Nobles'],
  ['54', 'Norman'],
  ['55', 'Olmsted'],
  ['56', 'Otter Tail'],
  ['57', 'Pennington'],
  ['58', 'Pine'],
  ['59', 'Pipestone'],
  ['60', 'Polk'],
  ['61', 'Pope'],
  ['62', 'Ramsey'],
  ['63', 'Red Lake'],
  ['64', 'Redwood'],
  ['65', 'Renville'],
  ['66', 'Rice'],
  ['67', 'Rock'],
  ['68', 'Roseau'],
  ['69', 'St. Louis'],
  ['70', 'Scott'],
  ['71', 'Sherburne'],
  ['72', 'Sibley'],
  ['73', 'Stearns'],
  ['74', 'Steele'],
  ['75', 'Stevens'],
  ['76', 'Swift'],
  ['77', 'Todd'],
  ['78', 'Traverse'],
  ['79', 'Wabasha'],
  ['80', 'Wadena'],
  ['81', 'Waseca'],
  ['82', 'Washington'],
  ['83', 'Watonwan'],
  ['84', 'Wilkin'],
  ['85', 'Winona'],
  ['86', 'Wright'],
  ['87', 'Yellow Medicine'],
  ['99', 'Non-Minnesota']
]);
