import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Checkout} from 'src/app/domain/checkout';
import {WaterOperator} from 'src/app/domain/water-operator';

@Injectable({
  providedIn: 'root'
})
export class OperatorsService {

  private operators = new BehaviorSubject<Checkout<WaterOperator[]>>(null);
  operators$ = this.operators.asObservable();

  constructor() { }

  changeWaterSystemOperators(system: Checkout<WaterOperator[]>) {
    this.sort(system);
    this.operators.next(system);
  }

  sort(waterOperatorsCheckout: Checkout<WaterOperator[]>) {
    if (waterOperatorsCheckout != null
        && waterOperatorsCheckout.data != null
        && waterOperatorsCheckout.data instanceof Array
        && waterOperatorsCheckout.data.length > 1) {
      waterOperatorsCheckout.data.sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
    }
  }
}
