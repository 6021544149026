export const ROLE_TYPES = [
  {code: 'CCS', description: 'Carbon Copy', order: 90},
  {code: 'CNSULT', description: 'Consultant', order: 130},
  {code: 'CCR', description: 'Consumer Confidence Report', order: 120},
  {code: 'CONTAC', description: 'Contact', order: 10},
  {code: 'EMLRMR', description: 'Email Notification', order: 60},
  {code: 'EMERAH', description: 'Emergency After-Hours', order: 80},
  {code: 'EMERWD', description: 'Emergency Workday', order: 70},
  {code: 'FINANC', description: 'Financial', order: 110},
  {code: 'SEASON', description: 'Off-Season Contact', order: 100},
  {code: 'OWNER', description: 'Owner/Responsible Party', order: 20},
  {code: 'PWSCOL', description: 'Sample Bottles/General Correspondence', order: 40},
  {code: 'SBMTTR', description: 'Submitter', order: 140},
  {code: 'UCOPER', description: 'Uncertified Operator', order: 50},
  {code: 'SUPER', description: 'Water Superintendent', order: 30}
];