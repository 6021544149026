import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CityService {
  private readonly CITY_URL = environment.serverUrl + '/city';

  constructor(private httpClient: HttpClient) { }

  getCitiesList(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.CITY_URL}`);
  }
}
