import { Component, Input, OnInit } from '@angular/core';
import { FacilityAny } from '../../../domain/facility';
import {PotentialContaminantType} from '../../../domain/potential-contaminant';

@Component({
  selector: 'app-facility-list-view',
  templateUrl: './facility-list-view.component.html',
  styleUrls: ['./facility-list-view.component.scss']
})
export class FacilityListViewComponent implements OnInit {

  @Input()
  allFacilities: FacilityAny[];

  @Input()
  facilities: FacilityAny[];

  @Input()
  public potentialContaminantsList: PotentialContaminantType[];

  constructor() { }

  ngOnInit(): void {
  }
}
