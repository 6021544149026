import {Pipe, PipeTransform} from '@angular/core';
import { FACILITY_STATUSES } from './facility-status';

@Pipe({name: 'facilityStatus'})
export class FacilityStatusPipe implements PipeTransform {
  transform(code: string): string {
    if (!code || code === '') {
      return code;
    }

    for (const status of FACILITY_STATUSES) {
      if (code.trim() === status.code.trim()) {
        return status.value.trim();
      }
    }
    return code;
  }
}
