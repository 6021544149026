<div id="current-site-visit" *ngIf="currentVisit">
  <div class="border p-2 panel-collapsible-header">
    <a href="javascript:void(0);" (click)="isCurrentVisitCollapsed = !isCurrentVisitCollapsed"
      [attr.aria-expanded]="!isCurrentVisitCollapsed" aria-controls="collapse-current-visit">
      <div>
        <h2>
          Current Site Visit
          <span class="ml-2 badge badge-pill badge-danger"
                *ngIf="!isOnline && currentVisit?.id == null"
                style="font-size: small !important">
            Offline - saved locally only
          </span>
          <span class="ml-2 badge badge-pill badge-warning"
                *ngIf="isCurrentVisitUploading"
                style="font-size: small !important">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
              <span class="sr-only">Online - uploading to database...</span>
            </span>
            Online - uploading to database...
          </span>
          <span class="ml-2 badge badge-pill badge-success"
                *ngIf="isCurrentVisitUploaded"
                style="font-size: small !important">
            Saved to database
          </span>
          <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isCurrentVisitCollapsed"></i>
          <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon"
            *ngIf="!isCurrentVisitCollapsed"></i>
        </h2>
      </div>
    </a>
  </div>
  <div id="collapse-current-visit" class="card" [ngbCollapse]="isCurrentVisitCollapsed" *ngIf="!deletingCurrentVisit">
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <div class="row">
            <div class="col-12">
              <strong>Visit Type: </strong>
              <br/>
              <span>{{ currentVisit.visitType | visitTypeAnnualSanitary }}</span>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12 form-group">
              <label for="current-visit-date" class="font-weight-bold">Visit Date (required):</label>
              <input id="current-visit-date"
                     type="date"
                     class="form-control"
                     [ngModel]="currentVisit.visitDate | date: 'yyyy-MM-dd': '+0000'"
                     (blur)="saveVisitDate($event.target.value)"
                     required
                     min="2000-01-01"
                     max="9999-12-31"
                     #currentVisitDate="ngModel"/>
              <div *ngIf="currentVisitDate.invalid && (currentVisitDate.dirty || currentVisitDate.touched)" class="alert alert-danger">
                <div *ngIf="currentVisitDate.errors">
                  Invalid Visit Date.
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="currentVisit.inspection" class="row">
            <div class="col-12 form-group">
              <label for="people-present" class="font-weight-bold">People Present (required):</label>
              <input id="people-present" class="form-control" [(ngModel)]="currentVisit.inspection.peoplePresent" maxlength="100" (change)="saveCurrentSiteVisit()" required #currentPeoplePresent="ngModel"/>
              <div *ngIf="currentPeoplePresent.errors?.required" class="alert alert-danger">
                  Include the names of the people present.
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div *ngIf="completing" class="row">
            <div class="spinner-border" role="status" aria-hidden="true">
              <span class="sr-only">Completing Site Visit...</span>
            </div>
            <span>Completing Site Visit...</span>
          </div>
          <div *ngIf="!completing" class="row mb-2">
            <div class="col-12">
              <strong>Actions:</strong>
            </div>
          </div>
          <div *ngIf="!(isOnline && currentVisit && currentVisit.inspection && isValid && !currentVisitDate.errors)" class="row mb-2">
            <div class="col-12">
              <span *ngIf="!isOnline">Unable to complete Site Visit or view Findings Summary while offline.<br/></span>
              <span *ngIf="!isValid">Site Visit has questions that need to be addressed before it can be completed or the Findings Summary can be viewed.<br/></span>
              <span *ngIf="currentVisitDate.errors">The Visit Date is invalid.</span>
            </div>
          </div>
          <div *ngIf="!completing" class="row">
            <div class="col-12">
              <button type="button" class="btn btn-primary mr-1 mb-2"  [state]="currentVisit" [routerLink]="['../visit', currentVisit.id]"
                routerLinkActive="true">Site Visit</button>
              <button type="button" *ngIf="currentVisit && currentVisit.inspection" class="btn btn-primary mr-1 mb-2" [state]="currentVisit"
                [routerLink]="['../visit', currentVisit.id, 'view']" routerLinkActive="true">Question Set Summary</button>
              <button type="button" *ngIf="currentVisit && currentVisit.inspection" class="btn btn-primary mr-1 mb-2" [state]="currentVisit"
                      [routerLink]="['../visit', currentVisit.id, 'viewfacility']" routerLinkActive="true">Facility Summary</button>
              <button type="button" *ngIf="isOnline && currentVisit && currentVisit.inspection" class="btn btn-primary mr-1 mb-2" [state]="currentVisit"
                      [routerLink]="['../visit', currentVisit.id, 'viewBasicDetails']" routerLinkActive="true">Basic Details Summary</button>
              <button type="button" *ngIf="isOnline && currentVisit && currentVisit.inspection" class="btn btn-primary mr-1 mb-2" [state]="currentVisit"
                      [routerLink]="['../visit', currentVisit.id, 'viewContacts']" routerLinkActive="true">Contacts Summary</button>
              <button type="button" *ngIf="isOnline && currentVisit && currentVisit.inspection && isValid && !currentVisitDate.errors" class="btn btn-primary mr-1 mb-2" [state]="currentVisit"
                [routerLink]="['../visit', currentVisit.id, 'findings-summary']" routerLinkActive="true">Findings Summary</button>
              <input *ngIf="isOnline && currentVisit && currentVisit.inspection && isValid && !currentVisitDate.errors && isPeoplePresentValid" type="button" class="btn btn-primary mr-1 mb-2"
                value="Complete Current Site Visit" (click)="confirmCompleteCurrentSiteVisit()" />
              <input type="button" class="btn btn-danger mb-2" value="Delete Current Site Visit"
                (click)="deleteCurrentSiteVisit()" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card" *ngIf="deletingCurrentVisit">
    <div>
      <div class="spinner-border" role="status" aria-hidden="true">
        <span class="sr-only">Deleting Site Visit...</span>
      </div>
      <span>Deleting Site Visit...</span>
    </div>
  </div>
</div>
<div *ngIf="isInspector && !currentVisit" id="start-site-visit">
  <div class="row" *ngIf="!isCurrentVisitStarting">
    <div class="col-12" *ngIf="isOnline">
      <input type="button" class="btn btn-outline-primary btn-lg" value="Start Site Visit" (click)="startSiteVisit()">
    </div>
  </div>
  <div *ngIf="isCurrentVisitStarting">
    <div class="spinner-border" role="status" aria-hidden="true">
      <span class="sr-only">Starting Site Visit...</span>
    </div>
    <span>Starting Site Visit...</span>
  </div>
</div>
