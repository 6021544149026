import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {District} from './domain/district';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DistrictService {

  private url = environment.serverUrl + '/district';

  constructor(private httpClient: HttpClient) { }

  findAll(): Observable<Array<District>> {
    return this.httpClient.get<Array<District>>(this.url);
  }

}
