import {Pipe, PipeTransform} from '@angular/core';
import {ROLE_TYPES} from './role-type';

@Pipe({name: 'roleType'})
export class RoleTypePipe implements PipeTransform {
  transform(code: string): string {
    for (let type of ROLE_TYPES) {
      if (code === type.code) {
        return type.description;
      }
    }
    return code;
  }
}