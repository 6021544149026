import { Injectable } from '@angular/core';
import { Checkout } from 'src/app/domain/checkout';
import { Contact } from 'src/app/domain/contact';
import { ROLE_TYPES } from 'src/app/shared/role-type';

@Injectable({
  providedIn: 'root'
})
export class ContactsUtilityService {

  private readonly ROLE_TYPES: any[] = ROLE_TYPES;

  constructor() { }

  sortContacts(contacts: Checkout<Contact[]>): void {
    if (contacts == null && contacts.data == null) {
      return;
    } else if (contacts.data.length == 1) {
      return;
    }

    contacts.data.sort((a, b) => {
      if (a.isPrimary && !b.isPrimary) {
        return -1;
      } else if (!a.isPrimary && b.isPrimary) {
        return 1;
      } else {
        let roleA = this.ROLE_TYPES.find(contact => contact.code === a.role);
        let roleB = this.ROLE_TYPES.find(contact => contact.code === b.role);
        if (roleA.order < roleB.order) {
          return -1;
        } else if (roleA.order === roleB.order) {
          return 0;
        } else if (roleA.order > roleB.order) {
          return 1;
        }
      }
    });
  }
}