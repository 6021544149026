import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConnectivityService } from 'src/app/connectivity/connectivity.service';
import { environment } from 'src/environments/environment';
import { MeasureType } from "../domain/measure-type";

@Injectable({
  providedIn: 'root'
})
export class MeasuresListService {

  private readonly MEASURES_LIST_URL = environment.serverUrl + '/measuretype';

  constructor(private connectivityService: ConnectivityService,
              private httpClient: HttpClient) { }

  /**
   * Gets the measures list from the remote API.
   */
  getMeasuresList(): Observable<MeasureType[]> {
    return this.httpClient.get<MeasureType[]>(`${this.MEASURES_LIST_URL}`);
  }
}
