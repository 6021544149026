import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {migrateQuestionaireToUuid, Questionnaire} from 'src/app/domain/questionnaire';
import { ConnectivityService } from 'src/app/connectivity/connectivity.service';
import { QuestionnairesService } from './questionnaires.service';
import { QUESTIONNAIRE_TYPES } from 'src/app/shared/questionnaire-type';
import { ConfirmatoryActionService } from 'src/app/confirmatory-action/confirmatory-action.service';
import { MessageService } from 'src/app/message/message.service';
import { Severity } from 'src/app/message/Severity';
import { Unsubscribable } from 'rxjs';

@Component({
  selector: 'app-questionnaires',
  templateUrl: './questionnaires.component.html',
  styleUrls: ['./questionnaires.component.scss']
})
export class QuestionnairesComponent implements OnInit {

  public questionnaires: Questionnaire[];

  public isQuestionnairesLoading = true;
  public isQuestionnaireDeleting = false;
  public isOnline: boolean;
  public error = null;

  constructor(private questionnairesService: QuestionnairesService,
              private connectivityService: ConnectivityService,
              private router: Router,
              private confirmatoryActionService: ConfirmatoryActionService,
              private messageService: MessageService) { }

  ngOnInit() {
    this.isOnline = this.connectivityService.isOnline();
    this.connectivityService.online$().subscribe(online => {
      this.isOnline = online;
    });
    this.getConfiguration();
  }

  getConfiguration() {
    this.questionnairesService.getAll().subscribe(
      questionnaires => {
        this.questionnaires = this.sortDefault(questionnaires);
        this.isQuestionnairesLoading = false;
      },
      error => {
        this.error = error;
        this.isQuestionnairesLoading = false;
      }
    );
  }

  sortDefault(questionnaires: Questionnaire[]): Questionnaire[] {
    if (questionnaires && questionnaires.length > 1) {
      questionnaires = questionnaires.sort((a, b) => {
        if (QUESTIONNAIRE_TYPES.find(questionnaire => questionnaire.type === a.item)
        && QUESTIONNAIRE_TYPES.find(questionnaire => questionnaire.type === b.item)) {
          const aOrder: number = QUESTIONNAIRE_TYPES.find(questionnaire => questionnaire.type === a.item).order;
          const bOrder: number = QUESTIONNAIRE_TYPES.find(questionnaire => questionnaire.type === b.item).order;
          if (aOrder < bOrder) {
            return -1;
          } else if (aOrder > bOrder) {
            return 1;
          } else {
            return 0;
          }
        } else if (QUESTIONNAIRE_TYPES.find(questionnaire => questionnaire.type === a.item)) {
          return 1;
        } else if (QUESTIONNAIRE_TYPES.find(questionnaire => questionnaire.type === b.item)) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    return questionnaires;
  }

  navigateToEdit(questionnaire: Questionnaire) {
    this.router.navigate(['/admin/questionnaires/edit', questionnaire.id], { state: { data: questionnaire }});
  }

  deleteQuestionnaire(questionnaire: Questionnaire) {
    this.messageService.clear();
    this.confirmatoryActionService.confirm('Confirmation', 'Are you sure you want to delete this questionnaire?',
      'Yes', 'No', 'sm')
      .then((confirmed) => {
        if (confirmed) {
          this.isQuestionnaireDeleting = true;
          this.questionnairesService.deleteRemotely(questionnaire).subscribe(
            () => {
              this.isQuestionnaireDeleting = false;
              this.ngOnInit();
              this.messageService.add({ severity: Severity.SUCCESS, value: 'The Question Set has been deleted.' });
            }
          );
        }
      })
      .catch(() => {});
  }

  exportQuestionnaire(questionnaire: Questionnaire) {
    this.messageService.clear();
    const exportQuestionnaire = JSON.parse(JSON.stringify(questionnaire));
    exportQuestionnaire.id = null;
    exportQuestionnaire.activeInd = false;
    const blob = new Blob([JSON.stringify(exportQuestionnaire)], { type: 'text/json' });
    const link = document.createElement('a');

    link.download = exportQuestionnaire.description + '.json';
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ['text/json', link.download, link.href].join(':');

    const evt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    });

    link.dispatchEvent(evt);
    link.remove();
  }

  onImportQuestionSet(files: FileList) {
    this.messageService.clear();
    if (files == null || files[0] == null) {
      this.messageService.add({ severity: Severity.ERROR, value: 'The Question Set import file is empty.' });
      return;
    } else if (files[0].type !== 'application/json') {
      this.messageService.add({ severity: Severity.ERROR, value: 'The Question Set import file type is invalid.' });
      return;
    } else if (files[0].size === 0) {
      this.messageService.add({ severity: Severity.ERROR, value: 'The Question Set import file is empty.' });
      return;
    }
    const fileReader = new FileReader();
    fileReader.readAsText(files[0], 'UTF-8');

    fileReader.onload = () => {
      const jsonResult: any = fileReader.result;
      const importQuestionnaire: Questionnaire = JSON.parse(jsonResult) as Questionnaire;
      if (importQuestionnaire === null || importQuestionnaire.item === null || importQuestionnaire.description === null) {
        this.messageService.add({ severity: Severity.ERROR, value: 'The Question Set import file is invalid.' });
        return;
      }
      this.isQuestionnairesLoading = true;
      migrateQuestionaireToUuid(importQuestionnaire);
      const questionnaireServiceSubscription: Unsubscribable = this.questionnairesService.upsertRemotely(importQuestionnaire).subscribe(
        () => {
          this.messageService.add({ severity: Severity.SUCCESS, value: 'The Question Set has been imported.' });
          this.getConfiguration();
          questionnaireServiceSubscription.unsubscribe();
        },
        () => questionnaireServiceSubscription.unsubscribe()
      );
    };
  }

}
