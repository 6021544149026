import {Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import { NgbdSortableHeader, SortEvent } from '../../shared/table-sortable';
import { SiteVisitCriteria } from '../../domain/SiteVisitCriteria';
import { PageRequest } from '../../domain/page-request';
import { Page } from '../../domain/page';
import { SiteVisitService } from '../../site-visit.service';
import { KeycloakProfile } from 'keycloak-js';
import { Router } from '@angular/router';
import { SiteVisit } from 'src/app/domain/site-visit';
import { IamService } from 'src/app/utils/iam.service';
import { StorageService } from 'src/app/utils/storage.service';
import {ConnectivityService} from '../../connectivity/connectivity.service';

@Component({
  selector: 'app-checked-out-site-visits',
  templateUrl: './checked-out-site-visits.component.html',
  styleUrls: ['./checked-out-site-visits.component.scss']
})
export class CheckedOutSiteVisitsComponent implements OnInit, OnDestroy {

  isCollapsed = false;
  userDetails: KeycloakProfile;
  criteria = new SiteVisitCriteria();
  private pageRequest = new PageRequest(0, 50);

  page: Page<SiteVisit>;

  completed = false;
  errored = false;
  isSiteVisitsLoading = false;
  readonly key = 'checkedOut';
  searchTimer;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(
    private iamService: IamService,
    private router: Router,
    private siteVisitService: SiteVisitService,
    private connectivityService: ConnectivityService
  ) { }

  async ngOnInit() {
    this.completed = false;
    this.isSiteVisitsLoading = true;
    this.userDetails = await this.iamService.getUserDetails();
    this.criteria.username = this.userDetails.username;
    this.search();
  }

  ngOnDestroy() {
    clearTimeout(this.searchTimer);
  }

  search() {
    this.siteVisitService.searchWithLocalStorage(
      `${SiteVisitService.checkedOutKey}-${this.userDetails.username}`,
      this.criteria, this.pageRequest
    ).subscribe(
      page => {
        this.page = page;
        if (this.connectivityService.isOnline() && this.page.results
          && this.page.results.find(r => r && r.status !== 'CHECKED_OUT')) {
          clearTimeout(this.searchTimer);
          this.searchTimer = setTimeout(() => {
            this.search();
          }, 3000);
        }
        this.isSiteVisitsLoading = false;
      },
      () => this.errored = true,
      () => this.completed = true,
    );
  }

  onSort({column, direction}: SortEvent) {
    const removedColumn = NgbdSortableHeader.doSort({column, direction}, this.criteria);
    if (removedColumn) {
      for (const header of this.headers.toArray()) {
        if (header.sortable === removedColumn) {
          header.direction = '';
          break;
        }
      }
    }
    this.search();
  }

  navigateToWaterSystemDetails(siteVisit: SiteVisit) {
    this.router.navigate(['/water-system', siteVisit.id], { state: { data: siteVisit }});
  }

  isResultsEmpty() {
    return this.page && this.page.results.length === 0;
  }

  getLocalCreatedDate(siteVisit: SiteVisit) {
    if (!siteVisit || !siteVisit.created) {
      return '';
    } else {
      return siteVisit.created;
    }
  }
}

