<div *ngIf="hasAdditional">
  <div class="row pb-1">
    <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <button class="btn btn-outline-dark btn-sm py-0" title="Expand All" aria-label="Expand All" (click)="expandAll()" type="button">
        <span>Expand All</span>
      </button>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <button class="btn btn-outline-dark btn-sm py-0" title="Collapse All" aria-label="Collapse All" (click)="collapseAll()" type="button">
        <span>Collapse All</span>
      </button>
    </div>
  </div>
  <div class="row" *ngIf="photo">
    <div class="col-12">
      <div class="border p-2 panel-collapsible-header">
        <a href="javascript:void(0);" (click)="isPhotoCollapsed = !isPhotoCollapsed"
          [attr.aria-expanded]="!isPhotoCollapsed" aria-controls="collapse-photo">
          <div>
            Photo:
            <span *ngIf="photo" data-toggle="tooltip" title="A photo is attached"><i class="fas fa-info-circle fa-xs align-top"></i></span>
            <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isPhotoCollapsed"></i>
            <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isPhotoCollapsed"></i>
          </div>
        </a>
      </div>
      <div id="collapse-photo" class="card" [ngbCollapse]="isPhotoCollapsed">
        <div class="card-body">
          <div *ngIf="photo" class="pt-2">
            <canvas [id]="photoThumbnailId" (click)="openPhoto()"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="hasAttachments">
    <div class="col-12">
      <div class="border p-2 panel-collapsible-header">
        <a href="javascript:void(0);" (click)="isAttachmentsCollapsed = !isAttachmentsCollapsed"
          [attr.aria-expanded]="!isAttachmentsCollapsed" aria-controls="collapse-attachments">
          <div>
            Attachment(s):
            <span *ngIf="attachments && attachments.length > 0" data-toggle="tooltip" title="Has one or more attachments"><i class="fas fa-info-circle fa-xs align-top"></i></span>
            <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isAttachmentsCollapsed"></i>
            <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isAttachmentsCollapsed"></i>
          </div>
        </a>
      </div>
      <div id="collapse-attachments" class="card" [ngbCollapse]="isAttachmentsCollapsed">
        <div class="card-body">
          <div *ngIf="attachments && attachments.length > 0" id="attachments">
            <div *ngFor="let attachment of attachments; let i = index">
              <button *ngIf="attachment" type="button" class="btn btn-outline-dark py-0" attr.aria-label="View File {{ attachment.filename }}" (click)="openAttachment(attachment)">View {{ attachment.filename }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="question.note">
    <div class="col-12">
      <div class="border p-2 panel-collapsible-header">
        <a href="javascript:void(0);" (click)="isNoteCollapsed = !isNoteCollapsed"
          [attr.aria-expanded]="!isNoteCollapsed" aria-controls="collapse-note">
          <div>
            <label for="note">
              {{ noteLabelOverride }}:
              <span *ngIf="question.note && question.note !== ''" data-toggle="tooltip" title="A note is entered"><i class="fas fa-info-circle fa-xs align-top"></i></span>
            </label>
            <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isNoteCollapsed"></i>
            <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isNoteCollapsed"></i>
          </div>
        </a>
      </div>
      <div id="collapse-note" class="card" [ngbCollapse]="isNoteCollapsed">
        <div class="card-body">
          <div class="form-group">
            <div>{{question.note}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="question.finding" class="row">
    <div class="col-12">
      <div class="border p-2 panel-collapsible-header">
        <a href="javascript:void(0);" (click)="isFindingCollapsed = !isFindingCollapsed"
          [attr.aria-expanded]="!isFindingCollapsed" aria-controls="collapse-finding">
          <div>
            Finding:
            <span *ngIf="question.findingNote && question.findingNote !== ''" data-toggle="tooltip" title="Additional finding comments are entered"><i class="fas fa-info-circle fa-xs align-top"></i></span>
            <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isFindingCollapsed"></i>
            <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isFindingCollapsed"></i>
          </div>
        </a>
      </div>
      <div id="collapse-finding" class="card" [ngbCollapse]="isFindingCollapsed">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <span>Code:</span>
              <br/>
              <span>{{ question.finding.code }}</span>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12">
              <span>Type:</span>
              <br/>
              <span>{{ question.finding.description }}</span>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12">
              <span>Language:</span>
              <div>
                <span class="white-space-break-spaces">{{ question.finding.language }}</span>
              </div>
            </div>
          </div>
          <div class="row pt-2" *ngIf="question.findingNote">
            <div class="col-12">
              <span>Additional Finding Comments:</span>
              <br/>
              <span class="white-space-break-spaces">{{ question.findingNote }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
