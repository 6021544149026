export const AVAILABILITY_TYPES = [
  {code: 'DNQL', value: 'Does Not Qualify'},
  {code: 'EMER', value: 'Emergency'},
  {code: 'NTDW', value: 'Not Drinking Water'},
  {code: 'NIUW', value: 'Not in Use'},
  {code: 'OBSV', value: 'Observation'},
  {code: 'OTHE', value: 'Other'},
  {code: 'GIND', value: 'Pending'},
  {code: 'PRIM', value: 'Primary'},
  {code: 'XXXX', value: 'Sealed'},
  {code: 'SEAS', value: 'Seasonal/Backup'}
];
