<div *ngIf="hasAnswers; else noAnswers">
  <div class="border p-2 panel-collapsible-header h3">
    <a href="javascript:void(0);" (click)="isQuestionnaireCollapsed = !isQuestionnaireCollapsed"
      [attr.aria-expanded]="!isQuestionnaireCollapsed" aria-controls="collapse-photo">
      <div>
        <span class="font-weight-bolder">{{questionnaire.category && questionnaire.category !== '' ?
          questionnaire.category :
          questionnaire.item}}</span>
        <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon"
          *ngIf="isQuestionnaireCollapsed"></i>
        <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon"
          *ngIf="!isQuestionnaireCollapsed"></i>
      </div>
    </a>
  </div>
  <div class="card" [ngbCollapse]="isQuestionnaireCollapsed">
    <div class="card-body">
      <div *ngFor="let question of questionnaire.question">
        <div *ngIf="showQuestion(question)">
          <app-question-summary [question]="question" [waterSystemId]="waterSystemId"
            [siteVisitId]="siteVisitId" [questionnaireId]="questionnaireId"></app-question-summary>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #noAnswers>
  <div>
    <span class="font-weight-bolder">{{questionnaire.category && questionnaire.category !== '' ?
      questionnaire.category :
      questionnaire.item}}</span>
    No Questions Answered.
  </div>
</ng-template>
