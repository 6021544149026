import { Directive, EventEmitter, Input, Output } from '@angular/core';

export type SortDirection = 'asc' | 'desc' | '';
export type RotateOption = 'default' | 'reverse' | 'noEmpty' | 'noEmptyReverse';
const rotate: {[key: string]: SortDirection} = { 'asc': 'desc', 'desc': '', '': 'asc' };
const reverseRotate: {[key: string]: SortDirection} = { 'desc': 'asc', 'asc': '', '': 'desc' };
const noEmptyRotate: {[key: string]: SortDirection} = { 'desc': 'asc', 'asc': 'desc', '': 'asc' };
const noEmptyReverseRotate: {[key: string]: SortDirection} = { 'desc': 'asc', 'asc': 'desc', '': 'desc' };
export const compare = (v1, v2) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: string;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()',
    '(keyup.enter)': 'rotate()'
  }
})
export class NgbdSortableHeader {

  @Input() sortable: string;
  @Input() direction: SortDirection = '';
  @Input() rotateOption: RotateOption = 'default';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    switch (this.rotateOption) {
      case 'default': {
        this.direction = rotate[this.direction];
        break;
      }
      case 'reverse': {
        this.direction = reverseRotate[this.direction];
        break;
      }
      case 'noEmpty': {
        this.direction = noEmptyRotate[this.direction];
        break;
      }
      case 'noEmptyReverse': {
        this.direction = noEmptyReverseRotate[this.direction];
        break
      }
      default: {
        this.direction = rotate[this.direction];
        break;
      }
    }
    this.sort.emit({column: this.sortable, direction: this.direction});
  }

  public static doSort({column, direction}: SortEvent, criteria: any): string {
    let removeColumn = null;
    if (criteria.sortOptions.length === 0) {
      criteria.sortOptions.push({column, direction});
    } else if (criteria.sortOptions.length === 1) {
      if (criteria.sortOptions[0].column === column) {
        if (direction === '') {
          removeColumn = criteria.sortOptions.shift().column;
        } else {
          criteria.sortOptions[0].direction = direction;
        }
      } else {
        criteria.sortOptions.push({column, direction});
      }
    } else {
      let noMatch = true;
      for (let option of criteria.sortOptions) {
        if (column === option.column) {
          noMatch = false;
          if (direction === '') {
            removeColumn = column;
            let removeIndex = criteria.sortOptions.findIndex(option => option.column === removeColumn);
            if (removeIndex > -1) {
              criteria.sortOptions.splice(removeIndex, 1);
            }
          } else {
            option.direction = direction;
          }
          break;
        }
      }
      if (noMatch) {
        removeColumn = criteria.sortOptions.shift().column;
        criteria.sortOptions.push({column, direction});
      }
    }

    return removeColumn;
  }
}