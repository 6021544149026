import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'waterSystemStatusType'})
export class WaterSystemStatusTypePipe implements PipeTransform {
  transform(value: string): string {
    if (value === 'A') {
      return 'Active';
    } else if (value === 'I') {
      return 'Inactive';
    } else if (value === 'P') {
      return 'Proposed';
    } else {
      return value;
    }
  }
}