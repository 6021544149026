import {Component, Input, OnInit} from '@angular/core';
import {WaterSystem} from '../../../../domain/water-system';

@Component({
  selector: 'app-service-area-summary',
  templateUrl: './service-area-summary.component.html',
  styleUrls: ['./service-area-summary.component.scss']
})
export class ServiceAreaSummaryComponent implements OnInit {

  @Input() summary: WaterSystem;
  public isCollapsed = true;

  constructor() { }

  ngOnInit(): void {
  }

}
