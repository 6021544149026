import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Checkout} from 'src/app/domain/checkout';
import {ComplianceOfficer} from 'src/app/domain/compliance-officer';

@Injectable({
  providedIn: 'root'
})
export class ComplianceOfficerService {

  private complianceOfficer = new BehaviorSubject<Checkout<ComplianceOfficer>>(null);
  complianceOfficer$ = this.complianceOfficer.asObservable();

  constructor() { }

  setWaterSystemComplianceOfficer(system: Checkout<ComplianceOfficer>) {
    this.complianceOfficer.next(system);
  }
}
