import {Component, Input, OnInit} from '@angular/core';
import {WaterSystem} from '../../../../domain/water-system';

@Component({
  selector: 'app-population-summary',
  templateUrl: './population-summary.component.html',
  styleUrls: ['./population-summary.component.scss']
})
export class PopulationSummaryComponent implements OnInit {

  @Input() summary: WaterSystem;
  public isCollapsed = true;

  constructor() { }

  ngOnInit(): void {
  }

}
