import { Component, OnInit } from '@angular/core';
import { KeycloakProfile } from 'keycloak-js';
import { IamService } from '../utils/iam.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  userDetails: KeycloakProfile;

  constructor(
    private iamService: IamService
  ) { }

  async ngOnInit() {
    this.userDetails = await this.iamService.getUserDetails();
  }

  get isAdmin() {
    return this.iamService.isUserInRole('admin');
  }

  get isInspector() {
    return this.iamService.isUserInRole('inspector');
  }
}
