import { Component, Input, OnInit } from '@angular/core';
import { Contact, ContactAction } from '../../../domain/contact';

@Component({
  selector: 'app-inventory-contacts-summary',
  templateUrl: './inventory-contacts-summary.component.html',
  styleUrls: ['./inventory-contacts-summary.component.scss']
})
export class InventoryContactsSummaryComponent implements OnInit {

  @Input()
  contacts: Contact[];

  createdContacts: Contact[];
  updatedContacts: Contact[];
  deletedContacts: Contact[];
  isCollapsed = true;
  isContactsChanged: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.createdContacts = [];
    this.updatedContacts = [];
    this.deletedContacts = [];
    this.contacts.forEach((c) => {
      switch (c.action) {
        case ContactAction.Create:
          this.createdContacts.push(c);
          this.isContactsChanged = true;
          break;
        case ContactAction.Update:
          this.updatedContacts.push(c);
          this.isContactsChanged = true;
          break;
        case ContactAction.Delete:
        case ContactAction.Erase:
          this.deletedContacts.push(c);
          this.isContactsChanged = true;
          break;
        default:
      }
    });
  }
}
