<div *ngIf="isAnswerListLoading; else listLoaded">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading Answer List...</span>
  </div>
  <span>Loading Answer List...</span>
</div>
<ng-template #listLoaded>
  <div class="table-responsive">
    <table class="table table-bordered table-hover table-sm" id="edit-answers-list-table">
      <thead class="thead-light">
        <tr class="d-flex">
          <th scope="col" style="width:24%">Answer</th>
          <th scope="col" style="width:24%">Element Code</th>
          <th scope="col" style="width:24%">Element Type</th>
          <th scope="col" style="width:8%">Note</th>
          <th scope="col" style="width:8%">Photo</th>
          <th scope="col" style="width:12%">Attachment</th>
          <th scope="col" style="width:24%">Note Label Override</th>
          <th scope="col" style="width:20%">Actions</th>
        </tr>
      </thead>
      <tbody *ngIf="(answers && answers.length > 0) || (newAnswers && newAnswers.length > 0)">
        <tr *ngFor="let answer of answers; let i = index" class="d-flex" [draggable]="!denyEdit" (dragstart)="drag(i, $event)" (drop)="drop(i)" (dragover)="allowDrop($event)">
          <td style="width:24%">
            <div class="form-group m-2">
              <label for="{{ 'answer-' + i + '-name' }}" [hidden]="true">Answer: (required)</label>
              <input type="text" id="{{ 'answer-' + i + '-name' }}" name="name" class="answer-input-text form-control" [(ngModel)]="answer.name" required="true" maxlength="100" (change)="onAnswerChange($event.target.value, i, $event.target.title)" [disabled]="denyEdit" title="Answer Text">
            </div>
            <div *ngIf="!isAnswerUnique(answer.name, i)" class="alert alert-danger">
              The Answer must be unique.
            </div>
          </td>
          <td style="width:24%">
            <div class="m-2">
              <button type="button"
                      class="btn btn-outline-dark btn-sm py-0 float-left"
                      (click)="openElementCodeModal(contentElementCode, i)"
                      [disabled]="denyEdit">
                <span *ngIf="answer.finding">{{ answer.finding.code }} </span>
                <span *ngIf="!answer.finding">Click to Select a Code</span>
              </button>
              <button type="button"
                        class="btn btn-outline-dark btn-sm py-0 float-right w-50"
                        (click)="openElementLanguageModal(contentLanguageModal, i)"
                        *ngIf="answer.finding">
                View Language
              </button>
            </div>
          </td>
          <td style="width:24%">
            <div class="m-2 form-group" *ngIf="answer.finding">
              <label for="answer-finding-description" [hidden]="true">Answer Element Type</label>
              <select id="answer-finding-description" class="form-control" [(ngModel)]="answer.finding.description" (change)="onChange($event.target.title)" title="Element Type" [disabled]="denyEdit">
                <option *ngFor="let type of ELEMENT_TYPES" value="{{type}}">{{type}}</option>
              </select>
            </div>
          </td>
          <td style="width:8%">
            <div class="form-group form-check m-2 d-inline-block align-middle">
              <label for="{{ 'answer-' + i + '-note-required' }}" [hidden]="true" class="form-check-label">Note: (required)</label>
              <input type="checkbox" id="{{ 'answer-' + i + '-note-required' }}" [(ngModel)]="answer.noteRequired" (change)="onChange($event.target.title)" name="{{ 'note-required-' + i }}" class="form-check-input" [disabled]="denyEdit" title="Answer Note">
            </div>
          </td>
          <td style="width:8%">
            <div class="form-group form-check m-2 d-inline-block align-middle">
              <label for="{{ 'answer-' + i + '-photo-required' }}" [hidden]="true" class="form-check-label">Photo: (required)</label>
              <input type="checkbox" id="{{ 'answer-' + i + '-photo-required' }}" [(ngModel)]="answer.photoRequired" (change)="onChange($event.target.title)" name="{{ 'photo-required-' + i }}" class="form-check-input" [disabled]="denyEdit" title="Answer Photo">
            </div>
          </td>
          <td style="width:12%">
            <div class="form-group form-check m-2 d-inline-block align-middle">
              <label for="{{ 'answer-' + i + '-attachment-required' }}" [hidden]="true" class="form-check-label">Attachment: (required)</label>
              <input type="checkbox" id="{{ 'answer-' + i + '-attachment-required' }}" [(ngModel)]="answer.attachmentRequired" (change)="onChange($event.target.title)" name="{{ 'attachment-required-' + i }}" class="form-check-input" [disabled]="denyEdit" title="Answer Attachment">
            </div>
          </td>
          <td style="width:24%">
            <div class="form-group m-2">
              <label for="{{ 'answer-' + i + '-note-label' }}" [hidden]="true">Note Label Override: (required)</label>
              <input type="text" id="{{ 'answer-' + i + '-note-label' }}" name="note-label" class="answer-input-text form-control" [(ngModel)]="answer.noteLabelOverride" maxlength="100" (change)="onChange($event.target.title)" [disabled]="denyEdit" title="Answer Note Label Override">
            </div>
          </td>
          <td style="width:20%">
            <button type="button" class="btn btn-outline-dark btn-sm py-0" (click)="deleteAnswer(i)" [disabled]="denyEdit" attr.aria-label="Delete answer {{ answer.name }}">Delete Answer</button>
            <br/>
            <button *ngIf="answer.finding" type="button" class="btn btn-outline-dark btn-sm py-0 mt-2" (click)="deleteElement(i)" [disabled]="denyEdit" attr.aria-label="Delete element {{ answer.finding.code }} from answer {{ answer.name }}">Delete Element</button>
          </td>
        </tr>
        <tr *ngFor="let newAnswer of newAnswers; let i = index" [ngClass]="{ 'd-flex' : !denyEdit }" [hidden]="denyEdit">
          <td style="width:24%">
            <div class="form-group m-2">
              <label for="{{ 'add-answer-name-' + i }}" [hidden]="true">Answer: (required)</label>
              <input type="text" id="{{ 'add-answer-name-' + i }}" name="add-name" class="answer-input-text form-control" [(ngModel)]="newAnswer.name" required="true" maxlength="15" (change)="onNewAnswerChange(newAnswer, i)" [disabled]="denyEdit">
            </div>
            <div *ngIf="!isNewAnswerUnique(newAnswer.name, i)" class="alert alert-danger">
              The Answer must be unique.
            </div>
          </td>
          <td style="width:24%"></td>
          <td style="width:24%"></td>
          <td style="width:8%">
            <div class="form-group form-check m-2 d-inline-block align-middle">
              <label for="{{ 'add-answer-note-required-' + i }}" [hidden]="true" class="form-check-label">Note: (required)</label>
              <input type="checkbox" id="{{ 'add-answer-note-required-' + i }}" [(ngModel)]="newAnswer.noteRequired" (change)="onNewAnswerChange(newAnswer, i)" name="{{ 'add-note-required-' + i }}" class="form-check-input" [disabled]="denyEdit">
            </div>
          </td>
          <td style="width:8%">
            <div class="form-group form-check m-2 d-inline-block align-middle">
              <label for="{{ 'add-answer-photo-required-' + i }}" [hidden]="true" class="form-check-label">Photo: (required)</label>
              <input type="checkbox" id="{{ 'add-answer-photo-required-' + i }}" [(ngModel)]="newAnswer.photoRequired" (change)="onNewAnswerChange(newAnswer, i)" name="{{ 'add-photo-required-' + i }}" class="form-check-input" [disabled]="denyEdit">
            </div>
          </td>
          <td style="width:12%">
            <div class="form-group form-check m-2 d-inline-block align-middle">
              <label for="{{ 'add-answer-attachment-required-' + i }}" [hidden]="true" class="form-check-label">Attachment: (required)</label>
              <input type="checkbox" id="{{ 'add-answer-attachment-required-' + i }}" [(ngModel)]="newAnswer.attachmentRequired" (change)="onNewAnswerChange(newAnswer, i)" name="{{ 'add-attachment-required-' + i }}" class="form-check-input" [disabled]="denyEdit">
            </div>
          </td>
          <td style="width:24%">
            <div class="form-group m-2">
              <label for="{{ 'add-answer-note-label-' + i }}" [hidden]="true">Note Label Override: (required)</label>
              <input type="text" id="{{ 'add-answer-note-label-' + i }}" name="add-note-label" class="answer-input-text form-control" [(ngModel)]="newAnswer.noteLabelOverride" maxlength="30" (change)="onNewAnswerChange(newAnswer, i)" [disabled]="denyEdit">
            </div>
          </td>
          <td style="width:20%">
            <button type="button" class="btn btn-outline-dark btn-sm py-0" (click)="deleteNewAnswer(i)" attr.aria-label="Delete new answer {{ i + 1 }}">Delete New Answer</button>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="(answers == null || answers.length == 0) && (newAnswers == null || newAnswers.length == 0)">
        <tr>
          <td colspan="4">
            No Answers Found.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #contentElementCode let-modal>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-select-element-code-title">Select Element Code</h2>
    <button type="button" class="close" aria-label="Close" (click)="cancelSelectElement()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form id="form-select-element-code" ngNativeValidate>
      <div class="row">
        <div class="col-md-6 col-sm-12 form-group">
          <label for="section-type">Section:</label>
          <select id="section-type"
                  class="form-control"
                  [(ngModel)]="selectedSectionType"
                  name="section-type"
                  #category="ngModel"
                  (change)="onSectionTypeSelect()">
            <option *ngFor="let type of sectionTypes" value="{{ type }}">{{ type }}</option>
          </select>
        </div>
      </div>
      <div *ngIf="selectedSectionType" class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-sm" id="edit-answers-findings-list-table">
              <thead class="thead-light">
                <tr class="d-flex">
                  <th scope="col" class="col-1"><span id="finding-select-text">Select</span></th>
                  <th scope="col" class="col-1">Code</th>
                  <th scope="col" class="col-2">Type</th>
                  <th scope="col" class="col-6">Description / Recommendation</th>
                  <th scope="col" class="col-2">Sanitary Defect</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let finding of filteredFindings; let i = index" class="d-flex">
                  <td class="col-1">
                    <div class="form-check">
                      <input type="radio" name="finding-select-radio" [(ngModel)]="selectedFinding" [value]="finding" id="{{ 'finding-select-radio-' + i }}" class="form-check-input" aria-labelledby="finding-select-text">
                      <label for="{{ 'finding-select-radio-' + i }}" [hidden]="true" class="form-check-label" class="form-check-label">Select</label>
                    </div>
                  </td>
                  <td class="col-1">{{ finding.code }}</td>
                  <td class="col-2 white-space-break-spaces">{{ finding.description }}</td>
                  <td class="col-6 white-space-break-spaces">{{ finding.language }}</td>
                  <td class="col-2">
                    <span *ngIf="finding.type === findingTypes.DEFICIENCY">{{ finding.sanitaryDefectIndicator === 'T' ? 'True' : 'False' }}</span>
                    <span *ngIf="finding.type !== findingTypes.DEFICIENCY">N/A</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" (click)="selectElementCode()" [disabled]="!selectedFinding">Select</button>
    <button type="button" class="btn btn-outline-secondary ml-4" (click)="cancelSelectElement()">Cancel</button>
  </div>
</ng-template>

<ng-template #contentLanguageModal let-modal>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-selected-element-language-title">Element Language</h2>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('cancel click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div id="finding-selected-language" class="white-space-break-spaces">
      {{ elementLanguage }}
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.dismiss('cancel click')">Close</button>
  </div>
</ng-template>
