import { Component, Input, OnInit } from "@angular/core";
import { Question } from "src/app/domain/question";
import {getQuestionByUuid, Questionnaire} from "src/app/domain/questionnaire";

@Component({
  selector: "app-questionniare-summary",
  templateUrl: "./questionniare-summary.component.html",
  styleUrls: ["./questionniare-summary.component.scss"],
})
export class QuestionniareSummaryComponent implements OnInit {

  @Input() questionnaire: Questionnaire;
  @Input() waterSystemId: number;
  @Input() siteVisitId: number;
  @Input() questionnaireId: string;
  @Input() isQuestionnaireCollapsed = false;

  constructor() {}

  ngOnInit(): void {}

  showQuestion(question: Question): boolean {
    if (!question.questionActiveInd) {
      return false;
    }
    if (question.value
      ||
      (question.required
        && (!question.prerequisiteQuestionUuid
          || (question.prerequisiteAnswer
            && question.prerequisiteAnswer === getQuestionByUuid(this.questionnaire,
              question.prerequisiteQuestionUuid).value)))) {
      return true;
    } else {
      return false;
    }
  }
  get hasAnswers() {
    if (!this.questionnaire.activeInd){
      return false;
    } else {
      return this.questionnaire.question.find(q => q.value !== undefined) !== undefined;
    }
  }
}
