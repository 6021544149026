import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, concatMap, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Checkout } from '../domain/checkout';
import { DistrictContact } from '../domain/district-contact';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { LocalStorageService } from '../local-storage.service';
import { tables } from '../utils/app-db';

@Injectable({
  providedIn: 'root'
})
export class WaterSystemDistrictContactService {

  private readonly WATER_SYSTEM_URL = environment.serverUrl + '/watersystem';
  private readonly DISTRICT_CONTACT_SUB_ENDPOINT = 'district';
  private readonly STORAGE_KEY = 'WATER_SYSTEM_DISTRICT_CONTACT';

  constructor(private http: HttpClient,
              private connectivityService: ConnectivityService,
              private localStorageService: LocalStorageService) { }

  /**
   * Returns water system district contact saved locally
   * If none found, then will be check-out remotely if online
   * @param id of the water system
   */
  find(id: number): Observable<Checkout<DistrictContact>> {
    return new Observable<Checkout<DistrictContact>>(obs => {
      this.findLocally(id).subscribe(wsLocal => {
        if (wsLocal) {
          obs.next(wsLocal);
          obs.complete();
        } else {
          if (this.connectivityService.isOnline()) {
            this.findRemotelyTimer(id).subscribe (wsRemote => {
              obs.next(wsRemote);
              obs.complete();
            }, error => {
              obs.error(error);
            });
          } else {
            obs.complete();
          }
        }
      });
    });
  }

  findLocally(id: number): Observable<Checkout<DistrictContact>> {
    return this.localStorageService.dbRetrieve<Checkout<DistrictContact>>(tables.waterSystemDistrictContact, id);
  }

  private findRemotely(id: number): Observable<Checkout<DistrictContact>> {
    return this.http.get<Checkout<DistrictContact>>(`${this.WATER_SYSTEM_URL}/${id}/${this.DISTRICT_CONTACT_SUB_ENDPOINT}`);
  }

  private findRemotelyTimer(id: number): Observable<Checkout<DistrictContact>> {
    const stopTimer$ = timer(10 * 60 * 1000);
    return timer(0, 15000)
      .pipe(
        takeUntil(stopTimer$),
        concatMap(() => this.findRemotely(id).pipe(map(response => response))),
        filter(backendData => backendData.dataReady),
        take(1),
        tap(found => {
          this.store(id, found);
          return found;
        }),
        catchError(error => {
          if (error.status === 404) {
            this.store(id, error.error);
            return of(error.error);
          } else {
            return throwError(error);
          }
        })
      );
  }

  private store(id: number, waterSystemDistrictContact: Checkout<DistrictContact>): void {
    this.localStorageService.dbStoreStorage(tables.waterSystemDistrictContact, id, waterSystemDistrictContact);
  }

  deleteLocally(id: number): void {
    this.localStorageService.dbRemove(tables.waterSystemDistrictContact, id);
  }
}
