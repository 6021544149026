import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer, of, throwError } from 'rxjs';
import { takeUntil, concatMap, catchError, map, filter, take, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { Checkout } from '../domain/checkout';
import { FacilityAny, Interconnect } from '../domain/facility';
import { SyncType } from '../domain/sync';
import { LocalStorageService } from '../local-storage.service';
import { tables } from '../utils/app-db';
import { SyncResultFactory } from '../utils/sync-result-factory';
import { SyncSchedulerService } from './sync-scheduler.service';

@Injectable({
  providedIn: 'root'
})
export class WaterSystemInterconnectFacilityService {

  private readonly WATER_SYSTEM_URL = environment.serverUrl + '/watersystem';
  private readonly INTERCONNECT_SUB_ENDPOINT = 'consecutiveconnection';
  private readonly INTERCONNECT_FACILITY_CODE = 'CC';

  constructor(private connectivityService: ConnectivityService,
              private httpClient: HttpClient,
              private localStorageService: LocalStorageService,
              private syncSchedulerService: SyncSchedulerService) { }

  /**
   * Returns a water system's interconnect facilities.
   *
   * @param id the id of the water system
   */
  find(id: number): Observable<Interconnect[]> {
    return new Observable<Interconnect[]>(obs => {
      this.findLocally(id).subscribe(wsLocal => {
        if (wsLocal) {
          obs.next(wsLocal);
          obs.complete();
        } else {
          if (this.connectivityService.isOnline()) {
            this.findRemotelyTimer(id).subscribe (wsRemote => {
              obs.next(wsRemote);
              obs.complete();
            }, error => {
              obs.error(error);
            });
          } else {
            obs.complete();
          }
        }
      });
    });
  }

  /**
   * Returns one interconnect facility saved locally.
   *
   * @param waterSystemId the water system id
   * @param facilityId the facility id
   */
  findOne(waterSystemId: number, facilityId: number): Observable<Interconnect> {
    return new Observable<Interconnect>(obs => {
      this.findOneLocally(waterSystemId, facilityId).subscribe(wsLocal => {
        if (wsLocal && wsLocal.length > 0) {
          obs.next(wsLocal[0]);
          obs.complete();
        } else {
          obs.next(null);
          obs.complete();
        }
      });
    });
  }

  findLocally(id: number): Observable<Interconnect[]> {
    return new Observable<Interconnect[]>(obs => {
      this.localStorageService.dbRetrieveAllByIndex<Interconnect>(tables.waterSystemInterconnectFacility, 'pwsId', id)
        .pipe(
          switchMap(local => {
            if (local && local.length > 0) {
              return of(local);
            } else {
              return this.localStorageService.dbRetrieveAllByIndex<any>(tables.waterSystemZeroFacilitiesOfType,
                'pwsIdFacilityCode', [id, this.INTERCONNECT_FACILITY_CODE])
                .pipe(
                  map(l => {
                    if (l && l.length > 0) {
                      return l;
                    } else {
                      return null;
                    }
                  })
                );
            }
          })
        ).subscribe(local => {
          obs.next(local);
          obs.complete();
        });
      });
  }

  findOneLocallyByKey(key: number): Observable<Interconnect> {
    return this.localStorageService.dbRetrieve<Interconnect>(tables.waterSystemInterconnectFacility, key);
  }

  private findOneLocally(waterSystemId: number, facilityId: number): Observable<Interconnect[]> {
    return this.localStorageService.dbRetrieveAllByIndex<Interconnect>(tables.waterSystemInterconnectFacility,
      'pwsIdFacilityId', [waterSystemId, facilityId]);
  }

  private findRemotely(id: number): Observable<Checkout<Interconnect[]>> {
    return this.httpClient.get<Checkout<any[]>>(`${this.WATER_SYSTEM_URL}/${id}/${this.INTERCONNECT_SUB_ENDPOINT}`);
  }

  private findRemotelyTimer(id: number): Observable<Interconnect[]> {
    const stopTimer$ = timer(10 * 60 * 1000);
    return timer(0, 15000)
      .pipe(
        takeUntil(stopTimer$),
        concatMap(() => this.findRemotely(id).pipe(map(response => response))),
        filter(backendData => backendData.dataReady),
        take(1),
        map(found => {
          const facilities: Interconnect[] = this.mapToInterconnectFacilities(found.data);
          this.store(facilities);
          if (found.data == null || found.data.length === 0) {
            this.localStorageService.dbStore(tables.waterSystemZeroFacilitiesOfType,
              { 'pwsId': id, 'facilityCode': this.INTERCONNECT_FACILITY_CODE});
          }
          return facilities;
        }),
        catchError(error => {
          if (error.status === 404) {
            this.localStorageService.dbStore(tables.waterSystemZeroFacilitiesOfType,
              { 'pwsId': id, 'facilityCode': this.INTERCONNECT_FACILITY_CODE});
            return of(error.error);
          } else {
            return throwError(error);
          }
        })
      );
  }

  private mapToInterconnectFacilities(results: any[]): Interconnect[] {
    const facilities: Interconnect[] = [];
    if (results && results.length > 0) {
      for (const result of results) {
        const facility: Interconnect = {
          pwsId: result.pwsId,
          facilityId: result.facilityId,
          name: result.name,
          facilityCode: result.facilityCode,
          availabilityCode: result.information.availabilityCode,
          statusCode: result.information.statusCode,
          statusReason: result.information.statusReason,
          downstreamFacility: result.information.downstreamFacility,
          facilitySeqNo: result.information.id,
          surveyNote: result.information.surveyNote,
          designCapacity: result.information.designCapacity,
          dcUnitMeasureCode: result.information.dcUnitMeasureCode,
          emergencyCapacity: result.information.emergencyCapacity,
          ecUnitMeasureCode: result.information.ecUnitMeasureCode
        };
        facilities.push(facility);
      }
    }
    return facilities;
  }

  store(interconnectFacilities: Interconnect[]): void {
    if (interconnectFacilities && interconnectFacilities.length > 1) {
      interconnectFacilities = interconnectFacilities.sort((a, b) => {
        if (a.facilityId > b.facilityId) {
          return 1;
        } else if (a.facilityId < b.facilityId) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    interconnectFacilities.forEach(interconnect => {
      this.localStorageService.dbStore(tables.waterSystemInterconnectFacility, interconnect);
    });
  }

  deleteLocally(id: number): void {
    this.localStorageService.dbRetrieveAllByIndex<Interconnect>(tables.waterSystemInterconnectFacility, 'pwsId', id).subscribe(results => {
      if (results) {
        for (const facility of results) {
          this.localStorageService.dbRemove(tables.waterSystemInterconnectFacility, facility.facilitySeqNo);
        }
      }
    });
    this.localStorageService.dbRetrieveAllByIndex<any>(tables.waterSystemZeroFacilitiesOfType, 'pwsId', id).subscribe(results => {
      if (results) {
        for (const result of results) {
          this.localStorageService.dbRemove(tables.waterSystemZeroFacilitiesOfType, result.key);
        }
      }
    });
  }

  insertRemotely(interconnect: Interconnect): Observable<any> {
    const remoteFacility = {
      facilityId: interconnect.facilityId,
      facilityCode: interconnect.facilityCode,
      name: interconnect.name,
      pwsId: interconnect.pwsId,
      information: {
        id: interconnect.facilitySeqNo,
        waterSystemId: interconnect.pwsId,
        name: interconnect.name,
        facilityCode: interconnect.facilityCode,
        availabilityCode: interconnect.availabilityCode,
        statusCode: interconnect.statusCode,
        statusReason: interconnect.statusReason,
        downstreamFacility: interconnect.downstreamFacility,
        surveyNote: interconnect.surveyNote,
        designCapacity: interconnect.designCapacity,
        dcUnitMeasureCode: interconnect.dcUnitMeasureCode,
        emergencyCapacity: interconnect.emergencyCapacity,
        ecUnitMeasureCode: interconnect.ecUnitMeasureCode
      }
    };
    return this.httpClient.post(`${this.WATER_SYSTEM_URL}/${interconnect.pwsId}/${this.INTERCONNECT_SUB_ENDPOINT}`, remoteFacility);
  }

  updateRemotely(interconnect: Interconnect): Observable<any> {
    const remoteFacility = {
      facilityId: interconnect.facilityId,
      facilityCode: interconnect.facilityCode,
      name: interconnect.name,
      pwsId: interconnect.pwsId,
      information: {
        id: interconnect.facilitySeqNo,
        waterSystemId: interconnect.pwsId,
        name: interconnect.name,
        facilityCode: interconnect.facilityCode,
        availabilityCode: interconnect.availabilityCode,
        statusCode: interconnect.statusCode,
        statusReason: interconnect.statusReason,
        downstreamFacility: interconnect.downstreamFacility,
        surveyNote: interconnect.surveyNote,
        designCapacity: interconnect.designCapacity,
        dcUnitMeasureCode: interconnect.dcUnitMeasureCode,
        emergencyCapacity: interconnect.emergencyCapacity,
        ecUnitMeasureCode: interconnect.ecUnitMeasureCode
      }
    };
    return this.httpClient.put(`${this.WATER_SYSTEM_URL}/${interconnect.pwsId}/`
      + `${this.INTERCONNECT_SUB_ENDPOINT}/${interconnect.facilityId}`, remoteFacility);
  }

  deleteOne(facility: FacilityAny): Observable<any> {
    if (this.connectivityService.isOnline()) {
      this.deleteOneLocally(facility.facilitySeqNo);
      return this.deleteRemotely(facility);
    } else {
      this.syncSchedulerService.schedule(SyncType.FacilityInterconnectDelete, facility.facilitySeqNo).subscribe();
    }
  }

  deleteOneLocally(id: number): void {
    this.localStorageService.dbRemove(tables.waterSystemInterconnectFacility, id);
  }

  private deleteRemotely(facility: FacilityAny): Observable<any> {
    return this.httpClient.delete(`${this.WATER_SYSTEM_URL}/${facility.pwsId}/${this.INTERCONNECT_SUB_ENDPOINT}/${facility.facilityId}`);
  }

  syncFacilityInterconnectDelete(): void {
    if (this.connectivityService.isOnline) {
      if (this.connectivityService.isOnline()) {
        this.syncSchedulerService.scheduled(SyncType.FacilityInterconnectDelete).subscribe(syncRequests => {
          syncRequests.forEach(syncRequest => {
            this.findOneLocallyByKey(syncRequest.data.id).subscribe(facility => {
              if (facility) {
                this.deleteRemotely(facility).subscribe(() => {
                  this.syncSchedulerService.unschedule(SyncType.FacilityInterconnectDelete, syncRequest.data.id,
                      SyncResultFactory.synced(SyncType.FacilityInterconnectDelete, syncRequest.data.id));
                  this.deleteOneLocally(facility.facilitySeqNo);
                }, error => {
                  this.syncSchedulerService.unschedule(SyncType.FacilityInterconnectDelete, syncRequest.data.id,
                      SyncResultFactory.errorHttp(SyncType.FacilityInterconnectDelete, syncRequest.data.id, error));
                });
              } else {
                this.syncSchedulerService.unschedule(SyncType.FacilityInterconnectDelete, syncRequest.data.id,
                  SyncResultFactory.synced(SyncType.FacilityInterconnectDelete, syncRequest.data.id));
              }
            });
          });
        });
      }
    }
  }
}
