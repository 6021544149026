import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-report-summary-view",
  templateUrl: "./report-summary-view.component.html"
})
export class ReportSummaryViewComponent implements OnInit {
  ngOnInit(): void {
  }
}
