import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, concatMap, map, take, takeUntil, filter, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { LocalStorageService } from '../local-storage.service';
import { tables } from '../utils/app-db';
import { Checkout } from '../domain/checkout';
import { FacilityAny, Well } from '../domain/facility';
import { SyncSchedulerService } from './sync-scheduler.service';
import { SyncType } from '../domain/sync';
import { SyncResultFactory } from '../utils/sync-result-factory';

@Injectable({
  providedIn: 'root'
})
export class WaterSystemWellsService {

  private readonly WATER_SYSTEM_URL = environment.serverUrl + '/watersystem';
  private readonly WELLS_SUB_ENDPOINT = 'well';
  private readonly WELLS_FACILITY_CODE = 'WL';

  constructor(private connectivityService: ConnectivityService,
              private httpClient: HttpClient,
              private localStorageService: LocalStorageService,
              private syncSchedulerService: SyncSchedulerService) { }

  /**
   * Returns a water system's wells.
   *
   * @param id the id of the water system
   */
  find(id: number): Observable<Well[]> {
    return new Observable<Well[]>(obs => {
      this.findLocally(id).subscribe(wsLocal => {
        if (wsLocal) {
          obs.next(wsLocal);
          obs.complete();
        } else {
          if (this.connectivityService.isOnline()) {
            this.findRemotelyTimer(id).subscribe (wsRemote => {
              obs.next(wsRemote);
              obs.complete();
            }, error => {
              obs.error(error);
            });
          } else {
            obs.complete();
          }
        }
      });
    });
  }

  /**
   * Returns one well facility saved locally.
   *
   * @param waterSystemId the water system id
   * @param facilityId the facility id
   */
  findOne(waterSystemId: number, facilityId: number): Observable<Well> {
    return new Observable<Well>(obs => {
      this.findOneLocally(waterSystemId, facilityId).subscribe(wsLocal => {
        if (wsLocal && wsLocal.length > 0) {
          obs.next(wsLocal[0]);
          obs.complete();
        } else {
          obs.next(null);
          obs.complete();
        }
      });
    });
  }

  findLocally(id: number): Observable<Well[]> {
    return new Observable<Well[]>(obs => {
      this.localStorageService.dbRetrieveAllByIndex<Well>(tables.waterSystemWell, 'pwsId', id)
        .pipe(
          switchMap(local => {
            if (local && local.length > 0) {
              return of(local);
            } else {
              return this.localStorageService.dbRetrieveAllByIndex<any>(tables.waterSystemZeroFacilitiesOfType,
                'pwsIdFacilityCode', [id, this.WELLS_FACILITY_CODE])
                .pipe(
                  map(l => {
                    if (l && l.length > 0) {
                      return l;
                    } else {
                      return null;
                    }
                  })
                );
            }
          })
        ).subscribe(local => {
          obs.next(local);
          obs.complete();
        });
      });
  }

  findOneLocallyByKey(key: number): Observable<Well> {
    return this.localStorageService.dbRetrieve<Well>(tables.waterSystemWell, key);
  }

  private findOneLocally(waterSystemId: number, facilityId: number): Observable<Well[]> {
    return this.localStorageService.dbRetrieveAllByIndex<Well>(tables.waterSystemWell, 'pwsIdFacilityId', [waterSystemId, facilityId]);
  }

  private findRemotely(id: number): Observable<Checkout<any[]>> {
    return this.httpClient.get<Checkout<any[]>>(`${this.WATER_SYSTEM_URL}/${id}/${this.WELLS_SUB_ENDPOINT}`);
  }

  private findRemotelyTimer(id: number): Observable<Well[]> {
    const stopTimer$ = timer(10 * 60 * 1000);
    return timer(0, 15000)
      .pipe(
        takeUntil(stopTimer$),
        concatMap(() => this.findRemotely(id).pipe(map(response => response))),
        filter(backendData => backendData.dataReady),
        take(1),
        map(found => {
          const wells: Well[] = this.mapToWell(found.data);
          this.store(wells);
          if (found.data == null || found.data.length === 0) {
            this.localStorageService.dbStore(tables.waterSystemZeroFacilitiesOfType,
              { 'pwsId': id, 'facilityCode': this.WELLS_FACILITY_CODE});
          }
          return wells;
        }),
        catchError(error => {
          if (error.status === 404) {
            this.localStorageService.dbStore(tables.waterSystemZeroFacilitiesOfType,
              { 'pwsId': id, 'facilityCode': this.WELLS_FACILITY_CODE});
            return of(error.error);
          } else {
            return throwError(error);
          }
        })
      );
  }

  private mapToWell(results: any[]): Well[] {
    const wells: Well[] = [];
    if (results && results.length > 0) {
      for (const result of results) {
        const well: Well = {
          pwsId: result.pwsId,
          facilityId: result.facilityId,
          name: result.name,
          facilityCode: result.information.facilityCode,
          availabilityCode: result.information.availabilityCode,
          statusCode: result.information.statusCode,
          downstreamFacility: result.information.downstreamFacility,
          facilitySeqNo: result.information.id,
          samplePointId: result.id,
          designCapacity: result.information.designCapacity,
          dcUnitMeasureCode: result.information.dcUnitMeasureCode,
          emergencyCapacity: result.information.emergencyCapacity,
          ecUnitMeasureCode: result.information.ecUnitMeasureCode,
          statusReason: result.information.statusReason,
          uniqueWellNbr: result.information.well?.uniqueWellNbr,
          yearInstalledDate: result.information.well?.yearInstalledDate,
          yearLastRehabilitated: result.information.well?.yearLastRehabilitated,
          depth: result.information.well?.depth,
          caseDiameter: result.information.well?.caseDiameter,
          caseDepth: result.information.well?.caseDepth,
          screenLength: result.information.well?.screenLength,
          depthStatic: result.information.well?.depthStatic,
          pumpCode: result.information.well?.pumpCode,
          pumpCapacity: result.information.well?.pumpCapacity,
          pumpingRate: result.information.well?.pumpingRate,
          rateSourceCode: result.information.well?.rateSourceCode,
          drawdown: result.information.well?.drawdown,
          surveyNote: result.information.surveyNote,
          iwmzUrl: result.iwmzUrl,
          iwmzPath: result.iwmzPath,
          iwmzPcsi: result.information.iwmzPcsi,
          existingMeasures: result.information.existingMeasures,
          newMeasures: result.information.newMeasures
        };
        wells.push(well);
      }
    }
    return wells;
  }

  store(wells: Well[], storeFile: boolean = true): void {
    if (wells && wells.length > 1) {
      wells = wells.sort((a, b) => {
        if (a.facilityId > b.facilityId) {
          return 1;
        } else if (a.facilityId < b.facilityId) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    wells.forEach(well => {
      this.localStorageService.dbStore(tables.waterSystemWell, well);
      if (storeFile && well.uniqueWellNbr != null) {
        this.localStorageService.storeFile(well.iwmzUrl, this.generateFilename(well));
      }
    });
  }

  generateFilename(well: Well) {
    return `${well.pwsId}-${well.samplePointId}.pdf`;
  }

  deleteLocally(id: number): void {
    this.localStorageService.dbRetrieveAllByIndex<Well>(tables.waterSystemWell, 'pwsId', id).subscribe(results => {
      if (results) {
        for (const well of results) {
          this.localStorageService.dbRemove(tables.waterSystemWell, well.facilitySeqNo);
          this.localStorageService.dbRemove(tables.file, this.generateFilename(well));
        }
      }
    });
    this.localStorageService.dbRetrieveAllByIndex<any>(tables.waterSystemZeroFacilitiesOfType, 'pwsId', id).subscribe(results => {
      if (results) {
        for (const result of results) {
          this.localStorageService.dbRemove(tables.waterSystemZeroFacilitiesOfType, result.key);
        }
      }
    });
  }

  displayWellPdf(well: Well) {
    this.localStorageService.retrieveFile(this.generateFilename(well)).subscribe(result => {
      const url = window.URL.createObjectURL(result.file);
      window.open(url);
    });
  }

  insertRemotely(well: Well): Observable<any> {
    const remoteFacility = {
      id: 'NEW' + well.facilityId,
      facilityId: well.facilityId,
      facilityCode: well.facilityCode,
      name: well.name,
      pwsId: well.pwsId,
      iwmzPath: well.iwmzPath,
      information: {
        id: well.facilitySeqNo,
        waterSystemId: well.pwsId,
        name: well.name,
        facilityCode: well.facilityCode,
        availabilityCode: well.availabilityCode,
        statusCode: well.statusCode,
        statusReason: well.statusReason,
        downstreamFacility: well.downstreamFacility,
        surveyNote: well.surveyNote,
        iwmzPcsi: well.iwmzPcsi,
        existingMeasures: well.existingMeasures,
        newMeasures: well.newMeasures,
        designCapacity: well.designCapacity,
        dcUnitMeasureCode: well.dcUnitMeasureCode,
        emergencyCapacity: well.emergencyCapacity,
        ecUnitMeasureCode: well.ecUnitMeasureCode,
        well: {
          pumpingRate: well.pumpingRate
        }
      }
    };
    return this.httpClient.post(`${this.WATER_SYSTEM_URL}/${well.pwsId}/${this.WELLS_SUB_ENDPOINT}`, remoteFacility);
  }

  updateRemotely(well: Well): Observable<any> {
    const remoteFacility = {
      id: well.samplePointId,
      facilityId: well.facilityId,
      facilityCode: well.facilityCode,
      name: well.name,
      pwsId: well.pwsId,
      iwmzPath: well.iwmzPath,
      information: {
        id: well.facilitySeqNo,
        waterSystemId: well.pwsId,
        name: well.name,
        facilityCode: well.facilityCode,
        availabilityCode: well.availabilityCode,
        statusCode: well.statusCode,
        statusReason: well.statusReason,
        downstreamFacility: well.downstreamFacility,
        designCapacity: well.designCapacity,
        dcUnitMeasureCode: well.dcUnitMeasureCode,
        emergencyCapacity: well.emergencyCapacity,
        ecUnitMeasureCode: well.ecUnitMeasureCode,
        surveyNote: well.surveyNote,
        iwmzUrl: well.iwmzUrl,
        iwmzPath: well.iwmzPath,
        well: {
          uniqueWellNbr: well.uniqueWellNbr,
          yearInstalledDate: well.yearInstalledDate,
          yearLastRehabilitated: well.yearLastRehabilitated,
          depth: well.depth,
          caseDiameter: well.caseDiameter,
          caseDepth: well.caseDepth,
          screenLength: well.screenLength,
          depthStatic: well.depthStatic,
          pumpCode: well.pumpCode,
          pumpCapacity: well.pumpCapacity,
          pumpingRate: well.pumpingRate,
          rateSourceCode: well.rateSourceCode,
          drawdown: well.drawdown
        },
        iwmzPcsi: well.iwmzPcsi,
        existingMeasures: well.existingMeasures,
        newMeasures: well.newMeasures
      }
    };
    return this.httpClient.put(`${this.WATER_SYSTEM_URL}/${well.pwsId}/${this.WELLS_SUB_ENDPOINT}/${well.facilityId}`, remoteFacility);
  }

  deleteOne(facility: FacilityAny): Observable<any> {
    if (this.connectivityService.isOnline()) {
      this.deleteOneLocally(facility.facilitySeqNo);
      return this.deleteRemotely(facility);
    } else {
      this.syncSchedulerService.schedule(SyncType.FacilityWellDelete, facility.facilitySeqNo).subscribe();
    }
  }

  deleteOneLocally(id: number): void {
    this.localStorageService.dbRemove(tables.waterSystemWell, id);
  }

  private deleteRemotely(facility: FacilityAny): Observable<any> {
    return this.httpClient.delete(`${this.WATER_SYSTEM_URL}/${facility.pwsId}/${this.WELLS_SUB_ENDPOINT}/${facility.facilityId}`);
  }

  syncFacilityWellDelete(): void {
    if (this.connectivityService.isOnline) {
      if (this.connectivityService.isOnline()) {
        this.syncSchedulerService.scheduled(SyncType.FacilityWellDelete).subscribe(syncRequests => {
          syncRequests.forEach(syncRequest => {
            this.findOneLocallyByKey(syncRequest.data.id).subscribe(facility => {
              if (facility) {
                this.deleteRemotely(facility).subscribe(() => {
                  this.syncSchedulerService.unschedule(SyncType.FacilityWellDelete, syncRequest.data.id,
                      SyncResultFactory.synced(SyncType.FacilityWellDelete, syncRequest.data.id));
                  this.deleteOneLocally(facility.facilitySeqNo);
                }, error => {
                  this.syncSchedulerService.unschedule(SyncType.FacilityWellDelete, syncRequest.data.id,
                      SyncResultFactory.errorHttp(SyncType.FacilityWellDelete, syncRequest.data.id, error));
                });
              } else {
                this.syncSchedulerService.unschedule(SyncType.FacilityWellDelete, syncRequest.data.id,
                  SyncResultFactory.synced(SyncType.FacilityWellDelete, syncRequest.data.id));
              }
            });
          });
        });
      }
    }
  }
}
