import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from '..//environments/environment';
import { DocumentService } from './document.service';
import { Profile } from './domain/profile';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private readonly PROFILE_URL = environment.serverUrl + '/profile';
  private readonly USER_STORAGE_PATH = 'users';
  private readonly PROFILE_STORAGE_PATH = 'profile';
  private readonly SIGNATURE_STORAGE_PATH = 'signature';

  constructor(
    private httpClient: HttpClient,
    private documentService: DocumentService
  ) { }

  getProfile(): Promise<Profile> {
    return this.httpClient.get<Profile>(this.PROFILE_URL).toPromise();
  }

  saveProfile(profile: Profile, signatureFile: File): Observable<any> {
    let storagePath = this.generateStoragePath(signatureFile, profile);
    profile.profile.signatureUrl = storagePath;
    return new Observable<any>(obs => {
      this.documentService
        .getUploadUrl(storagePath)
        .pipe(
          mergeMap((documentUrlRequest) =>
            this.httpClient.put<any>(
                documentUrlRequest.s3SecureUrl,
                signatureFile,
                { headers: { "Content-Type": signatureFile.type } }
            )
          )
        )
        .subscribe(() => {
          profile.profile.phoneNumber = profile.profile.phoneNumber.replace(/\D+/g, '');
          this.httpClient.post(this.PROFILE_URL, profile).subscribe(
            () => {
              obs.next();
            }
          )
        });
    });
  }

  downloadSignature(profile: Profile) {
    return new Observable<File>(obs => {
      this.documentService
        .getDownloadUrl(profile.profile.signatureUrl)
        .pipe(
          mergeMap((documentUrlRequest) =>
            this.httpClient.get(documentUrlRequest.s3SecureUrl, {
              responseType: "blob" as "json",
            })
          )
        )
        .subscribe((response: any) => {
          if (response) {
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let fileBlob: Blob = new Blob(binaryData, { type: dataType });
            let file = new File([fileBlob], profile.profile.signatureUrl.substring(profile.profile.signatureUrl.lastIndexOf('/') + 1), {
              type: dataType,
            });
            obs.next(file);
            obs.complete();
          } else {
            obs.complete();
          }
        });
    });
  }

  generateStoragePath(signatureFile: File, profile: Profile): string {
    let extension = signatureFile.name.substring(signatureFile.name.lastIndexOf('.'));
    return `${this.USER_STORAGE_PATH}/${profile.username}/${this.PROFILE_STORAGE_PATH}/${this.SIGNATURE_STORAGE_PATH}/${'signature'}${extension}`;
  }
}
