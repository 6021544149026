import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer, throwError, of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { LocalStorageService } from '../local-storage.service';
import {
  Facility,
  Well,
  SurfaceWaterFacility,
  StorageFacility,
  TreatmentPlant,
  DistributionFacility,
  BoosterStation,
  Interconnect,
  FacilityAny,
  isTreatmentPlant,
  isBoosterStation,
  isWell,
  isSurfaceWater,
  isStorageFacility,
  isDistributionFacility,
  isInterconnect
} from '../domain/facility';
import { tables } from '../utils/app-db';
import { FacilityFlow } from '../domain/facility-flow';
import { FacilityFlowGraph } from '../domain/facility-flow-graph';
import { FacilityTypePipe } from '../shared/facility-type.pipe';
import { FlowUtilityService } from './facilities/flow-utility.service';
import { Checkout } from '../domain/checkout';
import { WaterSystemWellsService } from './water-system-wells.service';
import { WaterSystemSurfaceWaterFacilityService } from './water-system-surface-water-facility.service';
import { WaterSystemTreatmentFacilitiesService } from './water-system-treatment-facility.service';
import { WaterSystemStorageFacilitiesService } from './water-system-storage-facility.service';
import { WaterSystemDistributionFacilityService } from './water-system-distribution-facility.service';
import { WaterSystemBoosterStationFacilityService } from './water-system-booster-station-facility.service';
import { WaterSystemInterconnectFacilityService } from './water-system-interconnect-facility.service';
import { SyncSchedulerService } from './sync-scheduler.service';
import { SyncType } from '../domain/sync';
import { SyncResultFactory } from '../utils/sync-result-factory';
import { IdbStorage } from '../domain/storage';
import { WellsService } from './wells/wells.service';
import { DistributionFacilityService } from './distribution/distribution-facility.service';
import { StorageFacilityService } from './storage/storage-facility.service';
import { TreatmentService } from './treatment/treatment.service';
import { SurfaceWaterFacilityService } from './surface-water/surface-water-facility.service';
import { InterconnectFacilityService } from './booster-station/interconnect-facility.service';
import { BoosterStationFacilityService } from './booster-station/booster-station-facility.service';

@Injectable({
  providedIn: 'root',
})
export class WaterSystemFacilitiesService {
  private readonly WATER_SYSTEM_URL = environment.serverUrl + '/watersystem';
  private readonly FLOW_SUB_ENDPOINT = 'flow';
  private readonly FLOW_FACILITY_ID_QUERY_PARAM = 'facilityId';

  constructor(
    private http: HttpClient,
    private connectivityService: ConnectivityService,
    private localStorageService: LocalStorageService,
    private facilityTypePipe: FacilityTypePipe,
    private flowUtilityService: FlowUtilityService,
    private waterSystemWellsService: WaterSystemWellsService,
    private waterSystemSurfaceWaterFacilityService: WaterSystemSurfaceWaterFacilityService,
    private waterSystemTreatmentFacilitiesService: WaterSystemTreatmentFacilitiesService,
    private waterSystemStorageFacilitiesService: WaterSystemStorageFacilitiesService,
    private waterSystemDistributionFacilityService: WaterSystemDistributionFacilityService,
    private waterSystemBoosterStationFacilityService: WaterSystemBoosterStationFacilityService,
    private waterSystemInterconnectFacilityService: WaterSystemInterconnectFacilityService,
    private syncSchedulerService: SyncSchedulerService,
    private wellsService: WellsService,
    private distributionService: DistributionFacilityService,
    private storageService: StorageFacilityService,
    private treatementService: TreatmentService,
    private surfaceWaterFacilityService: SurfaceWaterFacilityService,
    private interconnectFacilityService: InterconnectFacilityService,
    private boosterStationFacilityService: BoosterStationFacilityService
  ) {}

  /**
   * Returns all of a water system's facilities flow saved locally
   * If none found, then will be check-out remotely if online
   * @param id of the water system
   */
  findFlowAll(id: number): Observable<FacilityFlow[]> {
    return new Observable<FacilityFlow[]>((obs) => {
      this.findFlowAllLocally(id).subscribe((wsLocal) => {
        if (wsLocal && wsLocal.length > 0) {
          obs.next(wsLocal);
          obs.complete();
        } else {
          if (this.connectivityService.isOnline()) {
            this.findFlowAllRemotelyTimer(id).subscribe((wsRemote) => {
              obs.next(wsRemote.data);
              obs.complete();
            }, error => {
              obs.error(error);
            });
          } else {
            obs.complete();
          }
        }
      });
    });
  }

  /**
   * Returns one facility flow saved locally.
   * If none found, then will check remotely if online.
   * @param waterSystemId the water system id
   * @param facilityId the facility id
   */
  findFlowOne(
    waterSystemId: number,
    facilityId: number
  ): Observable<FacilityFlow> {
    return new Observable<FacilityFlow>((obs) => {
      this.findFlowOneLocally(waterSystemId, facilityId).subscribe(
        (wsLocal) => {
          if (wsLocal && wsLocal.length > 0) {
            obs.next(wsLocal[0]);
            obs.complete();
          } else {
            if (this.connectivityService.isOnline()) {
              this.findFlowOneRemotelyTimer(
                waterSystemId,
                facilityId
              ).subscribe((wsRemote) => {
                obs.next(wsRemote);
                obs.complete();
              });
            }
          }
        }
      );
    });
  }

  findFacilityOne(
    waterSystemId: number,
    facilityId: number
  ): Observable<FacilityAny> {
    return new Observable<FacilityAny>((obs) => {
      this.findFlowOneLocally(waterSystemId, facilityId).subscribe(
        (facilityFlowLocal) => {
          if (facilityFlowLocal && facilityFlowLocal.length > 0) {
            const facilityCode: string = facilityFlowLocal[0].facilityCode;
            switch (facilityCode) {
              case 'WL':
                this.waterSystemWellsService
                  .findOne(waterSystemId, facilityId)
                  .subscribe((wellLocal) => {
                    obs.next(wellLocal);
                    obs.complete();
                  });
                break;
              case 'IN':
                this.waterSystemSurfaceWaterFacilityService
                  .findOne(waterSystemId, facilityId)
                  .subscribe((surfaceWaterLocal) => {
                    obs.next(surfaceWaterLocal);
                    obs.complete();
                  });
                break;
              case 'SH':
              case 'SU':
              case 'ST':
              case 'SS':
              case 'SE':
                this.waterSystemStorageFacilitiesService
                  .findOne(waterSystemId, facilityId)
                  .subscribe((storageLocal) => {
                    obs.next(storageLocal);
                    obs.complete();
                  });
                break;
              case 'TP':
                this.waterSystemTreatmentFacilitiesService
                  .findOne(waterSystemId, facilityId)
                  .subscribe((treatmentLocal) => {
                    obs.next(treatmentLocal);
                    obs.complete();
                  });
                break;
              case 'DS':
                this.waterSystemDistributionFacilityService
                  .findOne(waterSystemId, facilityId)
                  .subscribe((distributionLocal) => {
                    obs.next(distributionLocal);
                    obs.complete();
                  });
                break;
              case 'BS':
                this.waterSystemBoosterStationFacilityService
                  .findOne(waterSystemId, facilityId)
                  .subscribe((boosterStationLocal) => {
                    obs.next(boosterStationLocal);
                    obs.complete();
                  });
                break;
              case 'CC':
                this.waterSystemInterconnectFacilityService
                  .findOne(waterSystemId, facilityId)
                  .subscribe((interconnectLocal) => {
                    obs.next(interconnectLocal);
                    obs.complete();
                  });
                break;
            }
          }
        }
      );
    });
  }

  private findFlowAllLocally(id: number): Observable<FacilityFlow[]> {
    return this.localStorageService.dbRetrieveAllByIndex<FacilityFlow>(
      tables.waterSystemFacilities,
      'pwsId',
      id
    );
  }

  private findFlowOneLocally(
    waterSystemId: number,
    id: number
  ): Observable<FacilityFlow[]> {
    return this.localStorageService.dbRetrieveAllByIndex<FacilityFlow>(
      tables.waterSystemFacilities,
      'pwsIdFacilityId',
      [waterSystemId, id]
    );
  }

  private findFlowOneLocallyByKey(key: number): Observable<FacilityFlow> {
    return this.localStorageService.dbRetrieve<FacilityFlow>(
      tables.waterSystemFacilities,
      key
    );
  }

  findFlowGraphLocally(id: number): Observable<FacilityFlowGraph> {
    return this.localStorageService.dbRetrieve<FacilityFlowGraph>(
      tables.waterSystemFacilitiesFlow,
      id
    );
  }

  private findFlowAllRemotely(
    id: number
  ): Observable<Checkout<FacilityFlow[]>> {
    return this.http.get<Checkout<FacilityFlow[]>>(
      `${this.WATER_SYSTEM_URL}/${id}/${this.FLOW_SUB_ENDPOINT}`
    );
  }

  private findFlowOneRemotely(
    waterSystemId: number,
    facilityId: number
  ): Observable<FacilityFlow> {
    return this.http.get<FacilityFlow>(
      `${this.WATER_SYSTEM_URL}/${waterSystemId}/${this.FLOW_SUB_ENDPOINT}?${this.FLOW_FACILITY_ID_QUERY_PARAM}=${facilityId}`
    );
  }

  private findFlowAllRemotelyTimer(
    id: number
  ): Observable<Checkout<FacilityFlow[]>> {
    const stopTimer$ = timer(10 * 60 * 1000);
    return timer(0, 15000).pipe(
      takeUntil(stopTimer$),
      concatMap(() =>
        this.findFlowAllRemotely(id).pipe(map((response) => response))
      ),
      filter((backendData) => backendData.dataReady),
      take(1),
      tap((found) => {
        this.storeFlowAll(id, found.data);
        return found.data;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  private findFlowOneRemotelyTimer(
    waterSystemId: number,
    facilityId: number
  ): Observable<FacilityFlow> {
    const stopTimer$ = timer(10 * 60 * 1000);
    return timer(0, 15000).pipe(
      takeUntil(stopTimer$),
      concatMap(() =>
        this.findFlowOneRemotely(waterSystemId, facilityId).pipe(
          map((response) => response)
        )
      ),
      take(1),
      tap((found) => {
        return found;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  private storeFlowAll(
    id: number,
    waterSystemFacilitiesFlow: FacilityFlow[]
  ): void {
    for (const facility of waterSystemFacilitiesFlow) {
      this.localStorageService.dbStore(tables.waterSystemFacilities, facility);
    }
  }

  async storeFlowGraph(id: number) {
    this.findFlowAllLocally(id).subscribe(async (waterSystemFacilitiesFlow) => {
      const nodesDetailed = [] as any;
      const nodesAggregate = [] as any;
      const linksDetailed = [] as any;
      const linksAggregate = [] as any;
      let maxId = 0;
      for (const facilityFlow of waterSystemFacilitiesFlow) {
        if (facilityFlow.facilityId > maxId) {
          maxId = facilityFlow.facilityId;
        }

        let facility: Facility;
        await this.getFacilityDetails(facilityFlow).then((result) => {
          facility = result;
        });
        let title = '';
        if (facility) {
          title = this.flowUtilityService.getNodeTitle(facility);
        }
        const color: string = this.flowUtilityService.getNodeColor(
          facilityFlow.facilityCode
        );
        const nodeDetailed = {
          id: facilityFlow.facilityId,
          name: facilityFlow.name,
          facilityId: facilityFlow.facilityId,
          type: facilityFlow.facilityCode,
          flowType: 'DETAILED',
          title: title,
          color: color,
        };
        nodesDetailed.push(nodeDetailed);
        if (
          nodesAggregate.some((node) => node.id === facilityFlow.facilityCode)
        ) {
          // do nothing
        } else {
          const name = this.facilityTypePipe.transform(facilityFlow.facilityCode);
          const nodeAggregate = {
            id: facilityFlow.facilityCode,
            name: name,
            type: facilityFlow.facilityCode,
            flowType: 'AGGREGATE',
            title: '',
            color: color,
          };
          nodesAggregate.push(nodeAggregate);
        }
        if (facilityFlow.flowToId) {
          const linkDetailed = {
            source: facilityFlow.facilityId,
            target: facilityFlow.flowToId,
            value: 1,
            title: this.flowUtilityService.getLinkTitle(
              facilityFlow.name,
              facilityFlow.flowToName
            ),
          };
          linksDetailed.push(linkDetailed);
          if (facilityFlow.facilityCode !== facilityFlow.flowToCode) {
            if (
              linksAggregate.some(
                (link) =>
                  link.source === facilityFlow.facilityCode &&
                  link.target === facilityFlow.flowToCode
              )
            ) {
              // do nothing
            } else {
              const linkAggregate = {
                source: facilityFlow.facilityCode,
                target: facilityFlow.flowToCode,
                value: 1,
                title: this.flowUtilityService.getLinkTitle(
                  this.facilityTypePipe.transform(facilityFlow.facilityCode),
                  this.facilityTypePipe.transform(facilityFlow.flowToCode)
                ),
              };
              linksAggregate.push(linkAggregate);
            }
          }
        }
      }

      const nodesRemovedFromFlow = [] as any;
      const linksRemovedFromFlow = [] as any;
      let target;
      for (let i = nodesDetailed.length - 1; i >= 0; i--) {
        const node = nodesDetailed[i];
        const foundLinks: [] = linksDetailed.filter(
          (l) => l.source === node.id || l.target === node.id
        );
        if (!foundLinks || foundLinks.length <= 0) {
          const nodeRemovedFromFlow = nodesDetailed.splice(i, 1);
          nodesRemovedFromFlow.push(nodeRemovedFromFlow[0]);
          if (target == null) {
            target = {
              id: -1,
              title: 'HIDE',
            };
            nodesRemovedFromFlow.push(target);
          }
          const link = {
            source: nodeRemovedFromFlow[0].facilityId,
            target: target.id,
            value: 1,
            title: 'HIDE',
          };
          linksRemovedFromFlow.push(link);
        }
      }

      const flow: FacilityFlowGraph = {
        waterSystemId: id,
        nodesDetailed: nodesDetailed,
        nodesAggregate: nodesAggregate,
        linksDetailed: linksDetailed,
        linksAggregate: linksAggregate,
        nodesRemovedFromFlow: nodesRemovedFromFlow,
        linksRemovedFromFlow: linksRemovedFromFlow,
      };
      this.localStorageService.dbStore(tables.waterSystemFacilitiesFlow, flow);

      this.localStorageService.dbStoreStorageAny(
        tables.waterSystemMaxFacilityId,
        id,
        maxId
      );
    });
  }

  deleteFlowOneLocally(id: number, facilityId: number) {
    this.localStorageService.dbRemove(
      tables.waterSystemFacilitiesFlow,
      'pwsId'
    );
  }

  deleteFlowAllLocally(id: number): void {
    this.localStorageService
      .dbRetrieveAllByIndex<FacilityFlow>(
        tables.waterSystemFacilities,
        'pwsId',
        id
      )
      .subscribe((results) => {
        if (results) {
          for (const facility of results) {
            this.localStorageService.dbRemove(
              tables.waterSystemFacilities,
              facility.key
            );
          }
        }
      });
    this.localStorageService.dbRemove(tables.waterSystemFacilitiesFlow, id);
    this.localStorageService.dbRemove(tables.waterSystemMaxFacilityId, id);
  }

  async save(facility: FacilityAny, flowsToFacility: FacilityFlow) {
    if (facility.facilityId == null) {
      facility.facilityId =
        (await this.getNextFacilityId(facility.pwsId)).data + 1;
      this.incrementNextFacilityId(facility.pwsId, facility.facilityId);
    }

    this.saveFacilityFlow(facility, flowsToFacility);

    if (flowsToFacility) {
      let downstreamFacility = null;
      await this.getFacilityDetails(flowsToFacility).then(
        (f) => (downstreamFacility = f)
      );
      if (downstreamFacility) {
        facility.downstreamFacility = [];
        facility.downstreamFacility.push(downstreamFacility.facilitySeqNo);
      }
    }
    this.saveByFacilityType(facility.facilityCode, facility);

    this.storeFlowGraph(facility.pwsId);
  }

  private mapToFacilityFlow(
    facility: Facility,
    flowsToFacility: FacilityFlow
  ): FacilityFlow {
    const facilityFlow: FacilityFlow = {
      pwsId: facility.pwsId,
      facilityCode: facility.facilityCode,
      facilityId: facility.facilityId,
      flowToCode: flowsToFacility?.facilityCode,
      flowToId: flowsToFacility?.facilityId,
      flowToName: flowsToFacility?.name,
      name: facility.name,
      newIndicator: true,
    };
    return facilityFlow;
  }

  private saveFacilityFlow(
    facility: Facility,
    flowsToFacility: FacilityFlow
  ): void {
    let facilityFlow: FacilityFlow;
    if (facility.newIndicator) {
      facilityFlow = this.mapToFacilityFlow(facility, flowsToFacility);
      this.saveFlow(facilityFlow);
    } else {
      this.localStorageService
        .dbRetrieveAllByIndex<FacilityFlow>(
          tables.waterSystemFacilities,
          'pwsIdFacilityId',
          [facility.pwsId, facility.facilityId]
        )
        .subscribe((results) => {
          facilityFlow = results[0];
          facilityFlow.flowToCode = flowsToFacility?.facilityCode;
          facilityFlow.flowToId = flowsToFacility?.facilityId;
          facilityFlow.flowToName = flowsToFacility?.name;
          facilityFlow.name = facility.name;
          this.saveFlow(facilityFlow);
        });
    }
  }

  private saveFlow(facilityFlow: FacilityFlow) {
    this.localStorageService.dbStore(
      tables.waterSystemFacilities,
      facilityFlow
    );
    this.localStorageService
      .dbRetrieveAllByIndex<FacilityFlow>(
        tables.waterSystemFacilities,
        'pwsIdFacilityId',
        [facilityFlow.pwsId, facilityFlow.facilityId]
      )
      .subscribe((results) => {
        if (results && results.length > 0) {
          this.syncSchedulerService
            .schedule(SyncType.FacilityFlow, results[0].key)
            .subscribe();
        }
      });
  }

  deleteFromFlow(facility: FacilityAny) {
    if (facility.pwsId && facility.facilityId) {
      this.localStorageService
        .dbRetrieveAllByIndex<FacilityFlow>(
          tables.waterSystemFacilities,
          'pwsId',
          facility.pwsId
        )
        .subscribe((results) => {
          if (results && results.length > 0) {
            const removedFacility = results.find(f => f.facilityId === facility.facilityId);
            const flowsToRemoved = results.filter(f => f.flowToId === facility.facilityId);
            this.byPassInFlow(flowsToRemoved, removedFacility);
            this.localStorageService
              .dbRemoveObs(tables.waterSystemFacilities, removedFacility.key)
              .subscribe(() => {
                this.syncSchedulerService
                  .schedule(
                    SyncType.FacilityFlowDelete,
                    removedFacility.key,
                    removedFacility
                  )
                  .subscribe();
                this.storeFlowGraph(removedFacility.pwsId);
              });
          }
        });
    }
  }

  private byPassInFlow(facilityFlows: FacilityFlow[], byPassFacility: FacilityFlow) {
    facilityFlows.forEach(facilityFlow => {
      facilityFlow.flowToCode = byPassFacility.flowToCode;
      facilityFlow.flowToId = byPassFacility.flowToId;
      facilityFlow.flowToName = byPassFacility.flowToName;
      this.saveFlow(facilityFlow);
    });
  }

  private saveByFacilityType(
    facilityCode: string,
    facility: FacilityAny
  ): void {
    switch (facilityCode) {
      case 'WL':
        this.saveWellFacility(facility);
        this.syncSchedulerService
          .schedule(SyncType.FacilityWell, facility.facilitySeqNo)
          .subscribe();
        break;
      case 'IN':
        this.saveSurfaceWaterFacility(facility);
        this.syncSchedulerService
          .schedule(SyncType.FacilitySurfaceWater, facility.facilitySeqNo)
          .subscribe();
        break;
      case 'SH':
      case 'SU':
      case 'ST':
      case 'SS':
      case 'SE':
        this.saveStorageFacility(facility);
        this.syncSchedulerService
          .schedule(SyncType.FacilityStorage, facility.facilitySeqNo)
          .subscribe();
        break;
      case 'TP':
        this.saveTreatmentFacility(facility);
        this.syncSchedulerService
          .schedule(SyncType.FacilityTreatment, facility.facilitySeqNo)
          .subscribe();
        break;
      case 'DS':
        this.saveDistributionFacility(facility);
        this.syncSchedulerService
          .schedule(SyncType.FacilityDistribution, facility.facilitySeqNo)
          .subscribe();
        break;
      case 'BS':
        this.saveBoosterStationFacility(facility);
        this.syncSchedulerService
          .schedule(SyncType.FacilityBoosterStation, facility.facilitySeqNo)
          .subscribe();
        break;
      case 'CC':
        this.saveInterconnectFacility(facility);
        this.syncSchedulerService
          .schedule(SyncType.FacilityInterconnect, facility.facilitySeqNo)
          .subscribe();
        break;
    }
  }

  private async getFacilityDetails(
    facilityFlow: FacilityFlow
  ): Promise<Facility> {
    switch (facilityFlow.facilityCode) {
      case 'WL':
        return await this.waterSystemWellsService
          .findOne(facilityFlow.pwsId, facilityFlow.facilityId)
          .toPromise();
      case 'IN':
        return await this.waterSystemSurfaceWaterFacilityService
          .findOne(facilityFlow.pwsId, facilityFlow.facilityId)
          .toPromise();
      case 'SH':
      case 'SU':
      case 'ST':
      case 'SS':
      case 'SE':
        return await this.waterSystemStorageFacilitiesService
          .findOne(facilityFlow.pwsId, facilityFlow.facilityId)
          .toPromise();
      case 'TP':
        return await this.waterSystemTreatmentFacilitiesService
          .findOne(facilityFlow.pwsId, facilityFlow.facilityId)
          .toPromise();
      case 'DS':
        return await this.waterSystemDistributionFacilityService
          .findOne(facilityFlow.pwsId, facilityFlow.facilityId)
          .toPromise();
      case 'BS':
        return await this.waterSystemBoosterStationFacilityService
          .findOne(facilityFlow.pwsId, facilityFlow.facilityId)
          .toPromise();
      case 'CC':
        return await this.waterSystemInterconnectFacilityService
          .findOne(facilityFlow.pwsId, facilityFlow.facilityId)
          .toPromise();
      case 'NP':
      default:
        return of(null).toPromise();
    }
  }

  sync(): void {
    this.syncFacilityFlow();
    this.syncFacilityWell();
    this.waterSystemWellsService.syncFacilityWellDelete();
    this.syncFacilitySurfaceWater();
    this.waterSystemSurfaceWaterFacilityService.syncFacilitySurfaceWaterDelete();
    this.syncFacilityStorage();
    this.waterSystemStorageFacilitiesService.syncFacilityStorageDelete();
    this.syncFacilityTreatment();
    this.waterSystemTreatmentFacilitiesService.syncFacilityTreatmentDelete();
    this.syncFacilityDistribution();
    this.waterSystemDistributionFacilityService.syncFacilityDistributionDelete();
    this.syncFacilityBoosterStation();
    this.waterSystemBoosterStationFacilityService.syncFacilityBoosterStationDelete();
    this.syncFacilityInterconnect();
    this.waterSystemInterconnectFacilityService.syncFacilityInterconnectDelete();
  }

  private syncFacilityFlow(): void {
    if (this.connectivityService.isOnline) {
      if (this.connectivityService.isOnline()) {
        this.syncSchedulerService
          .scheduled(SyncType.FacilityFlow)
          .subscribe((syncRequests) => {
            syncRequests.forEach((syncRequest) => {
              this.findFlowOneLocallyByKey(syncRequest.data.id).subscribe(
                (facility) => {
                  if (facility) {
                    if (facility.newIndicator) {
                      this.insertRemotely(facility).subscribe(
                        () => {
                          this.syncSchedulerService.unschedule(
                            SyncType.FacilityFlow,
                            syncRequest.data.id,
                            SyncResultFactory.synced(
                              SyncType.FacilityFlow,
                              syncRequest.data.id
                            )
                          );
                          this.clearNewIndicatorFlow(facility);
                        },
                        (error) =>
                          this.syncSchedulerService.unschedule(
                            SyncType.FacilityFlow,
                            syncRequest.data.id,
                            SyncResultFactory.errorHttp(
                              SyncType.FacilityFlow,
                              syncRequest.data.id,
                              error
                            )
                          )
                      );
                    } else {
                      this.updateRemotely(facility).subscribe(
                        () => {
                          this.syncSchedulerService.unschedule(
                            SyncType.FacilityFlow,
                            syncRequest.data.id,
                            SyncResultFactory.synced(
                              SyncType.FacilityFlow,
                              syncRequest.data.id
                            )
                          );
                        },
                        (error) =>
                          this.syncSchedulerService.unschedule(
                            SyncType.FacilityFlow,
                            syncRequest.data.id,
                            SyncResultFactory.errorHttp(
                              SyncType.FacilityFlow,
                              syncRequest.data.id,
                              error
                            )
                          )
                      );
                    }
                  } else {
                    this.syncSchedulerService.unschedule(
                      SyncType.FacilityFlow,
                      syncRequest.data.id,
                      SyncResultFactory.synced(
                        SyncType.FacilityFlow,
                        syncRequest.data.id
                      )
                    );
                  }
                }
              );
            });
          });
        this.syncSchedulerService
          .scheduled(SyncType.FacilityFlowDelete)
          .subscribe((syncRequests) => {
            syncRequests.forEach((syncRequest) => {
              if (syncRequest.data && syncRequest.data.data && syncRequest.data.data) {
                this.deleteRemotely(syncRequest.data.data as FacilityFlow).subscribe(
                  () => {
                    this.syncSchedulerService.unschedule(
                      SyncType.FacilityFlowDelete,
                      syncRequest.data.id,
                      SyncResultFactory.synced(
                        SyncType.FacilityFlowDelete,
                        syncRequest.data.id
                      )
                    );
                  },
                  (error) =>
                    this.syncSchedulerService.unschedule(
                      SyncType.FacilityFlowDelete,
                      syncRequest.data.id,
                      SyncResultFactory.errorHttp(
                        SyncType.FacilityFlowDelete,
                        syncRequest.data.id,
                        error
                      )
                    )
                );
              } else {
                this.syncSchedulerService.unschedule(
                  SyncType.FacilityFlowDelete,
                  syncRequest.data.id,
                  SyncResultFactory.synced(
                    SyncType.FacilityFlowDelete,
                    syncRequest.data.id
                  )
                );
              }
            });
          });
      }
    }
  }

  private syncFacilityWell(): void {
    if (this.connectivityService.isOnline) {
      if (this.connectivityService.isOnline()) {
        this.syncSchedulerService
          .scheduled(SyncType.FacilityWell)
          .subscribe((syncRequests) => {
            syncRequests.forEach((syncRequest) => {
              this.waterSystemWellsService
                .findOneLocallyByKey(syncRequest.data.id)
                .subscribe((facility) => {
                  if (facility) {
                    if (facility.newIndicator) {
                      this.waterSystemWellsService
                        .insertRemotely(facility)
                        .subscribe(
                          () => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityWell,
                              syncRequest.data.id,
                              SyncResultFactory.synced(
                                SyncType.FacilityWell,
                                syncRequest.data.id
                              )
                            );
                            this.clearNewIndicatorWell(facility);
                          },
                          (error) => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityWell,
                              syncRequest.data.id,
                              SyncResultFactory.errorHttp(
                                SyncType.FacilityWell,
                                syncRequest.data.id,
                                error
                              )
                            );
                          }
                        );
                    } else {
                      this.waterSystemWellsService
                        .updateRemotely(facility)
                        .subscribe(
                          () => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityWell,
                              syncRequest.data.id,
                              SyncResultFactory.synced(
                                SyncType.FacilityWell,
                                syncRequest.data.id
                              )
                            );
                          },
                          (error) => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityWell,
                              syncRequest.data.id,
                              SyncResultFactory.errorHttp(
                                SyncType.FacilityWell,
                                syncRequest.data.id,
                                error
                              )
                            );
                          }
                        );
                    }
                  } else {
                    this.syncSchedulerService.unschedule(
                      SyncType.FacilityWell,
                      syncRequest.data.id,
                      SyncResultFactory.synced(
                        SyncType.FacilityWell,
                        syncRequest.data.id
                      )
                    );
                  }
                });
            });
          });
      }
    }
  }

  private syncFacilitySurfaceWater(): void {
    if (this.connectivityService.isOnline) {
      if (this.connectivityService.isOnline()) {
        this.syncSchedulerService
          .scheduled(SyncType.FacilitySurfaceWater)
          .subscribe((syncRequests) => {
            syncRequests.forEach((syncRequest) => {
              this.waterSystemSurfaceWaterFacilityService
                .findOneLocallyByKey(syncRequest.data.id)
                .subscribe((facility) => {
                  if (facility) {
                    if (facility.newIndicator) {
                      this.waterSystemSurfaceWaterFacilityService
                        .insertRemotely(facility)
                        .subscribe(
                          () => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilitySurfaceWater,
                              syncRequest.data.id,
                              SyncResultFactory.synced(
                                SyncType.FacilitySurfaceWater,
                                syncRequest.data.id
                              )
                            );
                            this.clearNewIndicatorSurfaceWater(facility);
                          },
                          (error) => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilitySurfaceWater,
                              syncRequest.data.id,
                              SyncResultFactory.errorHttp(
                                SyncType.FacilitySurfaceWater,
                                syncRequest.data.id,
                                error
                              )
                            );
                          }
                        );
                    } else {
                      this.waterSystemSurfaceWaterFacilityService
                        .updateRemotely(facility)
                        .subscribe(
                          () => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilitySurfaceWater,
                              syncRequest.data.id,
                              SyncResultFactory.synced(
                                SyncType.FacilitySurfaceWater,
                                syncRequest.data.id
                              )
                            );
                          },
                          (error) => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilitySurfaceWater,
                              syncRequest.data.id,
                              SyncResultFactory.errorHttp(
                                SyncType.FacilitySurfaceWater,
                                syncRequest.data.id,
                                error
                              )
                            );
                          }
                        );
                    }
                  } else {
                    this.syncSchedulerService.unschedule(
                      SyncType.FacilitySurfaceWater,
                      syncRequest.data.id,
                      SyncResultFactory.synced(
                        SyncType.FacilitySurfaceWater,
                        syncRequest.data.id
                      )
                    );
                  }
                });
            });
          });
      }
    }
  }

  private syncFacilityStorage(): void {
    if (this.connectivityService.isOnline) {
      if (this.connectivityService.isOnline()) {
        this.syncSchedulerService
          .scheduled(SyncType.FacilityStorage)
          .subscribe((syncRequests) => {
            syncRequests.forEach((syncRequest) => {
              this.waterSystemStorageFacilitiesService
                .findOneLocallyByKey(syncRequest.data.id)
                .subscribe((facility) => {
                  if (facility) {
                    if (facility.newIndicator) {
                      this.waterSystemStorageFacilitiesService
                        .insertRemotely(facility)
                        .subscribe(
                          () => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityStorage,
                              syncRequest.data.id,
                              SyncResultFactory.synced(
                                SyncType.FacilityStorage,
                                syncRequest.data.id
                              )
                            );
                            this.clearNewIndicatorStorage(facility);
                          },
                          (error) => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityStorage,
                              syncRequest.data.id,
                              SyncResultFactory.errorHttp(
                                SyncType.FacilityStorage,
                                syncRequest.data.id,
                                error
                              )
                            );
                          }
                        );
                    } else {
                      this.waterSystemStorageFacilitiesService
                        .updateRemotely(facility)
                        .subscribe(
                          () => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityStorage,
                              syncRequest.data.id,
                              SyncResultFactory.synced(
                                SyncType.FacilityStorage,
                                syncRequest.data.id
                              )
                            );
                          },
                          (error) => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityStorage,
                              syncRequest.data.id,
                              SyncResultFactory.errorHttp(
                                SyncType.FacilityStorage,
                                syncRequest.data.id,
                                error
                              )
                            );
                          }
                        );
                    }
                  } else {
                    this.syncSchedulerService.unschedule(
                      SyncType.FacilityStorage,
                      syncRequest.data.id,
                      SyncResultFactory.synced(
                        SyncType.FacilityStorage,
                        syncRequest.data.id
                      )
                    );
                  }
                });
            });
          });
      }
    }
  }

  private syncFacilityTreatment(): void {
    if (this.connectivityService.isOnline) {
      if (this.connectivityService.isOnline()) {
        this.syncSchedulerService
          .scheduled(SyncType.FacilityTreatment)
          .subscribe((syncRequests) => {
            syncRequests.forEach((syncRequest) => {
              this.waterSystemTreatmentFacilitiesService
                .findOneLocallyByKey(syncRequest.data.id)
                .subscribe((facility) => {
                  if (facility) {
                    if (facility.newIndicator) {
                      this.waterSystemTreatmentFacilitiesService
                        .insertRemotely(facility)
                        .subscribe(
                          () => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityTreatment,
                              syncRequest.data.id,
                              SyncResultFactory.synced(
                                SyncType.FacilityTreatment,
                                syncRequest.data.id
                              )
                            );
                            this.clearNewIndicatorTreatment(facility);
                          },
                          (error) => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityTreatment,
                              syncRequest.data.id,
                              SyncResultFactory.errorHttp(
                                SyncType.FacilityTreatment,
                                syncRequest.data.id,
                                error
                              )
                            );
                          }
                        );
                    } else {
                      this.waterSystemTreatmentFacilitiesService
                        .updateRemotely(facility)
                        .subscribe(
                          () => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityTreatment,
                              syncRequest.data.id,
                              SyncResultFactory.synced(
                                SyncType.FacilityTreatment,
                                syncRequest.data.id
                              )
                            );
                          },
                          (error) => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityTreatment,
                              syncRequest.data.id,
                              SyncResultFactory.errorHttp(
                                SyncType.FacilityTreatment,
                                syncRequest.data.id,
                                error
                              )
                            );
                          }
                        );
                    }
                  } else {
                    this.syncSchedulerService.unschedule(
                      SyncType.FacilityTreatment,
                      syncRequest.data.id,
                      SyncResultFactory.synced(
                        SyncType.FacilityTreatment,
                        syncRequest.data.id
                      )
                    );
                  }
                });
            });
          });
      }
    }
  }

  private syncFacilityDistribution(): void {
    if (this.connectivityService.isOnline) {
      if (this.connectivityService.isOnline()) {
        this.syncSchedulerService
          .scheduled(SyncType.FacilityDistribution)
          .subscribe((syncRequests) => {
            syncRequests.forEach((syncRequest) => {
              this.waterSystemDistributionFacilityService
                .findOneLocallyByKey(syncRequest.data.id)
                .subscribe((facility) => {
                  if (facility) {
                    if (facility.newIndicator) {
                      this.waterSystemDistributionFacilityService
                        .insertRemotely(facility)
                        .subscribe(
                          () => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityDistribution,
                              syncRequest.data.id,
                              SyncResultFactory.synced(
                                SyncType.FacilityDistribution,
                                syncRequest.data.id
                              )
                            );
                            this.clearNewIndicatorDistribution(facility);
                          },
                          (error) => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityDistribution,
                              syncRequest.data.id,
                              SyncResultFactory.errorHttp(
                                SyncType.FacilityDistribution,
                                syncRequest.data.id,
                                error
                              )
                            );
                          }
                        );
                    } else {
                      this.waterSystemDistributionFacilityService
                        .updateRemotely(facility)
                        .subscribe(
                          () => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityDistribution,
                              syncRequest.data.id,
                              SyncResultFactory.synced(
                                SyncType.FacilityDistribution,
                                syncRequest.data.id
                              )
                            );
                          },
                          (error) => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityDistribution,
                              syncRequest.data.id,
                              SyncResultFactory.errorHttp(
                                SyncType.FacilityDistribution,
                                syncRequest.data.id,
                                error
                              )
                            );
                          }
                        );
                    }
                  } else {
                    this.syncSchedulerService.unschedule(
                      SyncType.FacilityDistribution,
                      syncRequest.data.id,
                      SyncResultFactory.synced(
                        SyncType.FacilityDistribution,
                        syncRequest.data.id
                      )
                    );
                  }
                });
            });
          });
      }
    }
  }

  private syncFacilityBoosterStation(): void {
    if (this.connectivityService.isOnline) {
      if (this.connectivityService.isOnline()) {
        this.syncSchedulerService
          .scheduled(SyncType.FacilityBoosterStation)
          .subscribe((syncRequests) => {
            syncRequests.forEach((syncRequest) => {
              this.waterSystemBoosterStationFacilityService
                .findOneLocallyByKey(syncRequest.data.id)
                .subscribe((facility) => {
                  if (facility) {
                    if (facility.newIndicator) {
                      this.waterSystemBoosterStationFacilityService
                        .insertRemotely(facility)
                        .subscribe(
                          () => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityBoosterStation,
                              syncRequest.data.id,
                              SyncResultFactory.synced(
                                SyncType.FacilityBoosterStation,
                                syncRequest.data.id
                              )
                            );
                            this.clearNewIndicatorBoosterStation(facility);
                          },
                          (error) => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityBoosterStation,
                              syncRequest.data.id,
                              SyncResultFactory.errorHttp(
                                SyncType.FacilityBoosterStation,
                                syncRequest.data.id,
                                error
                              )
                            );
                          }
                        );
                    } else {
                      this.waterSystemBoosterStationFacilityService
                        .updateRemotely(facility)
                        .subscribe(
                          () => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityBoosterStation,
                              syncRequest.data.id,
                              SyncResultFactory.synced(
                                SyncType.FacilityBoosterStation,
                                syncRequest.data.id
                              )
                            );
                          },
                          (error) => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityBoosterStation,
                              syncRequest.data.id,
                              SyncResultFactory.errorHttp(
                                SyncType.FacilityBoosterStation,
                                syncRequest.data.id,
                                error
                              )
                            );
                          }
                        );
                    }
                  } else {
                    this.syncSchedulerService.unschedule(
                      SyncType.FacilityBoosterStation,
                      syncRequest.data.id,
                      SyncResultFactory.synced(
                        SyncType.FacilityBoosterStation,
                        syncRequest.data.id
                      )
                    );
                  }
                });
            });
          });
      }
    }
  }

  private syncFacilityInterconnect(): void {
    if (this.connectivityService.isOnline) {
      if (this.connectivityService.isOnline()) {
        this.syncSchedulerService
          .scheduled(SyncType.FacilityInterconnect)
          .subscribe((syncRequests) => {
            syncRequests.forEach((syncRequest) => {
              this.waterSystemInterconnectFacilityService
                .findOneLocallyByKey(syncRequest.data.id)
                .subscribe((facility) => {
                  if (facility) {
                    if (facility.newIndicator) {
                      this.waterSystemInterconnectFacilityService
                        .insertRemotely(facility)
                        .subscribe(
                          () => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityInterconnect,
                              syncRequest.data.id,
                              SyncResultFactory.synced(
                                SyncType.FacilityInterconnect,
                                syncRequest.data.id
                              )
                            );
                            this.clearNewIndicatorInterconnect(facility);
                          },
                          (error) => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityInterconnect,
                              syncRequest.data.id,
                              SyncResultFactory.errorHttp(
                                SyncType.FacilityInterconnect,
                                syncRequest.data.id,
                                error
                              )
                            );
                          }
                        );
                    } else {
                      this.waterSystemInterconnectFacilityService
                        .updateRemotely(facility)
                        .subscribe(
                          () => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityInterconnect,
                              syncRequest.data.id,
                              SyncResultFactory.synced(
                                SyncType.FacilityInterconnect,
                                syncRequest.data.id
                              )
                            );
                          },
                          (error) => {
                            this.syncSchedulerService.unschedule(
                              SyncType.FacilityInterconnect,
                              syncRequest.data.id,
                              SyncResultFactory.errorHttp(
                                SyncType.FacilityInterconnect,
                                syncRequest.data.id,
                                error
                              )
                            );
                          }
                        );
                    }
                  } else {
                    this.syncSchedulerService.unschedule(
                      SyncType.FacilityInterconnect,
                      syncRequest.data.id,
                      SyncResultFactory.synced(
                        SyncType.FacilityInterconnect,
                        syncRequest.data.id
                      )
                    );
                  }
                });
            });
          });
      }
    }
  }

  private insertRemotely(facility: FacilityFlow): Observable<FacilityFlow> {
    return this.http.post<FacilityFlow>(
      `${this.WATER_SYSTEM_URL}/${facility.pwsId}/${this.FLOW_SUB_ENDPOINT}`,
      facility
    );
  }

  private updateRemotely(facility: FacilityFlow): Observable<FacilityFlow> {
    return this.http.put<FacilityFlow>(
      `${this.WATER_SYSTEM_URL}/${facility.pwsId}/${this.FLOW_SUB_ENDPOINT}/${facility.facilityId}`,
      facility
    );
  }

  private deleteRemotely(facility: FacilityFlow): Observable<any> {
    return this.http.delete(
      `${this.WATER_SYSTEM_URL}/${facility.pwsId}/${this.FLOW_SUB_ENDPOINT}/${facility.facilityId}`
    );
  }

  private saveWellFacility(facility: Facility) {
    let well: Well;
    if (isWell(facility)) {
      well = facility;
      if (facility.newIndicator) {
        well.iwmzPath = 'watersystem/' + facility.pwsId + '/NEW' + facility.facilityId + '.pdf';
        this.saveWell(well);
      } else {
        this.waterSystemWellsService
          .findOne(facility.pwsId, facility.facilityId)
          .subscribe((result) => {
            if (result) {
              result.name = facility.name;
              result.availabilityCode = facility.availabilityCode;
              result.statusCode = facility.statusCode;
              result.statusReason = facility.statusReason;
              result.downstreamFacility = facility.downstreamFacility;
              result.surveyNote = facility.surveyNote;
              result.iwmzPcsi = facility.iwmzPcsi;
              result.existingMeasures = facility.existingMeasures;
              result.newMeasures = facility.newMeasures;
              result.designCapacity = facility.designCapacity;
              result.dcUnitMeasureCode = facility.dcUnitMeasureCode;
              result.emergencyCapacity = facility.emergencyCapacity;
              result.ecUnitMeasureCode = facility.ecUnitMeasureCode;
              result.pumpingRate = facility.pumpingRate;
              this.saveWell(result);
            }
          });
      }
    }
  }

  private saveWell(well: Well) {
    const wells: Well[] = [];
    wells.push(well);
    this.waterSystemWellsService.store(wells, false);
    this.waterSystemWellsService.findLocally(well.pwsId).subscribe((w) => {
      this.wellsService.changeWaterSystemWells(w);
    });
  }

  private saveSurfaceWaterFacility(facility: Facility) {
    let surfaceWaterFacility: SurfaceWaterFacility;
    if (isSurfaceWater(facility)) {
      surfaceWaterFacility = facility;
      if (facility.newIndicator) {
        this.saveSurfaceWater(surfaceWaterFacility);
      } else {
        this.waterSystemSurfaceWaterFacilityService
          .findOne(facility.pwsId, facility.facilityId)
          .subscribe((result) => {
            if (result) {
              result.name = facility.name;
              result.availabilityCode = facility.availabilityCode;
              result.statusCode = facility.statusCode;
              result.statusReason = facility.statusReason;
              result.downstreamFacility = facility.downstreamFacility;
              result.surveyNote = facility.surveyNote;
              result.designCapacity = facility.designCapacity;
              result.dcUnitMeasureCode = facility.dcUnitMeasureCode;
              result.emergencyCapacity = facility.emergencyCapacity;
              result.ecUnitMeasureCode = facility.ecUnitMeasureCode;
              this.saveSurfaceWater(result);
            }
          });
      }
    }
  }

  private saveSurfaceWater(surfaceWater: SurfaceWaterFacility) {
    const surfaceWaterFacilities: SurfaceWaterFacility[] = [];
    surfaceWaterFacilities.push(surfaceWater);
    this.waterSystemSurfaceWaterFacilityService.store(surfaceWaterFacilities);
    this.waterSystemSurfaceWaterFacilityService
      .findLocally(surfaceWater.pwsId)
      .subscribe((swf) => {
        this.surfaceWaterFacilityService.changeWaterSystemSurfaceWaterFacilities(swf);
      });
  }

  private saveStorageFacility(facility: Facility) {
    let storageFacility: StorageFacility;
    if (isStorageFacility(facility)) {
      storageFacility = facility;
      if (facility.newIndicator) {
        this.saveStorage(storageFacility);
      } else {
        this.waterSystemStorageFacilitiesService
          .findOne(facility.pwsId, facility.facilityId)
          .subscribe((result) => {
            if (result) {
              result.name = facility.name;
              result.availabilityCode = facility.availabilityCode;
              result.statusCode = facility.statusCode;
              result.statusReason = facility.statusReason;
              result.downstreamFacility = facility.downstreamFacility;
              result.surveyNote = facility.surveyNote;
              result.designCapacity = facility.designCapacity;
              result.dcUnitMeasureCode = facility.dcUnitMeasureCode;
              result.emergencyCapacity = facility.emergencyCapacity;
              result.ecUnitMeasureCode = facility.ecUnitMeasureCode;
              this.saveStorage(result);
            }
          });
      }
    }
  }

  private saveStorage(storage: StorageFacility) {
    const storageFacilities: StorageFacility[] = [];
    storageFacilities.push(storage);
    this.waterSystemStorageFacilitiesService.store(storageFacilities);
    this.waterSystemStorageFacilitiesService
      .findLocally(storage.pwsId)
      .subscribe((sf) => {
        this.storageService.changeWaterSystemStorageFacilities(sf);
      });
  }

  private saveTreatmentFacility(facility: FacilityAny) {
    let treatmentFacility: TreatmentPlant;
    if (isTreatmentPlant(facility)) {
      treatmentFacility = facility;
      if (facility.newIndicator) {
        this.saveTreatment(treatmentFacility);
      } else {
        this.waterSystemTreatmentFacilitiesService
          .findOne(facility.pwsId, facility.facilityId)
          .subscribe((result) => {
            if (result) {
              result.name = treatmentFacility.name;
              result.availabilityCode = treatmentFacility.availabilityCode;
              result.statusCode = treatmentFacility.statusCode;
              result.statusReason = treatmentFacility.statusReason;
              result.downstreamFacility = treatmentFacility.downstreamFacility;
              result.facilityTreatments = treatmentFacility.facilityTreatments;
              result.surveyNote = treatmentFacility.surveyNote;
              result.designCapacity = facility.designCapacity;
              result.dcUnitMeasureCode = facility.dcUnitMeasureCode;
              result.emergencyCapacity = facility.emergencyCapacity;
              result.ecUnitMeasureCode = facility.ecUnitMeasureCode;
              this.saveTreatment(result);
            }
          });
      }
    }
  }

  private saveTreatment(treatment: TreatmentPlant) {
    const treatmentFacilities: TreatmentPlant[] = [];
    treatmentFacilities.push(treatment);
    this.waterSystemTreatmentFacilitiesService.store(treatmentFacilities);
    this.waterSystemTreatmentFacilitiesService
      .findLocally(treatment.pwsId)
      .subscribe((tf) => {
        this.treatementService.changeWaterSystemTreatmentFacilities(tf);
      });
  }

  private saveDistributionFacility(facility: Facility) {
    let distributionFacility: DistributionFacility;
    if (isDistributionFacility(facility)) {
      distributionFacility = facility;
      if (facility.newIndicator) {
        this.saveDistribution(distributionFacility);
      } else {
        this.waterSystemDistributionFacilityService
          .findOne(facility.pwsId, facility.facilityId)
          .subscribe((result) => {
            if (result) {
              result.name = facility.name;
              result.availabilityCode = facility.availabilityCode;
              result.statusCode = facility.statusCode;
              result.statusReason = facility.statusReason;
              result.downstreamFacility = facility.downstreamFacility;
              result.surveyNote = facility.surveyNote;
              result.designCapacity = facility.designCapacity;
              result.dcUnitMeasureCode = facility.dcUnitMeasureCode;
              result.emergencyCapacity = facility.emergencyCapacity;
              result.ecUnitMeasureCode = facility.ecUnitMeasureCode;
              this.saveDistribution(result);
            }
          });
      }
    }
  }

  private saveDistribution(distribution: DistributionFacility) {
    const distributionFacilities: DistributionFacility[] = [];
    distributionFacilities.push(distribution);
    this.waterSystemDistributionFacilityService.store(distributionFacilities);
    this.waterSystemDistributionFacilityService
      .findLocally(distribution.pwsId)
      .subscribe((df) => {
        this.distributionService.changeWaterSystemFacilities(df);
      });
  }

  private saveBoosterStationFacility(facility: FacilityAny) {
    let boosterStation: BoosterStation;
    if (isBoosterStation(facility)) {
      boosterStation = facility;
      if (facility.newIndicator) {
        this.saveBoosterStation(boosterStation);
      } else {
        this.waterSystemBoosterStationFacilityService
          .findOne(facility.pwsId, facility.facilityId)
          .subscribe((result) => {
            if (result) {
              result.name = facility.name;
              result.availabilityCode = facility.availabilityCode;
              result.statusCode = facility.statusCode;
              result.statusReason = facility.statusReason;
              result.downstreamFacility = facility.downstreamFacility;
              result.surveyNote = facility.surveyNote;
              result.designCapacity = facility.designCapacity;
              result.dcUnitMeasureCode = facility.dcUnitMeasureCode;
              result.emergencyCapacity = facility.emergencyCapacity;
              result.ecUnitMeasureCode = facility.ecUnitMeasureCode;
              result.chlorination = facility.chlorination;
              this.saveBoosterStation(result);
            }
          });
      }
    }
  }

  private saveBoosterStation(boosterStation: BoosterStation) {
    const boosterStations: BoosterStation[] = [];
    boosterStations.push(boosterStation);
    this.waterSystemBoosterStationFacilityService.store(boosterStations);
    this.waterSystemBoosterStationFacilityService
      .findLocally(boosterStation.pwsId)
      .subscribe((boosterStationFacilities) => {
        this.boosterStationFacilityService.changeWaterSystemBoosterStationFacilities(
          boosterStationFacilities
        );
      });
  }

  private saveInterconnectFacility(facility: Facility) {
    let interconnect: Interconnect;
    if (isInterconnect(facility)) {
      interconnect = facility;
      if (facility.newIndicator) {
        this.saveInterconnect(interconnect);
      } else {
        this.waterSystemInterconnectFacilityService
          .findOne(facility.pwsId, facility.facilityId)
          .subscribe((result) => {
            if (result) {
              result.name = facility.name;
              result.availabilityCode = facility.availabilityCode;
              result.statusCode = facility.statusCode;
              result.statusReason = facility.statusReason;
              result.downstreamFacility = facility.downstreamFacility;
              result.surveyNote = facility.surveyNote;
              result.designCapacity = facility.designCapacity;
              result.dcUnitMeasureCode = facility.dcUnitMeasureCode;
              result.emergencyCapacity = facility.emergencyCapacity;
              result.ecUnitMeasureCode = facility.ecUnitMeasureCode;
              this.saveInterconnect(result);
            }
          });
      }
    }
  }

  private saveInterconnect(interconnect: Interconnect) {
    const interconnects: Interconnect[] = [];
    interconnects.push(interconnect);
    this.waterSystemInterconnectFacilityService.store(interconnects);
    this.waterSystemInterconnectFacilityService
      .findLocally(interconnect.pwsId)
      .subscribe((interconnectFacilities) => {
        this.interconnectFacilityService.changeWaterSystemInterconnectFacilities(
          interconnectFacilities
        );
      });
  }

  private getNextFacilityId(pwsId: number): Promise<IdbStorage<number>> {
    return this.localStorageService
      .dbRetrieve<IdbStorage<number>>(tables.waterSystemMaxFacilityId, pwsId)
      .toPromise();
  }

  private incrementNextFacilityId(pwsId: number, facilityId: number) {
    this.localStorageService.dbStoreStorageAny(
      tables.waterSystemMaxFacilityId,
      pwsId,
      facilityId
    );
  }

  private clearNewIndicatorFlow(facilityFlow: FacilityFlow) {
    facilityFlow.newIndicator = null;
    this.localStorageService.dbStore(
      tables.waterSystemFacilities,
      facilityFlow
    );
  }

  private clearNewIndicatorWell(well: Well) {
    well.newIndicator = null;
    this.localStorageService.dbStore(tables.waterSystemWell, well);
  }

  private clearNewIndicatorSurfaceWater(surfaceWater: SurfaceWaterFacility) {
    surfaceWater.newIndicator = null;
    this.localStorageService.dbStore(
      tables.waterSystemSurfaceWaterFacility,
      surfaceWater
    );
  }

  private clearNewIndicatorStorage(storage: StorageFacility) {
    storage.newIndicator = null;
    this.localStorageService.dbStore(
      tables.waterSystemStorageFacility,
      storage
    );
  }

  private clearNewIndicatorTreatment(treatment: TreatmentPlant) {
    treatment.newIndicator = null;
    this.localStorageService.dbStore(
      tables.waterSystemTreatmentFacility,
      treatment
    );
  }

  private clearNewIndicatorDistribution(distribution: DistributionFacility) {
    distribution.newIndicator = null;
    this.localStorageService.dbStore(
      tables.waterSystemDistribution,
      distribution
    );
  }

  private clearNewIndicatorBoosterStation(boosterStation: BoosterStation) {
    boosterStation.newIndicator = null;
    this.localStorageService.dbStore(
      tables.waterSystemBoosterStation,
      boosterStation
    );
  }

  private clearNewIndicatorInterconnect(interconnect: Interconnect) {
    interconnect.newIndicator = null;
    this.localStorageService.dbStore(
      tables.waterSystemInterconnectFacility,
      interconnect
    );
  }

  deleteFacility(facility: FacilityAny) {
    this.deleteFromFlow(facility);
    switch (facility.facilityCode) {
      case 'WL':
        this.waterSystemWellsService.deleteOne(facility).subscribe(() => {
          this.waterSystemWellsService.find(facility.pwsId).subscribe(wells => {
            this.wellsService.changeWaterSystemWells(wells);
          });
        });
        break;
      case 'IN':
        this.waterSystemSurfaceWaterFacilityService
          .deleteOne(facility)
          .subscribe(() => {
            this.waterSystemSurfaceWaterFacilityService.find(facility.pwsId).subscribe(results => {
              this.surfaceWaterFacilityService.changeWaterSystemSurfaceWaterFacilities(results);
            });
          });
          break;
      case 'SH':
      case 'SU':
      case 'ST':
      case 'SS':
      case 'SE':
        this.waterSystemStorageFacilitiesService
          .deleteOne(facility)
          .subscribe(() => {
            this.waterSystemStorageFacilitiesService.find(facility.pwsId).subscribe(results => {
              this.storageService.changeWaterSystemStorageFacilities(results);
            });
          });
          break;
      case 'TP':
        this.waterSystemTreatmentFacilitiesService
          .deleteOne(facility)
          .subscribe(() => {
            this.waterSystemTreatmentFacilitiesService.find(facility.pwsId).subscribe(results => {
              this.treatementService.changeWaterSystemTreatmentFacilities(results);
            });
          });
          break;
      case 'DS':
        this.waterSystemDistributionFacilityService
          .deleteOne(facility)
          .subscribe(() => {
            this.waterSystemDistributionFacilityService.find(facility.pwsId).subscribe(results => {
              this.distributionService.changeWaterSystemFacilities(results);
            });
          });
          break;
      case 'BS':
        this.waterSystemBoosterStationFacilityService
          .deleteOne(facility)
          .subscribe(() => {
            this.waterSystemBoosterStationFacilityService.find(facility.pwsId).subscribe(results => {
              this.boosterStationFacilityService.changeWaterSystemBoosterStationFacilities(results);
            });
          });
          break;
      case 'CC':
        this.waterSystemInterconnectFacilityService
          .deleteOne(facility)
          .subscribe(() => {
            this.waterSystemInterconnectFacilityService.find(facility.pwsId).subscribe(results => {
              this.interconnectFacilityService.changeWaterSystemInterconnectFacilities(results);
            });
          });
          break;
      case 'NP':
      default:
        console.log(`Unknown Facility Type ${facility.facilityCode}`);
    }
  }

  deleteFacilities(facilities: FacilityAny[]) {
    if (facilities && facilities.length > 0) {
      for (const facility of facilities) {
        this.deleteFacility(facility);
      }
    }
  }
}
