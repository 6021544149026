import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DistributionFacility } from "../../domain/facility";

@Injectable({
  providedIn: 'root'
})
export class DistributionFacilityService {

  private facilities = new BehaviorSubject<DistributionFacility[]>([]);
  facilities$ = this.facilities.asObservable();

  constructor() { }

  changeWaterSystemFacilities(facilities: DistributionFacility[]) {
    this.facilities.next(facilities);
  }
}
