import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {WaterSystem} from 'src/app/domain/water-system';
import {Checkout} from 'src/app/domain/checkout';

@Injectable({
  providedIn: 'root'
})
export class BasicDetailsService {

  private waterSystem = new BehaviorSubject<Checkout<WaterSystem>>(null);
  waterSystem$ = this.waterSystem.asObservable();

  constructor() { }

  changeWaterSystem(system: Checkout<WaterSystem>) {
    this.waterSystem.next(system);
  }
}
