import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectivityService } from 'src/app/connectivity/connectivity.service';
import { Questionnaire } from 'src/app/domain/questionnaire';
import { QUESTIONNAIRE_TYPES } from 'src/app/shared/questionnaire-type';
import { ConfigurationService } from '../../configuration.service';
import { ConfirmatoryActionService } from 'src/app/confirmatory-action/confirmatory-action.service';
import { QuestionnairesService } from '../questionnaires.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'src/app/message/message.service';
import { Severity } from 'src/app/message/Severity';
import { ToastService } from 'src/app/toast/toast.service';
import { Toast } from 'src/app/toast/toast';

@Component({
  selector: 'app-edit-questionnaire',
  templateUrl: './edit-questionnaire.component.html',
  styleUrls: ['./edit-questionnaire.component.scss']
})
export class EditQuestionnaireComponent implements OnInit {

  questionnaire: Questionnaire;
  categories: string[];
  newCategory: string = null;
  deleteCategory: string = null;
  error = null;
  categoryExists: boolean = false;

  public isOnline: boolean;
  public isQuestionnaireLoading: boolean = true;
  public isQuestionnaireAdding: boolean = false;
  public isCategoriesLoading: boolean = true;
  public isCategoriesSaving: boolean = false;
  public isQuestionnaireDeleting: boolean = false;
  public add: boolean = false;

  readonly QUESTIONNAIRE_TYPES: any[] = QUESTIONNAIRE_TYPES;

  private CONFIG_TYPE_QUESTIONNAIRES_CATEGORY: string = 'questionnaireCategory';
  private modalRef: NgbModalRef;

  constructor(private connectivityService: ConnectivityService,
              private configurationService: ConfigurationService,
              private confirmatoryActionService: ConfirmatoryActionService,
              private questionnairesService: QuestionnairesService,
              private route: ActivatedRoute,
              private modalService: NgbModal,
              private messageService: MessageService,
              private router: Router,
              public toastService: ToastService) { }

  ngOnInit(): void {
    this.isOnline = this.connectivityService.isOnline();
    this.connectivityService.online$().subscribe(online => {
      this.isOnline = online;
    });
    this.getCategories();
    this.questionnaire = history.state.data;
    if (!this.questionnaire) {
      this.getQuestionnaire();
    } else {
      this.isQuestionnaireLoading = false;
    }
  }

  getQuestionnaire() {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id == null) {
        this.questionnaire = {
          id: null,
          item: null,
          category: null,
          description: null,
          activeInd: false,
          question: [],
          facilityStatusCode: null
        };
        this.isQuestionnaireLoading = false;
      }

      this.questionnairesService.get(id).subscribe(
        questionnaire => {
          if (questionnaire) {
            this.questionnaire = questionnaire;
          }
          this.isQuestionnaireLoading = false;
        },
        error => {
          this.error = error;
          this.isQuestionnaireLoading = false;
        }
      );
    });
  }

  getCategories() {
    this.configurationService.get(this.CONFIG_TYPE_QUESTIONNAIRES_CATEGORY).subscribe(
      categories => {
        if (categories) {
          this.categories = categories;
        } else {
          this.categories = [];
        }
        this.isCategoriesLoading = false;
      },
      error => {
        this.error = error;
        this.isCategoriesLoading = false;
      },
    )
  }

  deleteQuestionnaire() {
    this.messageService.clear();
    this.confirmatoryActionService.confirm("Confirmation", "Are you sure you want to delete this questionnaire?", "Yes", "No", "sm")
      .then((confirmed) => {
        if (confirmed) {
          this.isQuestionnaireDeleting = true;
          this.questionnairesService.deleteRemotely(this.questionnaire).subscribe(
            () => {
              this.isQuestionnaireDeleting = false;
              this.router.navigateByUrl('/admin/questionnaires');
              this.messageService.add({ severity: Severity.SUCCESS, value: 'The Question Set has been deleted.' });
            }
          );
        }
      })
      .catch(() => {});
  }

  addQuestion() {
    this.add = !this.add;
  }

  openAddCategoryModal(content) {
    this.newCategory = null;
    this.categoryExists = false;
    this.modalRef = this.modalService.open(content, { centered: true });
  }

  saveCategory() {
    this.messageService.clear();
    if (this.newCategory) {
      if (this.categories && this.categories.length > 0) {
        for (let c of this.categories) {
          if (this.newCategory.toUpperCase() === c.toUpperCase()) {
            this.categoryExists = true;
            return;
          }
        }
      } else {
        this.categories = [];
      }

      this.isCategoriesSaving = true;
      this.categoryExists = false;
      this.categories.push(this.newCategory);
      this.updateCategories('The category has been added', 'The category failed to be added due to: ');
    }
  }

  openDeleteCategoryModal(content) {
    this.deleteCategory = null;
    this.modalRef = this.modalService.open(content, { centered: true });
  }

  removeCategory() {
    this.messageService.clear();
    this.confirmatoryActionService.confirm(
        "Confirmation",
        "Are you sure you want to delete this category? It will be removed from ALL question sets.",
        "Yes",
        "No",
        "sm")
      .then((confirmed) => {
        if (confirmed) {
          this.isCategoriesSaving = true;
          for (let i = 0; i < this.categories.length; i++) {
            if (this.deleteCategory === this.categories[i]) {
              this.categories.splice(i, 1);
              break;
            }
          }
          this.updateCategories('The category has been deleted', 'The category failed to delete due to: ');
          this.deleteCategoryFromQuestionSets();
        }
      })
      .catch(() => {});
  }

  updateCategories(successMessage: string, failMessage: string) {
    this.configurationService.update(this.CONFIG_TYPE_QUESTIONNAIRES_CATEGORY, this.categories).subscribe(
      () => {
        this.isCategoriesSaving = false;
        const now: Date = new Date();
        const toast: Toast = {
          text: successMessage + ' at ' + now.toLocaleString(),
          classname: 'toast bg-success fade show',
          autohide: true,
          delay: 5000,
          showLoading: false
        };
        this.modalRef.close();
        this.toastService.show(toast);
        setTimeout(() => this.toastService.remove(toast), 5000);
        this.questionnaire.category = this.newCategory;
        this.onChange('Question Set Category');
      },
      error => {
        this.isCategoriesSaving = false;
        this.messageService.add({ severity: Severity.ERROR, value: failMessage + error.message });
        this.modalRef.close();
      }
    );
  }

  onChange(field: string) {
    this.messageService.clear();
    if (this.questionnaire?.id == null) {
      return;
    } else {
      if (this.questionnaire.item != null && this.questionnaire.description !== '') {
        const updatingToast = this.showUpdatingToast(field);
        this.updateQuestionnaire(updatingToast, field);
      } else {
        return;
      }
    }
  }

  updateQuestionnaire(updatingToast: Toast = null, field: string = null) {
    if (this.questionnaire.item !== 'PWS' && this.questionnaire.category) {
      this.questionnaire.category = null;
    }

    this.questionnairesService.upsertRemotely(this.questionnaire).subscribe(
      () => {
        if (this.questionnaire.id == null) {
          this.getNewlyAddedQuestionnaire();
        } else {
          setTimeout(() => this.toastService.remove(updatingToast), 1000);
          this.showUpdatedToast(field);
        }
      },
      error => {
        this.toastService.remove(updatingToast);
        this.messageService.add({ severity: Severity.ERROR, value: 'The Question Set failed to save due to: ' + error.message });
      }
    );
  }

  getNewlyAddedQuestionnaire() {
    this.questionnairesService.getAll().subscribe(
      questionnaires => {
        if (questionnaires) {
          this.questionnaire = this.sortQuestionnairesByIdDesc(questionnaires)[questionnaires.length - 1];
        }
        this.isQuestionnaireAdding = false;
        this.router.navigate(['/admin/questionnaires/edit', this.questionnaire.id]);
        const now = new Date();
        const toast = {
          text: 'The Question Set was added at ' + now.toLocaleString(),
          classname: 'toast bg-success fade show',
          autohide: true,
          delay: 5000,
          showLoading: false
        };
        this.toastService.show(toast);
        setTimeout(() => this.toastService.remove(toast), 5000);
      }
    )
  }

  sortQuestionnairesByIdDesc(questionnaires: Questionnaire[]): Questionnaire[] {
    if (questionnaires == null || questionnaires.length < 2) {
      return questionnaires;
    }

    return questionnaires.sort((a, b) => {
      if (a.id) {
        if (b.id) {
          return a.id - b.id;
        } else {
          return 1;
        }
      } else if (b.id) {
        return -1;
      } else {
        return 0;
      }
    })
  }

  deleteCategoryFromQuestionSets() {
    let questionnaires: Questionnaire[];
    this.questionnairesService.getAll().subscribe(
      results => {
        questionnaires = results;
        for (let q of questionnaires) {
          if (this.deleteCategory === q.category) {
            q.category = null;
            this.questionnairesService.upsertRemotely(q).subscribe(
              () => {}
            );
          }
        }
      }
    );
  }

  addQuestionnaire() {
    if (this.questionnaire.id != null) {
      return;
    }

    this.messageService.clear();
    if (this.questionnaire.item != null && this.questionnaire.description != null && this.questionnaire.description !== '') {
      this.isQuestionnaireAdding = true;
      this.updateQuestionnaire();
    }
  }

  showUpdatingToast(field: string): Toast {
    const toast = {
      text: 'Updating ' + field + '...',
      classname: 'toast bg-warning fade show',
      autohide: false,
      delay: 5000,
      showLoading: true
    };
    this.toastService.show(toast);
    return toast;
  }

  showUpdatedToast(field: string) {
    const now = new Date();
    const toast = {
      text: field + ' was updated at ' + now.toLocaleString(),
      classname: 'toast bg-success fade show',
      autohide: true,
      delay: 5000,
      showLoading: false
    };
    this.toastService.show(toast);
    setTimeout(() => this.toastService.remove(toast), 5000);
  }

  updateQuestion(questionChange: {questionnaire: Questionnaire, changedField: string}) {
    this.onChange(questionChange.changedField);
  }

  onActiveChange(field: string) {
    if (this.questionnaire.activeInd) {
      for (let i = 0; i < this.questionnaire.question.length; i++) {
        if (this.questionnaire.question[i].type === 'Section Header' || (this.questionnaire.question[i].answers && this.questionnaire.question[i].answers.length > 0)) {
          this.questionnaire.question[i].questionActiveInd = true;
        }
      }
    }
    this.onChange(field);
  }

  isActiveDisabled() {
    if (this.questionnaire.id == null || !this.questionnaire.question || this.questionnaire.question.length == 0 || !this.hasValidQuestions()) {
      return true;
    }
    return false;
  }

  hasValidQuestions() {
    if (this.questionnaire.question && this.questionnaire.question.length > 0) {
      for (let question of this.questionnaire.question) {
        if ((question.type === 'Finding' || question.type === 'Question') && (question.answers && question.answers.length > 0)) {
          return true;
        }
      }
    }
    return false;
  }
}
