<div id="water-system-production-source" *ngIf="waterSystem">
    <div class="border p-2 panel-collapsible-header">
      <a href="javascript:void(0);" (click)="isProductionSourceCollapsed = !isProductionSourceCollapsed"
         [attr.aria-expanded]="!isProductionSourceCollapsed"
         aria-controls="collapse-production-source">
        <div>
          <h2>
            Production &amp; Source Water Protection
            <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isProductionSourceCollapsed"></i>
            <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isProductionSourceCollapsed"></i>
          </h2>
        </div>
      </a>
    </div>
    <div id="collapse-production-source" class="card" [ngbCollapse]="isProductionSourceCollapsed">
      <div class="card-body row">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <span><strong>Production Data</strong></span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <fieldset class="form-group">
                <div class="row">
                  <legend id="production-source-design-capacity-label" class="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12">Design Capacity:</legend>
                  <div class="col-xl-5 col-lg-6 col-md-6 col-sm-9 col-12 input-group">
                    <input id="production-source-design-capacity-amount"
                           class="form-control"
                           aria-label="Amount"
                           [(ngModel)]="waterSystem.data.designCapacity"
                           type="number"
                           min="0"
                           [minnumber]="0"
                           max="999999999"
                           [maxnumber]="999999999"
                           (ngModelChange)="onChange()"
                           #designCapacityAmount="ngModel"
                           numbersOnly/>
                    <div class="input-group-append">
                      <label for="production-source-design-capacity-uom" hidden="true">Design Capacity Unit of Measure</label>
                      <select id="production-source-design-capacity-uom"
                              class="form-control"
                              name="production-source-design-capacity-uom"
                              [(ngModel)]="waterSystem.data.designCapacityMetric"
                              (ngModelChange)="onChange()">
                        <option *ngFor="let type of UNIT_MEASURE_CAPACITY_TYPES" value="{{type.code}}">
                          {{type.value}}
                        </option>
                      </select>
                    </div>
                    <div class="col-12"></div>
                    <div *ngIf="designCapacityAmount.invalid && (designCapacityAmount.dirty || designCapacityAmount.touched)" class="alert alert-danger">
                      <div *ngIf="designCapacityAmount.errors.min">
                        The value cannot be negative.
                      </div>
                      <div *ngIf="designCapacityAmount.errors.max">
                        The value cannot exceed 999999999.
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12">
              <fieldset class="form-group">
                <div class="row">
                  <legend id="production-source-emergency-capacity-label" class="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12">Emergency Capacity:</legend>
                  <div class="col-xl-5 col-lg-6 col-md-6 col-sm-9 col-12 input-group">
                    <input id="production-source-emergency-capacity-amount"
                           class="form-control"
                           aria-label="Amount"
                           [(ngModel)]="waterSystem.data.emergencyCapacity"
                           type="number"
                           min="0"
                           [minnumber]="0"
                           max="999999999"
                           [maxnumber]="999999999"
                           (ngModelChange)="onChange()"
                           #emergencyCapacityAmount="ngModel"
                           numbersOnly/>
                    <div class="input-group-append">
                      <label for="production-source-emergency-capacity-uom" hidden="true">Emergency Capacity Unit of Measure</label>
                      <select id="production-source-emergency-capacity-uom"
                              class="form-control"
                              name="production-source-emergency-capacity-uom"
                              [(ngModel)]="waterSystem.data.emergencyCapacityMetric"
                              (ngModelChange)="onChange()">
                        <option *ngFor="let type of UNIT_MEASURE_CAPACITY_TYPES" value="{{type.code}}">
                          {{type.value}}
                        </option>
                      </select>
                    </div>
                    <div class="col-12"></div>
                    <div *ngIf="emergencyCapacityAmount.invalid && (emergencyCapacityAmount.dirty || emergencyCapacityAmount.touched)" class="alert alert-danger">
                      <div *ngIf="emergencyCapacityAmount.errors.min">
                        The value cannot be negative.
                      </div>
                      <div *ngIf="emergencyCapacityAmount.errors.max">
                        The value cannot exceed 999999999.
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12">
              <fieldset class="form-group">
                <div class="row">
                  <legend id="production-source-max-daily-production-label" class="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12">Max Daily Production:</legend>
                  <div class="col-xl-5 col-lg-6 col-md-6 col-sm-9 col-12 input-group">
                    <input id="production-source-max-daily-production-capacity-amount"
                           class="form-control"
                           aria-label="Amount"
                           [(ngModel)]="waterSystem.data.maxDailyProduction"
                           type="number"
                           min="0"
                           [minnumber]="0"
                           max="999999999"
                           [maxnumber]="999999999"
                           (ngModelChange)="onChange()"
                           #maxDailyProductionAmount="ngModel"
                           numbersOnly/>
                    <div class="input-group-append">
                      <label for="production-source-max-daily-production-uom" hidden="true">Max Daily Production Unit of Measure</label>
                      <select id="production-source-max-daily-production-uom"
                              class="form-control"
                              name="production-source-max-daily-production-uom"
                              [(ngModel)]="waterSystem.data.maxDailyProductionMetric"
                              (ngModelChange)="onChange()">
                        <option *ngFor="let type of UNIT_MEASURE_CAPACITY_TYPES" value="{{type.code}}">
                          {{type.value}}
                        </option>
                      </select>
                    </div>
                    <div class="col-12"></div>
                    <div *ngIf="maxDailyProductionAmount.invalid && (maxDailyProductionAmount.dirty || maxDailyProductionAmount.touched)" class="alert alert-danger">
                      <div *ngIf="maxDailyProductionAmount.errors.min">
                        The value cannot be negative.
                      </div>
                      <div *ngIf="maxDailyProductionAmount.errors.max">
                        The value cannot exceed 999999999.
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12">
              <fieldset class="form-group">
                <div class="row">
                  <legend id="production-source-avg-daily-production-label" class="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12">Avg. Daily Production:</legend>
                  <div class="col-xl-5 col-lg-6 col-md-6 col-sm-9 col-12 input-group">
                    <input id="production-source-avg-daily-production-capacity-amount"
                           class="form-control"
                           aria-label="Amount"
                           [(ngModel)]="waterSystem.data.averageDailyProduction"
                           type="number"
                           min="0"
                           [minnumber]="0"
                           max="999999999"
                           [maxnumber]="999999999"
                           (ngModelChange)="onChange()"
                           #avgDailyProductionAmount="ngModel"
                           numbersOnly/>
                    <div class="input-group-append">
                      <label for="production-source-avg-daily-production-uom" hidden="true">Avg. Daily Production Unit of Measure</label>
                      <select id="production-source-avg-daily-production-uom"
                              class="form-control"
                              name="production-source-avg-daily-production-uom"
                              [(ngModel)]="waterSystem.data.averageDailyProductionMetric"
                              (ngModelChange)="onChange()">
                        <option *ngFor="let type of UNIT_MEASURE_CAPACITY_TYPES" value="{{type.code}}">
                          {{type.value}}
                        </option>
                      </select>
                    </div>
                    <div class="col-12"></div>
                    <div *ngIf="avgDailyProductionAmount.invalid && (avgDailyProductionAmount.dirty || avgDailyProductionAmount.touched)" class="alert alert-danger">
                      <div *ngIf="avgDailyProductionAmount.errors.min">
                        The value cannot be negative.
                      </div>
                      <div *ngIf="avgDailyProductionAmount.errors.max">
                        The value cannot exceed 999999999.
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12">
              <span><strong>Storage Capacity:</strong></span>
              <br/>
              <span>{{formattedStorageCapacity()}}</span>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12">
              <span><strong>Source Water Protection Data</strong></span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <span><strong>Tier:</strong></span>
              <br/>
              <span>{{waterSystem.data.tier}}</span>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12">
              <span><strong>Score:</strong></span>
              <br/>
              <span>{{waterSystem.data.score}}</span>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12">
              <span><strong>Ranking:</strong></span>
              <br/>
              <span>{{waterSystem.data.ranking}}</span>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12">
              <span><strong>WHP Program Status:</strong></span>
              <br/>
              <span>{{waterSystem.data.whpProgramStatus | whpProgramStatusType}}</span>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12">
              <span><strong>WHP Ranking:</strong></span>
              <br/>
              <span>{{waterSystem.data.whpRanking}}</span>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12">
              <span><strong>Last Ranked:</strong></span>
              <br/>
              <span>{{waterSystem.data.lastRanked | date: 'MM/dd/yyyy'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
