import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Visit, VISIT_STATUS } from '../../../domain/visit';
import { Unsubscribable } from 'rxjs';
import { SiteVisitsService } from '../site-visits.service';
import { FindingTypeCollection } from '../../../domain/inspection';
import { SiteVisit } from '../../../domain/site-visit';
import { IamService } from '../../../utils/iam.service';
import { KeycloakProfile } from 'keycloak-js';
import { SiteVisitService } from '../../../site-visit.service';
import { StorageService } from '../../../utils/storage.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { WaterSystemContactsService } from '../../water-system-contacts.service';
import { Contact, ContactAction } from '../../../domain/contact';
import { WaterSystemSiteVisitsService } from '../../water-system-site-visits.service';
import { ConnectivityService } from '../../../connectivity/connectivity.service';

@Component({
  selector: 'app-report-summary',
  templateUrl: './report-summary.component.html',
})
export class ReportSummaryComponent implements OnInit, OnDestroy {

  @Input()
  siteVisit: SiteVisit;

  isCollapsed = false;
  isLoading = {
    pwsId: false,
    siteVisit: false,
    userDetails: false,
    currentVisit: false,
    findings: false,
    systemContact: false
  };
  currentVisit: Visit;
  pwsId: number;
  findings: FindingTypeCollection;
  systemContact: Contact;
  isOnline: boolean;

  private visits$: Unsubscribable;
  private contactsLocal$: Unsubscribable;
  private contactsRemote$: Unsubscribable;
  private visit$: Unsubscribable;
  private userDetails: KeycloakProfile;

  constructor(private siteVisitsService: SiteVisitsService,
              private iamService: IamService,
              private route: ActivatedRoute,
              private checkedOutSiteVisitService: SiteVisitService,
              private waterSystemContactsService: WaterSystemContactsService,
              private waterSystemSiteVisitsService: WaterSystemSiteVisitsService,
              private connectivityService: ConnectivityService) { }

  ngOnInit() {
    this.isOnline = this.connectivityService.isOnline();
    this.connectivityService.online$().subscribe(online => {
      this.isOnline = online;
    });
    this.setIsLoading(true);
    if (!this.siteVisit) {
      this.route.parent.params.subscribe(params => {
        this.pwsId = +params['id'];
        this.isLoading.pwsId = false;
      });
      this.iamService.getUserDetails().then(result => {
        this.userDetails = result;
        this.isLoading.userDetails = false;
        this.getCheckedOutSiteVisit();
        this.findCurrentVisit();
      });
    } else {
      this.isLoading.siteVisit = false;
      this.isLoading.userDetails = false;
      this.route.params.subscribe(params => {
        this.pwsId = +params['id'];
        this.isLoading.pwsId = false;
      });
      this.findCurrentVisit();
    }
    this.findSystemContact();
  }

  setIsLoading(loading: boolean) {
    Object.keys(this.isLoading).forEach((l) => (this.isLoading[l] = loading));
  }

  getCheckedOutSiteVisit() {
    this.checkedOutSiteVisitService.getLocal(
      `${SiteVisitService.checkedOutKey}-${this.userDetails.username}`
    ).pipe(first()).subscribe(checkedOut => {
      if (checkedOut && checkedOut.results.length > 0) {
        for (const pws of checkedOut.results) {
          if (pws.id === this.pwsId) {
            this.siteVisit = pws;
          }
        }
      }
      this.isLoading.siteVisit = false;
    });
  }

  findCurrentVisit(): void {
    this.visits$ = this.siteVisitsService.visits$.subscribe(siteVisits => {
      if (siteVisits && siteVisits.length > 0) {
        const foundVisit = siteVisits.find(s => s.status.toUpperCase() === VISIT_STATUS.IN_PROGRESS);
        if (foundVisit) {
          this.currentVisit = foundVisit;
          this.isLoading.currentVisit = false;
          this.findFindings();
        } else {
          this.isLoading.currentVisit = false;
          this.isLoading.findings = false;
        }
      } else {
        this.isLoading.currentVisit = false;
        this.isLoading.findings = false;
      }
    }, error => {
      console.log(error);
      this.isLoading.currentVisit = false;
      this.isLoading.findings = false;
    });
  }

  findFindings(): void {
    this.visit$ = this.waterSystemSiteVisitsService.findOneRemotely(this.currentVisit.id).subscribe(visit => {
      if (visit && visit.inspection && visit.inspection.findingTypeCollection) {
        this.findings = visit.inspection.findingTypeCollection;
        this.isLoading.findings = false;
      } else {
        this.isLoading.findings = false;
      }
    }, error => {
      console.log(error);
      this.isLoading.findings = false;
    });
  }

  findSystemContact() {
    this.contactsLocal$ = this.waterSystemContactsService.findLocally(this.pwsId).subscribe(contactsLocal => {
      if (!contactsLocal) {
        this.contactsRemote$ = this.waterSystemContactsService.findRemotely(this.pwsId).subscribe(contactsRemote => {
          if (contactsRemote
            && contactsRemote.data
            && contactsRemote.data instanceof Array
            && contactsRemote.data.length > 0) {
            this.setSystemContact(contactsRemote.data);
          }
          this.isLoading.systemContact = false;
        }, error => {
          console.log(error);
          this.isLoading.systemContact = false;
        });
      } else {
        if (contactsLocal.data
          && contactsLocal.data instanceof Array
          && contactsLocal.data.length > 0) {
          this.setSystemContact(contactsLocal.data);
        }
        this.isLoading.systemContact = false;
      }
    }, error => {
      console.log(error);
      this.isLoading.systemContact = false;
    });
  }

  setSystemContact(contacts: Contact[]) {
    this.systemContact = contacts.find(
      c => c.isPrimary
        && (!c.action || c.action === ContactAction.Create || c.action === ContactAction.Update)
        && c.role === 'CONTAC');
  }

  get anyIsLoading(): boolean {
    return !Object.values(this.isLoading).every((l) => l === false);
  }

  get isSiteVisitInspector() {
    return this.userDetails
      && this.siteVisit
      && this.siteVisit.username === this.userDetails.username;
  }

  ngOnDestroy() {
    if (this.visits$ != null) {
      this.visits$.unsubscribe();
    }
    if (this.contactsLocal$ != null) {
      this.contactsLocal$.unsubscribe();
    }
    if (this.contactsRemote$ != null) {
      this.contactsRemote$.unsubscribe();
    }
    if (this.visit$ != null) {
      this.visit$.unsubscribe();
    }
  }
}
