<div>
  <div class="row">
    <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <button class="btn btn-outline-dark btn-sm py-0" title="Expand All" aria-label="Expand All" (click)="expandAll()" type="button">
        <span>Expand All</span>
      </button>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <button class="btn btn-outline-dark btn-sm py-0" title="Collapse All" aria-label="Collapse All" (click)="collapseAll()" type="button">
        <span>Collapse All</span>
      </button>
    </div>
  </div>
  <div class="row pt-1">
    <div class="col-12">
      <div class="border p-2 panel-collapsible-header">
        <a href="javascript:void(0);" (click)="isPhotoCollapsed = !isPhotoCollapsed"
          [attr.aria-expanded]="!isPhotoCollapsed" aria-controls="collapse-photo">
          <div>
            Photo:
            <span *ngIf="isPhotoRequired" [ngClass]="{ 'required' : !photo }"> (required)</span>
            <span *ngIf="photo" data-toggle="tooltip" title="A photo is attached"><i class="fas fa-info-circle fa-xs align-top"></i></span>
            <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isPhotoCollapsed"></i>
            <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isPhotoCollapsed"></i>
          </div>
        </a>
      </div>
      <div id="collapse-photo" class="card" [ngbCollapse]="isPhotoCollapsed">
        <div class="card-body">
          <button class="btn p-0" title="Open Camera" aria-label="Open Camera" (click)="openCapturePictureModal(contentVisitCamera)" type="button">
            <i class="fas fa-camera fa-2x"></i>
          </button>
          <span class="pl-1">OR</span>
          <div class="custom-file">
            <input type="file" class="custom-file-input" id="photo-upload" (change)="onPhotoChange($event)" accept="image/*" [required]="isPhotoRequired" [ngClass]="{ 'ng-touched ng-invalid' : isPhotoRequired && !photo }">
            <label class="custom-file-label" for="photo-upload">Drop image file OR click in this box to browse.</label>
          </div>
          <div *ngIf="showImagesOnlyMessage" class="alert alert-danger">
            The selected file is not an image.
          </div>
          <div *ngIf="photo" class="pt-2">
            <canvas [id]="generatePhotoThumbnailId()" (click)="openPhoto()"></canvas>
            <button type="button" class="btn btn-sm py-0 align-top" attr.aria-label="Delete Photo" (click)="removePhoto()">
              <span aria-hidden="true"><i class="fas fa-times fa-xs"></i></span>
            </button>
          </div>
          <span *ngIf="!photo" class="small">No photo selected</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="border p-2 panel-collapsible-header">
        <a href="javascript:void(0);" (click)="isAttachmentsCollapsed = !isAttachmentsCollapsed"
          [attr.aria-expanded]="!isAttachmentsCollapsed" aria-controls="collapse-attachments">
          <div>
            Attachment(s):
            <span *ngIf="isAttachmentRequired" [ngClass]="{ 'required' : !attachments || attachments.length == 0 }"> (required)</span>
            <span *ngIf="attachments && attachments.length > 0" data-toggle="tooltip" title="Has one or more attachments"><i class="fas fa-info-circle fa-xs align-top"></i></span>
            <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isAttachmentsCollapsed"></i>
            <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isAttachmentsCollapsed"></i>
          </div>
        </a>
      </div>
      <div id="collapse-attachments" class="card" [ngbCollapse]="isAttachmentsCollapsed">
        <div class="card-body">
          <div class="custom-file">
            <input type="file" class="custom-file-input" id="attachment-upload" (change)="onAttachmentChange($event)" multiple [required]="isAttachmentRequired" [ngClass]="{ 'ng-touched ng-invalid' : isAttachmentRequired && (!attachments || attachments.length == 0) }">
            <label class="custom-file-label" for="attachment-upload">Drop file(s) or click in this box to browse.</label>
          </div>
          <div *ngIf="attachments && attachments.length > 0" id="attachments" class="pt-1">
            <div *ngFor="let attachment of attachments; let i = index">
              <button type="button" class="btn btn-outline-dark py-0" attr.aria-label="View File {{ attachment.filename }}" (click)="openAttachment(attachment)">View {{ attachment.filename }}</button>
              <button type="button" class="btn btn-sm py-0" attr.aria-label="Delete {{ attachment.filename }}" (click)="removeAttachment(i)">
                <span aria-hidden="true"><i class="fas fa-times fa-xs"></i></span>
              </button>
            </div>
          </div>
          <span *ngIf="!attachments || attachments.length == 0" class="small">No attachments selected</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="border p-2 panel-collapsible-header">
        <a href="javascript:void(0);" (click)="isNoteCollapsed = !isNoteCollapsed"
          [attr.aria-expanded]="!isNoteCollapsed" aria-controls="collapse-note">
          <div>
            {{ noteLabelOverride }}
            <span *ngIf="isNoteRequired" [ngClass]="{ 'required' : !question.note }"> (required)</span>:
            <span *ngIf="question.note && question.note !== ''" data-toggle="tooltip" title="A note is entered"><i class="fas fa-info-circle fa-xs align-top"></i></span>
            <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isNoteCollapsed"></i>
            <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isNoteCollapsed"></i>
          </div>
        </a>
      </div>
      <div id="collapse-note" class="card" [ngbCollapse]="isNoteCollapsed">
        <div class="card-body">
          <div class="form-group">
            <label for="note" [hidden]="true">{{ noteLabelOverride }}<span *ngIf="isNoteRequired" [ngClass]="{ 'required' : !question.note }"> (required)</span>:</label>
            <textarea class="form-control" id="note" name="note" [(ngModel)]="question.note" maxlength="1000" rows="2" [required]="isNoteRequired" [ngClass]="{ 'ng-invalid' : isNoteRequired && !question.note }" (change)="save()"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="question.finding" class="row">
    <div class="col-12">
      <div class="border p-2 panel-collapsible-header">
        <a href="javascript:void(0);" (click)="isFindingCollapsed = !isFindingCollapsed"
          [attr.aria-expanded]="!isFindingCollapsed" aria-controls="collapse-finding">
          <div>
            Finding:
            <span *ngIf="question.findingNote && question.findingNote !== ''" data-toggle="tooltip" title="Additional finding comments are entered"><i class="fas fa-info-circle fa-xs align-top"></i></span>
            <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isFindingCollapsed"></i>
            <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isFindingCollapsed"></i>
          </div>
        </a>
      </div>
      <div id="collapse-finding" class="card" [ngbCollapse]="isFindingCollapsed">
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <span>Code: </span>
              <br/>
              <span>{{ question.finding.code }}</span>
            </div>
            <div class="form-group col-6">
              <label for="{{ 'question-' + question.uuid + '-finding-type' }}">Type:</label>
              <select id="{{ 'question-' + question.uuid + '-finding-type' }}" class="form-control"
                      [(ngModel)]="question.finding.description" (change)="saveFindingType()">
                <option [value]="findingType" *ngFor="let findingType of findingTypes">{{findingType}}</option>
              </select>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12">
              <span>Language:</span>
              <div>
                <span *ngIf="isShowCompleteFindingLanguage" class="white-space-break-spaces">{{ question.finding.language }}<br/></span>
                <span *ngIf="!isShowCompleteFindingLanguage" class="d-block overflow-hidden text-truncate text-nowrap">{{ question.finding.language }}</span>
                <button type="button" class="btn btn-outline-dark btn-sm py-0" (click)="toggleShowCompleteFindingLanguage()">
                  <span>{{ isShowCompleteFindingLanguage ? 'Collapse' : 'Expand' }} Language</span>
                </button>
              </div>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12">
              <div class="form-group pb-2">
                <label for="{{ 'question-' + question.uuid + '-finding-note' }}">Additional Finding Comments:</label>
                <textarea id="{{ 'question-' + question.uuid + '-finding-note' }}" class="form-control" [(ngModel)]="question.findingNote" maxlength="1000" rows="2" (change)="save()"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #contentVisitCamera let-modal>
  <div class="modal-header">
    <h3 class="modal-title">Capture Photo</h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="visit-contentarea">
      <div class="visit-camera">
        <video id="visit-video">Video stream not available.</video>
        <button type="button" id="visit-startbutton" (click)="capturePhoto()">Capture</button>
      </div>
      <canvas id="visit-canvas">
      </canvas>
      <div class="visit-output pt-4">
        <img id="visit-photo" alt="The screen capture will appear in this box.">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div *ngIf="!isCategoriesSaving">
      <button type="submit" class="btn btn-primary" (click)="acceptPicture()" [disabled]="!capturedPhoto">Accept Picture</button>
      <button type="button" class="btn btn-outline-secondary ml-4" (click)="modal.dismiss('cancel click')">Cancel</button>
    </div>
  </div>
</ng-template>
