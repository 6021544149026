import {Pipe, PipeTransform} from '@angular/core';
import {COUNTY_TYPES} from './county-type';

@Pipe({name: 'countyType'})
export class CountyTypePipe implements PipeTransform {
  transform(key: string): string {
    const value = COUNTY_TYPES.get(key);
    if (value == null) {
      return key;
    }
    return value;
  }
}
