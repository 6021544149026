<div id="water-system-water-operators" *ngIf="operators">
  <div class="border p-2 panel-collapsible-header">
    <a href="javascript:void(0);" (click)="isWaterOperatorsCollapsed = !isWaterOperatorsCollapsed"
      [attr.aria-expanded]="!isWaterOperatorsCollapsed" aria-controls="collapse-water-operators">
      <div>
        <h2>
          Water Operators
          <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isWaterOperatorsCollapsed"></i>
          <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isWaterOperatorsCollapsed"></i>
        </h2>
      </div>
    </a>
  </div>
  <div id="collapse-water-operators" class="card" [ngbCollapse]="isWaterOperatorsCollapsed">
    <div class="card-body row">
      <div class="col-12 table-responsive" [hidden]="isDeleting">
        <div class="pb-3">
          <button type="button" class="btn btn-outline-primary" routerLink="add-operator">Add Water Operator</button>
        </div>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Class</th>
              <th scope="col">Expiration Date</th>
              <th scope="col">Position</th>
              <th scope="col">Work Phone</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody *ngIf="!operators.data.statusCode && hasActiveOperators()">
            <tr *ngFor="let operator of operators.data">
              <ng-container *ngIf="isNotRemoved(operator)">
                <td>{{ operator.name }}</td>
                <td>{{ operator.certificateClass }}</td>
                <td>{{ operator.expirationDate | date:'MM/dd/yyyy' }}</td>
                <td>{{ operator.positionDesc }}</td>
                <td>{{ operator.phone | phoneNumber }}</td>
                <td>
                  <button *ngIf="isNewlyAdded(operator)"
                          type="button"
                          class="btn btn-outline-dark py-0"
                          (click)="delete(operator)"
                          attr.aria-label="Delete water operator {{ operator.name }}"
                          placement="top"
                          ngbTooltip="Delete is available for water operators added in this checkout."
                          [openDelay]="100"
                          container="body">
                    Delete
                  </button>
                </td>
              </ng-container>
            </tr>
          </tbody>
          <tbody *ngIf="operators.data.statusCode || !hasActiveOperators()">
            <tr *ngIf="operators.data.statusCode === 404 || !hasActiveOperators()">
              <td colspan="6">
                No active water operators.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="isDeleting" class="pl-3">
        <div class="spinner-border" role="status">
          <span class="sr-only">Deleting water operator...</span>
        </div>
        <span>Deleting water operator...</span>
      </div>
    </div>
  </div>
</div>
