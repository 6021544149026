import { Injectable } from '@angular/core';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { PotentialContaminantsListService } from "../water-system/potential-contaminants-list.service";
import { MeasuresListService } from "../water-system/measures-list.service";
import { WaterOperatorsListService } from "../water-system/water-operators-list.service";
import { TreatmentProcessService } from "../water-system/treatment-process.service";
import { CityService } from "../shared/city/city.service";
import {QuestionnairesService} from '../admin/questionnaires/questionnaires.service';

@Injectable({
  providedIn: 'root'
})
export class CacheRemoteApisService {

  constructor(
    private connectivityService: ConnectivityService,
    private potentialContaminantsListService: PotentialContaminantsListService,
    private measuresListService: MeasuresListService,
    private waterOperatorsListService: WaterOperatorsListService,
    private treatmentProcessService: TreatmentProcessService,
    private cityService: CityService,
    private questionnaireService: QuestionnairesService
    ) { }

  cache() {
    if (this.connectivityService.isOnline) {
      this.potentialContaminantsListService.getPotentialContaminantsList().subscribe();
      this.measuresListService.getMeasuresList().subscribe();
      this.waterOperatorsListService.getWaterOperatorsList().subscribe();
      this.treatmentProcessService.getProcessMechanisms().subscribe();
      this.treatmentProcessService.getObjectives().subscribe();
      this.cityService.getCitiesList().subscribe();
      this.questionnaireService.getAll().subscribe();
    }
  }
}
