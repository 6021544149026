import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Finding } from 'src/app/domain/finding';
import { FindingService } from 'src/app/shared/finding/finding.service';
import { Answer } from 'src/app/domain/answer';

@Component({
  selector: 'app-additional-finding',
  templateUrl: './additional-finding.component.html',
  styleUrls: ['./additional-finding.component.scss']
})
export class AdditionalFindingComponent implements OnInit {

  @Input() additionalFinding: Answer;
  @Input() denyEdit = false;
  @Output() findingChange = new EventEmitter<{additionalFinding: Answer, changedField: string}>();

  public isLoading = true;
  public findings: Finding[];

  constructor(private findingService: FindingService) { }

  ngOnInit(): void {
    if (this.additionalFinding == null) {
      this.additionalFinding = {
        name: null,
        noteRequired: false,
        photoRequired: false,
        attachmentRequired: false,
        noteLabelOverride: null,
        finding: {
          beginDate: null,
          code: null,
          description: null,
          language: null,
          sanitarySurveySectionCode: null,
          sanitarySurveySectionDescription: null,
          type: null
        }
      };
    }
    this.findingService.getAdditionalFindings().then(results => {
      this.findings = results;
      this.findings = this.findings.sort((a, b) => {
        return (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0);
      });
      this.isLoading = false;
    });
  }

  onFindingChange(selectedCode: string, changedField: string) {
    const selectedFinding = this.findings.filter(f => selectedCode === f.code);
    if (selectedFinding && selectedFinding.length > 0) {
      this.additionalFinding.finding = selectedFinding[0];
      this.onChange(changedField);
    }
  }

  onChange(changedField: string) {
    this.findingChange.emit({additionalFinding: this.additionalFinding, changedField: changedField});
  }
}
