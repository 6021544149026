import {Injectable, OnDestroy} from '@angular/core';
import {Message} from './Message';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Unsubscribable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService implements OnDestroy {

  private readonly routerSubscription: Unsubscribable;
  private readonly messages: Array<Message> = [];

  constructor(private router: Router) {
    // clearing messages on navigation
    this.routerSubscription = router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(() => this.clear());
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  find(): Array<Message> {
    return this.messages; // todo: immutable
  }

  add(message: Message): void {
    this.messages.push(message);
  }

  remove(message: Message): void {
    this.messages.splice(this.messages.indexOf(message), 1);
  }

  clear(): void {
    this.messages.splice(0, this.messages.length);
  }

}
