import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SortService {

  constructor() { }

  transformColumnName(columnName: string): string {
    if (columnName === null || columnName === '' || columnName === 'visitStatus') {
      return '';
    } else if (columnName === 'nextVisit') {
      return 'next_visit';
    } else if (columnName === 'nextVisitType') {
      return 'next_visit_type';
    } else {
      return columnName;
    }
  }
}
