import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Interconnect } from '../../domain/facility';

@Injectable({
  providedIn: 'root'
})
export class InterconnectFacilityService {

  private interconnectFacilities = new BehaviorSubject<Interconnect[]>([]);
  interconnects$ = this.interconnectFacilities.asObservable();

  constructor() { }

  changeWaterSystemInterconnectFacilities(interconnectFacilities: Interconnect[]) {
    this.interconnectFacilities.next(interconnectFacilities);
  }
}
