<div class="container-fluid">
  <div class="p-3" *ngIf="isOnline; else offlineMsg">
    <h1>Profile</h1>
    <div *ngIf="isDistrictsLoading || isProfileLoading; else loaded" class="pl-3">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading Profile...</span>
      </div>
      <span>Loading Profile...</span>
    </div>
    <ng-template #loaded>
      <app-message></app-message>

      <div *ngIf="!isProfileLoading && profileComplete">
        <p>On this page, you can select a default district to set your default view on the Upcoming Site Visits page and fill in your profile information.</p>
      </div>

      <div *ngIf="!isProfileLoading && !profileComplete" class="alert alert-warning" role="alert">
        <span>You have been redirected to this page because required profile information has not been set for your account.<br/>
          Please select a district to set your default view on the Upcoming Site Visits page, fill in your profile information, and click the Save button.</span>
      </div>

      <div *ngIf="districtNotSelected" class="alert alert-danger" role="alert">
        <span>A default district is required.</span>
      </div>

      <div *ngIf="isSaving" class="pl-3">
        <div class="spinner-border" role="status">
          <span class="sr-only">Saving Profile...</span>
        </div>
        <span>Saving Profile...</span>
      </div>

      <form class="form-group" *ngIf="districts && !isSaving" #profileForm="ngForm">
        
        <div class="row">
          <div class="form-group col-md-6 col-sm-12">
            <label for="districtPartyId">District (required):</label>
            <select id="districtPartyId" class="form-control" [(ngModel)]="profile.district" name="districtPartyId" required>
              <option [ngValue]="null">Select district</option>
              <option *ngFor="let district of districts" [value]="district.partyId">{{ district.name }}</option>
            </select>
          </div>
        </div>
        <hr>
        <div id="profile-cover-letter-fields">
          <h2>Cover Letter Information</h2>
          <div class="row">
            <div class="form-group col-md-6 col-sm-12">
              <label for="profile-name">Name and Optional Credentials (required):</label>
              <input type="text" class="form-control" id="profile-name" name="name" [(ngModel)]="profile.profile.name" required #profileName="ngModel" maxlength="200">
              <div *ngIf="profileName.invalid && (profileName.dirty || profileName.touched)" class="alert alert-danger">
                <div *ngIf="profileName.errors">
                  Name is required.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6 col-sm-12">
              <label for="profile-phone-number">Contact Phone Number (required):</label>
              <input type="tel" class="form-control" id="profile-phone-number" name="phone" [(ngModel)]="profile.profile.phoneNumber" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                     phoneNumberMask numbersOnly required #profilePhoneNumber="ngModel" maxlength="12">
              <div *ngIf="profilePhoneNumber.invalid && (profilePhoneNumber.dirty || profilePhoneNumber.touched)" class="alert alert-danger">
                <div *ngIf="profilePhoneNumber.errors.required">
                  Phone Number is required.
                </div>
                <div *ngIf="profilePhoneNumber.errors.pattern">
                  Phone Number is invalid.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6 col-sm-12">
              <label for="profile-address">Contact Address (required):</label>
              <textarea class="form-control" id="profile-address" name="address" [(ngModel)]="profile.profile.contactAddress" maxlength="1000" rows="4" #profileAddress="ngModel" required></textarea>
              <div *ngIf="profileAddress.invalid && (profileAddress.dirty || profileAddress.touched)" class="alert alert-danger">
                <div *ngIf="profileAddress.errors">
                  Contact Address is required.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-2 col-sm-6">
              <label for="profile-initials">Initials (required):</label>
              <input type="text" class="form-control" id="profile-initials" name="initials" [(ngModel)]="profile.profile.initials" #profileInitials="ngModel" required maxlength="10">
              <div *ngIf="profileInitials.invalid && (profileInitials.dirty || profileInitials.touched)" class="alert alert-danger">
                <div *ngIf="profileInitials.errors">
                  Initials is required.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6 col-sm-12" *ngIf="!isSignatureLoading">
              <label for="signature-upload">Signature (required):</label>
              <div class="custom-file">
                <input type="file" class="custom-file-input" id="signature-upload" (change)="onSignatureChange($event)" accept="image/*" required>
                <span class="custom-file-label">Drop image file OR click in this box to browse.</span>
              </div>
              <div *ngIf="showImagesOnlyMessage" class="alert alert-danger">
                The selected file is not an image.
              </div>
              <div *ngIf="signatureFile" class="pt-2">
                <a [routerLink]="" (click)="openSignatureImage()" attr.aria-label="Open Signature Image in New Window">
                  <canvas id="signatureThumbnail"></canvas>
                </a>
              </div>
            </div>
            <div *ngIf="isSignatureLoading" class="pl-3">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading Signature Thumbnail...</span>
              </div>
              <span>Loading Signature Thumbnail...</span>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="form-group col-12">
            <input type="submit" value="Save" class="btn btn-primary" (click)="save()" [disabled]="!profileForm.form.valid"/>
          </div>
        </div>
      </form>
    </ng-template>
  </div>
  <ng-template #offlineMsg>
    <div class="p-3">Profile not available offline.</div>
  </ng-template>
</div>
