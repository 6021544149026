<div class="container-fluid">
  <div class="p-3">
    <span><h2>System Flow Visualization</h2></span>
    <div *ngIf="isFacilitiesLoading; else facilitiesLoaded" class="pl-3">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading System Flow...</span>
      </div>
      <span>Loading System Flow...</span>
    </div>
    <ng-template #facilitiesLoaded>
      <div *ngIf="!errors" class="pt-4">
        <div>
          <fieldset>
            <legend [hidden]="true">View Type</legend>
            <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="flowToggle">
              <label ngbButtonLabel class="btn-sm" [ngClass]="{ 'btn-primary': flowToggle == 0, 'btn-outline-primary': flowToggle != 0 }">
                <input ngbButton type="radio" [value]="0" (change)="drawFlow()">Detailed
              </label>
              <label ngbButtonLabel class="btn-sm" [ngClass]="{ 'btn-primary': flowToggle == 1, 'btn-outline-primary': flowToggle != 1 }">
                <input ngbButton type="radio" [value]="1" (change)="drawFlow()">Aggregate
              </label>
              <label *ngIf="nodesRemovedFromFlow && nodesRemovedFromFlow.length > 0" ngbButtonLabel class="btn-sm" [ngClass]="{ 'btn-primary': flowToggle == 2, 'btn-outline-primary': flowToggle != 2 }">
                <input ngbButton type="radio" [value]="2" (change)="drawFlow()">Removed From Flow
              </label>
            </div>
          </fieldset>
          <button type="button" class="btn btn-outline-secondary btn-sm mt-2" (click)="drawFlow()">Reset View</button>
        </div>
        <div id="flow-chart" style="height:500px;" class="mt-2 p-1">
          <svg id="sankey"></svg>
        </div>
      </div>
      <div *ngIf="errors" class="pt-4">
        <div *ngFor="let error of errors">
          {{ error }}
        </div>
      </div>
    </ng-template>
  </div>
</div>