import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'waterSystemFluorideType'})
export class WaterSystemFluorideTypePipe implements PipeTransform {
  transform(value: string): string {
    if (value === 'F') {
      return 'Fluoridating Municipal';
    }else if (value === 'N') {
      return 'Nonfluoridating Municipal';
    } else if (value === 'O') {
      return 'Other';
    } else if (value === 'X') {
      return 'Not Applicable';
    } else if (value === 'Y') {
      return 'Natural';
    }  else {
      return value;
    }
  }
}