import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgbPaginationModule, NgbCollapseModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {HomeRoutingModule} from './home-routing.module';
import {HomeComponent} from './home.component';
import {SharedModule} from '../shared/shared.module';
import {PaginatorComponent} from '../pagination/paginator/paginator.component';
import {PaginationComponent} from '../pagination/pagination.component';
import {EntriesPerPageComponent} from '../pagination/entires-per-page/entries-per-page.component';
import {SiteVisitRemoteApi} from '../site-visit-remote-api';
import {UpcomingSiteVisitsComponent} from './upcoming-site-visits/upcoming-site-visits.component';
import { CheckedOutSiteVisitsComponent } from './checked-out-site-visits/checked-out-site-visits.component';
import { SearchComponent } from './search/search.component';

@NgModule({
  declarations: [
    HomeComponent,
    PaginatorComponent,
    PaginationComponent,
    EntriesPerPageComponent,
    UpcomingSiteVisitsComponent,
    CheckedOutSiteVisitsComponent,
    SearchComponent
  ],
  imports: [
    CommonModule,
    NgbPaginationModule,
    NgbCollapseModule,
    SharedModule,
    HomeRoutingModule,
    FormsModule,
    NgbModule
  ],
  providers: [
    DatePipe,
    SiteVisitRemoteApi
  ]
})
export class HomeModule { }
