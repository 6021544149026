<div id="water-system-district-compliance" *ngIf="districtContact && complianceOfficer">
  <div class="border p-2 panel-collapsible-header">
    <a href="javascript:void(0);" (click)="isDistrictComplianceCollapsed = !isDistrictComplianceCollapsed"
      [attr.aria-expanded]="!isDistrictComplianceCollapsed" aria-controls="collapse-district-compliance">
      <div>
        <h2>
          District Contact/Compliance Officer
          <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isDistrictComplianceCollapsed"></i>
          <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isDistrictComplianceCollapsed"></i>
        </h2>
      </div>
    </a>
  </div>
  <div id="collapse-district-compliance" class="card" [ngbCollapse]="isDistrictComplianceCollapsed">
    <div class="card-body row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <strong>District Contact</strong>
            <br/>
            <strong>Name:</strong><span class="pl-2">{{ districtContact.data.name }}</span>
            <br/>
            <strong>Phone:</strong><span class="pl-2">{{ districtContact.data.phone | phoneNumber }}</span>
          </div>
          <div class="col-md-6 col-sm-12">
            <strong>Compliance Officer</strong>
            <br/>
            <strong>Name:</strong><span class="pl-2">{{ complianceOfficer.data.name }}</span>
            <br/>
            <strong>Phone:</strong><span class="pl-2">{{ complianceOfficer.data.phone | phoneNumber }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>