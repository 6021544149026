import {Injectable} from '@angular/core';
import {LocalStorageService} from '../local-storage.service';
import {Observable} from 'rxjs';
import {SyncResultFactory} from '../utils/sync-result-factory';
import { tables } from '../utils/app-db';
import { SyncRequest, SyncResult, SyncType } from '../domain/sync';
import { isNumber } from 'lodash';

/**
 * Manages water system sync
 */
@Injectable({
  providedIn: 'root'
})
export class SyncSchedulerService {

  private readonly TYPE = 'type';

  constructor(private localStorageService: LocalStorageService) {}

  status(type: SyncType, id: number): Observable<SyncResult> {
    return new Observable<SyncResult>(obs => {
      this.localStorageService.dbRetrieveAllByIndex<SyncResult>(tables.syncStatus, this.TYPE, type).subscribe(syncResults => {
        const syncResult = syncResults.find(r => r['data']['id'] === id);
        if (syncResult !== null) {
          obs.next(syncResult);
          obs.complete();
        } else {
          const result: SyncResult = SyncResultFactory.unknown(type, id);
          this.updateResult(result);
          obs.next(result);
          obs.complete();
        }
      })
    });
  }

  /**
   * Returns all scheduled sync of water systems
   */
  scheduled(type: SyncType): Observable<SyncRequest[]> {
    return this.localStorageService.dbRetrieveAllByIndex(tables.sync, this.TYPE, type);
  }

  /**
   * Schedules a sync event if not already scheduled
   * @param id of the water system
   */
  schedule(type: SyncType, id: number, data?: any): Observable<SyncResult> {
    return new Observable<SyncResult>(obs => {
      this.scheduled(type).subscribe(syncRequests => {
        const syncRequest = syncRequests.find(s => s.data.id === id);
        if (!syncRequest) {
          this.createSchedule(type, id, data);
        }
        const result = SyncResultFactory.scheduled(type, id);
        this.updateResult(result);
        obs.next(result);
        obs.complete();
      })
    })
  }

  /**
   * Unschedules a water system sync
   * @param id of the water system
   * @param result of the sync
   */
  unschedule(type: SyncType, id: number, result: SyncResult): void {
    this.scheduled(type).subscribe(scheduled => {
      const item = scheduled.find(schedule => schedule.data.id === id);
      if (item) {
        this.localStorageService.dbRemove(tables.sync, item.key);
      }
      this.updateResult(result);
    })
  }

  private findResult(result: SyncResult): Observable<SyncResult> {
    return new Observable(obs => {
      this.localStorageService.dbRetrieveAllByIndex<SyncResult>(tables.syncStatus, this.TYPE, result.type).subscribe(syncResults => {
        const syncResult = syncResults.find(r => r.data.id === result.data.id);
        obs.next(syncResult);
        obs.complete();
      })
    })
  }

  private updateResult(result: SyncResult): void {
    this.findResult(result).subscribe(syncResult => {
      if (syncResult) {
        result.key = syncResult.key;
      }
      this.localStorageService.dbStore(tables.syncStatus, result);
    })
  }

  private createSchedule(type: SyncType, id: number, data?: any): void {
    this.localStorageService.dbStore(tables.sync, {type: type, data: {id: id, data: data}} as SyncRequest)
  }
}
