import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProfileComponent } from './profile.component';
import { Shell } from '../shell/shell.service';
import { AppAuthGuard } from '../app.guard';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'profile',
      component: ProfileComponent,
      canActivate: [AppAuthGuard],
      data: {
        title: 'Profile',
        roles: ['admin', 'inspector']
      }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
