import { Answer } from './answer';
import { Attachment } from './attachment';
import { Finding } from './finding';
import { v4 as uuidv4 } from 'uuid';

export interface Question {
  uuid: string;
  type: string;
  displayName: string;
  questionActiveInd: boolean;
  required: boolean;
  answers: Answer[];
  value?: string;
  photo?: Attachment;
  attachments?: Attachment[];
  note?: string;
  review?: boolean;
  /** @deprecated Only used for migration of older Questionnaires */
  prerequisiteQuestionIndex?: number;
  prerequisiteQuestionUuid?: string;
  prerequisiteAnswer?: string;
  finding?: Finding;
  findingNote?: string;
}

export function generateUuid() {
  return uuidv4();
}
