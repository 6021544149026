<div class="card">
  <div class="card-body">
    <div>
      <h1>Edit Note</h1>
    </div>
    <div class="pt-3" *ngIf="isEditing">
      <div class="spinner-border" role="status">
        <span class="sr-only">Updating Note...</span>
      </div>
      <span>Updating Note...</span>
    </div>
    <div class="pt-3" *ngIf="!isEditing">
      <app-note-info-form [note]="note" (saved)="save($event)" (cancelled)="cancel()"></app-note-info-form>
    </div>
  </div>
</div>