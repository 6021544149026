<div class="border p-2 panel-collapsible-header h4">
  <a href="javascript:void(0);" (click)="isQuestionCollapsed = !isQuestionCollapsed"
    [attr.aria-expanded]="!isQuestionCollapsed"
    aria-controls="collapse-photo">
    <div>
      <span class="font-weight-bolder">{{question.displayName}}</span>
      <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isQuestionCollapsed"></i>
      <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isQuestionCollapsed"></i>
    </div>
  </a>
</div>
<div class="card" [ngbCollapse]="isQuestionCollapsed">
  <div class="card-body">
    <span class="font-weight-bold" *ngIf="isValid; else missing">
      <span *ngIf="question.type === 'Question'">{{question.value}}</span>
      <span *ngIf="question.type === 'Finding'">
        <div class="row">
          <div class="col-12">
            <span>Code:</span>
            <br/>
            <span>{{ question.finding.code }}</span>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-12">
            <span>Type:</span>
            <br/>
            <span>{{ question.finding.description }}</span>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-12">
            <span>Section:</span>
            <br/>
            <span>{{ question.finding.sanitarySurveySectionDescription }}</span>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-12">
            <span>Language:</span>
            <div>
              <span class="white-space-break-spaces">{{ question.finding.language }}</span>
            </div>
          </div>
        </div>
      </span>
    </span>
    <app-question-additional-information-view [question]="question"
                                              [waterSystemId]="waterSystemId"
                                              [siteVisitId]="siteVisitId"
                                              [questionnaireId]="questionnaireId">
    </app-question-additional-information-view>
  </div>
</div>
<ng-template #missing><span class="text-danger">Missing Answer</span></ng-template>
