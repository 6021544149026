import { Component, OnInit } from '@angular/core';
import { FacilityAny } from 'src/app/domain/facility';
import { Router, ActivatedRoute } from '@angular/router';
import { WaterSystemFacilitiesService } from '../../water-system-facilities.service';
import { FacilityFlow } from 'src/app/domain/facility-flow';
import { MessageService } from 'src/app/message/message.service';
import { Severity } from 'src/app/message/Severity';
import { WaterSystemSiteVisitsService } from '../../water-system-site-visits.service';
import { QuestionniareSubscriber } from "../../questionnaire/questionnaire-subscriber";
import { Visit } from "../../../domain/visit";
import { SiteVisitsService } from "../../site-visits/site-visits.service";
import { Questionnaire } from "../../../domain/questionnaire";
import { FACILITY_STATUSES } from "../../../shared/facility-status";
import { flagRequiredQuestionnare, validate } from "../../site-visits/site-visit-utils";

@Component({
  selector: 'app-edit-facility',
  templateUrl: './edit-facility.component.html',
  styleUrls: ['./edit-facility.component.scss']
})
export class EditFacilityComponent implements OnInit {

  public facility: FacilityAny;
  public isLoading: boolean = true;

  private facilityId: number;
  private waterSystemId: number;
  private siteVisitId: number;
  private returnUrl: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private waterSystemFacilitiesService: WaterSystemFacilitiesService,
              private messageService: MessageService,
              private subscriber: QuestionniareSubscriber<Visit>,
              private siteVisitsService: SiteVisitsService,
              private waterSystemSiteVisitsService: WaterSystemSiteVisitsService) { }

  ngOnInit(): void {
    this.returnUrl = history.state.returnUrl;
    this.facilityId = Number(this.route.snapshot.params['id']);
    this.waterSystemId = Number(this.route.parent.snapshot.params['id']);
    this.siteVisitId = history.state.siteVisitId;
    this.waterSystemFacilitiesService.findFacilityOne(this.waterSystemId, this.facilityId).subscribe(
      facility => {
        this.facility = facility;
        this.isLoading = false;
      }, error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  save(facility: {facility: FacilityAny, flowsToFacility: FacilityFlow}) {
    this.waterSystemFacilitiesService.save(facility.facility, facility.flowsToFacility);
    if (this.siteVisitId) {
      this.updateSurvey();
    } else {
      this.cancel();
      this.messageService.add({ severity: Severity.SUCCESS, value: 'Inventory has been updated.' });
      window.scrollTo(0, 0);
    }
  }

  cancel() {
    if (this.returnUrl) {
      this.router.navigate([this.returnUrl]);
    } else {
      this.router.navigate(['facilities'], { relativeTo: this.route.parent });
    }
  }

  updateSiteVisit(siteVisit:Visit) {
    validate(siteVisit);
    this.waterSystemSiteVisitsService.save(siteVisit);
    this.subscriber.next(siteVisit);
    this.siteVisitsService.updateCurrentVisit(siteVisit);
  }

  updateSurvey() {
    this.waterSystemSiteVisitsService.find(this.siteVisitId, this.facility.pwsId).subscribe(siteVisit => {
      this.updateAddedFacility(siteVisit);
      switch (this.facility.facilityCode) {
        case 'WL':
          if (siteVisit.inspection.questionnaires.well.base) {
            for (let questionnaire of siteVisit.inspection.questionnaires.well.facilities) {
              this.activateQuestionnaireForActivatedFacility(questionnaire);
              if (questionnaire.facilityId && this.facility.facilityId === questionnaire.facilityId && this.facility.name !== questionnaire.category) {
                questionnaire.category = this.facility.name;
                break;
              }
            }
          }
          break;
        case 'IN':
          if (siteVisit.inspection.questionnaires.surfaceWater.base) {
            for (let questionnaire of siteVisit.inspection.questionnaires.surfaceWater.facilities) {
              this.activateQuestionnaireForActivatedFacility(questionnaire);
              if (questionnaire.facilityId && this.facility.facilityId === questionnaire.facilityId && this.facility.name !== questionnaire.category) {
                questionnaire.category = this.facility.name;
                break;
              }
            }
          }
          break;
        case 'SH':
        case 'SU':
        case 'ST':
        case 'SS':
        case 'SE':
          if (siteVisit.inspection.questionnaires.storage.base) {
            for (let questionnaire of siteVisit.inspection.questionnaires.storage.facilities) {
              this.activateQuestionnaireForActivatedFacility(questionnaire);
              if (questionnaire.facilityId && this.facility.facilityId === questionnaire.facilityId && this.facility.name !== questionnaire.category) {
                questionnaire.category = this.facility.name;
                break;
              }
            }
          }
          break;
        case 'TP':
          if (siteVisit.inspection.questionnaires.treatment.base) {
            for (let questionnaire of siteVisit.inspection.questionnaires.treatment.facilities) {
              this.activateQuestionnaireForActivatedFacility(questionnaire);
              if (questionnaire.facilityId && this.facility.facilityId === questionnaire.facilityId && this.facility.name !== questionnaire.category) {
                questionnaire.category = this.facility.name;
                break;
              }
            }
          }
          break;
        case 'DS':
          if (siteVisit.inspection.questionnaires.distribution.base) {
            for (let questionnaire of siteVisit.inspection.questionnaires.distribution.facilities) {
              this.activateQuestionnaireForActivatedFacility(questionnaire);
              if (questionnaire.facilityId && this.facility.facilityId === questionnaire.facilityId && this.facility.name !== questionnaire.category) {
                questionnaire.category = this.facility.name;
                break;
              }
            }
          }
          break;
        case 'CC':
          if (siteVisit.inspection.questionnaires.interconnect.base) {
            for (let questionnaire of siteVisit.inspection.questionnaires.interconnect.facilities) {
              this.activateQuestionnaireForActivatedFacility(questionnaire);
              if (questionnaire.facilityId && this.facility.facilityId === questionnaire.facilityId && this.facility.name !== questionnaire.category) {
                questionnaire.category = this.facility.name;
                break;
              }
            }
          }
          break;
      }
      this.updateSiteVisit(siteVisit);
      this.cancel();
      this.messageService.add({ severity: Severity.SUCCESS, value: 'Inventory has been updated.' });
      window.scrollTo(0, 0);
    });
  }

  activateQuestionnaireForActivatedFacility(questionnaire:Questionnaire) {
    if (questionnaire.facilityId && this.facility.facilityId === questionnaire.facilityId && this.facility.statusCode === FACILITY_STATUSES[0]['code']) {
      questionnaire.facilityStatusCode = FACILITY_STATUSES[0]['code'];
    }
    flagRequiredQuestionnare(questionnaire);
  }

  updateAddedFacility(siteVisit:Visit){
    if (siteVisit.inspection.addedFacilities && siteVisit.inspection.addedFacilities.length) {
      let facilityAddIndex = siteVisit.inspection.addedFacilities.findIndex(
        f => f.facilityId === this.facility.facilityId
      );
      if (facilityAddIndex === -1) {
        this.updateModifiedFacility(siteVisit)
      } else {
        siteVisit.inspection.addedFacilities[facilityAddIndex] = this.facility;
      }
    } else {
      this.updateModifiedFacility(siteVisit);
    }
  }

  updateModifiedFacility(siteVisit:Visit) {
    if (siteVisit.inspection.modifiedFacilities && siteVisit.inspection.modifiedFacilities.length) {
      let facilityModifiedIndex = siteVisit.inspection.modifiedFacilities.findIndex(
        f => f.facilityId === this.facility.facilityId
      );
      if (facilityModifiedIndex === -1) {
        siteVisit.inspection.modifiedFacilities.push(this.facility);
      } else {
        siteVisit.inspection.modifiedFacilities[facilityModifiedIndex] = this.facility;
      }
    } else {
      siteVisit.inspection.modifiedFacilities = []
      siteVisit.inspection.modifiedFacilities.push(this.facility);
    }
  }
}
