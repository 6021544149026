<div id="water-system-general" *ngIf="waterSystem">
  <div class="border p-2 panel-collapsible-header">
    <a href="javascript:void(0);" (click)="isGeneralCollapsed = !isGeneralCollapsed"
      [attr.aria-expanded]="!isGeneralCollapsed" aria-controls="collapse-general">
      <div>
        <h2>
          General
          <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isGeneralCollapsed"></i>
          <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isGeneralCollapsed"></i>
        </h2>
      </div>
    </a>
  </div>
  <div id="collapse-general" class="card" [ngbCollapse]="isGeneralCollapsed">
    <div class="card-body row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-4 col-sm-12 col-12">
            <div class="form-group pb-2">
              <label for="water-system-type">PWS Type:</label>
              <select id="water-system-type" class="form-control" [(ngModel)]="waterSystem.data.type" (ngModelChange)="onChange($event)">
                <option *ngFor="let type of WATER_SYSTEM_TYPES" value="{{type.code}}">{{type.value}}</option>
              </select>
            </div>
            <div class="pb-2">
              <span><strong>County:</strong></span>
              <br/>
              <span>{{waterSystem.data.county | countyType}}</span>
            </div>
            <div class="form-group pb-2">
              <label for="owner-type">Owner Type:</label>
              <select id="owner-type" class="form-control" [(ngModel)]="waterSystem.data.ownerType" (ngModelChange)="onChange($event)">
                <option *ngFor="let type of OWNER_TYPES" value="{{type.code}}">{{type.value}}</option>
              </select>
            </div>
            <div class="form-group pb-2">
              <label for="system-class">System Class:</label>
              <select id="system-class" class="form-control" [(ngModel)]="waterSystem.data.systemClass" (ngModelChange)="onChange($event)">
                <option *ngFor="let type of WATER_SYSTEM_CLASS_TYPES" value="{{type.code}}">{{type.value}}</option>
              </select>
            </div>
            <div class="form-group pb-2">
              <label for="system-class-points">System Class Points:</label>
              <input id="system-class-points"
                     class="form-control"
                     [(ngModel)]="waterSystem.data.systemClassPoints"
                     type="number"
                     min="0"
                     [minnumber]="0"
                     max="999"
                     [maxnumber]="999"
                     (ngModelChange)="onChange($event)"
                     #systemClassPoints="ngModel"/>
              <div *ngIf="systemClassPoints.invalid && (systemClassPoints.dirty || systemClassPoints.touched)" class="alert alert-danger">
                <div *ngIf="systemClassPoints.errors.min">
                  The value cannot be negative.
                </div>
                <div *ngIf="systemClassPoints.errors.max">
                  The value cannot exceed 999.
                </div>
              </div>
            </div>
            <div class="pb-2">
              <span><strong>Status:</strong></span>
              <br/>
              <span>{{waterSystem.data.status | waterSystemStatusType}}</span>
            </div>
            <div class="form-group pb-2">
              <label for="service-connections">Service Connections:</label>
              <input id="service-connections"
                     class="form-control"
                     [(ngModel)]="waterSystem.data.serviceConnections"
                     type="number"
                     min="0"
                     [minnumber]="0"
                     max="9999999"
                     [maxnumber]="9999999"
                     (ngModelChange)="onChange($event)"
                     #serviceConnections="ngModel"/>
              <div *ngIf="serviceConnections.invalid && (serviceConnections.dirty || serviceConnections.touched)" class="alert alert-danger">
                <div *ngIf="serviceConnections.errors.min">
                  The value cannot be negative.
                </div>
                <div *ngIf="serviceConnections.errors.max">
                  The value cannot exceed 9999999.
                </div>
              </div>
            </div>
            <div class="pb-2">
              <span><strong>Population Served:</strong></span>
              <br/>
              <span>{{waterSystem.data.totalPopulation}}</span>
            </div>
            <div class="pb-2">
              <span><strong>Fluoride Indicator:</strong></span>
              <br/>
              <span>{{waterSystem.data.flouride | waterSystemFluorideType}}</span>
            </div>
          </div>
          <div class="col-2"></div>
          <div class="col-md-4 col-sm-12 col-12" *ngIf="waterSystem.data.type === 'TNC' || waterSystem.data.type === 'NTNC' && waterSystem.data.addressCode === 'LCTN'">
            <div class="form-group pb-2">
              <label for="non-comm-address-line-1">Physical Address Line 1:</label>
              <input id="non-comm-address-line-1" class="form-control" [(ngModel)]="waterSystem.data.address1" maxlength="40" (ngModelChange)="onChange($event)"/>
            </div>
            <div class="form-group pb-2">
              <label for="non-comm-address-line-2">Physical Address Line 2:</label>
              <input id="non-comm-address-line-2" class="form-control" [(ngModel)]="waterSystem.data.address2" maxlength="40" (ngModelChange)="onChange($event)"/>
            </div>
            <div class="form-group pb-2">
              <label for="non-comm-address-line-3">Physical Address Line 3:</label>
              <input id="non-comm-address-line-3" class="form-control" [(ngModel)]="waterSystem.data.address3" maxlength="40" (ngModelChange)="onChange($event)"/>
            </div>
            <div class="form-group pb-2">
              <label for="non-comm-address-city">Physical Address City:</label>
              <select id="non-comm-address-city" class="form-control" [(ngModel)]="waterSystem.data.city" (ngModelChange)="onChange($event)">
                <option *ngFor="let city of cities" value="{{city}}">{{city}}</option>
              </select>
            </div>
            <div class="form-group pb-2">
              <label for="non-comm-address-zipcode">Physical Address Zip Code:</label>
              <input id="non-comm-address-zipcode" class="form-control" [(ngModel)]="waterSystem.data.zipCode" maxlength="9" pattern="[0-9]+" (ngModelChange)="onChange($event)"/>
            </div>
          </div>
          <div class="col-2"></div>
        </div>
      </div>
    </div>
  </div>
</div>
