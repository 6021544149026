import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, timer } from 'rxjs';
import {
  catchError,
  concatMap,
  map,
  take,
  takeUntil,
  tap,
  mergeMap,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { LocalStorageService } from '../local-storage.service';
import { Visit } from '../domain/visit';
import { tables } from '../utils/app-db';
import { SyncType } from '../domain/sync';
import { SyncResultFactory } from '../utils/sync-result-factory';
import { SyncSchedulerService } from './sync-scheduler.service';
import {Attachment, copyAttachmentRequired} from '../domain/attachment';
import { DocumentService } from '../document.service';
import { Questionnaire } from '../domain/questionnaire';

@Injectable({
  providedIn: 'root',
})
export class WaterSystemSiteVisitsService {
  private readonly WATER_SYSTEM_URL = environment.serverUrl + '/watersystem';
  private readonly SITE_VISITS_SUB_ENDPOINT = '/sitevisit';
  private readonly SITE_VISITS_URL =
    environment.serverUrl + this.SITE_VISITS_SUB_ENDPOINT;

  constructor(
    private connectivityService: ConnectivityService,
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService,
    private syncSchedulerService: SyncSchedulerService,
    private documentService: DocumentService
  ) {}

  /**
   * Returns water system site visits.
   *
   * @param waterSystemId the id of the water system
   * @param remotelyFirst if a remote find should be done before local find. Defaults to false.
   */
  findAll(
    waterSystemId: number,
    remotelyFirst: boolean = false
  ): Observable<Visit[]> {
    if (remotelyFirst && this.connectivityService.isOnline()) {
      return new Observable<Visit[]>((obs) => {
        this.findAllRemotelyTimer(waterSystemId).subscribe((wsRemote) => {
          obs.next(wsRemote);
          obs.complete();
        }, error => {
          obs.error(error);
        });
      });
    }

    return new Observable<Visit[]>((obs) => {
      this.findAllLocally(waterSystemId).subscribe((wsLocal) => {
        this.sortDefault(wsLocal);
        obs.next(wsLocal);
        if (this.connectivityService.isOnline()) {
          this.findAllRemotelyTimer(waterSystemId).subscribe((wsRemote) => {
            obs.next(wsRemote);
            obs.complete();
          });
        } else {
          obs.complete();
        }
      });
    });
  }

  findAllLocally(waterSystemId: number): Observable<Visit[]> {
    return this.localStorageService.dbRetrieveAllByIndex<Visit>(
      tables.waterSystemSiteVisit,
      'pwsId',
      waterSystemId
    );
  }

  find(id: number, waterSystemId: number): Observable<Visit> {
    return new Observable((obs) => {
      this.findLocally(id).subscribe((visit) => {
        if (visit) {
          obs.next(visit);
          obs.complete();
        } else {
          this.findFromAll(id, waterSystemId).subscribe((visitAll) => {
            obs.next(visitAll);
            obs.complete();
          });
        }
      });
    });
  }

  findFromAll(
    id: number,
    waterSystemId: number,
    remotelyFirst: boolean = false
  ): Observable<Visit> {
    return new Observable((obs) => {
      this.findAll(waterSystemId, remotelyFirst).subscribe((visits) => {
        const visit = visits.find((v) => v.id === id);
        obs.next(visit);
        obs.complete();
      });
    });
  }

  findLocally(id: number): Observable<Visit> {
    return this.localStorageService.dbRetrieve<Visit>(
      tables.waterSystemSiteVisit,
      id
    );
  }

  private storeAll(visits: Visit[]): void {
    visits.forEach((v) => {
      this.store(v);
      this.storeQuestionnaireAttachments(v);
    });
  }

  store(visit: Visit): void {
    this.localStorageService.dbStore(tables.waterSystemSiteVisit, visit);
  }

  /**
   * Saves the Visit Locally
   * If online, then also saved to remote service, otherwise save is scheduled
   * @param visit data
   */
  save(visit: Visit): void {
    this.store(visit);
    this.syncSchedulerService
      .schedule(SyncType.SiteVisit, visit.id)
      .subscribe();
  }

  deleteLocally(id: number): void {
    this.localStorageService.dbRemove(tables.waterSystemSiteVisit, id);
  }

  deleteAllLocally(waterSystemId: number): void {
    this.findAllLocally(waterSystemId).subscribe((visits) => {
      visits.forEach((visit) => {
        this.deleteLocally(visit.id);
        this.deleteLocalQuestionnaireAttachments(visit.id);
      });
    });
  }

  getBaseUrl(waterSystemId: number): string {
    return `${this.WATER_SYSTEM_URL}/${waterSystemId}${this.SITE_VISITS_SUB_ENDPOINT}`;
  }

  private findAllRemotely(waterSystemId: number): Observable<Visit[]> {
    return this.httpClient.get<Visit[]>(this.getBaseUrl(waterSystemId));
  }

  private findAllRemotelyTimer(waterSystemId: number): Observable<Visit[]> {
    const stopTimer$ = timer(10 * 60 * 1000);
    return timer(0, 15000).pipe(
      takeUntil(stopTimer$),
      concatMap(() =>
        this.findAllRemotely(waterSystemId).pipe(map((response) => response))
      ),
      take(1),
      tap((found) => {
        this.sortDefault(found);
        this.deleteAllLocally(waterSystemId);
        setTimeout(() => this.storeAll(found), 2000);
        return found;
      }),
      catchError((error) => {
        if (error.status === 404) {
          return of(error.error);
        } else {
          return throwError(error);
        }
      })
    );
  }

  findOneRemotely(id: number): Observable<Visit> {
    return this.httpClient.get<Visit>(`${this.SITE_VISITS_URL}/${id}`);
  }

  createRemotely(waterSystemId: number): Observable<Visit> {
    if (this.connectivityService.isOnline()) {
      return this.httpClient.post<Visit>(this.getBaseUrl(waterSystemId), {});
    } else {
      return of(null);
    }
  }

  private updateRemotely(visit: Visit): Observable<Visit> {
    return this.httpClient.put<Visit>(`${this.SITE_VISITS_URL}`, visit);
  }

  deleteInProgress(waterSystemId: number) {
    return this.httpClient.delete(
      `${this.getBaseUrl(waterSystemId)}/IN_PROGRESS`
    );
  }

  sync(): void {
    if (this.connectivityService.isOnline) {
      if (this.connectivityService.isOnline()) {
        this.syncSchedulerService
          .scheduled(SyncType.SiteVisit)
          .subscribe((syncRequests) => {
            syncRequests.forEach((syncRequest) => {
              this.findLocally(syncRequest.data.id).subscribe((visit) => {
                if (visit) {
                  this.updateRemotely(visit).subscribe(
                    () => {
                      this.syncAttachments(syncRequest.data.id);
                      this.syncSchedulerService.unschedule(
                        SyncType.SiteVisit,
                        syncRequest.data.id,
                        SyncResultFactory.synced(
                          SyncType.WaterSystem,
                          syncRequest.data.id
                        )
                      );
                    },
                    (error) =>
                      this.syncSchedulerService.unschedule(
                        SyncType.SiteVisit,
                        syncRequest.data.id,
                        SyncResultFactory.errorHttp(
                          SyncType.WaterSystem,
                          syncRequest.data.id,
                          error
                        )
                      )
                  );
                } else {
                  this.syncSchedulerService.unschedule(
                    SyncType.SiteVisit,
                    syncRequest.data.id,
                    SyncResultFactory.synced(
                      SyncType.WaterSystem,
                      syncRequest.data.id
                    )
                  );
                }
              });
            });
          });
        this.syncSchedulerService
          .scheduled(SyncType.SiteVisitDelete)
          .subscribe((syncRequests) => {
            syncRequests.forEach((syncRequest) => {
              this.deleteInProgress(syncRequest.data.id).subscribe(
                () => {
                  this.syncSchedulerService.unschedule(
                    SyncType.SiteVisitDelete,
                    syncRequest.data.id,
                    SyncResultFactory.synced(
                      SyncType.SiteVisitDelete,
                      syncRequest.data.id
                    )
                  );
                },
                (error) => {
                  this.syncSchedulerService.unschedule(
                    SyncType.SiteVisitDelete,
                    syncRequest.data.id,
                    SyncResultFactory.errorHttp(
                      SyncType.SiteVisitDelete,
                      syncRequest.data.id,
                      error
                    )
                  );
                }
              );
            });
          });
      }
    }
  }

  syncAttachments(visitId: number) {
    this.localStorageService
      .dbRetrieveAllByIndex<Attachment>(
        tables.questionnaireAttachment,
        'visitId',
        visitId
      )
      .subscribe((localAttachments) => {
        for (const attachment of localAttachments) {
          if (attachment.action && attachment.path) {
            if (attachment.action === 'add') {
              this.documentService
                .getUploadUrl(attachment.path)
                .pipe(
                  mergeMap((documentUrlRequest) =>
                    this.httpClient.put<any>(
                      documentUrlRequest.s3SecureUrl,
                      attachment.file,
                      { headers: { 'Content-Type': attachment.file.type } }
                    )
                  )
                )
                .subscribe(() => {
                  delete attachment.action;
                  this.localStorageService.dbStore(
                    tables.questionnaireAttachment,
                    attachment
                  );
                });
            } else if (attachment.action === 'delete') {
              this.localStorageService.dbRemove(
                tables.questionnaireAttachment,
                attachment.key
              );
            }
          }
        }
      });
  }

  storeQuestionnaireAttachments(visit: Visit) {
    if (visit.inspection && visit.inspection.questionnaires) {
      if (
        visit.inspection.questionnaires.interconnect &&
        visit.inspection.questionnaires.interconnect.facilities
      ) {
        for (const questionnaire of visit.inspection.questionnaires.interconnect
          .facilities) {
          this.storeRemotelyAttachments(questionnaire);
        }
      }
      if (visit.inspection.questionnaires.pws) {
        for (const questionnaire of visit.inspection.questionnaires.pws) {
          this.storeRemotelyAttachments(questionnaire);
        }
      }
      if (
        visit.inspection.questionnaires.well &&
        visit.inspection.questionnaires.well.facilities
      ) {
        for (const questionnaire of visit.inspection.questionnaires.well
          .facilities) {
          this.storeRemotelyAttachments(questionnaire);
        }
      }
      if (
        visit.inspection.questionnaires.storage &&
        visit.inspection.questionnaires.storage.facilities
      ) {
        for (const questionnaire of visit.inspection.questionnaires.storage
          .facilities) {
          this.storeRemotelyAttachments(questionnaire);
        }
      }
    }
  }

  storeRemotelyAttachments(questionnaire: Questionnaire) {
    for (const question of questionnaire.question) {
      if (
        question.photo &&
        question.photo.pwsId &&
        question.photo.visitId &&
        question.photo.questionnaireId &&
        question.photo.questionUuid &&
        question.photo.filename &&
        question.photo.path
      ) {
        this.documentService
          .getDownloadUrl(question.photo.path)
          .pipe(
            mergeMap((documentUrlRequest) =>
              this.httpClient.get(documentUrlRequest.s3SecureUrl, {
                responseType: 'blob' as 'json',
              })
            )
          )
          .subscribe((response: any) => {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const fileBlob: Blob = new Blob(binaryData, { type: dataType });
            const photo = copyAttachmentRequired(question.photo);
            photo.file = new File([fileBlob], question.photo.filename, {
              type: dataType,
            });
            this.localStorageService.dbStore(
              tables.questionnaireAttachment,
              photo
            );
          });
      }
      if (question.attachments) {
        for (const attachment of question.attachments) {
          if (attachment.path) {
            this.documentService
            .getDownloadUrl(attachment.path)
            .pipe(
              mergeMap((documentUrlRequest) =>
                this.httpClient.get(documentUrlRequest.s3SecureUrl, {
                  responseType: 'blob' as 'json',
                })
              )
            )
            .subscribe((response: any) => {
              const dataType = response.type;
              const binaryData = [];
              binaryData.push(response);
              const fileBlob: Blob = new Blob(binaryData, {type: dataType});
              const attachmentDownload = copyAttachmentRequired(attachment);
              attachmentDownload.file = new File([fileBlob], attachment.filename, {
                  type: dataType,
              });
              this.localStorageService.dbStore(
                tables.questionnaireAttachment,
                attachmentDownload
              );
            });
          }
        }
      }
    }
  }

  deleteLocalQuestionnaireAttachments(visitId: number) {
    this.localStorageService
      .dbRetrieveAllByIndex<Attachment>(
        tables.questionnaireAttachment,
        'visitId',
        visitId
      )
      .subscribe((results) => {
        for (const attachment of results) {
          this.localStorageService.dbRemove(
            tables.questionnaireAttachment,
            attachment.key
          );
        }
      });
  }

  deleteOfflineInProgress(pwsId: number) {
    this.syncSchedulerService
      .schedule(SyncType.SiteVisitDelete, pwsId)
      .subscribe();
    this.syncSchedulerService
      .scheduled(SyncType.SiteVisit)
      .subscribe((syncRequests) => {
        syncRequests.forEach((syncRequest) => {
          this.findLocally(syncRequest.data.id).subscribe((visit) => {
            if (visit && visit.pwsId === pwsId) {
              this.syncSchedulerService.unschedule(
                SyncType.SiteVisit,
                syncRequest.data.id,
                SyncResultFactory.synced(
                  SyncType.SiteVisit,
                  syncRequest.data.id
                )
              );
            }
          });
        });
      });
  }

  private sortDefault(visits: Visit[]): Visit[] {
    if (visits == null) {
      return [];
    } else if (visits.length === 1) {
      return visits;
    }
    visits.sort((a, b) => {
      if (new Date(a.visitDate).getTime() === new Date(b.visitDate).getTime()) {
        if (a.status.toUpperCase() === 'IN_PROGRESS') {
          return -1;
        } else if (b.status.toUpperCase() === 'IN_PROGRESS') {
          return 1;
        } else {
          return 0;
        }
      } else {
        return new Date(b.visitDate).getTime() - new Date(a.visitDate).getTime();
      }
    });
    return visits;
  }
}
