<div id="notes">
  <div class="border p-2 panel-collapsible-header">
    <a href="javascript:void(0);" (click)="isNotesCollapsed = !isNotesCollapsed"
      [attr.aria-expanded]="!isNotesCollapsed" aria-controls="collapse-notes">
      <div>
        <h2>
          Notes
          <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isNotesCollapsed"></i>
          <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isNotesCollapsed"></i>
        </h2>
      </div>
    </a>
  </div>
  <div id="collapse-notes" class="card" [ngbCollapse]="isNotesCollapsed">
    <div class="card-body row">
      <div class="col-12 table-responsive" *ngIf="!isDeleting">
        <div class="pb-3" *ngIf="!disableAdd">
          <button class="btn btn-outline-primary" routerLink="add-note" [state]="{ relatedItem: relatedItem }">Add Note</button>
        </div>
        <table class="table table-sm">
          <thead>
            <tr class="d-flex">
              <th scope="col" class="col-7">Note</th>
              <th scope="col" class="col-2" sortable="name" (sort)="onSort($event)" tabindex="0" rotateOption="noEmpty">Author</th>
              <th scope="col" class="col-2" sortable="createDate" (sort)="onSort($event)" tabindex="0" rotateOption="noEmptyReverse">Entry Date</th>
              <th scope="col" class="col-1">Actions</th>
            </tr>
          </thead>
          <tbody *ngIf="notes && hasNotes">
            <tr *ngFor="let note of notes" class="d-flex">
              <ng-container *ngIf="note.action !== 'D' && (note.relatedItem === relatedItem || note.createdBy === 'system')">
                <td class="col-7 white-space-break-spaces">{{ note.note }}</td>
                <td class="col-2">{{ note.name }}</td>
                <td class="col-2">{{ note.createDate | date:'MM/dd/yyyy hh:mm a' }}</td>
                <td class="col-1">
                  <button type="button" class="btn btn-outline-dark btn-sm py-0" [routerLink]="['edit-note', note.id]" [state]="{ data: note }" attr.aria-label="Edit note created by {{ note.name }} on {{ note.createDate | date:'MM/dd/yyyy hh:mm a' }}">Edit</button>
                  <br/>
                  <button type="button" class="btn btn-outline-dark btn-sm py-0 mt-2" (click)="remove(note.id)" attr.aria-label="Delete note created by {{ note.name }} on {{ note.createDate | date:'MM/dd/yyyy hh:mm a' }}">Delete</button>
                </td>
              </ng-container>
            </tr>
          </tbody>
          <tbody *ngIf="notes == null || !hasNotes">
            <tr>
              <td colspan="4">
                No Notes Found.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pt-3" *ngIf="isDeleting">
        <div class="spinner-border" role="status">
          <span class="sr-only">Deleting Note...</span>
        </div>
        <span>Deleting Note...</span>
      </div>
    </div>
  </div>
</div>