import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { TreatmentProcessService } from '../../treatment-process.service';
import { TreatmentPlant } from 'src/app/domain/facility';
import {
  TreatmentProcess,
  TreatmentCode
} from 'src/app/domain/treatment-process';
import { forkJoin } from 'rxjs';
import { ConfirmatoryActionService } from 'src/app/confirmatory-action/confirmatory-action.service';

@Component({
  selector: 'app-treatment-process-info',
  templateUrl: './treatment-process-info.component.html',
  styleUrls: ['./treatment-process-info.component.scss']
})
export class TreatmentProcessInfoComponent implements OnInit {

  @Input()
  facility: TreatmentPlant;

  @Output()
  edit = new EventEmitter<TreatmentPlant>();

  public isLoading = true;
  public mechanisms: TreatmentCode[];
  public objectives: TreatmentCode[];
  public newTreatmentProcesses: TreatmentProcess[];
  public originalTreatmentProcesses: TreatmentProcess[];

  constructor(private treatmentProcessService: TreatmentProcessService,
              private confirmatoryActionService: ConfirmatoryActionService) { }

  ngOnInit(): void {
    this.initMechanismsAndObjectives();
    this.originalTreatmentProcesses = JSON.parse(JSON.stringify(this.facility.facilityTreatments));
  }

  private initMechanismsAndObjectives() {
    const mechanismsAndObjectivesObservable = forkJoin({
      mechanisms: this.treatmentProcessService.getProcessMechanisms(),
      objectives: this.treatmentProcessService.getObjectives()
    });
    mechanismsAndObjectivesObservable.subscribe(response => {
      this.mechanisms = response.mechanisms;
      this.objectives = response.objectives;
      this.isLoading = false;
    });
  }

  onTreatmentProcessChange(treatment: TreatmentProcess) {
    this.determineModified(treatment);
    this.edit.emit(this.facility);
  }

  deleteTreatmentProcess(treatment: TreatmentProcess) {
    this.confirmatoryActionService.confirm(
        'Confirm Deletion',
        'Do not delete treatment. If treatment has changed, enter an end date in the' +
      ' column and add a new objective/mechanism.\n\nDo you still want to delete this treatment?',
        'Yes',
        'No',
        'md')
      .then((confirmed) => {
        if (confirmed) {
          treatment.status = 'deleted';
          this.onTreatmentProcessChange(treatment);
        }
      })
      .catch(() => {});
  }

  restoreTreatmentProcess(treatment: TreatmentProcess) {
    delete treatment.status;
  }

  addTreatmentProcess() {
    if (!this.newTreatmentProcesses) {
      this.newTreatmentProcesses = [];
    }
    const newTreatmentProcess: TreatmentProcess = {
      objective: {
        code: null
      },
      processMechanism: {
        code: null
      },
      status: 'added'
    };
    this.newTreatmentProcesses.push(newTreatmentProcess);
  }

  onNewTreatmentProcessChange(newTreatmentProcess: TreatmentProcess, newProcessIndex: number) {
    if (!this.facility.facilityTreatments) {
      this.facility.facilityTreatments = [];
    }
    if (newTreatmentProcess && newTreatmentProcess.objective.code && newTreatmentProcess.processMechanism.code) {
      this.facility.facilityTreatments.push(newTreatmentProcess);
      this.newTreatmentProcesses.splice(newProcessIndex, 1);
      this.onTreatmentProcessChange(newTreatmentProcess);
    }
  }

  deleteNewTreatmentProcess(newProcessIndex: number) {
    this.newTreatmentProcesses.splice(newProcessIndex, 1);
  }

  treatmentCodeCompare(tc0: TreatmentCode, tc1: TreatmentCode) {
    return tc0 && tc1 && tc0.code && tc1.code && tc0.code === tc1.code;
  }

  determineModified(treatment: TreatmentProcess) {
    if (!(treatment.status === 'added' || treatment.status === 'deleted')) {
      const originalTreatment = this.originalTreatmentProcesses.find(t => t.id === treatment.id);
      if ('modified' !== originalTreatment.status) {
        if (this.equalTreatment(treatment, originalTreatment)) {
          delete treatment.status;
        } else {
          treatment.status = 'modified';
        }
      }
    }
  }

  dateCompareFalsyEqual(date1: Date, date2: Date): boolean {
    if (date1 || date2) {
      return date1 === date2;
    } else {
      return true;
    }
  }

  equalTreatment(treatment: TreatmentProcess, originalTreatment: TreatmentProcess): boolean {
    return treatment.id === originalTreatment.id
      && treatment.processMechanism.code === originalTreatment.processMechanism.code
      && treatment.objective.code === originalTreatment.objective.code
      && this.dateCompareFalsyEqual(treatment.treatmentBegin, originalTreatment.treatmentBegin)
      && this.dateCompareFalsyEqual(treatment.treatmentEnd, originalTreatment.treatmentEnd);
  }

  isDeleted(treatment: TreatmentProcess) {
    return treatment.status === 'deleted';
  }

}
