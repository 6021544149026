import {Component, OnDestroy, OnInit} from '@angular/core';
import { Unsubscribable } from 'rxjs';
import { BasicDetailsService } from './basic-details.service';
import { WaterSystemService } from '../water-system.service';
import { Checkout } from 'src/app/domain/checkout';
import { WaterSystem } from 'src/app/domain/water-system';
import { MessageService } from 'src/app/message/message.service';
import { Severity } from 'src/app/message/Severity';

@Component({
  selector: 'app-basic-details',
  templateUrl: './basic-details.component.html',
  styleUrls: ['./basic-details.component.scss']
})
export class BasicDetailsComponent implements OnInit, OnDestroy {

  private waterSystem$: Unsubscribable;
  waterSystem: Checkout<WaterSystem>;

  error = null;
  valid = true;

  constructor(private waterSystemService: WaterSystemService,
              private messageService: MessageService,
              private basicDetailsService: BasicDetailsService) { }

  ngOnInit() {
    this.basicDetailsService.waterSystem$.subscribe(
      waterSystem => {
        this.waterSystem = waterSystem;
      },
      err => {
        if (this.error == null) {
          this.error = [];
        }
        this.error.push(err);
      }
    );
  }

  save() {
    this.messageService.clear();
    if (this.isValid()) {
      this.waterSystemService.save(this.waterSystem.data.id, this.waterSystem).subscribe(() => {});
      this.messageService.add({ severity: Severity.SUCCESS, value: 'Basic Details has been saved.' });
    }
    window.scrollTo(0, 0);
  }

  isValid(): boolean {
    this.valid = true;
    this.isGeneralValid();
    this.isPopulationValid();
    this.isServiceAreaValid();
    this.isProductionSourceWaterProtectionValid();
    return this.valid;
  }

  isGeneralValid(): void {
    if (this.waterSystem.data.systemClassPoints) {
      if (this.waterSystem.data.systemClassPoints < 0) {
        this.messageService.add({ severity: Severity.ERROR, value: 'The System Class Points cannot be negative.' });
        this.valid = false;
      } else if (this.waterSystem.data.systemClassPoints > 999) {
        this.messageService.add({ severity: Severity.ERROR, value: 'The System Class Points cannot exceed 999.' });
        this.valid = false;
      }
    }

    if (this.waterSystem.data.serviceConnections) {
      if (this.waterSystem.data.serviceConnections < 0) {
        this.messageService.add({ severity: Severity.ERROR, value: 'The Service Connections cannot be negative.' });
        this.valid = false;
      } else if (this.waterSystem.data.serviceConnections > 9999999) {
        this.messageService.add({ severity: Severity.ERROR, value: 'The Service Connections cannot exceed 9999999.' });
        this.valid = false;
      }
    }
  }

  isPopulationValid(): void {
    this.isMonthPopulationValid(this.waterSystem.data.popJan, 'January');
    this.isMonthPopulationValid(this.waterSystem.data.popFeb, 'February');
    this.isMonthPopulationValid(this.waterSystem.data.popMar, 'March');
    this.isMonthPopulationValid(this.waterSystem.data.popApr, 'April');
    this.isMonthPopulationValid(this.waterSystem.data.popMay, 'May');
    this.isMonthPopulationValid(this.waterSystem.data.popJun, 'June');
    this.isMonthPopulationValid(this.waterSystem.data.popJul, 'July');
    this.isMonthPopulationValid(this.waterSystem.data.popAug, 'August');
    this.isMonthPopulationValid(this.waterSystem.data.popSep, 'September');
    this.isMonthPopulationValid(this.waterSystem.data.popOct, 'October');
    this.isMonthPopulationValid(this.waterSystem.data.popNov, 'November');
    this.isMonthPopulationValid(this.waterSystem.data.popDec, 'December');
  }

  isMonthPopulationValid(population: number, month: string): void {
    if (population == null) {
      return;
    }
    if (population < 0) {
      this.messageService.add({ severity: Severity.ERROR, value: 'The population for ' + month + ' cannot be negative.' });
      this.valid = false;
    } else if (population > 9999999) {
      this.messageService.add({ severity: Severity.ERROR, value: 'The population for ' + month + ' cannot exceed 9999999.' });
      this.valid = false;
    }
  }

  isServiceAreaValid(): void {
    if (this.waterSystem.data.serviceAreaPrimary == null || this.waterSystem.data.serviceAreaPrimary === '') {
      this.messageService.add({ severity: Severity.ERROR, value: 'A Primary Service Area Characteristic is Required.' });
      this.valid = false;
    }
  }

  isProductionSourceWaterProtectionValid(): void {
    this.isProductionDataValid(this.waterSystem.data.designCapacity, 'Design Capacity');
    this.isProductionDataValid(this.waterSystem.data.emergencyCapacity, 'Emergency Capacity');
    this.isProductionDataValid(this.waterSystem.data.maxDailyProduction, 'Max Daily Production');
    this.isProductionDataValid(this.waterSystem.data.averageDailyProduction, 'Avg Daily Production');
  }

  isProductionDataValid(amount: number, amountDescription: string): void {
    if (amount != null) {
      if (amount === 0) {
        // no-op
      } else if (amount < 0) {
        this.messageService.add({ severity: Severity.ERROR, value: 'The ' + amountDescription + ' cannot be negative.' });
        this.valid = false;
      } else if (amount > 999999999) {
        this.messageService.add({ severity: Severity.ERROR, value: 'The ' + amountDescription + ' cannot exceed 999999999.' });
        this.valid = false;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.waterSystem$) {
      this.waterSystem$.unsubscribe();
    }
  }
}
