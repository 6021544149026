import {Component, OnInit, Input} from '@angular/core';
import {BasicDetailsService} from '../basic-details.service';
import {Checkout} from 'src/app/domain/checkout';
import {WaterSystem} from 'src/app/domain/water-system';
import {SERVICE_AREA_TYPES} from 'src/app/shared/service-area-type';

@Component({
  selector: 'app-service-area',
  templateUrl: './service-area.component.html',
  styleUrls: ['./service-area.component.scss']
})
export class ServiceAreaComponent implements OnInit {

  @Input() waterSystem: Checkout<WaterSystem>;

  public isServiceAreaCollapsed = true;

  readonly SERVICE_AREA_TYPES: any[] = SERVICE_AREA_TYPES;

  constructor(private basicDetailsService: BasicDetailsService) { }

  ngOnInit() {
    if (this.waterSystem.data.licFpls == null) {
      this.waterSystem.data.licFpls = false;
    }
    if (this.waterSystem.data.licLoc == null) {
      this.waterSystem.data.licLoc = false;
    }
    if (this.waterSystem.data.licMda == null) {
      this.waterSystem.data.licMda = false;
    }
    if (this.waterSystem.data.licDot == null) {
      this.waterSystem.data.licDot = false;
    }
    if (this.waterSystem.data.serviceAreaSecondary == null || this.waterSystem.data.serviceAreaSecondary.length === 0) {
      this.waterSystem.data.serviceAreaSecondary = [];
    }
  }

  onChange() {
    this.basicDetailsService.changeWaterSystem(this.waterSystem);
  }

  onSecondaryCharacteristicChange(index: number, value: string) {
    this.waterSystem.data.serviceAreaSecondary[index] = value;
    this.onChange();
  }

  removeArea(index: number) {
    if (index > -1) {
      this.waterSystem.data.serviceAreaSecondary.splice(index, 1);
    }
    this.onChange();
  }

  addArea() {
    this.waterSystem.data.serviceAreaSecondary.push('');
  }
}
