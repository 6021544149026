import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
import * as d3Sankey from 'd3-sankey';
import { Facility } from 'src/app/domain/facility';
import { WaterSystemFacilitiesService } from '../../water-system-facilities.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-flow-visualization',
  templateUrl: './flow-visualization.component.html',
  styleUrls: ['./flow-visualization.component.scss']
})
export class FlowVisualizationComponent implements OnInit {

  facilities: Facility[];
  nodesDetailed = [] as any;
  nodesAggregate = [] as any;
  linksDetailed = [] as any;
  linksAggregate = [] as any;
  nodesRemovedFromFlow = [] as any;
  linksRemovedFromFlow = [] as any;
  errors: any[];
  isFacilitiesLoading: boolean = true;
  waterSystemId: number;
  flowToggle: number = 0;

  constructor(private route: ActivatedRoute,
              private waterSystemFacilitiesService: WaterSystemFacilitiesService) { }

  ngOnInit() {
    this.getFlow();
  }

  getFlow() {
    this.waterSystemId = Number(this.route.parent.snapshot.params['id']);
    this.waterSystemFacilitiesService.findFlowGraphLocally(this.waterSystemId).subscribe(result => {
      this.nodesDetailed = result.nodesDetailed;
      this.nodesAggregate = result.nodesAggregate;
      this.linksDetailed = result.linksDetailed;
      this.linksAggregate = result.linksAggregate;
      this.nodesRemovedFromFlow = result.nodesRemovedFromFlow;
      this.linksRemovedFromFlow = result.linksRemovedFromFlow;
      this.isFacilitiesLoading = false;
      setTimeout(() => this.drawFlow(), 500);
    });
  }

  drawFlow() {
    const nodeWidth = 15;

    let svg = d3.select("#sankey"),
        container = document.getElementById("flow-chart"),
        width = container.offsetWidth,
        height = container.offsetHeight;

    svg
        .attr('viewBox','0 0 '+width +' '+height )
        .attr('preserveAspectRatio','xMinYMin');

    svg.selectAll("*").remove();

    let sankey = d3Sankey.sankey()
        .nodeId(function id(d: any) { return d.id; })
        .nodeWidth(nodeWidth)
        .nodePadding(10)
        .extent([[1, 1], [width - 1, height - 6]]);

    let link = svg.append("g")
        .attr("class", "links")
        .attr("fill", "none")
        .attr("stroke", "#000")
        .attr("stroke-opacity", 0.2)
        .selectAll("path");

    let node = svg.append("g")
        .attr("class", "nodes")
        .attr("font-family", "sans-serif")
        .attr("font-size", 12)
        .selectAll("g");

    let nodes = [];
    let links = [];
    if (this.flowToggle == 0) {
      nodes = this.nodesDetailed;
      links = this.linksDetailed;
    } else if (this.flowToggle == 1) {
      nodes = this.nodesAggregate;
      links = this.linksAggregate;
    } else if (this.flowToggle == 2) {
      nodes = this.nodesRemovedFromFlow;
      links = this.linksRemovedFromFlow;
    }
    let graph = {
      nodes: nodes,
      links: links
    }
    sankey(graph);

    let path = d3Sankey.sankeyLinkHorizontal()
        .source(function(d: any) {
          return [
            d.source.x0 + nodeWidth,
            (d.source.y0 + d.source.y1 + nodeWidth) / 2
          ]
        })
        .target(function(d: any) {
          return [
            d.target.x0 + nodeWidth,
            (d.target.y0 + d.target.y1 + nodeWidth) / 2
          ]
        });

    link = link
        .data(links)
        .enter().append("path")
        .attr("d", path)
        .attr("stroke-width", function (d: any) {
          if (d.title && d.title === 'HIDE') {
            return 0;
          } else {
            return nodeWidth;
          }
        })
        .attr("tabindex", function (d: any) {
          if (d.title && d.title === 'HIDE') {
            return "-1";
          } else {
            return "0";
          }
        });

    link.append("title")
        .text(function (d: any) { return d.title; });

    node = node
        .data(nodes)
        .enter().append("g");

    node.append("rect")
        .attr("x", function (d: any) { return d.x0; })
        .attr("y", function (d: any) { return (d.y1 + d.y0) / 2; })
        .attr("height",  function (d: any) {
          if (d.title && d.title === 'HIDE') {
            return 0;
          } else {
            return nodeWidth;
          }
        })
        .attr("width",  function (d: any) {
          if (d.title && d.title === 'HIDE') {
            return 0;
          } else {
            return d.x1 - d.x0;
          }
        })
        .attr("fill", function (d: any) { return d.color; })
        .attr("stroke", "#000")
        .attr("tabindex", function (d: any) {
          if (d.title && d.title === 'HIDE') {
            return "-1";
          } else {
            return "0";
          }
        })
        .attr("aria-label", function (d: any) { return d.title; });

    node.append("text")
        .attr("x", function (d: any) {
          return d.x1;
        })
        .attr("y", function (d: any) {
          if (d.depth % 2 === 0) {
            return (d.y1 + d.y0 - (nodeWidth / 4) * 4) / 2;
          } else {
            return (d.y1 + d.y0 + nodeWidth * 4) / 2;
          }
        })
        .attr("text-anchor", "end")
        .text(function (d: any) { return d.name; })
        .filter(function (d: any) { return d.x0 < width / 2; })
        .attr("x", function (d: any) { return d.x0; })
        .attr("text-anchor", "start");

    node.append("title")
        .text(function (d: any) { return d.title; });

    svg.call(d3.zoom()
        .extent([[0, 0], [width, height]])
        .scaleExtent([1, 8])
        .on("zoom", zoomed));

    function zoomed({transform}) {
        node.attr("transform", transform);
        link.attr("transform", transform);
    }
  }
}
