<div class="container-fluid">
  <div *ngIf="!isAdmin && !isInspector">
    <div class="card">
      <div class="card-body">
        <h1>Unauthorized Access</h1>
        <p>You do not currently have access to this application. For access, please contact an administrator.</p>
      </div>
    </div>
  </div>
  <div *ngIf="isAdmin || isInspector">
    <h1>DWP Site Visit Home</h1>
    <app-checked-out-site-visits></app-checked-out-site-visits>
    <app-upcoming-site-visits></app-upcoming-site-visits>
  </div>
</div>
