import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContactsService } from './contacts.service';
import { OperatorsService } from './operators.service';
import { Checkout } from 'src/app/domain/checkout';
import { ComplianceOfficer } from 'src/app/domain/compliance-officer';
import { Contact } from 'src/app/domain/contact';
import { DistrictContact } from 'src/app/domain/district-contact';
import { WaterOperator } from 'src/app/domain/water-operator';
import { ROLE_TYPES } from 'src/app/shared/role-type';
import { DistrictContactService } from './district-contact.service';
import { ComplianceOfficerService } from './compliance-officer.service';
import { Unsubscribable } from 'rxjs';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, OnDestroy {

  contacts: Checkout<Contact[]>;
  operators: Checkout<WaterOperator[]>;
  districtContact: Checkout<DistrictContact>;
  complianceOfficer: Checkout<ComplianceOfficer>;

  public isContactsLoading = true;
  public isOperatorsLoading = true;
  public isDistrictContactLoading = true;
  public isComplianceOfficerLoading = true;

  private readonly ROLE_TYPES: any[] = ROLE_TYPES;

  private contacts$: Unsubscribable;
  private operators$: Unsubscribable;
  private districtContact$: Unsubscribable;
  private complianceOfficer$: Unsubscribable;

  error = null;

  constructor(private complianceOfficerService: ComplianceOfficerService,
              private contactsService: ContactsService,
              private districtContactService: DistrictContactService,
              private operatorsService: OperatorsService) { }

  ngOnInit() {
    this.contacts$ = this.contactsService.contacts$.subscribe(
      contacts => {
        this.contacts = contacts;
        this.isContactsLoading = false;
      },
      err => {
        if (this.error == null) {
          this.error = [];
        }
        this.error.push(err);
        this.isContactsLoading = false;
      },
      () => this.isContactsLoading = false
    );
  
    this.operators$ = this.operatorsService.operators$.subscribe(
      operators => {
        if (operators) {
          this.operators = operators;
        }
        this.isOperatorsLoading = false;
      },
      err => {
        if (this.error == null) {
          this.error = [];
        }
        this.error.push(err);
        this.isOperatorsLoading = false;
      },
      () => this.isOperatorsLoading = false
    );

    this.districtContact$ = this.districtContactService.districtContact$.subscribe(
      districtContact => {
        if (districtContact) {
          this.districtContact = districtContact;
        }
        this.isDistrictContactLoading = false;
      },
      err => {
        if (this.error == null) {
          this.error = [];
        }
        this.error.push(err);
        this.isDistrictContactLoading = false;
      },
      () => this.isDistrictContactLoading = false
    );

    this.complianceOfficer$ = this.complianceOfficerService.complianceOfficer$.subscribe(
      complianceOfficer => {
        if (complianceOfficer) {
          this.complianceOfficer = complianceOfficer;
        }
        this.isComplianceOfficerLoading = false;
      },
      err => {
        if (this.error == null) {
          this.error = [];
        }
        this.error.push(err);
        this.isComplianceOfficerLoading = false;
      },
      () => this.isComplianceOfficerLoading = false
    );
  }

  ngOnDestroy() {
    if (this.contacts$ != null) {
      this.contacts$.unsubscribe();
    }
    if (this.operators$ != null) {
      this.operators$.unsubscribe();
    }
    if (this.districtContact$ != null) {
      this.districtContact$.unsubscribe();
    }
    if (this.complianceOfficer$ != null) {
      this.complianceOfficer$.unsubscribe();
    }
  }
}
