import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Note } from 'src/app/domain/note';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  private notes = new BehaviorSubject<Note[]>([]);
  notes$ = this.notes.asObservable();

  constructor() { }

changeWaterSystemNotes(notes: Note[]) {
    this.notes.next(notes);
  }
}