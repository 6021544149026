import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WaterOperator } from "../domain/water-operator";

@Injectable({
  providedIn: 'root'
})
export class WaterOperatorsListService {

  private readonly WATER_OPERATORS_LIST_URL = environment.serverUrl + '/wateroperatorslist';

  constructor(private httpClient: HttpClient) { }

  /**
   * Gets the water operators list from the remote API.
   */
  getWaterOperatorsList(): Observable<WaterOperator[]> {
    return this.httpClient.get<WaterOperator[]>(`${this.WATER_OPERATORS_LIST_URL}`);
  }
}
