import { Component, Input, OnInit } from '@angular/core';
import { Well } from "../../domain/facility";

@Component({
  selector: 'app-iwmz',
  templateUrl: './iwmz.component.html',
})
export class IwmzComponent implements OnInit {

  @Input()
  well: Well;

  constructor() { }

  ngOnInit() {

  }
}
