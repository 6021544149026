<div class="card">
  <div class="card-body">
    <div>
      <h1>Add Note</h1>
    </div>
    <div class="pt-3" *ngIf="isAdding">
      <div class="spinner-border" role="status">
        <span class="sr-only">Adding Note...</span>
      </div>
      <span>Adding Note...</span>
    </div>
    <div class="pt-3" *ngIf="!isAdding">
      <app-note-info-form [note]="note" (saved)="add($event)" (cancelled)="cancel()"></app-note-info-form>
    </div>
  </div>
</div>