import {generateUuid, Question} from './question';

export interface Questionnaire {
  id: number;
  item: string;
  category: string;
  description: string;
  activeInd: boolean;
  question: Question[];
  facilityId?: number;
  complete?: boolean;
  facilityStatusCode: string;
}

export function getQuestionByUuid(questionnaire: Questionnaire, uuid: string): Question {
  return questionnaire.question.find(q => q.uuid === uuid);
}

export function removeQuestionByUuid(questionnaire: Questionnaire, uuid: string) {
  questionnaire.question.splice(questionnaire.question.findIndex(q => q.uuid === uuid), 1);
}

export function addQuestionAfterIndex(questionnaire: Questionnaire, index: number, question: Question) {
  questionnaire.question.splice(index, 0, question);
}

export function removeQuestionAfterIndex(questionnaire: Questionnaire, index: number) {
  questionnaire.question.splice(index, 1);
}

/**
 * Check and if needed migrate questionnaire from using indexes for dependent questions to
 * using uuids. Questions that do not currently have uuids will be given them.
 * @param importQuestionnaire questionnaire to migrate.
 */
export function migrateQuestionaireToUuid(questionnaire: Questionnaire) {
  questionnaire.question.forEach(q => {
    if (!q.uuid) {
      q.uuid = generateUuid();
    }
  });
  questionnaire.question.forEach(q => {
    if (q.prerequisiteQuestionIndex) {
      q.prerequisiteQuestionUuid = questionnaire.question[q.prerequisiteQuestionIndex].uuid;
      delete q.prerequisiteQuestionIndex;
    }
  });
}

/**
 * Get a list of dependent questions for the given question.
 * @param questionnaire The Questionnaire to search
 * @param uuid The uuid of the question
 */
export function getDependentQuestions(questionnaire: Questionnaire, uuid: string): Question[] {
  return questionnaire.question.filter(q => q.prerequisiteQuestionUuid === uuid);
}

/**
 * Get a list of all dependent questions including dependents of dependents.
 * @param questionnaire The questionnaire to search
 * @param uuid The uuid of the quesition
 */
export function getAllDependentQuestions(questionnaire: Questionnaire, uuid: string): Question[] {
  const dependants = getDependentQuestions(questionnaire, uuid);
  dependants.forEach(d => {
    dependants.push(...getAllDependentQuestions(questionnaire, d.uuid));
  });
  return dependants;
}

export interface QuestionnaireList {
  pws: Questionnaire[];
  well: FacilityQuestionnaire;
  distribution: FacilityQuestionnaire;
  storage: FacilityQuestionnaire;
  treatment: FacilityQuestionnaire;
  interconnect: FacilityQuestionnaire;
  surfaceWater: FacilityQuestionnaire;
}

export interface FacilityQuestionnaire {
  base: Questionnaire;
  facilities: Questionnaire[];
  missing: MissingFacility;
}

export interface MissingFacility {
  base: Questionnaire;
  missing: Questionnaire;
}
