<div class="container-fluid">
  <div *ngIf="isSummaryLoading; else loaded">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading Summary...</span>
    </div>
    <span>Loading Summary...</span>
  </div>

  <ng-template #loaded>
    <app-message></app-message>
    <app-toast aria-live="polite" aria-atomic="true"></app-toast>

    <div *ngIf="siteVisit && !invalidPwsId">
      <ul class="list-group list-group-horizontal-sm mb-3">
        <li class="list-group-item"><strong>PWS ID: </strong>{{ siteVisit.id }}</li>
        <li class="list-group-item"><strong>PWS Name: </strong>{{ siteVisit.name }}</li>
      </ul>
      <div *ngIf="isCheckoutWidgetLoading">
        <div class="spinner-border" role="status">
          <span class="sr-only">Refreshing Checkout Status...</span>
        </div>
        <span>Refreshing Checkout Status...</span>
      </div>
      <ul class="list-group list-group-horizontal-md mb-3" *ngIf="!isCheckoutWidgetLoading && !isCancelling && !isCheckingIn">
        <li class="list-group-item"><strong>Upcoming Visit Type: </strong>{{ siteVisit?.nextVisitType |
          visitTypeAnnualSanitary }}</li>
        <li class="list-group-item"><strong>Planned Visit Date: </strong>{{ siteVisit?.nextVisit | date: 'MM/dd/yyyy'
          }}</li>
        <li *ngIf="isOwner" class="list-group-item display-flex">
          <button type="button" (click)="cancelCheckout()" class="btn py-0" title="Delete" aria-label="Delete"><i
              class="fa fa-trash-alt fa-lg"></i></button>
        </li>
        <li *ngIf="isNotCheckedOut" class="list-group-item display-flex">
          <button type="button" (click)="checkout()" class="btn py-0" title="Check Out" aria-label="Check Out"><i
              class="fa fa-cloud-download-alt"></i></button>
        </li>
        <li *ngIf="isCheckedOutByOther" class="list-group-item display-flex">
          <button type="button" (click)="cancelCheckout()" class="btn py-0 btn-checkout-widget-disabled"
            title="Delete" [disabled]="true" aria-label="Delete"><i class="fa fa-trash-alt fa-lg"></i></button>
          <!-- Disabled 6/27/2024 <button type="button" (click)="requestUnlock()" class="btn py-0" title="Request Unlock"
            aria-label="Request Unlock"><i class="fa fa-paper-plane fa-lg"></i></button>-->
        </li>
        <li *ngIf="isOwner" class="list-group-item">
          <strong>Checkout Date: </strong>{{ getLocalCreatedDate(siteVisit) | date: 'MM/dd/yyyy' }}
        </li>
        <li *ngIf="isCheckedOutByOther" class="list-group-item">
          <strong>Locked By: </strong>{{ siteVisit.fullName }} on {{ getLocalCreatedDate(siteVisit) | date: 'MM/dd/yyyy h:mm a' }}
        </li>
      </ul>
    </div>

    <div *ngIf="!error">
      <div *ngIf="!anyIsLoading && !isCheckingIn && !isCancelling && !error">
        <h1 *ngIf="isCheckedOutByOther">Locked Water System</h1>
        <h1 *ngIf="isNotCheckedOut">Unlocked Water System</h1>
      </div>
      <div *ngIf="invalidPwsId" class="alert alert-danger" role="alert">
        The given PWS ID is invalid.
      </div>

      <div *ngIf="anyIsLoading && !isCheckingIn">
        <div class="border p-2 panel-collapsible-header">
          <a href="javascript:void(0);" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapse-checked-out">
            <span>Loaded {{loadedCount}} of {{loadingTotal}} data items.</span>
            <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isCollapsed"></i>
            <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isCollapsed"></i>
          </a>
        </div>
        <div id="collapse-checked-out" class="card px-2" [ngbCollapse]="isCollapsed">
          <div *ngIf="isLoading.waterSystem">Loading Water System Basic Data...</div>
          <div *ngIf="isLoading.contacts">Loading Contacts...</div>
          <div *ngIf="isLoading.operators">Loading Operators...</div>
          <div *ngIf="isLoading.districtContact">Loading District Contact...</div>
          <div *ngIf="isLoading.complianceOfficer">Loading Compliance Officer...</div>
          <div *ngIf="isLoading.notes">Loading Notes...</div>
          <div *ngIf="isLoading.flow">Loading Facility Flow...</div>
          <div *ngIf="isLoading.visits">Loading Site Visits...</div>
          <div *ngIf="isLoading.wells">Loading Wells...</div>
          <div *ngIf="isLoading.distribution">Loading Distribution Facilities...</div>
          <div *ngIf="isLoading.treatment">Loading Treatment Plants...</div>
          <div *ngIf="isLoading.storage">Loading Storage...</div>
          <div *ngIf="isLoading.surface">Loading Surface Water Facilities...</div>
          <div *ngIf="isLoading.booster">Loading Booster Stations...</div>
          <div *ngIf="isLoading.interconnect">Loading Consecutive Connections...</div>
          <div *ngIf="isLoading.deficiencies">Loading Deficiencies...</div>
        </div>
        <p>
          <ngb-progressbar type="info" [value]="percentLoaded" [striped]="true"></ngb-progressbar>
        </p>
      </div>
      <div *ngIf="isCheckingIn">
        <div class="spinner-border" role="status">
          <span class="sr-only">Checking In Water System...</span>
        </div>
        <span>Checking In Water System...</span>
      </div>
      <div class="row pt-3" *ngIf="!isLoadingLastVisit && (isNotCheckedOut || isCheckedOutByOther)">
        <div class="col-xl-12">
          <app-report-summary [siteVisit]="siteVisit"></app-report-summary>
        </div>
        <div class="col-xl-12 pt-3">
          <app-site-visit-summary [isCollapsed]="false"></app-site-visit-summary>
        </div>
      </div>
      <div class="row"
        *ngIf="!anyIsLoading && waterSystem && waterSystem.dataReady && contacts && contacts.dataReady && !isCancelling && isOwner">
        <div class="col-lg-3 col-xl-2 order-lg-2">
          <div class="card mb-3">
            <nav class="list-group list-group-flush">
              <a class="sr-only sr-only-focusable list-group-item mb-1" [href]="skipWaterSystemNavigationLink">skip
                water system navigation</a>
              <a class="list-group-item list-group-item-action" routerLink="contacts"
                routerLinkActive="active">Manage Contacts</a>
              <a class="list-group-item list-group-item-action" routerLink="basic-details"
                routerLinkActive="active">Basic Details</a>
              <a class="list-group-item list-group-item-action" routerLink="notes" routerLinkActive="active">Notes</a>
              <a class="list-group-item list-group-item-action" routerLink="site-visits" routerLinkActive="active">Site
                Visits</a>
              <a class="list-group-item list-group-item-action" routerLink="facilities"
                routerLinkActive="active">Facilities</a>
            </nav>
          </div>
        </div>

        <div id="water-system-contents" class="col-lg-9 col-xl-10 order-lg-1">
          <router-outlet></router-outlet>
        </div>
      </div>

      <div *ngIf="isCancelling">
        <div class="spinner-border" role="status">
          <span class="sr-only">Cancelling Checkout...</span>
        </div>
        <span>Cancelling Checkout...</span>
      </div>
    </div>

    <div *ngIf="error">
      <div class="card">
        <div class="card-body">
          <h1>Error</h1>
          <p>{{ error }}</p>
        </div>
      </div>
    </div>
  </ng-template>
</div>
