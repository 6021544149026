import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Checkout } from 'src/app/domain/checkout';
import { ExistingDeficiency } from "../../domain/finding";

@Injectable({
  providedIn: 'root'
})
export class DeficienciesService {

  private deficiencies = new BehaviorSubject<Checkout<ExistingDeficiency[]>>(
    {
      status: '',
      access: '',
      username: '',
      version: 0,
      dataReady: false,
      data: [],
      statusMessage: ''
    }
  );
  deficiencies$ = this.deficiencies.asObservable();

  constructor() { }

  changeWaterSystemDeficiencies(system: Checkout<ExistingDeficiency[]>) {
    this.deficiencies.next(system);
  }
}
