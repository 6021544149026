import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'waterSystemType'})
export class WaterSystemTypePipe implements PipeTransform {
  transform(value: string): string {
    if (value === 'COMM') {
      return 'Community';
    } else if (value === 'TNC') {
      return 'Transient Noncommunity';
    } else if (value === 'NTNC') {
      return 'Nontransient Noncommunity';
    } else {
      return value;
    }
  }
}
