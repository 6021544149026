<div class="card mb-3">
  <div class="card-body">
    <h1>Site Visits</h1>
    <div *ngIf="isSiteVisitsLoading; else loaded" class="pl-3">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading Site Visits...</span>
      </div>
      <span>Loading Site Visits...</span>
    </div>
    <ng-template #loaded>
      <div id="water-system-site-visits">
        <div class="pt-3">
          <app-current-site-visit [currentVisit]="currentVisit" (currentVisitEvent)="handleCurrentEvent($event)"></app-current-site-visit>
        </div>
        <div class="pt-3">
          <app-active-deficiencies></app-active-deficiencies>
        </div>
        <div class="pt-3" *ngIf="pastVisits">
          <app-site-visit-summary></app-site-visit-summary>
        </div>
        <div class="pt-3" *ngIf="pastVisits">
          <app-past-site-visits [pastVisits]="pastVisits"></app-past-site-visits>
        </div>
      </div>
    </ng-template>
  </div>
</div>
