<div class="card mb-3">
  <div class="card-body">
    <h2>New Potential Contaminants</h2>
    <div *ngIf="isLoading; else loaded">
      <div class="spinner-border"
           role="status">
        <span class="sr-only">Loading Potential Contaminants...</span>
      </div>
      <span>Loading Potential Contaminants...</span>
    </div>
    <ng-template #loaded>
      <div class="table-responsive">
        <table class="table table-bordered table-hover table-sm caption-top">
          <thead class="thead-light">
          <tr>
            <th scope="col"
                class="col-7">
              Type
            </th>
            <th scope="col"
                class="col-2">
              Bearing
            </th>
            <th scope="col"
                class="col-2">
              Distance
            </th>
            <th scope="col"
                class="col-1">
              Actions
            </th>
          </tr>
          </thead>
          <tbody>
            <tr *ngIf="!well.iwmzPcsi || well.iwmzPcsi.length === 0">
              <td colspan="4">
                No New Potential Contaminants Added.
              </td>
            </tr>
            <tr *ngFor="let pc of well.iwmzPcsi; let i = index">
              <td class="col-7">
                <div class="form-group my-0">
                  <label for="{{ 'potential-contaminant-type-' + i }}"
                         [hidden]="true">
                    Potential Contaminant Type:
                  </label>
                  <select id="{{ 'potential-contaminant-type-' + i }}"
                          [(ngModel)]="pc.pcsi_type"
                          [compareWith]="potentialContaminantCompare"
                          name="potential-contaminant-type"
                          required="true"
                          class="form-control"
                          readonly
                          style="pointer-events: none;">
                    <option *ngFor="let type of potentialContaminantsList"
                            [ngValue]="type">
                      {{type.potentialContaminationDescription}}
                    </option>
                  </select>
                </div>
              </td>
              <td class="col-2">
                <div class="form-group my-0">
                  <label for="{{ 'potential-contaminant-bearing-' + i }}"
                         [hidden]="true">
                    Bearing:
                  </label>
                  <input id="{{ 'potential-contaminant-bearing-' + i }}"
                         [(ngModel)]="pc.bearing"
                         type="number"
                         name="potential-contaminant-bearing"
                         class="form-control"
                         min="0"
                         max="260"
                         required
                         (change)="onPcsiChange()">
                </div>
              </td>
              <td class="col-2">
                <div class="form-group my-0">
                  <label for="{{ 'potential-contaminant-distance-' + i }}"
                         [hidden]="true">
                    Distance:
                  </label>
                  <input id="{{ 'potential-contaminant-distance-' + i }}"
                         [(ngModel)]="pc.distance"
                         type="number"
                         name="potential-contaminant-distance"
                         class="form-control"
                         min="0"
                         max="200"
                         required
                         (change)="onPcsiChange()"/>
                </div>
              </td>
              <td class="col-1 align-middle">
                <button type="button"
                        class="btn btn-outline-dark btn-sm py-0"
                        (click)="delete(i)"
                        attr.aria-label="Delete new potential contaminant {{ i + 1 }}">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col-12">
          <button id="add-potential-contaminant-button" type="button" class="btn btn-outline-primary" (click)="addPotentialContaminant()">Add Potential Contaminant</button>
        </div>
      </div>
      <div class="row pt-2" *ngIf="newPcsi">
        <div class="col-lg-2 col-md-6 col-sm-12">
          <div class="form-group my-0">
            <label for="add-potential-contaminant-class">
              Class (required):
            </label>
            <select id="add-potential-contaminant-class"
                    [(ngModel)]="selectedClass"
                    name="add-potential-contaminant-class"
                    required="true"
                    class="form-control"
                    (change)="onClassChange($event.target.value)">
              <option *ngFor="let class of classes"
                      value="{{class.classCode}}">
                {{class.classDescription}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="form-group my-0">
            <label for="add-potential-contaminant-type">
              Type (required):
            </label>
            <select id="add-potential-contaminant-type"
                    [(ngModel)]="newPcsi.pcsi_type"
                    name="add-potential-contaminant-type"
                    required="true"
                    class="form-control">
              <option *ngFor="let type of filteredPcList"
                      [ngValue]="type">
                {{type.potentialContaminationDescription}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12">
          <div class="form-group my-0">
            <label for="add-potential-contaminant-bearing">
              Bearing (required):
            </label>
            <input id="add-potential-contaminant-bearing"
                   [(ngModel)]="newPcsi.bearing"
                   type="number"
                   name="add-potential-contaminant-bearing"
                   class="form-control"
                   min="0"
                   max="260"
                   required>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12">
          <div class="form-group my-0">
            <label for="add-potential-contaminant-distance">
              Distance (required):
            </label>
            <input id="add-potential-contaminant-distance"
                   [(ngModel)]="newPcsi.distance"
                   type="number"
                   name="potential-contaminant-distance"
                   class="form-control"
                   min="0"
                   max="200"
                   required/>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12 pt-2" style="margin-top:30px;">
          <button type="button"
                  class="btn btn-outline-dark btn-sm py-0 mr-2"
                  (click)="addNew()"
                  [disabled]="!newPcsi?.pcsi_type || !newPcsi.bearing || !newPcsi.distance">
            Add
          </button>
          <button type="button"
                  class="btn btn-outline-dark btn-sm py-0"
                  (click)="deleteNew()">
            Delete
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
