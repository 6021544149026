import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Checkout} from 'src/app/domain/checkout';
import {Contact} from 'src/app/domain/contact';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  private contacts = new BehaviorSubject<Checkout<Contact[]>>(
    {
      status: '',
      access: '',
      username: '',
      version: 0,
      dataReady: false,
      data: [],
      statusMessage: ''
    }
  );
  contacts$ = this.contacts.asObservable();

  constructor() { }

  changeWaterSystemContacts(system: Checkout<Contact[]>) {
    this.contacts.next(system);
  }
}
