import { Injectable } from '@angular/core';
import { FacilityTypePipe } from 'src/app/shared/facility-type.pipe';
import { FacilityStatusPipe } from 'src/app/shared/facility-status.pipe';
import { Facility, Well } from 'src/app/domain/facility';
import { AvailabilityTypePipe } from 'src/app/shared/availability-type.pipe';
import { PumpTypePipe } from 'src/app/shared/pump-type.pipe';

@Injectable({
  providedIn: 'root'
})
export class FlowUtilityService {

  constructor(private facilityTypePipe: FacilityTypePipe,
              private facilityStatusPipe: FacilityStatusPipe,
              private availabilityTypePipe: AvailabilityTypePipe,
              private pumpTypePipe: PumpTypePipe) { }

  getNodeColor(type: string): string {
    switch (type) {
      case 'WL':
      case 'NP':
      case 'IN':
      case 'CC':
        return 'blue';
      case 'SH':
      case 'SU':
      case 'ST':
      case 'SS':
      case 'SE':
        return 'green';
      case 'TP':
        return 'yellow';
      case 'DS':
        return 'orange';
      case 'BS':
        return 'red';
      default:
        return 'black';
    }
  }

  getNodeTitle(facility: Facility): string {
    switch (facility.facilityCode) {
      case 'WL':
        return this.getWellFacilityTitle(facility as Well);
      case 'CD':
      case 'TP':
      case 'SH':
      case 'SU':
      case 'ST':
      case 'SS':
      case 'SE':
      case 'DS':
        return this.getDefaultTitle(facility);
      default:
        return 'Name: ' + facility.name;
    }
  }

  getWellFacilityTitle(well: Well): string {
    let title: string = this.getDefaultTitle(well as Facility);
    title += '\n' + 'Year Constructed: ' + (well.yearInstalledDate || '');
    title += '\n' + 'Well Depth: ' + (well.depth || '');
    title += '\n' + 'Casing Diameter: ' + (well.caseDiameter == null ? '' : well.caseDiameter);
    title += '\n' + 'Pump Type: ' + this.pumpTypePipe.transform(well.pumpCode);
    return title;
  }

  getDefaultTitle(facility: Facility): string {
    let title: string = '';
    title += 'Name: ' + facility.name;
    title += '\n' + 'Facility ID: ' + (facility.facilityId || '');
    title += '\n' + 'Type: ' + this.facilityTypePipe.transform(facility.facilityCode);
    title += '\n' + 'Status: ' + this.facilityStatusPipe.transform(facility.statusCode);
    title += '\n' + 'Availability: ' + this.availabilityTypePipe.transform(facility.availabilityCode);
    return title;
  }

  getLinkTitle(sourceName: string, targetName: string): string {
    return sourceName + " → " + targetName + "\n";
  }
}
