import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Well } from "../../../domain/facility";
import { PotentialContaminantsListService } from "../../potential-contaminants-list.service";
import { Unsubscribable } from "rxjs";
import {
  PotentialContaminant,
  PotentialContaminantType
} from "../../../domain/potential-contaminant";
import { ConfirmatoryActionService } from "../../../confirmatory-action/confirmatory-action.service";

@Component({
  selector: 'app-potential-contaminants',
  templateUrl: './potential-contaminants.component.html',
})
export class PotentialContaminantsComponent implements OnInit, OnDestroy {

  @Input()
  well: Well;

  @Output()
  edit = new EventEmitter<Well>();

  public isLoading = true;
  public potentialContaminantsList: PotentialContaminantType[];
  public filteredPcList: PotentialContaminantType[];
  public classes: any[];
  public selectedClass: string;
  public newPcsi: PotentialContaminant;

  private potentialContaminantsList$: Unsubscribable;

  constructor(private potentialContaminantsListService: PotentialContaminantsListService,
              private confirmatoryActionService: ConfirmatoryActionService) { }

  ngOnInit() {
    this.classes = [
      {
        classCode: null,
        classDescription: 'Select a class...'
      }
    ];
    this.potentialContaminantsList$ = this.potentialContaminantsListService.getPotentialContaminantsList().subscribe(
      result => {
        this.potentialContaminantsList = this.sortPotentialContaminantsListDefault(result);
        this.classes = this.getClasses();
        this.isLoading = false;
      }
    )
  }

  sortPotentialContaminantsListDefault(results: PotentialContaminantType[]): PotentialContaminantType[] {
    return results.sort((a, b) => {
      return (a.potentialContaminationDescription > b.potentialContaminationDescription)
        ? 1
        : ((b.potentialContaminationDescription > a.potentialContaminationDescription)
          ? -1
          : 0);
    });
  }

  getClasses() {
    if (!this.potentialContaminantsList) {
      return;
    }
    let tempClasses = this.potentialContaminantsList.map(({ classCode, classDescription }) =>
      ({ classCode, classDescription })
    );
    const map = new Map(tempClasses.map(c => [c.classCode, c]));
    if (!map || map.size === 0) {
      return
    }
    return ([...map.values()]).sort((a, b) => {
      return (a.classDescription > b.classDescription)
        ? 1
        : ((b.classDescription > a.classDescription)
          ? -1
          : 0);
    });
  }

  addPotentialContaminant() {
    this.newPcsi = {
      bearing: null,
      distance: null,
      pcsi_type: null,
    };
    if (this.well.samplePointId) {
      this.newPcsi.pwssrc_id = this.well.pwsId + this.well.samplePointId;
    }
  }

  onClassChange(classValue) {
    this.filteredPcList = this.potentialContaminantsList.filter(pc => pc.classCode === classValue);
  }

  addNew() {
    if (!this.well.iwmzPcsi) {
      this.well.iwmzPcsi = [];
    }
    if (this.newPcsi && this.newPcsi.pcsi_type && this.newPcsi.bearing && this.newPcsi.distance) {
      this.well.iwmzPcsi.push(this.newPcsi);
    }
    this.newPcsi = null;
    this.selectedClass = null;
    this.filteredPcList = [];
  }

  onPcsiChange() {
    this.edit.emit(this.well);
  }

  delete(processIndex) {
    this.confirmatoryActionService.confirm(
      "Confirm Deletion",
      "Are you sure you want to delete this Potential Contaminant?",
      "Yes",
      "No",
      "md")
      .then((confirmed) => {
        if (confirmed) {
          this.well.iwmzPcsi.splice(processIndex, 1);
          this.onPcsiChange();
        }
      })
      .catch(() => {});
  }

  deleteNew() {
    this.newPcsi = null;
    this.selectedClass = null;
    this.filteredPcList = [];
  }

  ngOnDestroy() {
    if (this.potentialContaminantsList$ != null) {
      this.potentialContaminantsList$.unsubscribe();
    }
  }

  potentialContaminantCompare(pc0:PotentialContaminantType, pc1:PotentialContaminantType){
    return pc0 && pc1 && pc0.potentialContaminationCode === pc1.potentialContaminationCode
  }
}
