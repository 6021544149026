import { Component, OnDestroy, OnInit } from '@angular/core';
import { Contact, ContactAction } from '../../../../domain/contact';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactsService } from '../../contacts.service';
import { Checkout } from 'src/app/domain/checkout';
import { MessageService } from 'src/app/message/message.service';
import { Severity } from 'src/app/message/Severity';
import { WaterSystemContactsService } from '../../../water-system-contacts.service';
import { Unsubscribable } from 'rxjs';

@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.component.html',
  styleUrls: ['./edit-contact.component.scss']
})
export class EditContactComponent implements OnInit, OnDestroy {

  public contact: Contact;
  public contactIndex: number;
  contacts: Checkout<Contact[]>;

  private contacts$: Unsubscribable;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private contactsService: ContactsService,
              private messageService: MessageService,
              private waterSystemContactsService: WaterSystemContactsService) { }

  ngOnInit(): void {
    this.contact = history.state.data;
    this.contacts$ = this.contactsService.contacts$.subscribe(contacts => {
      this.contacts = contacts;
      this.contactIndex = Number(this.route.snapshot.params['id']);
      if (this.contact == null) {
        this.contact = this.contacts.data[this.contactIndex];
      }
    });
  }

  save(contact: Contact) {
    if (contact.id > 0) {
      if (contact.action !== ContactAction.Create) {
        contact.action = ContactAction.Update;
      }
    }
    this.contacts.data[this.contactIndex] = contact;
    this.waterSystemContactsService.save(contact.pwsId, this.contacts);
    this.router.navigate(['contacts'], { relativeTo: this.route.parent });
    this.messageService.add({ severity: Severity.SUCCESS, value: 'Contact has been updated.' });
    window.scrollTo(0, 0);
  }

  cancel() {
    this.router.navigate(['contacts'], { relativeTo: this.route.parent });
  }

  ngOnDestroy() {
    if (this.contacts$ != null) {
      this.contacts$.unsubscribe();
    }
  }
}
