import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Question } from 'src/app/domain/question';
import {
  addQuestionAfterIndex,
  getQuestionByUuid,
  Questionnaire,
  removeQuestionAfterIndex
} from 'src/app/domain/questionnaire';
import { Visit } from 'src/app/domain/visit';
import { WaterSystemSiteVisitsService } from '../water-system-site-visits.service';
import { QuestionniareSubscriber } from './questionnaire-subscriber';
import * as _ from 'lodash';
import {flagRequiredQuestionnare, validate} from '../site-visits/site-visit-utils';
import { Unsubscribable } from 'rxjs';
import { SiteVisitsService } from '../site-visits/site-visits.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit, OnDestroy {

  waterSystemId: number;
  siteVisitId: number;
  siteVisit: Visit;
  questionnaireType: string;
  questionnaireId: string;
  questionnaire: Questionnaire;
  form: UntypedFormGroup;
  facilities: Questionnaire[];
  multi = false;
  isCollapsed: boolean[] = [];
  isSection: number[] = [];
  inSection = false;
  clearQuestionUuid: string;
  private visit$: Unsubscribable;
  isValidAdditionalLanguage: boolean;

  constructor(
    private route: ActivatedRoute,
    private siteVisitService: WaterSystemSiteVisitsService,
    private subscriber: QuestionniareSubscriber<Visit>,
    private siteVisitsSerivce: SiteVisitsService
    ) { }

  ngOnInit(): void {
    this.waterSystemId = Number(this.route.parent.parent.snapshot.params['id']);
    this.siteVisitId = Number(this.route.parent.snapshot.params['id']);
    this.route.paramMap.subscribe(params => {
      this.questionnaireType = params.get('type');
      this.subscriber.observable$.pipe(take(1)).subscribe(visit => {
        this.siteVisit = visit;
        this.findQuestionnaire();
      });
    });
  }

  findQuestionnaire() {
    this.route.queryParams.subscribe(queryParams => {
      this.isCollapsed = [];
      this.isSection = [];
      const el = document.getElementById('water-system-questionnaire');
      el.scrollIntoView();
      switch (this.questionnaireType) {
        case 'pws':
          this.setupPws(Number(queryParams.id));
          break;
        case 'well':
          this.setupWell(queryParams);
          break;
        case 'distribution':
          this.setupDistribution(queryParams);
          break;
        case 'storage':
          this.setupStorage(queryParams);
          break;
        case 'treatment':
          this.setupTreatment(queryParams);
          break;
        case 'surfacewater':
          this.setupSurfaceWater(queryParams);
          break;
        case 'interconnect':
          this.setupInterconnect(queryParams);
          break;
      }
    });
  }

  setupPws(id: number) {
    this.questionnaire = this.siteVisit.inspection.questionnaires.pws.find(q => q.id === id);
    if (this.questionnaire) {
      this.setupFormGroup(this.questionnaire);
      this.questionnaireId = this.questionnaire.id.toString();
    }
  }

  setupWell(queryParams: any) {
    if (queryParams.id) {
      this.facilities = undefined;
      if (Array.isArray(queryParams.id)) {
        this.facilities = queryParams.id.map((f: any) => {
          const facilityId = Number(f);
          return this.questionnaire = this.siteVisit.inspection.questionnaires.well.facilities
            .find(facility => facility.facilityId === facilityId);
        });
        this.questionnaire = _.cloneDeep(this.siteVisit.inspection.questionnaires.well.base);
        this.questionnaire.category = this.facilities.map(f => f.category).join(',');
        this.multi = true;
      } else {
        this.multi = false;
        const facilityId = Number(queryParams.id);
        this.questionnaireId = `${this.questionnaireType}-${facilityId}`;
        this.questionnaire = this.siteVisit.inspection.questionnaires.well.facilities
          .find(f => f.facilityId === facilityId);
      }
      if (this.questionnaire) {
        this.setupFormGroup(this.questionnaire);
      }
    }
  }

  setupDistribution(queryParams: any) {
    if (queryParams.id) {
      const facilityId = Number(queryParams.id);
      this.questionnaireId = `${this.questionnaireType}-${facilityId}`;
      this.questionnaire = this.siteVisit.inspection.questionnaires.distribution.facilities
        .find(f => f.facilityId === facilityId);
      if (this.questionnaire) {
        this.setupFormGroup(this.questionnaire);
      }
    }
  }

  setupStorage(queryParams: any) {
    if (queryParams.id) {
      const facilityId = Number(queryParams.id);
      this.questionnaireId = `${this.questionnaireType}-${facilityId}`;
      this.questionnaire = this.siteVisit.inspection.questionnaires.storage.facilities
        .find(f => f.facilityId === facilityId);
      if (this.questionnaire) {
        this.setupFormGroup(this.questionnaire);
      }
    } else {
      this.questionnaireId = `${this.questionnaireType}-missing`;
      this.questionnaire = this.siteVisit.inspection.questionnaires.storage.missing.missing;
      if (this.questionnaire) {
        this.setupFormGroup(this.questionnaire);
      }
    }
  }

  setupTreatment(queryParams: any) {
    if (queryParams.id) {
      const facilityId = Number(queryParams.id);
      this.questionnaireId = `${this.questionnaireType}-${facilityId}`;
      this.questionnaire = this.siteVisit.inspection.questionnaires.treatment.facilities
        .find(f => f.facilityId === facilityId);
      if (this.questionnaire) {
        this.setupFormGroup(this.questionnaire);
      }
    } else {
      this.questionnaireId = `${this.questionnaireType}-missing`;
      this.questionnaire = this.siteVisit.inspection.questionnaires.treatment.missing.missing;
      if (this.questionnaire) {
        this.setupFormGroup(this.questionnaire);
      }
    }
  }

  setupSurfaceWater(queryParams: any) {
    if (queryParams.id) {
      const facilityId = Number(queryParams.id);
      this.questionnaireId = `${this.questionnaireType}-${facilityId}`;
      this.questionnaire = this.siteVisit.inspection.questionnaires.surfaceWater.facilities
        .find(f => f.facilityId === facilityId);
      if (this.questionnaire) {
        this.setupFormGroup(this.questionnaire);
      }
    }
  }

  setupInterconnect(queryParams: any) {
    if (queryParams.id) {
      const facilityId = Number(queryParams.id);
      this.questionnaireId = `${this.questionnaireType}-${facilityId}`;
      this.questionnaire = this.siteVisit.inspection.questionnaires.interconnect.facilities
        .find(f => f.facilityId === facilityId);
      if (this.questionnaire) {
        this.setupFormGroup(this.questionnaire);
      }
    }
  }

  isHeader(question: Question, index: number): boolean {
    if (question.questionActiveInd && question.type === 'Section Header') {
      if (this.isSection.indexOf(index) === -1) {
        this.isSection.push(index);
        this.isCollapsed[index] = true;
      }
      return true;
    }
    return false;
  }

  isQuestion(question: Question): boolean {
    return question.questionActiveInd && question.type === 'Question' && question.answers !== undefined && question.answers.length > 0;
  }

  isFinding(question: Question): boolean {
    return question.questionActiveInd && question.type === 'Finding' && question.answers !== undefined && question.answers.length > 0;
  }

  isQuestionCollapsed(index: any): boolean {
    // find header if any.
    for (let counter = index; counter >= -1; counter--) {
      if (counter === -1) {
        return false;
      }
      if (this.isSection.indexOf(counter) > -1) {
        return this.isCollapsed[counter];
      }
    }
    return false;
  }

  setupFormGroup(questionnaire: Questionnaire ) {
    const group: any = {};
    questionnaire.question.forEach(question => {
      if (this.isQuestion(question)) {
        let disableFormControl = false;
        if (question.prerequisiteQuestionUuid) {
          const prerequisiteQuestion = getQuestionByUuid(questionnaire, question.prerequisiteQuestionUuid);
          if (!prerequisiteQuestion.value || !question.prerequisiteAnswer
            || prerequisiteQuestion.value !== question.prerequisiteAnswer) {
            disableFormControl = true;
          }
        }
        group[question.displayName] = new UntypedFormControl({value: question.value, disabled: disableFormControl});
        if (question.required) {
          group[question.displayName].setValidators([Validators.required]);
        }
        group[this.getReviewName(question)] = new UntypedFormControl(question.review || false);
      } else if (this.isFinding(question) && question.finding) {
        group[this.getFormGroupName(question)]
          = new UntypedFormControl({value: question.finding.language, disabled: false});
      }
    });
    this.form = new UntypedFormGroup(group);
  }

  isValid(question: Question) {
    return this.form.controls[this.getFormGroupName(question)].disabled
      || this.form.controls[this.getFormGroupName(question)].valid;
  }

  setValidAdditionalLanguage(question) {
      this.isValidAdditionalLanguage = this.form.controls[this.getFormGroupName(question)].valid;
  }

  getReviewName(question: Question): string {
    return question.displayName + 'review';
  }

  save(question: Question, clear?: boolean, deleteFinding?: boolean) {
    if (question.type === 'Finding') {
      this.saveAdditionalFinding(question, deleteFinding);
      return;
    }
    question.value = this.form.value[question.displayName];
    question.review = this.form.value[this.getReviewName(question)];
    this.setFinding(question);
    this.setConditional(question);
    this.setValidAdditionalLanguage(question);
    this.questionnaire.complete = this.isFormValid() && this.isComplete() && this.isNotFlagged();
    if (clear) {
      if (!this.questionnaire.question.find(q => q.value)) {
        flagRequiredQuestionnare(this.questionnaire);
      }
    }
    if (this.facilities && this.facilities.length > 0) {
      this.multiSave();
    }
    validate(this.siteVisit);
    this.siteVisitService.save(this.siteVisit);
    this.subscriber.next(this.siteVisit);
    this.siteVisitsSerivce.updateCurrentVisit(this.siteVisit);
  }

  multiSave() {
    this.facilities.forEach(f => {
      f.question = _.cloneDeep(this.questionnaire.question);
      f.complete = this.questionnaire.complete;
    });
  }

  clear(question: Question) {
    this.form.controls[question.displayName].reset();
    delete question.value;
    this.form.controls[this.getReviewName(question)].reset();
    delete question.review;
    this.clearQuestionUuid = undefined;
    this.clearQuestionUuid = question.uuid;
    this.save(question, true);
  }
  isNotFlagged(): boolean {
    return this.questionnaire.question.find(q => q.review) === undefined;
  }

  isComplete() {
    return this.questionnaire.question.find(q => !this.isAdditionalValid(q)) === undefined;
  }

  isFormValid(): boolean {
    let valid = true;
    for (const question of this.questionnaire.question) {
      const control = this.form.controls[this.getFormGroupName(question)];
      if (control && control.enabled && !control.valid) {
        valid = false;
        break;
      }
    }
    return valid;
  }

  isAdditionalValid(question: Question): boolean {
    if (question.type === 'Section Header' || question.type === 'Finding' || !question.questionActiveInd
       || (question.type === 'Question' && this.form.controls[this.getFormGroupName(question)].disabled)) {
      return true;
    }
    const selected = question.answers.find(a => a.name === question.value);
    if (!selected) {
      return true;
    }
    if (selected.noteRequired && !question.note) {
      return false;
    }
    if (selected.photoRequired && ! question.photo) {
      return false;
    }
    if (selected.attachmentRequired && (!question.attachments || (question.attachments && question.attachments.length <= 0))) {
      return false;
    }
    return true;
  }

  setConditional(question: Question) {
    this.questionnaire.question.forEach((q, i) => {
      if (q.type === 'Question' && q.prerequisiteQuestionUuid && q.prerequisiteQuestionUuid === question.uuid) {
        if (question.value && q.prerequisiteAnswer === question.value) {
          this.form.controls[this.getFormGroupName(q)].enable();
          if (q.required) {
            this.form.controls[this.getFormGroupName(q)].setValidators([Validators.required]);
          }
        } else {
          this.questionnaire.question[i].value = null;
          this.form.controls[this.getFormGroupName(q)].setValue(null);
          this.form.controls[this.getFormGroupName(q)].disable();
          this.form.controls[this.getFormGroupName(q)].clearValidators();
          this.form.controls[this.getFormGroupName(q)].setErrors(null);
          this.form.controls[this.getReviewName(q)].setValue(null);
        }
        this.form.controls[this.getFormGroupName(q)].updateValueAndValidity();
        this.setConditional(q);
      }
    });
  }

  isQuestionDisabled(question: Question): boolean {
    return this.form.controls[this.getFormGroupName(question)]
      && this.form.controls[this.getFormGroupName(question)].disabled;
  }

  setFinding(question: Question) {
    const selectedAnswer = question.answers.find(a => a.name === question.value);
    if (selectedAnswer) {
      question.finding = selectedAnswer.finding;
    } else {
      question.finding = null;
    }
  }

  addFinding(question: Question, index: number) {
    const additionalFindingQuestion: Question = JSON.parse(JSON.stringify(question));
    additionalFindingQuestion.finding = JSON.parse(JSON.stringify(question.answers[0].finding));
    additionalFindingQuestion.value = additionalFindingQuestion.finding.language;
    addQuestionAfterIndex(this.questionnaire, index, additionalFindingQuestion);
    this.isSection = this.isSection.map(item => item > index ? item + 1 : item);
    this.form.addControl(
      this.getFormGroupName(question),
      new UntypedFormControl());
    this.form.controls[this.getFormGroupName(question)]
    .setValue(additionalFindingQuestion.value);
    this.save(additionalFindingQuestion);
  }

  deleteFinding(question: Question, index: number) {
    removeQuestionAfterIndex(this.questionnaire, index);
    this.isSection = this.isSection.map(item => item > index ? item - 1 : item);
    this.form.removeControl(this.getFormGroupName(question));
    this.save(question, false, true);
  }

  saveAdditionalFinding(question: Question, deleteFinding?: boolean) {
    if (question.uuid && deleteFinding !== true) {
      question.value = this.form.value[this.getFormGroupName(question)];
      question.finding.language = this.form.value[this.getFormGroupName(question)];
    }
    if (deleteFinding !== true) {
      this.setValidAdditionalLanguage(question);
    }
    this.questionnaire.complete = this.isFormValid() && this.isComplete() && this.isNotFlagged();
    validate(this.siteVisit);
    this.siteVisitService.save(this.siteVisit);
    this.subscriber.next(this.siteVisit);
    this.siteVisitsSerivce.updateCurrentVisit(this.siteVisit);
  }

  getFormGroupName(question: Question) {
    return this.isFinding(question) ? question.displayName + '-' + question.uuid : question.displayName;
  }

  ngOnDestroy() {
    if (this.visit$ != null) {
      this.visit$.unsubscribe();
    }
  }
}
