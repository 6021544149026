import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {FacilityAny, isBoosterStation, isTreatmentPlant, isWell} from 'src/app/domain/facility';
import { FACILITY_TYPES } from 'src/app/shared/facility-type';
import { AVAILABILITY_TYPES } from 'src/app/shared/availability-type';
import { FACILITY_STATUSES } from 'src/app/shared/facility-status';
import { WaterSystemFacilitiesService } from '../../water-system-facilities.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FacilityFlow } from 'src/app/domain/facility-flow';
import { UNIT_MEASURE_CAPACITY_TYPES } from "../../../shared/unit-measure-capacity-type";

@Component({
  selector: 'app-facility-info-form',
  templateUrl: './facility-info-form.component.html',
  styleUrls: ['./facility-info-form.component.scss']
})
export class FacilityInfoFormComponent implements OnInit {

  @Input()
  facility: FacilityAny;

  @Output()
  saved = new EventEmitter<{facility: FacilityAny, flowsToFacility: FacilityFlow}>();

  @Output()
  cancelled = new EventEmitter<void>();

  readonly FACILITY_TYPES: any[] = FACILITY_TYPES;
  readonly AVAILABILITY_TYPES: any[] = AVAILABILITY_TYPES;
  readonly FACILITY_STATUSES: any[] = FACILITY_STATUSES;
  readonly UNIT_MEASURE_CAPACITY_TYPES: any[] = UNIT_MEASURE_CAPACITY_TYPES;

  public flowsTo: FacilityFlow;
  public facilitiesFlowTo: FacilityFlow[];
  public isLoading = true;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private waterSystemFacilitiesService: WaterSystemFacilitiesService) { }

  ngOnInit() {
    this.route.parent.params.subscribe(params => {
      let waterSystemId: number = +params['id'];
      this.waterSystemFacilitiesService.findFlowAll(waterSystemId).subscribe(
        results => {
          this.facilitiesFlowTo = results;
          if (this.facility) {
            this.filterOutSelf();
            if (this.facility.downstreamFacility && this.facility.downstreamFacility.length > 0) {
              this.getFlowsTo(waterSystemId);
            } else {
              this.isLoading = false;
            }
          } else {
            this.isLoading = false;
          }
        },
        error => {
          console.log(error);
          this.isLoading = false;
        }
      )
    });
  }

  save() {
    this.saved.emit({facility: this.facility, flowsToFacility: this.flowsTo});
  }

  cancel() {
    this.cancelled.emit();
  }

  private filterOutSelf() {
    for (let i = 0; i < this.facilitiesFlowTo.length; i++) {
      let facilityFlow: FacilityFlow = this.facilitiesFlowTo[i];
      if (this.facility.facilityId === facilityFlow.facilityId) {
        this.facilitiesFlowTo.splice(i, 1);
      }
    }
  }

  private getFlowsTo(waterSystemId: number) {
    this.waterSystemFacilitiesService.findFlowOne(waterSystemId, this.facility.facilityId).subscribe(facilityFlow => {
      this.flowsTo = this.facilitiesFlowTo.find(f => facilityFlow.flowToId === f.facilityId);
      this.isLoading = false;
    });
  }

  get isTreatmentPlant() {
    return isTreatmentPlant(this.facility);
  }

  get isWellFacility() {
    return isWell(this.facility);
  }

  get isBoosterStation() {
    return isBoosterStation(this.facility);
  }

  editFacilityAdditional(facility: FacilityAny) {
    this.facility = facility;
  }
}
