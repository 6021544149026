import { Injectable } from '@angular/core';
import { KeycloakProfile } from 'keycloak-js';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { PageRequest } from '../domain/page-request';
import { SiteVisitCriteria } from '../domain/SiteVisitCriteria';
import { SiteVisitService } from '../site-visit.service';
import { WaterSystemComplianceOfficerService } from '../water-system/water-system-compliance-officer.service';
import { WaterSystemContactsService } from '../water-system/water-system-contacts.service';
import { WaterSystemDistrictContactService } from '../water-system/water-system-district-contact.service';
import { WaterSystemNotesService } from '../water-system/water-system-notes.service';
import { WaterSystemOperatorsService } from '../water-system/water-system-operators.service';
import { WaterSystemSiteVisitsService } from '../water-system/water-system-site-visits.service';
import { WaterSystemWellsService } from '../water-system/water-system-wells.service';
import { WaterSystemService } from '../water-system/water-system.service';
import { WaterSystemStorageFacilitiesService } from '../water-system/water-system-storage-facility.service';
import { WaterSystemTreatmentFacilitiesService } from '../water-system/water-system-treatment-facility.service';
import { WaterSystemFacilitiesService } from '../water-system/water-system-facilities.service';
import { WaterSystemSurfaceWaterFacilityService } from '../water-system/water-system-surface-water-facility.service';
import { WaterSystemDistributionFacilityService } from '../water-system/water-system-distribution-facility.service';
import { WaterSystemBoosterStationFacilityService } from '../water-system/water-system-booster-station-facility.service';
import { WaterSystemInterconnectFacilityService } from '../water-system/water-system-interconnect-facility.service';
import { WaterSystemDeficienciesService } from '../water-system/water-system-deficiencies.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
      private siteVisitService: SiteVisitService,
      private connectivityService: ConnectivityService,
      private waterSystemService: WaterSystemService,
      private waterSystemContactsService: WaterSystemContactsService,
      private waterSystemComplianceOfficerService: WaterSystemComplianceOfficerService,
      private waterSystemDistrictContactService: WaterSystemDistrictContactService,
      private waterSystemNotesService: WaterSystemNotesService,
      private waterSystemOperatorsService: WaterSystemOperatorsService,
      private waterSystemSiteVisitsService: WaterSystemSiteVisitsService,
      private waterSystemWellsService: WaterSystemWellsService,
      private waterSystemDistributionService: WaterSystemDistributionFacilityService,
      private waterSystemStorageFacilitiesService: WaterSystemStorageFacilitiesService,
      private waterSystemTreatmentFacilitiesService: WaterSystemTreatmentFacilitiesService,
      private waterSystemFacilitiesService: WaterSystemFacilitiesService,
      private waterSystemSurfaceWaterFacilityService: WaterSystemSurfaceWaterFacilityService,
      private waterSystemBoosterStationFacilityService: WaterSystemBoosterStationFacilityService,
      private waterSystemInterconnectFacilityService: WaterSystemInterconnectFacilityService,
      private waterSystemDeficienciesService: WaterSystemDeficienciesService
    ) { }

  populate(userDetails: KeycloakProfile) {
    if (this.connectivityService.isOnline) {
      this.populateCheckedOut(userDetails);
    }
  }

  private populateCheckedOut(userDetails: KeycloakProfile) {
    const criteria = new SiteVisitCriteria();
    criteria.username = userDetails.username;
    const pageRequest = new PageRequest(0, 50);
    this.siteVisitService.searchWithLocalStorage(
      `${SiteVisitService.checkedOutKey}-${userDetails.username}`,
      criteria,
      pageRequest
    ).subscribe(checkedOut => {
      checkedOut.results.forEach(siteVisit => {
        if (siteVisit.status === 'CHECKED_OUT') {
          this.populateWaterSystem(siteVisit.id);
        }
      });
    });
  }

  populateWaterSystem(id: number) {
    console.log(`Populating water system ${id} data`);
    this.waterSystemService.find(id).subscribe();
    this.waterSystemContactsService.find(id).subscribe();
    this.waterSystemOperatorsService.find(id).subscribe();
    this.waterSystemDistrictContactService.find(id).subscribe();
    this.waterSystemComplianceOfficerService.find(id).subscribe();
    this.waterSystemNotesService.find(id, false).subscribe();
    this.waterSystemSiteVisitsService.findAll(id).subscribe();
    this.waterSystemWellsService.find(id).subscribe();
    this.waterSystemDistributionService.find(id).subscribe();
    this.waterSystemStorageFacilitiesService.find(id).subscribe();
    this.waterSystemTreatmentFacilitiesService.find(id).subscribe();
    this.waterSystemFacilitiesService.findFlowAll(id).subscribe();
    this.waterSystemSurfaceWaterFacilityService.find(id).subscribe();
    this.waterSystemBoosterStationFacilityService.find(id).subscribe();
    this.waterSystemInterconnectFacilityService.find(id).subscribe();
    this.waterSystemDeficienciesService.find(id).subscribe();
  }
}
