import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class QuestionniareSubscriber<T>
{
  protected observable = new BehaviorSubject<T>(null);
  observable$ = this.observable.asObservable();

  public next(item: T)
  {
    this.observable.next(item);
  }
}
