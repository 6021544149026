import {Component, Input, OnInit} from '@angular/core';
import {WaterSystem} from '../../../../domain/water-system';

@Component({
  selector: 'app-production-source-water-protection-summary',
  templateUrl: './production-source-water-protection-summary.component.html',
  styleUrls: ['./production-source-water-protection-summary.component.scss']
})
export class ProductionSourceWaterProtectionSummaryComponent implements OnInit {

  @Input() summary: WaterSystem;
  public isCollapsed = true;
  constructor() { }

  ngOnInit(): void {
  }

}
