import { Component, OnInit } from '@angular/core';
import { Note } from 'src/app/domain/note';
import { NotesService } from '../shared/notes/notes.service';

@Component({
  selector: 'app-water-system-notes',
  templateUrl: './water-system-notes.component.html',
  styleUrls: ['./water-system-notes.component.scss']
})
export class WaterSystemNotesComponent implements OnInit {

  notes: Note[];

  public isNotesLoading = true;

  error = null;

  constructor(private notesService: NotesService) { }

  ngOnInit() {
    this.getNotes();
  }

  getNotes(): void {
    this.notesService.notes$.subscribe(
      notes => {
        this.notes = notes;
        this.isNotesLoading = false;
      },
      err => {
        if (this.error == null) {
          this.error = [];
        }
        this.error.push(err);
        this.isNotesLoading = false;
      },
      () => this.isNotesLoading = false
    );
  }
}
