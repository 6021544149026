import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from '../domain/contact';

@Pipe({name: 'contactAddress'})
export class ContactAddressPipe implements PipeTransform {
  transform(contact: Contact): string {
    let address = '';
    if (contact.address1 != null && contact.address1 !== '') {
      address = contact.address1;
    }
    if (contact.address2 != null && contact.address2 !== '') {
      address = address + '\n' + contact.address2;
    }
    if (contact.address3 != null && contact.address3 !== '') {
      address = address + '\n' + contact.address3;
    }
    if (contact.city != null && contact.city !== '') {
      address = address + '\n' + contact.city;
    }
    if (contact.state != null && contact.state !== '') {
      address = address + ', ' + contact.state;
    }
    if (contact.zip != null && contact.zip !== '') {
      address = address + ' ' + contact.zip;
    }
    return address;
  }
}