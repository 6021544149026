<div *ngIf="isLoading">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <span>Loading...</span>
</div>
<form (submit)="save()" id="water-operator-form" *ngIf="!isLoading">
  <ng-template #rt let-r="result" let-t="term">
    <ngb-highlight [result]="r.name + ' - ' + r.certificateClass" [term]="t"></ngb-highlight>
  </ng-template>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="water-operator-search">Search:</label>
      <input id="water-operator-search"
             type="text"
             [(ngModel)]="operator"
             name="searchName"
             class="form-control"
             [ngbTypeahead]="searchName"
             [editable]="false"
             (selectItem)="onSearchNameSelect($event)"
             aria-describedby="name-search-auto-complete-help-text"
             [resultTemplate]="rt"
             [inputFormatter]="formatter"
             (keyup.arrowDown)="scroll($event)"
             (keyup.arrowUp)="scroll($event)"/>
      <span id="name-search-auto-complete-help-text" class="small">Type 2 letters to get a list of matching water operators to select from.</span>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="water-operator-name">Name (read-only):</label>
      <input type="text" class="form-control" id="water-operator-name" name="name" [ngModel]="operator?.name" readonly>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="water-operator-phone">Phone (read-only):</label>
      <input type="text" class="form-control" id="water-operator-phone" name="phone" [ngModel]="operator?.formattedPhone" readonly>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="water-operator-class">Class (read-only):</label>
      <input type="text" class="form-control" id="water-operator-class" name="class" [ngModel]="operator?.certificateClass" readonly>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="water-operator-expiration-date">Expiration Date (read-only):</label>
      <input type="text" class="form-control" id="water-operator-expiration-date" name="expiration-date" [ngModel]="operator?.formattedExpirationDate" readonly/>
    </div>
  </div>

  <button type="submit" class="btn btn-primary" [disabled]="operator == null || operator.name == null || operator.name === ''">Add</button>
  <button type="button" class="btn btn-outline-secondary ml-4" (click)="cancel()">Cancel</button>
</form>
