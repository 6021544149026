import { Component, OnDestroy, OnInit } from '@angular/core';
import { Visit } from 'src/app/domain/visit';
import { QuestionniareSubscriber } from '../questionnaire/questionnaire-subscriber';
import { Unsubscribable } from 'rxjs';
import { SiteVisitsService } from './site-visits.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-site-visits',
  templateUrl: './site-visits.component.html',
  styleUrls: ['./site-visits.component.scss']
})
export class SiteVisitsComponent implements OnInit, OnDestroy {

  currentVisit: Visit;
  pastVisits: Visit[];
  error = null;
  isSiteVisitsLoading: boolean;
  waterSystemId: number;

  private visits$: Unsubscribable;

  constructor(private subscriber: QuestionniareSubscriber<Visit>,
              private route: ActivatedRoute,
              private siteVisitsService: SiteVisitsService) { }

  ngOnInit() {
    this.isSiteVisitsLoading = true;
    this.visits$ = this.siteVisitsService.visits$.subscribe(siteVisits => {
      this.separateSiteVisits(siteVisits);
    });
  }

  separateSiteVisits(siteVisits: Visit[]): void {
    if (siteVisits && siteVisits.length > 0) {
      if (['IN_PROGRESS', 'PENDING', 'REPORTING'].includes(siteVisits[0].status.toUpperCase())) {
        this.currentVisit = siteVisits[0];
        this.subscriber.next(siteVisits[0]);
        this.pastVisits = siteVisits.slice(1);
      } else {
        this.currentVisit = null;
        this.pastVisits = siteVisits;
      }
    } else {
      this.pastVisits = [];
    }
    if (this.siteVisitsService.isInitialized()) {
      this.isSiteVisitsLoading = false;
    }
  }

  handleCurrentEvent(message: string) {
    if ('completed' === message) {
      this.currentVisit = null;
      this.subscriber.next(null);
      this.pastVisits = [];
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    if (this.visits$ != null) {
      this.visits$.unsubscribe();
    }
  }
}
