<div class="card mb-3">
  <div class="card-body">
    <h1>Site Visit</h1>
    <div id="water-system-questionnaire" *ngIf="siteVisit">
      <router-outlet></router-outlet>
      <div *ngIf="siteVisit && siteVisit.inspection && siteVisit.inspection.questionnaires; else loading" class="w-100">
        <div *ngFor="let questionnaire of siteVisit.inspection.questionnaires.pws" class="w-100">
          <div *ngIf="questionnaire.activeInd" class="w-100">
            <button type="button" class="btn m-2 w-75" [state]="siteVisit" [routerLink]="['questionnaire', 'pws']"
              [queryParams]="{id: questionnaire.id}" routerLinkActive="true"
              [ngClass]="questionnaire.complete == undefined ? 'btn-primary' : questionnaire.complete == true ? 'btn-success' : 'btn-danger'">
              {{questionnaire.category && questionnaire.category !== '' ? questionnaire.category : questionnaire.item}}
            </button>
          </div>
        </div>
        <div *ngIf="hasSourceQuestions; else noSource" class="card p-3">
          <h2>Water Source</h2>
          <form class="card p-3 bg-light" [formGroup]="wellForm" (ngSubmit)="multiSubmit('wells')"
            *ngIf="hasWellQuestions">
            <h3>Wells</h3>
            <div *ngFor="let questionnaire of siteVisit.inspection.questionnaires.well.facilities" class="container">
              <div *ngIf="questionnaire.activeInd" class="row" [ngClass]="{'form-group': wellMulti}">
                <div *ngIf="!wellMulti" class="col-sm-6 col-xs-12">
                  <button class="btn w-100" type="button"
                     [state]="siteVisit"
                     [routerLink]="['questionnaire', 'well']"
                     [queryParams]="{id: questionnaire.facilityId}"
                     routerLinkActive="true"
                     [ngClass]="questionnaire.complete == undefined ? 'btn-primary' : questionnaire.complete == true ? 'btn-success' : 'btn-danger'"
                     [disabled]=isInactiveFacility(questionnaire.facilityStatusCode)>
                    {{questionnaire.category}}
                  </button>
                </div>
                <div *ngIf="wellMulti" class="col-md-4 col-sm-12">
                  <button type="button"
                          class="btn w-100"
                          [state]="siteVisit"
                          [routerLink]="['questionnaire', 'well']"
                          [queryParams]="{id: questionnaire.facilityId}"
                          routerLinkActive="true"
                          [ngClass]="questionnaire.complete == undefined ? 'btn-primary' : questionnaire.complete == true ? 'btn-success' : 'btn-danger'"
                          [disabled]=isInactiveFacility(questionnaire.facilityStatusCode)>
                    {{questionnaire.category}}
                  </button>
                </div>
                <div class="col-auto my-2">
                  <a [routerLink]="['../../facilities/edit-facility', questionnaire.facilityId]"
                     [state]="{ returnUrl : 'water-system/' + waterSystemId + '/visit/' + siteVisitId, siteVisitId: siteVisitId  }">
                    Edit {{questionnaire.category}} {{isInactiveFacility(questionnaire.facilityStatusCode) ? "(inactive)" : "" }}
                  </a>
                </div>
                <div class="col-auto" *ngIf="isNew(questionnaire.facilityId)">
                  <button type="button"
                          class="btn btn-outline-danger"
                          (click)="deleteFacility(questionnaire.facilityId,questionnaire.category)">
                    Delete {{questionnaire.category}}
                  </button>
                </div>
                <div class="col-auto my-2" *ngIf="!isNew(questionnaire.facilityId)">
                  <a href="javascript:void(0);"
                     (click)="openIwmzPcsiReport(questionnaire.facilityId)"
                     attr.aria-label="View IWMZ Report for {{questionnaire.category}}">
                    IWMZ Report for {{questionnaire.category}}
                  </a>
                </div>
                <div class="col my-2 text-right" *ngIf="wellMulti">
                  <input class="col-auto" type="checkbox" [formControlName]="questionnaire.facilityId"
                    id="{{questionnaire.facilityId}}w">
                  <label class="col-auto" for="{{questionnaire.facilityId}}w">Select Well</label>
                </div>
              </div>
            </div>
            <button *ngIf="wellMulti" class="btn btn-primary col-12 mt-2" type="submit"
              [disabled]="!hasSelectedWells">Edit Selected Wells</button>
          </form>
          <div class="card p-3 bg-light" *ngIf="hasSurfaceWaterQuestions">
            <h3>Surface Water</h3>
            <div *ngFor="let questionnaire of siteVisit.inspection.questionnaires.surfaceWater.facilities"
              class="container">
              <div *ngIf="questionnaire.activeInd" class="row my-2">
                <div class="col-sm-6 col-xs-12">
                  <button type="button" class="btn w-100" [state]="siteVisit" [routerLink]="['questionnaire', 'surfacewater']"
                    [queryParams]="{id: questionnaire.facilityId}" routerLinkActive="true"
                    [ngClass]="questionnaire.complete == undefined ? 'btn-primary' : questionnaire.complete == true ? 'btn-success' : 'btn-danger'"
                    [disabled]=isInactiveFacility(questionnaire.facilityStatusCode)>
                    {{questionnaire.category && questionnaire.category !== '' ? questionnaire.category :
                    questionnaire.item}}
                  </button>
                </div>
                <div class="col-auto my-2">
                  <a [routerLink]="['../../facilities/edit-facility', questionnaire.facilityId]"
                     [state]="{ returnUrl : 'water-system/' + waterSystemId + '/visit/' + siteVisitId, siteVisitId: siteVisitId  }">
                    Edit {{questionnaire.category}} {{isInactiveFacility(questionnaire.facilityStatusCode) ? "(inactive)" : "" }}
                  </a>
                </div>
                <div class="col-auto">
                  <button type="button" *ngIf="isNew(questionnaire.facilityId)" class="btn btn-outline-danger"
                    (click)="deleteFacility(questionnaire.facilityId,questionnaire.category)">
                    Delete {{questionnaire.category}}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card p-3 bg-light" *ngIf="hasInterconnectQuestions">
            <h3>Consecutive Connections</h3>
            <div *ngFor="let questionnaire of siteVisit.inspection.questionnaires.interconnect.facilities"
              class="container">
              <div *ngIf="questionnaire.activeInd" class="row my-2">
                <div class="col-sm-6 col-xs-12">
                  <button class="btn w-100" [state]="siteVisit" [routerLink]="['questionnaire', 'interconnect']"
                    [queryParams]="{id: questionnaire.facilityId}" routerLinkActive="true"
                    [ngClass]="questionnaire.complete == undefined ? 'btn-primary' : questionnaire.complete == true ? 'btn-success' : 'btn-danger'"
                    [disabled]=isInactiveFacility(questionnaire.facilityStatusCode)>
                    {{questionnaire.category && questionnaire.category !== '' ? questionnaire.category :
                    questionnaire.item}}
                  </button>
                </div>
                <div class="col-auto my-2">
                  <a [routerLink]="['../../facilities/edit-facility', questionnaire.facilityId]"
                     [state]="{ returnUrl : 'water-system/' + waterSystemId + '/visit/' + siteVisitId, siteVisitId: siteVisitId  }">
                    Edit {{questionnaire.category}} {{isInactiveFacility(questionnaire.facilityStatusCode) ? "(inactive)" : "" }}
                  </a>
                </div>
                <div class="col-auto">
                  <button *ngIf="isNew(questionnaire.facilityId)" class="btn btn-outline-danger"
                    (click)="deleteFacility(questionnaire.facilityId,questionnaire.category)">
                    Delete {{questionnaire.category}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #noSource>
          <app-missing-facility [facilityType]="facilityType.source" [title]="facilityTypeTitle.source"
            [warning]="'There are NO Known Source Facilities Within The PWS'" [waterSystemId]="waterSystemId"
            [siteVisitId]="siteVisitId" [siteVisit]="siteVisit">
          </app-missing-facility>
        </ng-template>
        <div class="card p-3 bg-light" *ngIf="hasDistributionQuestions; else noDistribution">
          <h2>Distribution</h2>
          <div *ngFor="let questionnaire of siteVisit.inspection.questionnaires.distribution.facilities"
            class="container">
            <div *ngIf="questionnaire.activeInd" class="row my-2">
              <div class="col-sm-6 col-xs-12">
                <button type="button" class="btn w-100" [state]="siteVisit" [routerLink]="['questionnaire', 'distribution']"
                  [queryParams]="{id: questionnaire.facilityId}" routerLinkActive="true"
                  [ngClass]="questionnaire.complete == undefined ? 'btn-primary' : questionnaire.complete == true ? 'btn-success' : 'btn-danger'"
                  [disabled]=isInactiveFacility(questionnaire.facilityStatusCode)>
                  {{questionnaire.category && questionnaire.category !== '' ? questionnaire.category :
                  questionnaire.item}}
                </button>
              </div>
              <div class="col-auto my-2">
                <a [routerLink]="['../../facilities/edit-facility', questionnaire.facilityId]"
                   [state]="{ returnUrl : 'water-system/' + waterSystemId + '/visit/' + siteVisitId, siteVisitId: siteVisitId  }">
                  Edit {{questionnaire.category}} {{isInactiveFacility(questionnaire.facilityStatusCode) ? "(inactive)" : "" }}
                </a>
              </div>
              <div class="col-auto">
                <button type="button" *ngIf="isNew(questionnaire.facilityId)" class="btn btn-outline-danger"
                  (click)="deleteFacility(questionnaire.facilityId,questionnaire.category)">
                  Delete {{questionnaire.category}}
                </button>
              </div>
            </div>
          </div>
        </div>
        <ng-template #noDistribution>
          <app-missing-facility [facilityType]="facilityType.distribution" [title]="facilityTypeTitle.distribution"
            [warning]="'There are NO Known Distribution Facilities Within The PWS'" [waterSystemId]="waterSystemId"
            [siteVisitId]="siteVisitId" [siteVisit]="siteVisit">
          </app-missing-facility>
        </ng-template>
        <div class="card p-3 bg-light" *ngIf="hasStorageQuestions; else noStorage">
          <h2>Water Storage</h2>
          <div *ngFor="let questionnaire of siteVisit.inspection.questionnaires.storage.facilities" class="container">
            <div *ngIf="questionnaire.activeInd" class="row my-2">
              <div class="col-sm-6 col-xs-12">
                <button type="button" class="btn w-100" [state]="siteVisit" [routerLink]="['questionnaire', 'storage']"
                  [queryParams]="{id: questionnaire.facilityId}" routerLinkActive="true"
                  [ngClass]="questionnaire.complete == undefined ? 'btn-primary' : questionnaire.complete == true ? 'btn-success' : 'btn-danger'"
                  [disabled]=isInactiveFacility(questionnaire.facilityStatusCode)>
                  {{questionnaire.category && questionnaire.category !== '' ? questionnaire.category :
                  questionnaire.item}}
                </button>
              </div>
              <div class="col-auto my-2">
                <a [routerLink]="['../../facilities/edit-facility', questionnaire.facilityId]"
                   [state]="{ returnUrl : 'water-system/' + waterSystemId + '/visit/' + siteVisitId, siteVisitId: siteVisitId  }">
                  Edit {{questionnaire.category}} {{isInactiveFacility(questionnaire.facilityStatusCode) ? "(inactive)" : "" }}
                </a>
              </div>
              <div class="col-auto">
                <button type="button" *ngIf="isNew(questionnaire.facilityId)" class="btn btn-outline-danger"
                  (click)="deleteFacility(questionnaire.facilityId,questionnaire.category)">
                  Delete {{questionnaire.category}}
                </button>
              </div>
            </div>
          </div>
        </div>
        <ng-template #noStorage>
          <app-missing-facility [facilityType]="facilityType.storage" [title]="facilityTypeTitle.storage"
            [warning]="'There are NO Known Strorage Facilities Within The PWS'" [waterSystemId]="waterSystemId"
            [siteVisitId]="siteVisitId" [siteVisit]="siteVisit">
          </app-missing-facility>
        </ng-template>
        <div class="card p-3 bg-light" *ngIf="hasTreatmentQuestions; else noTreatment">
          <h2>Treatment</h2>
          <div *ngFor="let questionnaire of siteVisit.inspection.questionnaires.treatment.facilities" class="container">
            <div *ngIf="questionnaire.activeInd" class="row my-2">
              <div class="col-sm-6 col-xs-12">
                <button type="button" class="btn w-100" [state]="siteVisit" [routerLink]="['questionnaire', 'treatment']"
                  [queryParams]="{id: questionnaire.facilityId}" routerLinkActive="true"
                  [ngClass]="questionnaire.complete == undefined ? 'btn-primary' : questionnaire.complete == true ? 'btn-success' : 'btn-danger'"
                  [disabled]=isInactiveFacility(questionnaire.facilityStatusCode)>
                  {{questionnaire.category && questionnaire.category !== '' ? questionnaire.category :
                  questionnaire.item}}
                </button>
              </div>
              <div class="col-auto my-2">
                <a [routerLink]="['../../facilities/edit-facility', questionnaire.facilityId]"
                   [state]="{ returnUrl : 'water-system/' + waterSystemId + '/visit/' + siteVisitId, siteVisitId: siteVisitId }">
                  Edit {{questionnaire.category}} {{isInactiveFacility(questionnaire.facilityStatusCode) ? "(inactive)" : "" }}
                </a>
              </div>
              <div class="col-auto">
                <button type="button" *ngIf="isNew(questionnaire.facilityId)" class="btn btn-outline-danger"
                  (click)="deleteFacility(questionnaire.facilityId,questionnaire.category)">
                  Delete {{questionnaire.category}}
                </button>
              </div>
            </div>
          </div>
        </div>
        <ng-template #noTreatment>
          <app-missing-facility [facilityType]="facilityType.treatment" [title]="facilityTypeTitle.treatment"
            [warning]="'There are NO Known Treatment Facilities Within The PWS'" [waterSystemId]="waterSystemId"
            [siteVisitId]="siteVisitId" [siteVisit]="siteVisit">
          </app-missing-facility>
        </ng-template>
        <div class="card p-3 bg-light" *ngIf="hasOtherFacilities">
          <h2>Other Facilities</h2>
          <div *ngFor="let facility of boosterStationFacilities" class="row my-2">
            <div class="col-auto">
              <a [routerLink]="['../../facilities/edit-facility', facility.facilityId]"
                 [state]="{ returnUrl : 'water-system/' + waterSystemId + '/visit/' + siteVisitId, siteVisitId: siteVisitId }">
                Edit {{facility.name}}
              </a>
            </div>
            <div class="col-auto">
              <button *ngIf="isNew(facility.facilityId)" class="btn btn-outline-danger"
                (click)="deleteFacility(facility.facilityId, facility.name)">
                Delete {{facility.name}}
              </button>
            </div>
          </div>
        </div>
        <div class="w-100">
          <button type="button" class="btn btn-outline-primary m-2 w-75" [routerLink]="['../../facilities/add-facility']"
            [state]="{ data : 'water-system/' + waterSystemId + '/visit/' + siteVisitId, siteVisitId: siteVisitId }">Add
            New Inventory</button>
        </div>
      </div>
      <ng-template #loading>
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading Site Visit...</span>
        </div>
        <span>Loading Site Visit...</span>
      </ng-template>
      <!--<pre>{{siteVisit | json}}</pre>-->
    </div>
  </div>
</div>
