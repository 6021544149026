import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home.component';
import { Shell } from '../shell/shell.service';
import { AppAuthGuard } from '../app.guard';
import { SearchComponent } from './search/search.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: '',
      redirectTo: '/home',
      pathMatch: 'full'
    },
    {
      path: 'home',
      component: HomeComponent,
      canActivate: [AppAuthGuard],
      data: {
        title: 'Home'
      }
    }
  ]),
  Shell.childRoutes([
    {
      path: 'search',
      component: SearchComponent,
      canActivate: [AppAuthGuard],
      data: {
        title: 'Search'
      }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
