import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {ShellComponent} from './shell.component';
import {HeaderComponent} from './header/header.component';
import {ConnectivityComponent} from '../connectivity/connectivity.component';
import {ConnectivityService} from '../connectivity/connectivity.service';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    RouterModule
  ],
  declarations: [
    HeaderComponent,
    ShellComponent,
    ConnectivityComponent,
  ],
  providers: [
    ConnectivityService,
  ]
})
export class ShellModule {
}
