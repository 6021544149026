<div id="water-system-questionnaire">
  <div *ngIf="siteVisit && siteVisit.inspection && siteVisit.inspection.questionnaires; else loading" class="w-100">
    <div *ngFor="let questionnaire of siteVisit.inspection.questionnaires.pws">
      <app-questionniare-summary [questionnaire]="questionnaire" [questionnaireId]="questionnaire.id.toString()"
                                 [waterSystemId]="waterSystemId" [siteVisitId]="siteVisit.id"
                                 [isQuestionnaireCollapsed]="collapseAll"></app-questionniare-summary>
    </div>
    <div class="card p-3 bg-light" *ngIf="hasWellQuestions">
      <h2>Wells</h2>
      <div *ngFor="let questionnaire of siteVisit.inspection.questionnaires.well.facilities">
        <app-questionniare-summary [questionnaire]="questionnaire" [questionnaireId]="questionnaire.id.toString()"
                                   [waterSystemId]="waterSystemId" [siteVisitId]="siteVisit.id"
                                   [isQuestionnaireCollapsed]="collapseAll"></app-questionniare-summary>
      </div>
    </div>
    <div class="card p-3 bg-light" *ngIf="hasSurfaceWaterQuestions">
      <h2>Surface Water</h2>
      <div *ngFor="let questionnaire of siteVisit.inspection.questionnaires.surfaceWater.facilities" class="w-100">
        <app-questionniare-summary [questionnaire]="questionnaire" [questionnaireId]="questionnaire.id.toString()"
                                   [waterSystemId]="waterSystemId" [siteVisitId]="siteVisit.id"
                                   [isQuestionnaireCollapsed]="collapseAll"></app-questionniare-summary>
      </div>
    </div>
    <div class="card p-3 bg-light" *ngIf="hasInterconnectQuestions">
      <h2>Consecutive Connections</h2>
      <div *ngFor="let questionnaire of siteVisit.inspection.questionnaires.interconnect.facilities" class="w-100">
        <app-questionniare-summary [questionnaire]="questionnaire" [questionnaireId]="questionnaire.id.toString()"
                                   [waterSystemId]="waterSystemId" [siteVisitId]="siteVisit.id"
                                   [isQuestionnaireCollapsed]="collapseAll"></app-questionniare-summary>
      </div>
    </div>
    <div class="card p-3 bg-light" *ngIf="hasDistributionQuestions">
      <h2>Distribution Facilities</h2>
      <div *ngFor="let questionnaire of siteVisit.inspection.questionnaires.distribution.facilities" class="w-100">
        <app-questionniare-summary [questionnaire]="questionnaire" [questionnaireId]="questionnaire.id.toString()"
                                   [waterSystemId]="waterSystemId" [siteVisitId]="siteVisit.id"
                                   [isQuestionnaireCollapsed]="collapseAll"></app-questionniare-summary>
      </div>
    </div>
    <div class="card p-3 bg-light" *ngIf="hasStorageQuestions">
      <h2>Storage Facilities</h2>
      <div *ngFor="let questionnaire of siteVisit.inspection.questionnaires.storage.facilities" class="w-100">
        <app-questionniare-summary [questionnaire]="questionnaire" [questionnaireId]="questionnaire.id.toString()"
                                   [waterSystemId]="waterSystemId" [siteVisitId]="siteVisit.id"
                                   [isQuestionnaireCollapsed]="collapseAll"></app-questionniare-summary>
      </div>
      <app-missing-summary [waterSystemId]="waterSystemId" [siteVisitId]="siteVisit.id" [siteVisit]="siteVisit"
                           [facilityType]="facilityType.storage" [isCollapsed]="collapseAll">

      </app-missing-summary>
    </div>
    <div class="card p-3 bg-light" *ngIf="hasTreatmentQuestions">
      <h2>Treatment Plants</h2>
      <div *ngFor="let questionnaire of siteVisit.inspection.questionnaires.treatment.facilities" class="w-100">
        <app-questionniare-summary [questionnaire]="questionnaire" [questionnaireId]="questionnaire.id.toString()"
                                   [waterSystemId]="waterSystemId" [siteVisitId]="siteVisit.id"
                                   [isQuestionnaireCollapsed]="collapseAll"></app-questionniare-summary>
      </div>
      <app-missing-summary [waterSystemId]="waterSystemId" [siteVisitId]="siteVisit.id" [siteVisit]="siteVisit"
                           [facilityType]="facilityType.treatment" [isCollapsed]="collapseAll">
      </app-missing-summary>
    </div>
  </div>
  <ng-template #loading>
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading Summary...</span>
    </div>
    <span>Loading Summary...</span>
  </ng-template>
</div>
