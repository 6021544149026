import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { ConnectivityService } from './connectivity/connectivity.service';
import { IamService } from './utils/iam.service';

@Injectable()
export class AppAuthGuard extends KeycloakAuthGuard {
  constructor(protected router: Router,
    protected keycloakAngular: KeycloakService,
    protected iamService: IamService,
    protected connectivity: ConnectivityService) {
    super(router, keycloakAngular);
  }

  async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.connectivity.isOnline() && !this.authenticated) {
      await this.keycloakAngular.login({
        redirectUri: location.origin + state.url,
        scope: 'openid offline_access'
      });
    } else if (!this.connectivity.isOnline()) {
      if (!this.iamService.isOfflineAuthenticated()) {
        return false;
      }
    }
    const requiredRoles = route.data.roles;

    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    return requiredRoles.some((role) => this.iamService.isUserInRole(role));
  }
}
