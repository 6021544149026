<div class="card mb-3">
  <div class="card-body">
    <h1>Manage Contacts</h1>
    <div id="water-system-contacts">
      <div class="pt-3">
        <app-inventory-contacts [contacts]="contacts"></app-inventory-contacts>
      </div>
      <div class="pt-3">
        <app-water-operators [operators]="operators"></app-water-operators>
      </div>
      <div class="pt-3">
        <app-district-compliance-officer [districtContact]="districtContact" [complianceOfficer]="complianceOfficer"></app-district-compliance-officer>
      </div>
    </div>
  </div>
</div>
