<ngb-toast *ngFor="let toast of toastService.toasts"
           [class]="toast.classname"
           [autohide]="toast.autohide"
           [delay]="toast.delay || 5000">
  <ng-template [ngIf]="true">
    <div class="toast-body">
      <span *ngIf="toast.showLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
        <span class="sr-only">{{ toast.text }}</span>
      </span>
      {{ toast.text }}
    </div>
  </ng-template>
</ngb-toast>