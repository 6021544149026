import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SurfaceWaterFacility } from "../../domain/facility";

@Injectable({
  providedIn: 'root'
})
export class SurfaceWaterFacilityService {

  private surfaceWaterFacilities = new BehaviorSubject<SurfaceWaterFacility[]>([]);
  surfaceWaterFacilities$ = this.surfaceWaterFacilities.asObservable();

  constructor() { }

  changeWaterSystemSurfaceWaterFacilities(surfaceWaterFacilities: SurfaceWaterFacility[]) {
    this.surfaceWaterFacilities.next(surfaceWaterFacilities);
  }
}
