<div class="card">
  <div class="card-body">
    <div>
      <h1>Add Facility</h1>
    </div>
    <div *ngIf="isAddingFacility">
      <div class="spinner-border" role="status">
        <span class="sr-only">Adding Facility...</span>
      </div>
      <span>Adding Facility...</span>
    </div>
    <div *ngIf="!isAddingFacility" class="pt-3">
      <app-facility-info-form [facility]="facility" (saved)="add($event)" (cancelled)="cancel()"></app-facility-info-form>
    </div>
  </div>
</div>
