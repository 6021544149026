import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Visit } from 'src/app/domain/visit';
import { WaterSystemSiteVisitsService } from '../water-system-site-visits.service';
import { FacilityType } from 'src/app/domain/inspection';

@Component({
  selector: 'app-questionnaire-view',
  templateUrl: './questionnaire-view.component.html',
  styleUrls: ['./questionnaire-view.component.scss'],
})
export class QuestionnaireViewComponent implements OnInit {
  waterSystemId: number;
  siteVisitId: number;
  siteVisit: Visit;

  constructor(
    private route: ActivatedRoute,
    private siteVisitService: WaterSystemSiteVisitsService
  ) {}

  ngOnInit(): void {
    this.siteVisitId = Number(this.route.snapshot.params["id"]);
    this.waterSystemId = Number(this.route.parent.snapshot.params["id"]);
    this.siteVisitService
      .find(this.siteVisitId, this.waterSystemId)
      .subscribe((visit) => {
        this.siteVisit = visit;
      });
  }
}
