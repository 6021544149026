import {Component, OnDestroy, OnInit} from '@angular/core';
import {Unsubscribable} from 'rxjs';
import {Checkout} from '../../../domain/checkout';
import {WaterSystem} from '../../../domain/water-system';
import {BasicDetailsService} from '../basic-details.service';

@Component({
  selector: 'app-basic-details-summary',
  templateUrl: './basic-details-summary.component.html',
  styleUrls: ['./basic-details-summary.component.scss']
})
export class BasicDetailsSummaryComponent implements OnInit, OnDestroy {

  private waterSystem$: Unsubscribable;
  waterSystem: Checkout<WaterSystem>;
  waterSystemChanges: WaterSystem;

  error: any;

  constructor(private basicDetailsService: BasicDetailsService) { }

  ngOnInit(): void {
    this.basicDetailsService.waterSystem$.subscribe(
      waterSystem => {
        this.waterSystem = waterSystem;
        this.waterSystemChanges = this.waterSystem.data.diff;
      },
      err => {
        this.error = err;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.waterSystem$) {
      this.waterSystem$.unsubscribe();
    }
  }

}
