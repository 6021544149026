<div class="container-fluid">
  <div class="p-3" *ngIf="isOnline; else offlineMsg">
    <div *ngIf="isQuestionnaireLoading || isCategoriesLoading; else loaded" class="pl-3">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading Question Set...</span>
      </div>
      <span>Loading Question Set...</span>
    </div>
    <ng-template #loaded>
      <h1 *ngIf="questionnaire && questionnaire.id != null">Edit Question Set</h1>
      <h1 *ngIf="questionnaire == null || questionnaire.id == null">Add Question Set</h1>
      <form id="questionnaire-form" *ngIf="!isQuestionnaireDeleting && !isQuestionnaireAdding" (submit)="addQuestionnaire()">
        <app-message></app-message>
        <app-toast aria-live="polite" aria-atomic="true"></app-toast>
        <div class="row">
          <div class="col-md-6 col-sm-12 form-group">
            <label for="questionnaire-set-type">Question Set Type:*</label>
            <select id="questionnaire-set-type" class="form-control" [(ngModel)]="questionnaire.item" name="questionnaire-type" required="true" #questionSetType="ngModel" (change)="onChange($event.target.title)" title="Question Set Type">
              <option *ngFor="let type of QUESTIONNAIRE_TYPES" value="{{ type.type }}">{{ type.type }}</option>
            </select>
            <div *ngIf="(questionSetType.dirty || questionSetType.touched) && questionSetType.invalid">
              <div *ngIf="questionSetType.hasError('required')" class="alert alert-danger">
                Question Set Type is required.
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 form-group">
            <label for="questionnaire-set-category">Question Set Category (only for type PWS):</label>
            <div class="input-group">
              <select id="questionnaire-set-category" class="form-control" [(ngModel)]="questionnaire.category" name="questionnaire-category" [disabled]="questionnaire && questionnaire.item !== 'PWS'" (change)="onChange($event.target.title)" title="Question Set Category">
                <option value=""></option>
                <option *ngFor="let category of categories" value="{{ category }}">{{ category }}</option>
                <option *ngIf="categories == null || categories.length < 1" [disabled]="true" [selected]="true">No Categories Found</option>
              </select>
              <div class="input-group-append">
                <button class="btn p-0" title="Add Category" aria-label="Add Category" (click)="openAddCategoryModal(contentAddCategory)" type="button" [disabled]="questionnaire && questionnaire.item !== 'PWS'">
                  <i class="fas fa-plus-square fa-2x"></i>
                </button>
                <button class="btn p-0" title="Delete Category" aria-label="Delete Category" (click)="openDeleteCategoryModal(contentDeleteCategory)" type="button" [disabled]="questionnaire && questionnaire.item !== 'PWS'">
                  <i class="fas fa-minus-square fa-2x"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-12 form-group">
            <label for="questionnaire-description">Question Set Description:*</label>
            <input id="questionnaire-description" class="form-control" [(ngModel)]="questionnaire.description" maxlength="50" name="questionnaire-description" required="true" #questionSetDescription="ngModel" (change)="onChange($event.target.title)" title="Question Set Description">
            <div *ngIf="(questionSetDescription.dirty || questionSetDescription.touched) && questionSetDescription.invalid">
              <div *ngIf="questionSetDescription.hasError('required')" class="alert alert-danger">
                Question Set Description is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-2">
          <div class="form-group col-12">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="questionnaire-active" [(ngModel)]="questionnaire.activeInd" name="questionnaire-active" [disabled]="isActiveDisabled()" (change)="onActiveChange($event.target.title)" title="Question Set Active Indicator">
              <label class="form-check-label" for="questionnaire-active">Active</label>
            </div>
          </div>
        </div>
        <div class="row pt-2" *ngIf="!questionnaire || questionnaire.id == null">
          <div class="col-12">
            <button id="edit-questionnaire-add-button" type="submit" class="btn btn-primary">Add Question Set</button>
          </div>
        </div>
        <div class="row pt-2" *ngIf="questionnaire && questionnaire.id != null">
          <div class="col-12">
            <app-questions-list [questionnaire]="questionnaire" (questionnaireChange)="updateQuestion($event)" [(addQuestion)]="add"></app-questions-list>
          </div>
          <div class="col-12">
            <button *ngIf="questionnaire?.id" id="edit-questionnaire-add-question-button" type="button" class="btn btn-outline-primary" (click)="addQuestion()">Add Question</button>
          </div>
        </div>
        <div class="row pt-4" *ngIf="questionnaire && questionnaire.id != null">
          <div class="col-12">
            <button id="edit-questionnaire-delete-button" type="button" class="btn btn-danger" (click)="deleteQuestionnaire()">Delete Question Set</button>
          </div>
        </div>
      </form>
      <div *ngIf="isQuestionnaireDeleting" class="pl-3">
        <div class="spinner-border" role="status">
          <span class="sr-only">Deleting Question Set...</span>
        </div>
        <span>Deleting Question Set...</span>
      </div>
      <div *ngIf="isQuestionnaireAdding" class="pl-3">
        <div class="spinner-border" role="status">
          <span class="sr-only">Adding Question Set...</span>
        </div>
        <span>Adding Question Set...</span>
      </div>
    </ng-template>
  </div>

  <ng-template #offlineMsg>
    <div class="p-3">Question Sets are not available offline.</div>
  </ng-template>
</div>

<ng-template #contentAddCategory let-modal>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-add-question-set-category-title">Add Category</h2>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form id="form-add-question-category" ngNativeValidate>
      <div *ngIf="categoryExists" class="alert alert-danger" role="alert">
        <span>The category entered matches an existing category. Please enter a different category.</span>
      </div>
      <div class="form-group">
        <label for="question-set-category">Category:*</label>
        <input id="question-set-category"
               class="form-control"
               name="question-set-category"
               required="true"
               [(ngModel)]="newCategory"
               #category="ngModel"
               maxlength="50">
        <div *ngIf="(category.dirty || category.touched) && category.invalid">
          <div *ngIf="category.hasError('required')" class="alert alert-danger">
            Category is required.
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div *ngIf="!isCategoriesSaving">
      <button type="submit" class="btn btn-primary" (click)="saveCategory()" [disabled]="category.invalid">Add</button>
      <button type="button" class="btn btn-outline-secondary ml-4" (click)="modal.dismiss('cancel click')">Cancel</button>
    </div>
    <div *ngIf="isCategoriesSaving" class="pl-3">
      <div class="spinner-border" role="status">
        <span class="sr-only">Adding Category...</span>
      </div>
      <span>Adding Category...</span>
    </div>
  </div>
</ng-template>

<ng-template #contentDeleteCategory let-modal>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-delete-question-set-category-title">Delete Category</h2>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form id="form-delete-question-category" ngNativeValidate>
      <div class="form-group">
        <label for="delete-questionnaire-set-category">Category:*</label>
        <select id="delete-questionnaire-set-category"
                class="form-control"
                [(ngModel)]="deleteCategory"
                name="delete-questionnaire-category"
                required="true"
                #category="ngModel">
          <option *ngFor="let category of categories" value="{{ category }}">{{ category }}</option>
          <option *ngIf="categories == null || categories.length < 1" disabled selected>No Categories Found</option>
        </select>
        <div *ngIf="(category.dirty || category.touched) && category.invalid">
          <div *ngIf="category.hasError('required')" class="alert alert-danger">
            Category is required.
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div *ngIf="!isCategoriesSaving">
      <button type="submit" class="btn btn-danger" (click)="removeCategory()" [disabled]="category.invalid">Delete</button>
      <button type="button" class="btn btn-outline-secondary ml-4" (click)="modal.dismiss('cancel click')">Cancel</button>
    </div>
    <div *ngIf="isCategoriesSaving" class="pl-3">
      <div class="spinner-border" role="status">
        <span class="sr-only">Deleting Category...</span>
      </div>
      <span>Deleting Category...</span>
    </div>
  </div>
</ng-template>
