<header>
  <a class="sr-only sr-only-focusable" [href]="skipToMainContentLink">skip to main content</a>
  <div class="mdh-header">
      <img class="banner-img" src="/assets/logo-reverse.png" alt="Department of Health Logo"/>
      <div class="title mobileHide" translate>Drinking Water Protection Site Visit Application</div>
  </div>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <button class="navbar-toggler" type="button" aria-controls="navbar-menu"
            aria-label="Toggle navigation" (click)="toggleMenu()" [attr.aria-expanded]="!menuHidden">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div id="navbar-menu" class="collapse navbar-collapse float-xs-none" [ngbCollapse]="menuHidden">
      <div class="navbar-nav">
        <a class="nav-item nav-link text-uppercase" routerLink="/home" routerLinkActive="active">
          <i class="fas fa-home"></i>
          HOME
        </a>
        <div *ngIf="userDetails" ngbDropdown placement="bottom-right">
          <a id="site-visit-dropdown" class="nav-item nav-link text-uppercase" ngbDropdownToggle [routerLink]="">
            <i class="fas fa-file"></i>
            SITE VISIT
          </a>
          <div ngbDropdownMenu aria-labelledby="site-visit-dropdown">
            <a *ngIf="isAdmin || isInspector" ngbDropdownItem class="dropdown-item" routerLink="/search">
              <span translate>UPCOMING VISITS SEARCH</span>
            </a>
          </div>
        </div>
        <div *ngIf="userDetails && isAdmin" ngbDropdown placement="bottom-right">
          <a id="admin-dropdown" class="nav-item nav-link text-uppercase" ngbDropdownToggle [routerLink]="">
            <i class="fas fa-users-cog"></i>
            ADMIN
          </a>
          <div ngbDropdownMenu aria-labelledby="admin-dropdown">
            <!-- Disabled 6/27/2024: <a ngbDropdownItem class="dropdown-item" routerLink="/admin/notifications">
              <span translate>NOTIFICATIONS</span>
            </a> -->
            <a ngbDropdownItem class="dropdown-item" routerLink="/admin/questionnaires">
              <span translate>MAINTAIN QUESTIONS</span>
            </a>
          </div>
        </div>
        <div *ngIf="userDetails" ngbDropdown placement="bottom-right">
          <a id="user-dropdown" class="nav-item nav-link text-uppercase" ngbDropdownToggle [routerLink]="">
            <i class="fas fa-user"></i>
            {{userDetails.username}}
          </a>
          <div ngbDropdownMenu aria-labelledby="user-dropdown">
            <a *ngIf="isAdmin || isInspector" ngbDropdownItem class="dropdown-item" routerLink="/profile">
              <span translate>PROFILE</span>
            </a>
            <button *ngIf="isOnline" type="button" ngbDropdownItem class="dropdown-item" (click)="doLogout()">
              <span translate>LOGOUT</span>
            </button>
          </div>
        </div>
      </div>
      <span class="navbar-text d-none d-lg-inline">
        <app-connectivity></app-connectivity>
      </span>
    </div>
    <span class="navbar-text d-lg-none">
        <app-connectivity></app-connectivity>
    </span>
  </nav>
</header>
