import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, concatMap, map, take, takeUntil, filter, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ConnectivityService } from '../connectivity/connectivity.service';
import { LocalStorageService } from '../local-storage.service';
import { tables } from '../utils/app-db';
import { FacilityAny, SurfaceWaterFacility } from '../domain/facility';
import { Checkout } from '../domain/checkout';
import { SyncSchedulerService } from './sync-scheduler.service';
import { SyncType } from '../domain/sync';
import { SyncResultFactory } from '../utils/sync-result-factory';

@Injectable({
  providedIn: 'root'
})
export class WaterSystemSurfaceWaterFacilityService {

  private readonly WATER_SYSTEM_URL = environment.serverUrl + '/watersystem';
  private readonly SURFACE_WATER_FACILITY_SUB_ENDPOINT = 'intake';
  private readonly SURFACE_WATER_FACILITY_CODE = 'IN';

  constructor(private connectivityService: ConnectivityService,
              private httpClient: HttpClient,
              private localStorageService: LocalStorageService,
              private syncSchedulerService: SyncSchedulerService) { }

  /**
   * Returns a water system's surface water facilities.
   *
   * @param id the id of the water system
   */
  find(id: number): Observable<SurfaceWaterFacility[]> {
    return new Observable<SurfaceWaterFacility[]>(obs => {
      this.findLocally(id).subscribe(wsLocal => {
        if (wsLocal) {
          obs.next(wsLocal);
          obs.complete();
        } else {
          if (this.connectivityService.isOnline()) {
            this.findRemotelyTimer(id).subscribe (wsRemote => {
              obs.next(wsRemote);
              obs.complete();
            }, error => {
              obs.error(error);
            });
          } else {
            obs.complete();
          }
        }
      });
    });
  }

  /**
   * Returns one surface water facility saved locally.
   *
   * @param waterSystemId the water system id
   * @param facilityId the facility id
   */
  findOne(waterSystemId: number, facilityId: number): Observable<SurfaceWaterFacility> {
    return new Observable<SurfaceWaterFacility>(obs => {
      this.findOneLocally(waterSystemId, facilityId).subscribe(wsLocal => {
        if (wsLocal && wsLocal.length > 0) {
          obs.next(wsLocal[0]);
          obs.complete();
        } else {
          obs.next(null);
          obs.complete();
        }
      });
    });
  }

  findLocally(id: number): Observable<SurfaceWaterFacility[]> {
    return new Observable<SurfaceWaterFacility[]>(obs => {
      this.localStorageService.dbRetrieveAllByIndex<SurfaceWaterFacility>(tables.waterSystemSurfaceWaterFacility, 'pwsId', id)
        .pipe(
          switchMap(local => {
            if (local && local.length > 0) {
              return of(local);
            } else {
              return this.localStorageService.dbRetrieveAllByIndex<any>(tables.waterSystemZeroFacilitiesOfType,
                'pwsIdFacilityCode', [id, this.SURFACE_WATER_FACILITY_CODE])
                .pipe(
                  map(l => {
                    if (l && l.length > 0) {
                      return l;
                    } else {
                      return null;
                    }
                  })
                );
            }
          })
        ).subscribe(local => {
          obs.next(local);
          obs.complete();
        });
      });
  }

  findOneLocallyByKey(key: number): Observable<SurfaceWaterFacility> {
    return this.localStorageService.dbRetrieve<SurfaceWaterFacility>(tables.waterSystemSurfaceWaterFacility, key);
  }

  private findOneLocally(waterSystemId: number, facilityId: number): Observable<SurfaceWaterFacility[]> {
    return this.localStorageService.dbRetrieveAllByIndex<SurfaceWaterFacility>(tables.waterSystemSurfaceWaterFacility,
      'pwsIdFacilityId', [waterSystemId, facilityId]);
  }

  private findRemotely(id: number): Observable<Checkout<SurfaceWaterFacility[]>> {
    return this.httpClient.get<Checkout<any[]>>(`${this.WATER_SYSTEM_URL}/${id}/${this.SURFACE_WATER_FACILITY_SUB_ENDPOINT}`);
  }

  private findRemotelyTimer(id: number): Observable<SurfaceWaterFacility[]> {
    const stopTimer$ = timer(10 * 60 * 1000);
    return timer(0, 15000)
      .pipe(
        takeUntil(stopTimer$),
        concatMap(() => this.findRemotely(id).pipe(map(response => response))),
        filter(backendData => backendData.dataReady),
        take(1),
        map(found => {
          const surfaceWaterFacilities: SurfaceWaterFacility[] = this.mapToSurfaceWaterFacilities(found.data);
          this.store(surfaceWaterFacilities);
          if (found.data == null || found.data.length === 0) {
            this.localStorageService.dbStore(tables.waterSystemZeroFacilitiesOfType,
              { 'pwsId': id, 'facilityCode': this.SURFACE_WATER_FACILITY_CODE});
          }
          return surfaceWaterFacilities;
        }),
        catchError(error => {
          if (error.status === 404) {
            this.localStorageService.dbStore(tables.waterSystemZeroFacilitiesOfType,
              { 'pwsId': id, 'facilityCode': this.SURFACE_WATER_FACILITY_CODE});
            return of(error.error);
          } else {
            return throwError(error);
          }
        })
      );
  }

  private mapToSurfaceWaterFacilities(results: any[]): SurfaceWaterFacility[] {
    const surfaceWaterFacilities: SurfaceWaterFacility[] = [];
    if (results && results.length > 0) {
      for (const result of results) {
        const surfaceWaterFacility: SurfaceWaterFacility = {
          pwsId: result.pwsId,
          facilityId: result.facilityId,
          name: result.name,
          facilityCode: result.facilityCode,
          availabilityCode: result.information.availabilityCode,
          statusCode: result.information.statusCode,
          statusReason: result.information.statusReason,
          downstreamFacility: result.information.downstreamFacility,
          facilitySeqNo: result.information.id,
          surveyNote: result.information.surveyNote,
          designCapacity: result.information.designCapacity,
          dcUnitMeasureCode: result.information.dcUnitMeasureCode,
          emergencyCapacity: result.information.emergencyCapacity,
          ecUnitMeasureCode: result.information.ecUnitMeasureCode,
          waterCode: result.information.waterCode
        };
        surfaceWaterFacilities.push(surfaceWaterFacility);
      }
    }
    return surfaceWaterFacilities;
  }

  store(surfaceWaterFacilities: SurfaceWaterFacility[]): void {
    if (surfaceWaterFacilities && surfaceWaterFacilities.length > 1) {
      surfaceWaterFacilities = surfaceWaterFacilities.sort((a, b) => {
        if (a.facilityId > b.facilityId) {
          return 1;
        } else if (a.facilityId < b.facilityId) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    surfaceWaterFacilities.forEach(surfaceWaterFacility => {
      this.localStorageService.dbStore(tables.waterSystemSurfaceWaterFacility, surfaceWaterFacility);
    });
  }

  deleteLocally(id: number): void {
    this.localStorageService.dbRetrieveAllByIndex<SurfaceWaterFacility>(tables.waterSystemSurfaceWaterFacility,
      'pwsId', id).subscribe(results => {
      if (results) {
        for (const facility of results) {
          this.localStorageService.dbRemove(tables.waterSystemSurfaceWaterFacility, facility.facilitySeqNo);
        }
      }
    });
    this.localStorageService.dbRetrieveAllByIndex<any>(tables.waterSystemZeroFacilitiesOfType, 'pwsId', id).subscribe(results => {
      if (results) {
        for (const result of results) {
          this.localStorageService.dbRemove(tables.waterSystemZeroFacilitiesOfType, result.key);
        }
      }
    });
  }

  insertRemotely(surfaceWaterFacility: SurfaceWaterFacility): Observable<any> {
    const remoteFacility = {
      facilityId: surfaceWaterFacility.facilityId,
      facilityCode: surfaceWaterFacility.facilityCode,
      name: surfaceWaterFacility.name,
      pwsId: surfaceWaterFacility.pwsId,
      information: {
        id: surfaceWaterFacility.facilitySeqNo,
        waterSystemId: surfaceWaterFacility.pwsId,
        name: surfaceWaterFacility.name,
        facilityCode: surfaceWaterFacility.facilityCode,
        availabilityCode: surfaceWaterFacility.availabilityCode,
        statusCode: surfaceWaterFacility.statusCode,
        statusReason: surfaceWaterFacility.statusReason,
        downstreamFacility: surfaceWaterFacility.downstreamFacility,
        surveyNote: surfaceWaterFacility.surveyNote,
        designCapacity: surfaceWaterFacility.designCapacity,
        dcUnitMeasureCode: surfaceWaterFacility.dcUnitMeasureCode,
        emergencyCapacity: surfaceWaterFacility.emergencyCapacity,
        ecUnitMeasureCode: surfaceWaterFacility.ecUnitMeasureCode
      }
    };
    return this.httpClient.post(`${this.WATER_SYSTEM_URL}/${surfaceWaterFacility.pwsId}/`
      + `${this.SURFACE_WATER_FACILITY_SUB_ENDPOINT}`, remoteFacility);
  }


  updateRemotely(surfaceWaterFacility: SurfaceWaterFacility): Observable<any> {
    const remoteFacility = {
      facilityId: surfaceWaterFacility.facilityId,
      facilityCode: surfaceWaterFacility.facilityCode,
      name: surfaceWaterFacility.name,
      pwsId: surfaceWaterFacility.pwsId,
      information: {
        id: surfaceWaterFacility.facilitySeqNo,
        waterSystemId: surfaceWaterFacility.pwsId,
        name: surfaceWaterFacility.name,
        facilityCode: surfaceWaterFacility.facilityCode,
        availabilityCode: surfaceWaterFacility.availabilityCode,
        statusCode: surfaceWaterFacility.statusCode,
        statusReason: surfaceWaterFacility.statusReason,
        downstreamFacility: surfaceWaterFacility.downstreamFacility,
        surveyNote: surfaceWaterFacility.surveyNote,
        designCapacity: surfaceWaterFacility.designCapacity,
        dcUnitMeasureCode: surfaceWaterFacility.dcUnitMeasureCode,
        emergencyCapacity: surfaceWaterFacility.emergencyCapacity,
        ecUnitMeasureCode: surfaceWaterFacility.ecUnitMeasureCode,
        waterCode: surfaceWaterFacility.waterCode
      }
    };
    return this.httpClient.put(`${this.WATER_SYSTEM_URL}/${surfaceWaterFacility.pwsId}/`
      + `${this.SURFACE_WATER_FACILITY_SUB_ENDPOINT}/${surfaceWaterFacility.facilityId}`, remoteFacility);
  }

  deleteOne(facility: FacilityAny): Observable<any> {
    if (this.connectivityService.isOnline()) {
      this.deleteOneLocally(facility.facilitySeqNo);
      return this.deleteRemotely(facility);
    } else {
      this.syncSchedulerService.schedule(SyncType.FacilitySurfaceWaterDelete, facility.facilitySeqNo).subscribe();
    }
  }

  deleteOneLocally(id: number): void {
    this.localStorageService.dbRemove(tables.waterSystemSurfaceWaterFacility, id);
  }

  private deleteRemotely(facility: FacilityAny): Observable<any> {
    return this.httpClient.delete(`${this.WATER_SYSTEM_URL}/${facility.pwsId}/`
      + `${this.SURFACE_WATER_FACILITY_SUB_ENDPOINT}/${facility.facilityId}`);
  }

  syncFacilitySurfaceWaterDelete(): void {
    if (this.connectivityService.isOnline) {
      if (this.connectivityService.isOnline()) {
        this.syncSchedulerService.scheduled(SyncType.FacilitySurfaceWaterDelete).subscribe(syncRequests => {
          syncRequests.forEach(syncRequest => {
            this.findOneLocallyByKey(syncRequest.data.id).subscribe(facility => {
              if (facility) {
                this.deleteRemotely(facility).subscribe(() => {
                  this.syncSchedulerService.unschedule(SyncType.FacilitySurfaceWaterDelete, syncRequest.data.id,
                      SyncResultFactory.synced(SyncType.FacilitySurfaceWaterDelete, syncRequest.data.id));
                  this.deleteOneLocally(facility.facilitySeqNo);
                }, error => {
                  this.syncSchedulerService.unschedule(SyncType.FacilitySurfaceWaterDelete, syncRequest.data.id,
                      SyncResultFactory.errorHttp(SyncType.FacilitySurfaceWaterDelete, syncRequest.data.id, error));
                });
              } else {
                this.syncSchedulerService.unschedule(SyncType.FacilitySurfaceWaterDelete, syncRequest.data.id,
                  SyncResultFactory.synced(SyncType.FacilitySurfaceWaterDelete, syncRequest.data.id));
              }
            });
          });
        });
      }
    }
  }
}
