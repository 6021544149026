import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {TreatmentCode} from '../domain/treatment-process';

@Injectable({
  providedIn: 'root'
})
export class TreatmentProcessService {

  private readonly PROCESS_MECHANISM_URL = environment.serverUrl + '/processmechanismtype';
  private readonly OBJECTIVE_URL = environment.serverUrl + '/objectivetype';

  constructor(private httpClient: HttpClient) { }

  /**
   * Gets the treatment process mechanisms from the remote API.
   */
  getProcessMechanisms(): Observable<TreatmentCode[]> {
    return this.httpClient.get<TreatmentCode[]>(`${this.PROCESS_MECHANISM_URL}`);
  }

  /**
   * Gets the treatment objectives from the remote API.
   */
  getObjectives(): Observable<TreatmentCode[]> {
    return this.httpClient.get<TreatmentCode[]>(`${this.OBJECTIVE_URL}`);
  }
}
