<form (submit)="save()" id="note-form">
    <div class="row">
      <div class="col-12">
        <span><strong>Entry Date:</strong></span>
        <br/>
        <span *ngIf="note.id">{{ note.createDate | date: 'MM/dd/yyyy hh:mm a' }}</span>
        <span *ngIf="!note.id">{{ note.createDate | date: 'MM/dd/yyyy' }}</span>
      </div>
    </div>

    <div class="row pt-2">
      <div class="col-12">
        <span><strong>Author:</strong></span>
        <br/>
        <span>{{ note.name }}</span>
      </div>
    </div>

    <div class="row pt-2">
      <div class="form-group col-12">
        <label for="note">Note:</label>
        <textarea class="form-control" id="note" name="note" [(ngModel)]="note.note" maxlength="2000" rows="5"></textarea>
      </div>
    </div>
  
    <button type="submit" class="btn btn-primary">Save</button>
    <button type="button" class="btn btn-outline-secondary ml-4" (click)="cancel()">Cancel</button>
  </form>
  