import { Component, OnDestroy, OnInit } from '@angular/core';
import { Contact } from '../../../domain/contact';
import { ContactsService } from '../contacts.service';
import { Unsubscribable } from 'rxjs';
import { WaterOperator } from '../../../domain/water-operator';
import { OperatorsService } from '../operators.service';

@Component({
  selector: 'app-contacts-summary',
  templateUrl: './contacts-summary.component.html'
})
export class ContactsSummaryComponent implements OnInit, OnDestroy {

  contacts: Contact[];
  waterOperators: WaterOperator[];
  contacts$: Unsubscribable;
  waterOperators$: Unsubscribable;
  error = null;

  constructor(private contactsService: ContactsService,
              private operatorsService: OperatorsService) { }

  ngOnInit(): void {
    this.contacts$ = this.contactsService.contacts$.subscribe(
      contacts => {
        if (contacts.data) {
          this.contacts = contacts.data;
        }
      },
      err => {
        if (this.error == null) {
          this.error = [];
        }
        this.error.push(err);
      }
    );
    this.waterOperators$ = this.operatorsService.operators$.subscribe(
      waterOperators => {
        if (waterOperators.data) {
          this.waterOperators = waterOperators.data;
        }
      },
      err => {
        if (this.error == null) {
          this.error = [];
        }
        this.error.push(err);
      }
    )
  }

  ngOnDestroy() {
    if (this.contacts != null) {
      this.contacts$.unsubscribe();
    }
    if (this.waterOperators$ != null) {
      this.waterOperators$.unsubscribe();
    }
  }
}
