import {Component} from '@angular/core';
import {MessageService} from './message.service';
import {Severity} from './Severity';
import {Message} from './Message';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {

  private readonly ALERT_SEVERITY = {
    [Severity.SUCCESS]: 'alert-success',
    [Severity.ERROR]: 'alert-danger',
    [Severity.WARNING]: 'alert-warning'
  };

  constructor(private messageService: MessageService) { }

  messages() {
    return this.messageService.find();
  }

  class(severity: Severity) {
    return this.ALERT_SEVERITY[severity];
  }

  dismiss(message: Message) {
    this.messageService.remove(message);
  }

}
