<div *ngIf="isLoading" class="pl-3 pt-3">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <span>Loading...</span>
</div>

<form (submit)="facilityInfoForm.checkValidity() ? save() : null" id="facility-form" *ngIf="!isLoading" ngNativeValidate #facilityInfoForm>
  <div class="row" *ngIf="!facility.facilitySeqNo">
    <div class="form-group col-md-6 col-sm-12">
      <label for="facility-type">Facility Type:<span *ngIf="facility.statusCode && facility.statusCode === 'A'"> (required)</span></label>
      <select id="facility-type" class="form-control" name="role" [(ngModel)]="facility.facilityCode"
              [required]="facility.statusCode && facility.statusCode === 'A'">
        <ng-container *ngFor="let type of FACILITY_TYPES">
          <option *ngIf="type.addable" value="{{type.code}}">{{type.value}}</option>
        </ng-container>
      </select>
    </div>
  </div>
  <div class="row" *ngIf="facility.facilitySeqNo">
    <div class="form-group col-md-6 col-sm-12">
      <label for="facility-type">Facility Type:<span *ngIf="facility.statusCode && facility.statusCode === 'A'"> (required)</span></label>
      <select id="facility-type" class="form-control" name="role" [(ngModel)]="facility.facilityCode"
              [required]="facility.statusCode && facility.statusCode === 'A'" disabled="true">
        <option value="{{facility.facilityCode}}" disabled="true">{{facility.facilityCode | facilityType}}</option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="facility-name">Name: (required)</label>
      <input type="text" class="form-control" id="facility-name" name="facility-name" [(ngModel)]="facility.name" required>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="facility-availability">Availability: (required)</label>
      <select id="facility-availability" class="form-control" name="facility-availability" [(ngModel)]="facility.availabilityCode" required>
        <option *ngFor="let type of AVAILABILITY_TYPES" value="{{type.code}}">{{type.value}}</option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="facility-status">Status: (required)</label>
      <select id="facility-status" class="form-control" name="facility-status" [(ngModel)]="facility.statusCode" required>
        <option *ngFor="let type of FACILITY_STATUSES" value="{{type.code}}">{{type.value}}</option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="facility-status-reason">Status Reason:</label>
      <input type="text" class="form-control" id="facility-status-reason" name="facility-status-reason" [(ngModel)]="facility.statusReason" maxlength="500">
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="facility-flows-to">Flows To:<span *ngIf="facility.facilityCode !== 'DS'"> (required)</span></label>
      <select id="facility-flows-to" class="form-control" name="facility-flows-to" [(ngModel)]="flowsTo" [required]="facility.facilityCode !== 'DS'">
        <option *ngFor="let option of facilitiesFlowTo" [ngValue]="option">{{option.name}}</option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label for="design-capacity">Design Capacity:</label>
    </div>
    <div class="input-group col-md-6 col-sm-12 mb-3">
      <input id="design-capacity"
             class="form-control"
             name="design-capacity"
             [(ngModel)]="facility.designCapacity"
             type="number"
             min="0"
             [minnumber]="0"
             max="99999999"
             [maxnumber]="99999999"
             maxlength="8"
             #designCapacityAmount="ngModel"
             numbersOnly>
      <div class="input-group-append">
        <label for="design-capacity-units" hidden="true">Unit of Measure</label>
        <select id="design-capacity-units"
                class="form-control"
                name="design-capacity-units"
                [(ngModel)]="facility.dcUnitMeasureCode">
          <option *ngFor="let type of UNIT_MEASURE_CAPACITY_TYPES" value="{{type.code}}">
            {{type.value}}
          </option>
        </select>
      </div>
      <div class="col-12"></div>
      <div *ngIf="designCapacityAmount.invalid && (designCapacityAmount.dirty || designCapacityAmount.touched)" class="alert alert-danger">
        <div *ngIf="designCapacityAmount.errors.min">
          The value cannot be negative.
        </div>
        <div *ngIf="designCapacityAmount.errors.max">
          The value cannot exceed 99999999.
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <label for="emergency-capacity">Emergency Capacity:</label>
    </div>
    <div class="input-group col-md-6 col-sm-12 mb-3">
      <input id="emergency-capacity"
             class="form-control"
             name="emergency-capacity"
             [(ngModel)]="facility.emergencyCapacity"
             type="number"
             min="0"
             [minnumber]="0"
             max="99999999"
             [maxnumber]="99999999"
             maxlength="8"
             #emergencyCapacityAmount="ngModel"
             numbersOnly>
      <div class="input-group-append">
        <label for="emergency-capacity-units" hidden="true">Unit of Measure</label>
        <select id="emergency-capacity-units"
                class="form-control"
                name="emergency-capacity-units"
                [(ngModel)]="facility.ecUnitMeasureCode">
          <option *ngFor="let type of UNIT_MEASURE_CAPACITY_TYPES" value="{{type.code}}">
            {{type.value}}
          </option>
        </select>
      </div>
      <div class="col-12"></div>
      <div *ngIf="emergencyCapacityAmount.invalid && (emergencyCapacityAmount.dirty || emergencyCapacityAmount.touched)" class="alert alert-danger">
        <div *ngIf="emergencyCapacityAmount.errors.min">
          The value cannot be negative.
        </div>
        <div *ngIf="emergencyCapacityAmount.errors.max">
          The value cannot exceed 99999999.
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="isWellFacility">
    <div class="col-12">
      <label for="design-capacity">Pumping Rate:</label>
    </div>
    <div class="input-group col-md-6 col-sm-12 mb-3">
      <input id="pumping-rate"
             class="form-control"
             name="pumping-rate"
             [(ngModel)]="facility.pumpingRate"
             type="number"
             min="0"
             [minnumber]="0"
             max="9999"
             [maxnumber]="9999"
             maxlength="4"
             #pumpingRateAmount="ngModel"
             numbersOnly>
      <div class="input-group-append">
        <span class="input-group-text">Gallons per Minute</span>
      </div>
      <div class="col-12"></div>
      <div *ngIf="pumpingRateAmount.invalid && (pumpingRateAmount.dirty || pumpingRateAmount.touched)" class="alert alert-danger">
        <div *ngIf="pumpingRateAmount.errors.min">
          The value cannot be negative.
        </div>
        <div *ngIf="pumpingRateAmount.errors.max">
          The value cannot exceed 9999.
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="isBoosterStation">
    <div class="col-12 mb-3">
      <div class="form-check">
        <input id="chlorination"
               class="form-check-input"
               type="checkbox"
               name="chlorination"
               [checked]="facility.chlorination && facility.chlorination === 'Y' ? true : false"
               (change)="facility.chlorination === 'Y' ? facility.chlorination = 'N' : facility.chlorination = 'Y'">
        <label class="form-check-label" for="chlorination">Chlorination</label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
      <label for="facility-notes">Notes:</label>
      <textarea class="form-control" id="facility-notes" name="facility-notes" [(ngModel)]="facility.surveyNote" maxlength="2000" rows="5"></textarea>
    </div>
  </div>

  <div *ngIf="isTreatmentPlant">
    <app-treatment-process-info [facility]="facility" (edit)="editFacilityAdditional($event)"></app-treatment-process-info>
  </div>

  <div *ngIf="isWellFacility">
    <app-iwmz [well]="facility" (edit)="editFacilityAdditional($event)"></app-iwmz>
  </div>

  <input type="submit" class="btn btn-primary" value="Save"/>
  <input type="button" class="btn btn-outline-secondary ml-4" value="Cancel" (click)="cancel()"/>
</form>
