import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AdminRoutingModule } from './admin-routing.module';
import { NotificationsComponent } from './notifications/notifications.component';
import { SharedModule } from '../shared/shared.module';
import { QuestionnairesComponent } from './questionnaires/questionnaires.component';
import { EditQuestionnaireComponent } from './questionnaires/edit-questionnaire/edit-questionnaire.component';
import { QuestionsListComponent } from './questionnaires/questions-list/questions-list.component';
import { EditQuestionComponent } from './questionnaires/edit-question/edit-question.component';
import { AnswersListComponent } from './questionnaires/answers-list/answers-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SanitarySurveySectionTypePipe } from '../shared/sanitary-survey-section-type.pipe';
import { SurveySubcategoryTypePipe } from '../shared/survey-subcategory-type.pipe';
import { AdditionalFindingComponent } from './questionnaires/additional-finding/additional-finding.component';
import { ChipInputComponent } from '../shared/chip-input/chip-input.component';
import { NotificationEmailComponent } from './notifications/notification-email/notification-email.component';

@NgModule({
  declarations: [
    NotificationsComponent,
    QuestionnairesComponent,
    EditQuestionnaireComponent,
    QuestionsListComponent,
    EditQuestionComponent,
    AnswersListComponent,
    AdditionalFindingComponent,
    NotificationEmailComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NgbModule,
    AdminRoutingModule
  ],
  providers: [
    SanitarySurveySectionTypePipe,
    SurveySubcategoryTypePipe
  ]
})
export class AdminModule { }
