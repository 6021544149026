import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Note } from 'src/app/domain/note';
import { Severity } from 'src/app/message/Severity';
import { MessageService } from 'src/app/message/message.service';
import { NotesService } from '../notes.service';
import { WaterSystemNotesService } from 'src/app/water-system/water-system-notes.service';

@Component({
  selector: 'app-edit-note',
  templateUrl: './edit-note.component.html',
  styleUrls: ['./edit-note.component.scss']
})
export class EditNoteComponent implements OnInit {

  public isEditing: boolean = false;
  public note: Note;
  private notes: Note[];

  constructor(private messageService: MessageService,
              private notesService: NotesService,
              private waterSystemNotesService: WaterSystemNotesService,
              private router: Router,
              private route: ActivatedRoute,) { }

  ngOnInit() {
    this.note = history.state.data;
    this.notesService.notes$.subscribe(notes => this.notes = notes);
  }

  save(note: Note) {
    this.isEditing = true;
    this.messageService.clear();
    if (!note.action) {
      note.action = "U";
    }
    const noteIndex = this.notes.findIndex(n => n.id === note.id);
    if (noteIndex > -1) {
      this.notes[noteIndex] = note;
    }
    this.notesService.changeWaterSystemNotes(this.notes);
    this.waterSystemNotesService.save(+note.pwsId, this.notes);
    this.isEditing = false;
    this.router.navigate(['notes'], { relativeTo: this.route.parent });
    this.messageService.add({ severity: Severity.SUCCESS, value: 'Note has been updated.' });
    window.scrollTo(0, 0);
  }

  cancel() {
    this.router.navigate(['notes'], { relativeTo: this.route.parent });
  }
}
