import {Pipe, PipeTransform} from '@angular/core';
import { SANITARY_SURVEY_SECTION_TYPES } from './finding-type';

@Pipe({name: 'sanitarySurveySectionType'})
export class SanitarySurveySectionTypePipe implements PipeTransform {
  transform(code: string): string {
    if (!code || code === '' || !(code in SANITARY_SURVEY_SECTION_TYPES)) {
      return code;
    } else {
      return SANITARY_SURVEY_SECTION_TYPES[code];
    }
  }
}