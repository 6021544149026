import { Component, Input, OnInit } from '@angular/core';
import { Contact } from '../../../../domain/contact';

@Component({
  selector: 'app-inventory-contact-summary',
  templateUrl: './inventory-contact-summary.component.html',
  styleUrls: ['./inventory-contact-summary.component.scss']
})
export class InventoryContactSummaryComponent implements OnInit {

  @Input()
  contacts: Contact[];
  @Input()
  header: String;

  constructor() {
  }

  ngOnInit(): void {
  }

}
