import { Component, OnInit, Input } from '@angular/core';
import { Checkout } from 'src/app/domain/checkout';
import { DistrictContact } from 'src/app/domain/district-contact';
import { ComplianceOfficer } from 'src/app/domain/compliance-officer';

@Component({
  selector: 'app-district-compliance-officer',
  templateUrl: './district-compliance-officer.component.html',
  styleUrls: ['./district-compliance-officer.component.scss']
})
export class DistrictComplianceOfficerComponent implements OnInit {

  @Input() districtContact: Checkout<DistrictContact>;
  @Input() complianceOfficer: Checkout<ComplianceOfficer>;

  public isDistrictComplianceCollapsed = false;

  constructor() { }

  ngOnInit() {
  }

}
