<div class="modal-header">
  <span class="modal-title"><strong>{{ title }}</strong></span>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [innerHtml]="message">
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="decline()">{{ btnCancelText }}</button>
  <button type="button" class="btn btn-danger ml-4" (click)="accept()">{{ btnOkText }}</button>
</div>
