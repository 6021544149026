import { KeycloakService, KeycloakEventType } from 'keycloak-angular';
import { from } from 'rxjs';
import { KeycloakTokenStorage } from '../domain/keycloak';
import { IamService } from './iam.service';
import { StorageService } from './storage.service';
import { RemoteSyncService } from '../remote-sync.service';
import { CacheRemoteApisService } from './cache-remote-apis.service';

export function initializer(
    keycloak: KeycloakService,
    iam: IamService,
    storage: StorageService,
    remote: RemoteSyncService,
    cacheRemoteApis: CacheRemoteApisService) {
  return async () => {
    from(keycloak.keycloakEvents$).subscribe((event) => {
      if (KeycloakEventType.OnAuthSuccess === event.type) {
        iam.storeToken({
          idToken: keycloak.getKeycloakInstance().idToken,
          idTokenParsed: keycloak.getKeycloakInstance().idTokenParsed,
          refreshToken: keycloak.getKeycloakInstance().refreshToken,
          refreshTokenParsed: keycloak.getKeycloakInstance().refreshTokenParsed
        } as KeycloakTokenStorage);
        keycloak.loadUserProfile().then(details =>  {
          iam.storeUserDetails(details);
          storage.populate(details);
          remote.sync(details);
          cacheRemoteApis.cache();
        });
        const roles = keycloak.getUserRoles(false, keycloak.getKeycloakInstance().clientId);
        iam.storeUserRoles(roles);
      }
    });

    return keycloak.init({
      bearerExcludedUrls: ['mn-mdh*'],
      initOptions: {
        checkLoginIframe: false
      }
    });
  };
}
