import { DBConfig } from 'ngx-indexed-db';

export enum tables {
  file = 'file',
  search = 'search',
  waterSystem = 'water_system',
  waterSystemContact = 'water_system_contact',
  waterSystemWell = 'water_system_well',
  waterSystemSurfaceWaterFacility = 'water_system_surface_water_facility',
  waterSystemDistribution = 'water_system_distribution',
  waterSystemStorageFacility = 'water_system_storage_facility',
  waterSystemTreatmentFacility = 'water_system_treatment_facility',
  waterSystemBoosterStation = 'water_system_booster_station',
  waterSystemInterconnectFacility = 'water_system_interconnect_facility',
  waterSystemNote = 'water_system_note',
  waterSystemSiteVisit = 'site_visit',
  waterSystemComplianceOfficer = 'water_system_compliance_officer',
  waterSystemDistrictContact = 'water_system_district_contact',
  waterSystemOperator = 'water_system_operator',
  questionnaire = 'questionnaire',
  sync = 'sync',
  syncStatus = 'sync_status',
  questionnaireAttachment = 'questionnaire_attachment',
  waterSystemFacilitiesFlow = 'water_system_facilities_flow',
  waterSystemFacilities = 'water_system_facilities',
  waterSystemMaxFacilityId = 'water_system_max_facility_id',
  waterSystemZeroFacilitiesOfType = 'water_system_zero_facilities_of_type',
  waterSystemDeficiencies = 'water_system_deficiencies'
}

export function migrationFactory() {
  return {
    19: (db, transaction) => {
      // Remove 'questionnaires' object store as it is replaced by 'questionnaire'.
      db.deleteObjectStore('questionnaires');
    }
  };
}

export const dbConfig: DBConfig  = {
  name: 'dwpsvdb',
  version: 19,
  objectStoresMeta: [{
    store: tables.search,
    storeConfig: { keyPath: 'key', autoIncrement: false },
    storeSchema: []
  }, {
    store: tables.file,
    storeConfig: { keyPath: 'key', autoIncrement: false },
    storeSchema: []
  }, {
    store: tables.waterSystem,
    storeConfig: { keyPath: 'key', autoIncrement: false },
    storeSchema: []
  }, {
    store: tables.waterSystemContact,
    storeConfig: { keyPath: 'key', autoIncrement: false },
    storeSchema: []
  }, {
    store: tables.waterSystemWell,
    storeConfig: { keyPath: 'facilitySeqNo', autoIncrement: false },
    storeSchema: [
      { name: 'pwsId', keypath: 'pwsId', options: { unique: false } },
      { name: 'pwsIdFacilityId', keypath: ['pwsId', 'facilityId'], options: { unique: true } }
    ]
  }, {
    store: tables.waterSystemStorageFacility,
    storeConfig: { keyPath: 'facilitySeqNo', autoIncrement: false },
    storeSchema: [
      { name: 'pwsId', keypath: 'pwsId', options: { unique: false } },
      { name: 'pwsIdFacilityId', keypath: ['pwsId', 'facilityId'], options: { unique: true } }
    ]
  }, {
    store: tables.waterSystemTreatmentFacility,
    storeConfig: { keyPath: 'facilitySeqNo', autoIncrement: false },
    storeSchema: [
      { name: 'pwsId', keypath: 'pwsId', options: { unique: false } },
      { name: 'pwsIdFacilityId', keypath: ['pwsId', 'facilityId'], options: { unique: true } }
    ]
  }, {
    store: tables.waterSystemSurfaceWaterFacility,
    storeConfig: { keyPath: 'facilitySeqNo', autoIncrement: false },
    storeSchema: [
      { name: 'pwsId', keypath: 'pwsId', options: { unique: false } },
      { name: 'pwsIdFacilityId', keypath: ['pwsId', 'facilityId'], options: { unique: true } }
    ]
  }, {
    store: tables.waterSystemBoosterStation,
    storeConfig: { keyPath: 'facilitySeqNo', autoIncrement: false },
    storeSchema: [
      { name: 'pwsId', keypath: 'pwsId', options: { unique: false } },
      { name: 'pwsIdFacilityId', keypath: ['pwsId', 'facilityId'], options: { unique: true } }
    ]
  }, {
    store: tables.waterSystemInterconnectFacility,
    storeConfig: { keyPath: 'facilitySeqNo', autoIncrement: false },
    storeSchema: [
      { name: 'pwsId', keypath: 'pwsId', options: { unique: false } },
      { name: 'pwsIdFacilityId', keypath: ['pwsId', 'facilityId'], options: { unique: true } }
    ]
  }, {
    store: tables.waterSystemNote,
    storeConfig: { keyPath: 'key', autoIncrement: false },
    storeSchema: []
  }, {
    store: tables.waterSystemSiteVisit,
    storeConfig: { keyPath: 'id', autoIncrement: false },
    storeSchema: [
      { name: 'pwsId', keypath: 'pwsId', options: { unique: false } },
    ]
  }, {
    store: tables.waterSystemComplianceOfficer,
    storeConfig: { keyPath: 'key', autoIncrement: false },
    storeSchema: []
  }, {
    store: tables.waterSystemDistrictContact,
    storeConfig: { keyPath: 'key', autoIncrement: false },
    storeSchema: []
  }, {
    store: tables.waterSystemOperator,
    storeConfig: { keyPath: 'key', autoIncrement: false },
    storeSchema: []
  }, {
    store: tables.questionnaire,
    storeConfig: { keyPath: 'id', autoIncrement: false },
    storeSchema: []
  }, {
    store: tables.sync,
    storeConfig: { keyPath: 'key', autoIncrement: true },
    storeSchema: [
      { name: 'type', keypath: 'type', options: { unique: false } },
      { name: 'data', keypath: 'data', options: { unique: false } }
    ]
  }, {
    store: tables.syncStatus,
    storeConfig: {keyPath: 'key', autoIncrement: true },
    storeSchema: [
      { name: 'type', keypath: 'type', options: { unique: false } },
      { name: 'data', keypath: 'data', options: { unique: false } }
    ]
  }, {
    store: tables.questionnaireAttachment,
    storeConfig: { keyPath: 'key', autoIncrement: false },
    storeSchema: [
      { name: 'pwsId', keypath: 'pwsId', options: { unique: false } },
      { name: 'visitId', keypath: 'visitId', options: { unique: false } }
    ]
  }, {
    store: tables.waterSystemFacilitiesFlow,
    storeConfig: { keyPath: 'waterSystemId', autoIncrement: false },
    storeSchema: []
  }, {
    store: tables.waterSystemDistribution,
    storeConfig: { keyPath: 'facilitySeqNo', autoIncrement: false },
    storeSchema: [
      { name: 'pwsId', keypath: 'pwsId', options: { unique: false } },
      { name: 'pwsIdFacilityId', keypath: ['pwsId', 'facilityId'], options: { unique: true } }
    ]
  }, {
    store: tables.waterSystemFacilities,
    storeConfig: { keyPath: 'key', autoIncrement: true },
    storeSchema: [
      { name: 'pwsId', keypath: 'pwsId', options: { unique: false } },
      { name: 'pwsIdFacilityCode', keypath: ['pwsId', 'facilityCode'], options: { unique: false } },
      { name: 'pwsIdFacilityId', keypath: ['pwsId', 'facilityId'], options: { unique: true } }
    ]
  }, {
    store: tables.waterSystemMaxFacilityId,
    storeConfig: { keyPath: 'key', autoIncrement: false },
    storeSchema: []
  }, {
    store: tables.waterSystemZeroFacilitiesOfType,
    storeConfig: { keyPath: 'key', autoIncrement: true },
    storeSchema: [
      { name: 'pwsId', keypath: 'pwsId', options: { unique: false }},
      { name: 'pwsIdFacilityCode', keypath: ['pwsId', 'facilityCode'], options: { unique: true }}
    ]
  }, {
    store: tables.waterSystemDeficiencies,
    storeConfig: { keyPath: 'key', autoIncrement: false },
    storeSchema: []
  }],
  migrationFactory
};
