<div id="report-summary" *ngIf="isOnline; else offlineMsg">
  <div class="border p-2 panel-collapsible-header" *ngIf="!isSiteVisitInspector">
    <a href="javascript:void(0);" (click)="isCollapsed = !isCollapsed"
       [attr.aria-expanded]="!isCollapsed" aria-controls="collapse-report-summary">
      <div>
        <h2>
          Current Site Visit Findings Summary
          <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isCollapsed"></i>
          <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isCollapsed"></i>
        </h2>
      </div>
    </a>
  </div>
  <div id="collapse-report-summary" class="card" [ngbCollapse]="isCollapsed">
    <div class="card-body row">
      <div class="col-12">
        <div *ngIf="anyIsLoading; else viewReportSummary">
          <div class="spinner-border" role="status">
            <span class="sr-only">Getting Current Site Visit Findings Summary...</span>
          </div>
          <span>Getting Current Site Visit Findings Summary...</span>
        </div>
        <ng-template #viewReportSummary>
          <div *ngIf="currentVisit; else noCurrentVisit">
            <div class="row">
              <div class="col-md-3 h3">PWS ID</div>
              <div class="col-md-9">{{ siteVisit?.id }}</div>
            </div>
            <div class="row">
              <div class="col-md-3 h3">System Name</div>
              <div class="col-md-9">{{ siteVisit?.name }}</div>
            </div>
            <div class="row">
              <div class="col-md-3 h3">PWS Type</div>
              <div class="col-md-9">{{ siteVisit?.type | waterSystemType }}</div>
            </div>
            <div class="row">
              <div class="col-md-3 h3">Inspector</div>
              <div class="col-md-9">{{ siteVisit?.fullName }}</div>
            </div>
            <div class="row">
              <div class="col-md-3 h3">Survey Date</div>
              <div class="col-md-9">{{ currentVisit?.visitDate | date: 'MM/dd/yyyy' : "+0000" }}</div>
            </div>
            <div class="row">
              <div class="col-md-3 h3">System Contact</div>
              <div class="col-md-9">{{ systemContact?.name || 'None' }}</div>
            </div>
            <div class="row">
              <div class="col-md-3 h3">People Present</div>
              <div class="col-md-9">{{ currentVisit?.inspection?.peoplePresent || 'None' }}</div>
            </div>
            <div *ngIf="findings && findings.findingCollections && findings.findingCollections.length > 0; else noFindings">
              <div *ngFor="let findingCollection of findings.findingCollections">
                <div class="row">
                  <div class="col-md-3 h3">{{findingCollection.title}}</div>
                  <div class="col-md-9">
                    <div class="card" *ngFor="let findingCategory of findingCollection.findingCategories">
                      <div class="h3 card-header">{{findingCategory.description}}</div>
                      <div class="card-body" *ngFor="let finding of findingCategory.findings">
                        <div class="row">
                          <div class="col-sm-3 col-md-2 font-weight-bold">Category</div>
                          <div class="col-sm-9 col-md-10">{{finding.category}}</div>
                        </div>
                        <div class="row">
                          <div class="col-sm-3 col-md-2 font-weight-bold">Code</div>
                          <div class="col-sm-9 col-md-10">{{finding.code}}</div>
                        </div>
                        <div class="row">
                          <div class="col-sm-3 col-md-2 font-weight-bold">Language</div>
                          <div class="col-sm-9 col-md-10">{{finding.language}}</div>
                        </div>
                        <div class="row" *ngIf="finding.additionalLanguage">
                          <div class="col-sm-3 col-md-2 font-weight-bold">Comments</div>
                          <div class="col-sm-9 col-md-10">{{finding.additionalLanguage}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #noFindings>
              <div class="row">
                <div class="col-md-3 h3">No Findings</div>
              </div>
            </ng-template>
          </div>
          <ng-template #noCurrentVisit>
            <div class="row">
              <div class="col-md-3 h3">No Current Site Visit In Progress</div>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<ng-template #offlineMsg>
  <div class="p-3">Findings Summary not available offline.</div>
</ng-template>
