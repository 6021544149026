import {Injectable} from '@angular/core';
import {SiteVisitGateway} from './site-visit-gateway';
import {SiteVisitCriteria} from './domain/SiteVisitCriteria';
import {WaterSystem} from './domain/water-system';
import {Page} from './domain/page';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import {Es5UriParamCodec} from './utils/Es5UriParamCodec';
import {Observable, throwError} from 'rxjs';
import {PageRequest} from './domain/page-request';
import {catchError} from 'rxjs/operators';
import {SortService} from './sort.service';
import {environment} from '../environments/environment';
import {SiteVisit} from './domain/site-visit';

@Injectable({
  providedIn: 'root'
})
export class SiteVisitRemoteApi implements SiteVisitGateway {

  private readonly siteVisitUrl = environment.serverUrl + '/watersystem';

  constructor(private http: HttpClient, private sortService: SortService) { }

  private static handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    return throwError('Something bad happened; please try again later.');
  }

  search(criteria: SiteVisitCriteria, pageRequest: PageRequest): Observable<Page<SiteVisit>> {
    const rsql: Array<string> = [];
    let orderString: string = 'next_visit';

    if (criteria.districtPartyId !== null) {
      rsql.push(`n-district_party_id==${criteria.districtPartyId}`);
    }

    if (criteria.pwsId !== null && criteria.pwsId !== '') {
      rsql.push(`id==${criteria.pwsId}`);
    }

    if (criteria.nextVisitFrom !== null) {
      rsql.push(`d-next_visit=ge=${criteria.nextVisitFrom}`);
    }

    if (criteria.nextVisitTo !== null) {
      rsql.push(`d-next_visit=le=${criteria.nextVisitTo}`);
    }

    if (criteria.username !== null) {
      rsql.push(`username==${criteria.username}`);
    }

    if (criteria.city != null && criteria.city.length > 0) {
      const cityString = "'" + criteria.city.join("','") + "'";
      rsql.push(`city=in=(${cityString})`);
    }

    if (criteria.pwsName != null && criteria.pwsName !== '') {
      rsql.push(`name=contains='${criteria.pwsName.replace(/'/g, "''")}'`);
    }

    if (criteria.county != null && criteria.county !== '') {
      rsql.push(`county==${criteria.county}`);
    }

    if (criteria.sortOptions) {
      if (criteria.sortOptions.length === 1) {
        orderString = this.sortService.transformColumnName(criteria.sortOptions[0].column) + ':' + criteria.sortOptions[0].direction;
      } else {
        let index = 0;
        for (let option of criteria.sortOptions) {
          if (index === 0) {
            orderString = this.sortService.transformColumnName(option.column) + ':' + option.direction;
          } else {
            orderString = orderString + ';' + this.sortService.transformColumnName(option.column) + ':' + option.direction;
          }
          index = index + 1;
        }
      }
    }

    const params = new HttpParams({ encoder: new Es5UriParamCodec() })
      .set('search', rsql.join(';'))
      .set('index', pageRequest.index.toString())
      .set('limit', pageRequest.limit.toString())
      .set('order', orderString);

    return this.http.get<Page<SiteVisit>>(this.siteVisitUrl, { params: params }).pipe(catchError(SiteVisitRemoteApi.handleError));
  }
}
