<div id="water-system-population-summary" *ngIf="summary && (summary.popJan || summary.popFeb || summary.popMar
|| summary.popApr || summary.popMay || summary.popJun|| summary.popJul || summary.popAug || summary.popSep
|| summary.popOct || summary.popNov || summary.popDec || summary.seasonal || summary.popComments); else noChanges">
  <div class="border p-2 panel-collapsible-header">
    <a href="javascript:void(0);" (click)="isCollapsed = !isCollapsed"
       [attr.aria-expanded]="!isCollapsed" aria-controls="collapse-population">
      <div>
        <h2>
          Population
          <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon" *ngIf="isCollapsed"></i>
          <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon" *ngIf="!isCollapsed"></i>
        </h2>
      </div>
    </a>
  </div>
  <div id="collapse-population" class="card" [ngbCollapse]="isCollapsed">
    <div class="card-body row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-3" *ngIf="summary.popJan">
            <span><strong>January:</strong></span>
            <br/>
            <span>{{summary.popJan}}</span>
          </div>
          <div class="col-12 col-sm-6 col-md-3" *ngIf="summary.popFeb">
            <span><strong>February:</strong></span>
            <br/>
            <span>{{summary.popFeb}}</span>
          </div>
          <div class="col-12 col-sm-6 col-md-3" *ngIf="summary.popMar">
            <span><strong>March:</strong></span>
            <br/>
            <span>{{summary.popMar}}</span>
          </div>
          <div class="col-12 col-sm-6 col-md-3" *ngIf="summary.popApr">
            <span><strong>April:</strong></span>
            <br/>
            <span>{{summary.popApr}}</span>
          </div>
          <div class="col-12 col-sm-6 col-md-3" *ngIf="summary.popMay">
            <span><strong>May:</strong></span>
            <br/>
            <span>{{summary.popMay}}</span>
          </div>
          <div class="col-12 col-sm-6 col-md-3" *ngIf="summary.popJun">
            <span><strong>June:</strong></span>
            <br/>
            <span>{{summary.popJun}}</span>
          </div>
          <div class="col-12 col-sm-6 col-md-3" *ngIf="summary.popJul">
            <span><strong>July:</strong></span>
            <br/>
            <span>{{summary.popJul}}</span>
          </div>
          <div class="col-12 col-sm-6 col-md-3" *ngIf="summary.popAug">
            <span><strong>August:</strong></span>
            <br/>
            <span>{{summary.popAug}}</span>
          </div>
          <div class="col-12 col-sm-6 col-md-3" *ngIf="summary.popSep">
            <span><strong>September:</strong></span>
            <br/>
            <span>{{summary.popSep}}</span>
          </div>
          <div class="col-12 col-sm-6 col-md-3" *ngIf="summary.popOct">
            <span><strong>October:</strong></span>
            <br/>
            <span>{{summary.popOct}}</span>
          </div>
          <div class="col-12 col-sm-6 col-md-3" *ngIf="summary.popNov">
            <span><strong>November:</strong></span>
            <br/>
            <span>{{summary.popNov}}</span>
          </div>
          <div class="col-12 col-sm-6 col-md-3" *ngIf="summary.popDec">
            <span><strong>December:</strong></span>
            <br/>
            <span>{{summary.popDec}}</span>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-12" *ngIf="summary.seasonal">
            <span><strong>Seasonal:</strong></span>
            <br/>
            <span>{{summary.seasonal | seasonalIndYesNoNotverified}}</span>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-12" *ngIf="summary.popComments">
            <span><strong>Comments</strong></span>
            <br/>
            <span>{{summary.popComments}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #noChanges>
  <div>No Population Changes.</div>
</ng-template>
