import { Component, OnInit, Input, QueryList, ViewChildren, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Note } from 'src/app/domain/note';
import { Severity } from 'src/app/message/Severity';
import { MessageService } from 'src/app/message/message.service';
import { NotesService } from './notes.service';
import { NgbdSortableHeader, SortEvent, compare } from '../../../shared/table-sortable';
import { WaterSystemNotesService } from '../../water-system-notes.service';
import { ConfirmatoryActionService } from 'src/app/confirmatory-action/confirmatory-action.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, AfterViewInit {

  @Input() notes: Note[];
  @Input() relatedItem: string;
  @Input() disableAdd: boolean = false;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  public isDeleting: boolean = false;
  public isNotesCollapsed: boolean = false;
  public hasNotes: boolean = false;

  constructor(private messageService: MessageService,
              private notesService: NotesService,
              private waterSystemNotesService: WaterSystemNotesService,
              private changeDetectorRef: ChangeDetectorRef,
              private confirmatoryActionService: ConfirmatoryActionService) { }

  ngOnInit() {
    if (this.notes) {
      this.hasNotes = this.hasActiveNotes(this.notes);
    } else {
      this.notes = [];
    }
  }

  ngAfterViewInit() {
    this.headers.forEach((header: NgbdSortableHeader) => {
      if (header.sortable === 'createDate') {
        header.direction = 'desc';
        header.sort.emit({column: header.sortable, direction: header.direction});
      }
    });
    this.changeDetectorRef.detectChanges();
  }

  remove(id: number) {
    this.confirmatoryActionService.confirm("Confirmation", "Are you sure you want to delete this note?", "Yes", "No", "sm")
      .then((confirmed) => {
        if (confirmed) {
          this.delete(id);
        }
      })
      .catch(() => {});
  }

  delete(id: number) {
    this.isDeleting = true;
    this.messageService.clear();
    const deleteIndex = this.notes.findIndex(n => n.id === id);
    if (deleteIndex > -1) {
      if (this.notes[deleteIndex].action && this.notes[deleteIndex].action === 'I') {
        const pwsId = this.notes[deleteIndex].pwsId;
        this.notes.splice(deleteIndex, 1);
        this.waterSystemNotesService.saveLocally(+pwsId, this.notes);
      } else {
        this.notes[deleteIndex].action = 'D';
        this.waterSystemNotesService.save(+this.notes[deleteIndex].pwsId, this.notes);
      }
    }
    this.notesService.changeWaterSystemNotes(this.notes);
    this.hasNotes = this.hasActiveNotes(this.notes);
    this.isDeleting = false;
    this.messageService.add({ severity: Severity.SUCCESS, value: 'Note has been deleted.' });
    window.scrollTo(0, 0);
  }

  onSort({column, direction}: SortEvent) {
    this.sortDefault(this.notes);
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '' || column === '') {
      this.notes = this.sortDefault(this.notes);
    } else {
      this.notes = [...this.notes].sort((a, b) => {
        if (a[column] instanceof Date) {
          const res = compare(a[column].getTime(), b[column].getTime());
          return direction === 'asc' ? res : -res;
        } else {
          const res = compare(a[column], b[column]);
          return direction === 'asc' ? res : -res;
        }
      });
    }
  }

  sortDefault(notes: Note[]): Note[] {
    if (notes == null) {
      return null;
    } else if (notes.length == 1) {
      return notes;
    }
    notes.sort((a, b) => {
      return new Date(b.createDate).getTime() - new Date(a.createDate).getTime();
    });
    return notes;
  }

  hasActiveNotes(notes: Note[]): boolean {
    if (notes == null || notes.length < 1) {
      return false;
    }

    for (let note of notes) {
      if (this.relatedItem === note.relatedItem || note.createdBy === 'system') {
        if ((note.action && note.action !== 'D') || note.action == null) {
          return true;
        }
      }
    }

    return false;
  }
}
