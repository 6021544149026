import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'whpProgramStatusType'})
export class WhpProgramStatusTypePipe implements PipeTransform {
  transform(value: string): string {
    if (value === 'C') {
      return 'Complete';
    } else if (value === 'U') {
      return 'Unknown';
    } else if (value === 'A') {
      return 'Active';
    } else {
      return value;
    }
  }
}