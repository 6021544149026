import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Checkout} from 'src/app/domain/checkout';
import {WaterOperator} from 'src/app/domain/water-operator';
import {MessageService} from 'src/app/message/message.service';
import {OperatorsService} from '../../operators.service';
import {Severity} from 'src/app/message/Severity';
import {WaterSystemOperatorsService} from 'src/app/water-system/water-system-operators.service';
import {ContactAction} from "../../../../domain/contact";
import {ConnectivityService} from "../../../../connectivity/connectivity.service";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-add-operator',
  templateUrl: './add-operator.component.html',
  styleUrls: ['./add-operator.component.scss']
})
export class AddOperatorComponent implements OnInit {

  private pwsId;
  private operators: Checkout<WaterOperator[]>;
  public isAdding: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private messageService: MessageService,
              private operatorsService: OperatorsService,
              private waterSystemOperatorsService: WaterSystemOperatorsService,
              private connectivityService: ConnectivityService) { }

  ngOnInit() {
    this.isAdding = false;
    this.operatorsService.operators$.subscribe(operators => this.operators = operators);
    this.route.parent.params.subscribe(params => {
      this.pwsId = +params['id'];
    });
  }

  add(operator: WaterOperator) {
    this.messageService.clear();
    this.isAdding = true;
    operator.pwsId = this.pwsId;
    operator.action = ContactAction.Create;
    if (this.operators.data == null || !(this.operators.data instanceof Array)) {
      this.operators.data = [];
    } else {
      if (this.isOperatorAlreadyAffiliated(operator)) {
        this.isAdding = false;
        this.messageService.add({ severity: Severity.ERROR, value: 'The Water Operator is already affiliated with this water system.' });
        window.scrollTo(0, 0);
        return;
      }
    }
    this.operators.data.push(operator);
    if (this.connectivityService.isOnline()) {
      this.waterSystemOperatorsService.saveRemotely(operator.pwsId, this.operators.data).pipe(take(1)).subscribe(() => {
        this.waterSystemOperatorsService.findRemotely(operator.pwsId).pipe(take(1)).subscribe(operators => {
          this.operatorsService.changeWaterSystemOperators(operators);
          this.waterSystemOperatorsService.store(operator.pwsId, operators);
          this.navigateToParent();
        })
      })
    } else {
      this.operatorsService.changeWaterSystemOperators(this.operators);
      this.waterSystemOperatorsService.save(operator.pwsId, this.operators);
      this.navigateToParent();
    }
  }

  navigateToParent(): void {
    this.isAdding = false;
    this.router.navigate(['contacts'], { relativeTo: this.route.parent });
    this.messageService.add({ severity: Severity.SUCCESS, value: 'Water Operator has been added.' });
    window.scrollTo(0, 0);
  }

  cancel() {
    this.router.navigate(['contacts'], { relativeTo: this.route.parent });
  }

  isOperatorAlreadyAffiliated(operator): boolean {
    if (this.operators.data == null || this.operators.data.length < 1) {
      return false;
    }
    const found = this.operators.data.find(o => o.personSeqNo === operator.personSeqNo && o.certificateClass === operator.certificateClass);
    if (found != null) {
      return true;
    } else {
      return false;
    }
  }
}
