<div id="inventory-contacts-summary" *ngIf="isContactsChanged; else noContacts">
  <div class="border p-2 panel-collapsible-header">
    <a href="javascript:void(0);" (click)="isCollapsed = !isCollapsed"
       [attr.aria-expanded]="!isCollapsed" aria-controls="collapse-general">
      <div>
        <h2>
          Contacts/Mailing
          <i class="fas fa-plus-square collapsible-panel-header-expand-collapse-icon"
             *ngIf="isCollapsed"></i>
          <i class="fas fa-minus-square collapsible-panel-header-expand-collapse-icon"
             *ngIf="!isCollapsed"></i>
        </h2>
      </div>
    </a>
  </div>
  <div id="collapse-general" class="card" [ngbCollapse]="isCollapsed">
    <div class="card-body row">
      <div class="col-12">
        <app-inventory-contact-summary [contacts]="createdContacts"
                                       [header]="'Added Contacts'"></app-inventory-contact-summary>
        <app-inventory-contact-summary [contacts]="updatedContacts"
                                       [header]="'Updated Contacts'"></app-inventory-contact-summary>
        <app-inventory-contact-summary [contacts]="deletedContacts"
                                       [header]="'Deleted Contacts'"></app-inventory-contact-summary>
      </div>
    </div>
  </div>
</div>
<ng-template #noContacts>
  <div>No Inventory Contacts/Mailing Changes.</div>
</ng-template>
