import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Page} from '../domain/page';
import {PageRequest} from '../domain/page-request';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  @Input()
  page: Page<any>;

  @Output()
  pageRequested = new EventEmitter<PageRequest>();

  emitEntriesPerPage(entriesPerPage: number) {
    this.emitPageRequest({ index: 0, limit: entriesPerPage });
  }

  emitPageRequest(pageRequest: PageRequest) {
    this.pageRequested.emit(pageRequest);
  }

}
