import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TreatmentPlant } from "../../domain/facility";

@Injectable({
  providedIn: 'root'
})
export class TreatmentService {

  private treatmentFacilities = new BehaviorSubject<TreatmentPlant[]>([]);
  treatments$ = this.treatmentFacilities.asObservable();

  constructor() { }

  changeWaterSystemTreatmentFacilities(treatments: TreatmentPlant[]) {
    this.treatmentFacilities.next(treatments);
  }
}
